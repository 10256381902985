$color-border1: #c71a23;
$color-green: #283360;
$color: #ffffff;
$black: #000;
$color-balck: #202647;
$color-text: #6a6c72;
$color-yellow: #6a6c72;
$color-border: #6a6c72;
$font-size: 15px;
$transition: 0.5s;
$primary: #283360;
$color-gr-br: #283360;
$secondary: #89c540;
$white-color: $color;
$secondary-color: $color-border1;
$black-color: $black;
$border1: #eeeeee;
$border3: #eeeeee;

.testimonial-area {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 63px 0;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        background: #fff;
        // opacity: 0.98;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .sec-title {
        h4 {
            // font-family: "Poppins", "sans-serif";
            font-size: 1.8rem;
            color: $primary;
            line-height: 35px;
            font-weight: 700;
            max-width: 550px;
            margin: auto;
            margin-bottom: 43px;

            @media (max-width: 575px) {
                margin-bottom: 15px;
                font-size: 20px;
            }
        }
    }

    .testimonial-slider {
        .slider-item {
            .desc {
                background-color: #f8f9fc;
                padding: 30px 38px;
                border-radius: 5px;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    border-width: 15px 15px;
                    border-style: solid;
                    border-top-color: rgba(255, 255, 255, 0.08);
                    border-right-color: transparent;
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    top: 100%;
                    left: 47px;
                    z-index: 1;
                }

                h5 {
                    font-size: 18px;
                    color: $border1;
                    margin-bottom: 15px;
                }
            }
        }

        .slider-dot {
            margin-top: 48px !important;

            .swiper-pagination-bullet {
                width: 22px;
                height: 9px;
            }
            display: inline-block;
            margin: 3px;
            opacity: 1 !important;
            border-radius: 5px;
        }
    }
}

.tutor-slider {
    .slick-prev {
        @media (min-width: 1694px) {
            left: 405px !important;
        }
        @media (min-width: 1301px) and (max-width: 1693px) {
            left: 280px !important;
        }
        @media (min-width: 991px) and (max-width: 1300px) {
            left: 200px;
        }
        @media (max-width: 990px) {
            left: 0px;
        }
        z-index: 100;
    }
    .slick-next {
        @media (min-width: 1694px) {
            right: 405px !important;
        }
        @media (min-width: 1300px) and (max-width: 1693px) {
            right: 280px !important;
        }
        @media (min-width: 991px) and (max-width: 1300px) {
            right: 200px;
        }
        @media (max-width: 990px) {
            right: 1px;
        }
        @include phone {
            z-index: 100;
        }
    }
}

.testimonial-slider-item {
    &::after {
        content: "";
        position: absolute;
        left: 18px;
        right: 20px;
        bottom: 0;
        top: 50px;
        background-color: #f6f6f6;
        border-radius: 18px;
        z-index: -1;
    }
    img {
        border-radius: 50%;
        overflow: hidden;
    }
    .border-bottom {
        border-bottom: 1px dotted #000 !important;
    }
}
