.banner-form {
    .title {
        text-align: center;
        margin-bottom: 20px;    
    }
    p.account {
        text-align: center;
        margin-bottom: 0px;
        // color: $primary;
        span {
            cursor: pointer;
        }
    }
    form {
        .form-group {
            margin-bottom: 20px;
        }
        .btn {
            font-weight: 400;
            font-size: 16px;
            border: none;
            padding: 15px 40px;
            position: relative;
            border-radius: 4px;
            z-index: 1;
            text-transform: capitalize;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            width: 100%;
            margin-top: 10px;
        }
        .btn-primary {
            color: $white-color;
            background-color: $secondary-color;
            -webkit-box-shadow: 0 13px 27px 0 rgba(255, 255, 255, 0.25);
            box-shadow: 0 13px 27px 0 rgba(255, 255, 255, 0.25);
            transition: $transition;
            border: 1px solid $secondary-color;
            &:hover {
                background-color: $white-color;
                color: $secondary-color;
            }
        }
    }
}

/* Course Grid */

.course-grid-area {
    padding: 70px 0;
    .course-items {
        @media (min-width: 1800px) {
            // margin-right: 50px;
            margin-left: 30px;
        }
        .course-item {
            border: 1px solid #bdbfc3;
            border-radius: 5px;
            transition: all 0.2s ease;
            overflow: hidden;
            margin-bottom: 30px;
            .a {
                text-decoration: none;
            }
            .course-image {
                width: 100%;
                height: 240px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 5px 5px 0 0;
                position: relative;

                @media (max-width: 767px) {
                    height: 185px;
                }
            }
            .duration {
                position: absolute;
                left: 20px;
                bottom: 20px;
                .title {
                    background: #ffffff;
                    padding: 3px 8px;
                    border-radius: 5px;
                    p {
                        font-size: 12px;
                        color: $color-balck;
                        font-weight: 500;
                        margin-bottom: -4px;
                    }
                    span {
                        font-size: 11px;
                        color: $color-text;
                        font-weight: 500;
                    }
                }
            }
            .course-price {
                p {
                    font-size: 12px;
                    color: #fff;
                    background: #283360;
                    position: absolute;
                    right: 20px;
                    bottom: 10px;
                    padding: 8px 10px;
                    font-weight: 500;
                    border-radius: 5px;
                }
            }
            .course-content {
                background: #fff;
                padding: 20px 25px;
                padding-right: 35px;
                .category {
                    position: absolute;
                    border-radius: 5px;
                    p {
                        font-size: 12px;
                        color: rgb(7, 7, 7);
                        font-weight: 500;
                        background: #e8e8f1;
                        padding: 3px 8px;
                        // min-height: 46px;
                    }
                }
                .lesson {
                    position: absolute;
                    right: 10px;
                }
                .content-detail {
                    margin-top: 50px;
                    min-height: 86px;
                    h6.heading {
                        a {
                            color: $color-balck;
                            font-weight: 600;
                            display: inline-block;
                            margin-bottom: 12px;
                            margin-top: 100px;
                            &:hover {
                                color: $color-green;
                            }
                        }
                    }
                    p.desc {
                        font-size: 14px;
                        color: $color-text;
                        line-height: 25px;
                        padding-bottom: 10px;
                        margin-bottom: 12px;
                    }
                }
                .course-face {
                    .duration,
                    .student {
                        p {
                            font-size: 13px;
                            color: $color-text;
                            i {
                                font-size: 16px;
                                color: $color-green;
                                vertical-align: text-bottom;
                                margin-right: 3px;
                            }
                        }
                    }
                }
            }
            &:hover {
                box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
            }
        }
    }
    @media (max-width: 767px) {
        padding: 40px 0 30px;
    }
}

/* Course Details */

.course-details-area{
    max-width: 1600px;
    margin: 0 auto !important;
    padding: 0 68px !important;
    @media screen and (max-width: 640px){
        padding: 0px !important;
    }

    @media (max-width: 767px) {
        margin-bottom: 40px;
    }

}

.course-details-area, .room-details-area {
    .fixed {
        position: fixed;
        top: 160px;
        right: 220px;
        z-index: 3;
        width: 20%;
    }
    .course-details-top {
        .heading {
            h4 {
                color: $color-balck;
                font-weight: 600;
                line-height: 35px;
                margin-bottom: 25px;
                @media (max-width: 575px) {
                    font-size: 20px;
                }
            }
        }
        .course-top-overview {
            margin-bottom: 25px;
            .overviews {
                .author {
                    margin-right: 20px;
                    padding-right: 20px;
                    margin-top: -3px;
                    border-right: 1px solid $color-border;
                    img {
                        float: left;
                        max-width: 50px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                    .author-name {
                        float: left;
                        margin-top: 3px;
                        h6 {
                            color: $color-black;
                            font-weight: 600;
                            text-transform: uppercase;
                            margin-bottom: 5px;
                            @media (max-width: 767px) {
                                font-size: 14px;
                            }
                        }
                        p {
                            font-size: 15px;
                            color: $color-text;
                            font-weight: 500;
                            @media (max-width: 767px) {
                                font-size: 14px;
                            }
                        }
                    }
                }
                .category {
                    margin-right: 20px;
                    padding-right: 20px;
                    border-right: 1px solid $color-border;
                    h6 {
                        color: $color-black;
                        font-weight: 600;
                        text-transform: uppercase;
                        margin-bottom: 5px;
                        @media (max-width: 767px) {
                            font-size: 14px;
                        }
                    }
                    p {
                        font-size: 15px;
                        color: $color-text;
                        font-weight: 500;
                        @media (max-width: 767px) {
                            font-size: 14px;
                        }
                    }
                }
                .rating {
                    margin-right: 20px;
                    padding-right: 20px;
                    border-right: 1px solid $color-border;
                    h6 {
                        color: $color-black;
                        font-weight: 600;
                        text-transform: uppercase;
                        margin-bottom: 5px;
                        @media (max-width: 767px) {
                            font-size: 14px;
                        }
                    }
                    ul {
                        li {
                            margin-right: 1px;
                            i {
                                font-size: 16px;
                                color: $color-yellow;
                            }
                            &:last-child {
                                font-size: 15px;
                                color: $color-text;
                                font-weight: 500;
                                margin-left: 5px;
                                @media (max-width: 767px) {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .price {
                    h6 {
                        color: $color-black;
                        font-weight: 600;
                        text-transform: uppercase;
                        margin-bottom: 5px;
                        @media (max-width: 767px) {
                            font-size: 14px;
                        }
                    }
                    p {
                        font-size: 15px;
                        color: $color-text;
                        font-weight: 500;
                        @media (max-width: 767px) {
                            font-size: 14px;
                        }
                    }
                }
            }
            @media (max-width: 480px) {
                display: none;
            }
        }
        .course-details-banner {
            margin-bottom: 20px;
            img {
                border-radius: 5px;
            }
        }
        .course-tab-list {
            @media screen and (max-width: 640px){
                padding: 0 16px !important;
            }
            .nav {
                display: inline-block;
                border-radius: 18px;
                // box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
                margin-bottom: 35px;
                @include phone {
                    margin-bottom: 0px !important;
                }
                .nav-item {
                    display: inline-block;
                    a.nav-link {
                        font-size: .9375rem;
                        color: $color-black;
                        font-weight: 600;
                        font-family: inherit;
                        @media (max-width: 991px) {
                            padding: 12px 16px 9px;
                        }
                        &:hover {
                            background: #F0F2F5;
                            border-radius: 8px;
                        }
                    }
                    a.nav-link.active {
                        color: $primary;
                        border-bottom: 3px solid $primary;
                        &:hover {
                            background: none;
                            border-radius: unset;
                        }
                    }
                }
            }
            .tab-content {
                .overview-tab {
                    h5 {
                        font-weight: 500;
                        color: $color-black;
                       padding-bottom: 10px;
                        margin-bottom: 20px;
                        position: relative;
                        @media (max-width: 575px) {
                            font-size: 17px;
                        }
                    }
                    div {
                        font-size: 15px;
                        color: $color-text;
                        line-height: 25px;
                        @media (max-width: 575px) {
                            font-size: 14px;
                        }
                    }
                    .course-desc {
                        margin-bottom: 40px;
                    }
                    .course-feature {
                        margin-bottom: 40px;
                        ul {
                            margin-top: 20px;
                            li {
                                font-size: 14px;
                                color: $color-text;
                                line-height: 25px;
                                margin-bottom: 10px;
                                i {
                                    font-size: 20px;
                                    color: $color-green;
                                    float: left;
                                    height: 40px;
                                    line-height: 27px;
                                    margin-right: 10px;
                                }
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    .course-learn {
                        margin-bottom: 40px;
                        ul {
                            margin-top: 20px;
                            li {
                                font-size: 14px;
                                color: $color-text;
                                line-height: 25px;
                                margin-bottom: 15px;
                                i {
                                    float: left;
                                    color: $color-green;
                                    border: 1px solid $color-border;
                                    width: 35px;
                                    height: 35px;
                                    border-radius: 50%;
                                    text-align: center;
                                    padding-top: 9px;
                                    margin-top: 8px;
                                    margin-right: 15px;
                                }
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    .course-share {
                        ul.social {
                            margin-top: 30px;
                            li {
                                a {
                                    text-align: center;
                                    position: relative;
                                    height: 18px;
                                    display: inline-block;
                                    &:before {
                                        content: "";
                                        position: absolute;
                                        border-width: 9px 17px;
                                        border-style: solid;
                                        border-top-color: transparent;
                                        border-right-color: transparent;
                                        border-left-color: transparent;
                                        top: -18px;
                                        left: 0;
                                        z-index: 1;
                                        transition: all 0.2s ease;
                                    }
                                    &:after {
                                        content: "";
                                        position: absolute;
                                        border-width: 9px 17px;
                                        border-style: solid;
                                        border-right-color: transparent;
                                        border-bottom-color: transparent;
                                        border-left-color: transparent;
                                        bottom: -18px;
                                        left: 0;
                                        z-index: 1;
                                        transition: all 0.2s ease;
                                    }
                                    i {
                                        font-size: 14px;
                                        color: #ffffff;
                                        width: 34px;
                                    }
                                    &:hover {
                                        background-color: $color-green !important;
                                        &:before {
                                            border-bottom-color: $color-green !important;
                                        }
                                        &:after {
                                            border-top-color: $color-green !important;
                                        }
                                    }
                                }
                                &:nth-child(1) {
                                    a {
                                        background-color: #4267b2;
                                        &:before {
                                            border-bottom-color: #4267b2;
                                        }
                                        &:after {
                                            border-top-color: #4267b2;
                                        }
                                    }
                                }
                                &:nth-child(2) {
                                    a {
                                        background-color: #1da1f2;
                                        &:before {
                                            border-bottom-color: #1da1f2;
                                        }
                                        &:after {
                                            border-top-color: #1da1f2;
                                        }
                                    }
                                }
                                &:nth-child(3) {
                                    a {
                                        background-color: #2867b2;
                                        &:before {
                                            border-bottom-color: #2867b2;
                                        }
                                        &:after {
                                            border-top-color: #2867b2;
                                        }
                                    }
                                }
                                &:nth-child(4) {
                                    a {
                                        background-color: #dd1343;
                                        &:before {
                                            border-bottom-color: #dd1343;
                                        }
                                        &:after {
                                            border-top-color: #dd1343;
                                        }
                                    }
                                }
                                &:nth-child(5) {
                                    a {
                                        background-color: #ea4c89;
                                        &:before {
                                            border-bottom-color: #ea4c89;
                                        }
                                        &:after {
                                            border-top-color: #ea4c89;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // .curriculum-tab {
                .course-curriculum {
                    margin-bottom: 40px;
                    h5 {
                        color: $color-black;
                        // font-weight: 600;
                        padding-bottom: 10px;
                        margin-bottom: 20px;
                        position: relative;
                        // &:before {
                        //     position: absolute;
                        //     content: "";
                        //     background: $color-green;
                        //     width: 50px;
                        //     height: 2px;
                        //     bottom: 0;
                        //     left: 0;
                        // }
                        @media (max-width: 575px) {
                            font-size: 17px;
                        }
                    }
                    p {
                        font-size: 15px;
                        color: $color-text;
                        line-height: 25px;
                        @media (max-width: 575px) {
                            font-size: 14px;
                        }
                    }
                }
                .course-element {
                    h5 {
                        color: $color-black;
                        font-weight: 600;
                        padding-bottom: 10px;
                        margin-bottom: 20px;
                        position: relative;
                        // &:before {
                        //     position: absolute;
                        //     content: "";
                        //     background: $color-green;
                        //     width: 50px;
                        //     height: 2px;
                        //     bottom: 0;
                        //     left: 0;
                        // }
                        @media (max-width: 575px) {
                            font-size: 17px;
                        }
                    }
                    .course-item {
                        margin-bottom: 10px;
                        .course-button {
                            border: none;
                            background: transparent;
                            margin-bottom: 15px;
                            display: block;
                            width: 100%;
                            text-align: left;
                            padding: 0;
                            font-size: 18px;
                            color: $color-black;
                            font-weight: 500;
                            span {
                                float: right;
                                font-size: 14px;
                                color: $color-text;
                                font-weight: 400;
                            }
                        }
                        .course-content {
                            max-height: 0;
                            overflow: hidden;
                            transition: max-height 0.2s ease-in-out;
                            span.lectures {
                                font-size: 14px;
                                color: $color-text;
                                font-weight: 400;
                            }
                            ul {
                                li {
                                    border-bottom: 1px solid $color-border;
                                    margin-left: 25px;
                                    padding: 12px 0;
                                    span.play-icon {
                                        font-size: 14px;
                                        color: $color-text;
                                        margin-right: 20px;
                                        i {
                                            color: #1da1f2;
                                            border: 1px solid $color-green;
                                            font-size: 22px;
                                            width: 30px;
                                            height: 30px;
                                            border-radius: 50%;
                                            padding-left: 3px;
                                            text-align: center;
                                            line-height: 29px;
                                            vertical-align: middle;
                                            margin-right: 10px;
                                        }
                                    }
                                    span.content-title {
                                        font-size: 13px;
                                        font-weight: normal;
                                        color: #000;
                                        @media (max-width: 575px) {
                                            font-size: 14px;
                                        }
                                    }
                                    span.content-title.clickable {
                                        cursor: pointer;
                                        color: $primary;
                                        // font-weight: bold;
                                        text-decoration: underline;
                                        &:hover {
                                            color: $secondary;
                                        }
                                    }
                                    span.content-duration {
                                        float: right;
                                        font-size: 14px;
                                        color: $color-text;
                                        line-height: 28px;
                                        font-weight: 400;
                                    }
                                }
                            }
                        }
                        .course-content.show {
                            max-height: 100%;
                            margin-bottom: 40px;
                        }
                        &:last-child {
                            margin-bottom: 40px;
                        }
                    }
                }
                // }
                .instructor-tab {
                    a {
                        color: #283360;
                    }
                    h5 {
                        color: $color-black;
                        // font-weight: 600;
                        padding-bottom: 10px;
                        margin-bottom: 35px;
                        position: relative;
                        font-weight: 500 !important;
                        // &:before {
                        //     position: absolute;
                        //     content: "";
                        //     background: $color-green;
                        //     width: 50px;
                        //     height: 2px;
                        //     bottom: 0;
                        //     left: 0;
                        // }
                        @media (max-width: 575px) {
                            font-size: 17px;
                        }
                    }
                    .instructor-item {
                        margin-bottom: 30px;
                        .instructor-img {
                            img {
                                border-radius: 5px;
                                margin-bottom: 10px;
                            }
                        }
                        .instructor-content {
                            position: relative;
                            .instructor-box {
                                // box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
                                // padding: 25px;
                                // background: #ffffff;
                                // border-radius: 5px;
                                // position: absolute;
                                // top: 32px;
                                // left: -11%;
                                // z-index: 1;
                                box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
                                padding: 15px;
                                background: #ffffff;
                                border-radius: 5px;
                                .top-content {
                                    margin-bottom: 20px;
                                    .instructor-name {
                                        h6 {
                                            color: $color-black;
                                            font-weight: 600;
                                            text-transform: uppercase;
                                            margin-bottom: 12px;
                                            @media (max-width: 575px) {
                                                font-size: 14px;
                                            }
                                        }
                                        p {
                                            font-size: 14px;
                                            font-weight: 500;
                                        }
                                    }
                                    .instructor-social {
                                        // margin-top: 25px;
                                        ul.social {
                                            li {
                                                cursor: pointer;
                                                font-size: 22px;
                                                &:hover {
                                                    color: $color-green;
                                                }
                                                a {
                                                    // text-align: center;
                                                    // position: relative;
                                                    // &:before {
                                                    //     content: "";
                                                    //     position: absolute;
                                                    //     border-width: 8px 14px;
                                                    //     border-style: solid;
                                                    //     border-top-color: transparent;
                                                    //     border-right-color: transparent;
                                                    //     border-left-color: transparent;
                                                    //     top: -16px;
                                                    //     left: 0;
                                                    //     z-index: 1;
                                                    //     transition: all 0.2s ease;
                                                    // }
                                                    // &:after {
                                                    //     content: "";
                                                    //     position: absolute;
                                                    //     border-width: 8px 14px;
                                                    //     border-style: solid;
                                                    //     border-right-color: transparent;
                                                    //     border-bottom-color: transparent;
                                                    //     border-left-color: transparent;
                                                    //     bottom: -16px;
                                                    //     left: 0;
                                                    //     z-index: 1;
                                                    //     transition: all 0.2s ease;
                                                    // }
                                                    i {
                                                        font-size: 13px;
                                                        color: #ffffff;
                                                        width: 28px;
                                                    }
                                                    // &:hover {
                                                    //     background-color: #2867b2 !important;
                                                    //     &:before {
                                                    //         border-bottom-color: #2867b2 !important;
                                                    //     }
                                                    //     &:after {
                                                    //         border-top-color: #2867b2 !important;
                                                    //     }
                                                    // }
                                                }
                                                // &:nth-child(1) {
                                                //     a {
                                                //         background-color: #4267b2;
                                                //         &:before {
                                                //             border-bottom-color: #4267b2;
                                                //         }
                                                //         &:after {
                                                //             border-top-color: #4267b2;
                                                //         }
                                                //     }
                                                // }
                                                // &:nth-child(2) {
                                                //     a {
                                                //         background-color: #1da1f2;
                                                //         &:before {
                                                //             border-bottom-color: #1da1f2;
                                                //         }
                                                //         &:after {
                                                //             border-top-color: #1da1f2;
                                                //         }
                                                //     }
                                                // }
                                                &:nth-child(3) {
                                                    a {
                                                        background-color: #2867b2;
                                                        &:before {
                                                            border-bottom-color: #2867b2;
                                                        }
                                                        &:after {
                                                            border-top-color: #2867b2;
                                                        }
                                                    }
                                                }
                                                &:nth-child(4) {
                                                    a {
                                                        background-color: #dd1343;
                                                        &:before {
                                                            border-bottom-color: #dd1343;
                                                        }
                                                        &:after {
                                                            border-top-color: #dd1343;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .instructor-desk {
                                    p {
                                        font-size: 15px;
                                        line-height: 25px;
                                        @media (max-width: 575px) {
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                        }
                        // .ant-list-split .ant-list-item {
                            // padding: 0px !important;
                        // }
                        .ant-list-item-meta-content {
                            margin-top: 12px;
                        }
                    }
                }
            }
            .review-tab {
                .review-comments {
                    margin-bottom: 40px;
                    h5 {
                        color: $color-black;
                        font-weight: 600;
                        padding-bottom: 10px;
                        margin-bottom: 35px;
                        position: relative;
                        // &:before {
                        //     position: absolute;
                        //     content: "";
                        //     background: $color-green;
                        //     width: 50px;
                        //     height: 2px;
                        //     bottom: 0;
                        //     left: 0;
                        // }
                        @media (max-width: 575px) {
                            font-size: 17px;
                        }
                    }
                    .comment-box {
                        // border-bottom: 1px solid #f0f2f5;
                        padding-bottom: 20px;
                        margin-bottom: 25px;
                        .comment-image {
                            img {
                                max-width: 100px;
                                border-radius: 5px;
                                margin-right: 20px;
                            }
                        }
                        .comment-content {
                            .content-title {
                                .comment-writer {
                                    h6 {
                                        color: $color-black;
                                        font-weight: 600;
                                        // margin-bottom: 10px;
                                        @media (max-width: 575px) {
                                            font-size: 14px;
                                        }
                                    }
                                    h4 {
                                        color: #c71a23;
                                    }
                                    p {
                                        font-size: 12px;
                                        // color: $color-text;
                                        margin-bottom: 5px;
                                    }
                                    ul {
                                        // margin-bottom: 8px;
                                        li {
                                            margin-right: 1px;
                                            i {
                                                font-size: 16px;
                                                color: $color-yellow;
                                            }
                                        }
                                    }
                                }
                                .reply-btn {
                                    .review-btn {
                                        font-size: 14px;
                                        color: $secondary;
                                        background: transparent;
                                        border: 1px solid $secondary;
                                        font-weight: 500;
                                        border-radius: 25px;
                                        padding: 4px 10px 10px;
                                        cursor: pointer;
                                        &:hover {
                                            color: #ffffff;
                                            background: #c71a23;
                                            border-color: #c71a23;
                                        }
                                    }
                                    .liked,
                                    .disliked {
                                        color: #ffffff;
                                        background: #c71a23;
                                        border-color: #c71a23;
                                    }
                                    .loading {
                                        pointer-events: none;
                                        cursor: not-allowed;
                                    }
                                }
                            }
                            .comment-desc {
                                p {
                                    font-size: 12px;
                                    color: $color-text;
                                    // font-family: "Poppins", "sans-serif" !important;
                                    @extend .montserrat-font-family;
                                }
                            }
                        }
                        &:last-child {
                            border-bottom: none;
                            padding-bottom: 0;
                            margin-bottom: 0;
                        }
                    }
                }
                .review-form {
                    h5 {
                        color: $color-black;
                        font-weight: 600;
                        padding-bottom: 10px;
                        margin-bottom: 25px;
                        position: relative;
                        &:before {
                            position: absolute;
                            content: "";
                            background: $color-green;
                            width: 50px;
                            height: 2px;
                            bottom: 0;
                            left: 0;
                        }
                        @media (max-width: 575px) {
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }

}

.single-details-sidbar {
    // @media (min-width: 67.5em){
    //     display: block;
    //     position: absolute;
    //     top: 3.2rem;
    //     margin-left: 69.6rem;
    // }
    // @include media(laptop, tablet) {
    //     display: block;
    //     position: fixed;
    //     top: 6.6rem;
    //     z-index: 2;
    // }

    // opacity: 0;
    .course-details-feature {
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 15px 20px 20px;
        // h5.title {
        //     color:$color-green;
        //     font-weight: 600;
        //     padding-bottom: 10px;
        //     margin-bottom: 20px;
        //     position: relative;
        //     &:before {
        //         position: absolute;
        //         content: "";
        //         background: $color-green;
        //         width: 50px;
        //         height: 2px;
        //         bottom: 0;
        //         left: 0;
        //     }
        //     @media(max-width: 575px) {
        //         font-size : 17px;
        //     }
        // }
        ul.feature-list {
            margin-bottom: 20px;
            li {
                border-top: 1px dashed $color-green !important;
                padding: 12px 0;
                font-size: 14px;
                color: $color-green;
                font-weight: 500;
                i {
                    font-size: 20px;
                    color: $color-green;
                    vertical-align: top;
                    margin-right: 2px;
                    margin-top: 4px !important;
                }
                span {
                    float: right;
                    font-size: 13px;
                    color: $color-green;
                    font-weight: 400;
                    line-height: 20px;
                }
                &:first-child {
                    border-top: none;
                    padding-top: 0;
                }
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
        .enroll-btn {
            font-size: 16px;
            color: #fff;
            background: $secondary;
            display: block;
            width: 100%;
            height: 40px;
            font-weight: 500;
            border: none;
            padding: 9px;
            border-radius: 5px;
            &:hover {
                background: #ffffff;
                border: 1px solid $secondary;
                color: $secondary;
            }
            @media (max-width: 575px) {
                font-size: 14px;
            }
        }
        .start-learning-btn {
            text-align: center;
            cursor: pointer;
            font-size: 16px;
            color: #ffffff;
            background: $secondary;
            display: block;
            width: 100%;
            height: 40px;
            font-weight: 500;
            border: none;
            padding: 9px;
            border-radius: 5px;
            a {
                color: #ffffff;
                text-decoration: none;
            }
            &:hover {
                background: #ffffff;
                border: 1px solid $secondary;
                color: $secondary;
                a {
                    color: $secondary;
                    text-decoration: none;
                }
            }
            @media (max-width: 575px) {
                font-size: 14px;
            }
        }
        @media (max-width: 1199px) {
            padding: 12px 15px 15px;
        }
        @include phone{
            padding: 0px;
        }
        .ant-card-body {
            padding: 0;
        }
        .course-preview-cover-card {
            @include media(laptop, tablet) {
                @media (min-width: 821px) {
                    height: 220px;
                    width: 220px;
                }
            }
        }
    }
    .sticky {
        // @media (min-width: 768px) {
        position: fixed;
        margin-top: 5%;
        top: 4%;
        // width: 17%;
        background: #fff;
        z-index: 3;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        // }
    }
}

.modal-rate .ant-rate {
    position: relative !important;
    left: 11% !important;
}

.modal-rate li.ant-rate-star {
    font-size: 64px !important;
}

@media (max-width: 767px) {
    .modal-rate .ant-rate {
        left: 7% !important;
    }
    .modal-rate li.ant-rate-star {
        font-size: 44px !important;
    }
}

.spin {
    text-align: center;
    position: absolute;
    top: 15%;
    left: 47%;
}
.course-details-page
    .ant-list-split.ant-list-something-after-last-item
    .ant-spin-container
    > .ant-list-items
    > .ant-list-item {
    margin-top: 18px;
}

.course-details-page {
    .ant-list-item-action li .review-btn {
        margin: 2px;
        border: 1px solid #000 !important;
        padding: 8px !important;
        border-radius: 50% !important;
        cursor: pointer;
    }

    .ant-list-item-action li .review-btn.selected {
        background: #000 !important;
        color: #fff !important;
    }   

    .ant-list-item-action li .review-btn:hover {
        background: #000 !important;
        color: #fff !important;
    }   

    .ant-list-item-action-split {
        display: none !important;
    }

    .ant-breadcrumb a, .ant-breadcrumb li:last-child {
        // color: #822727 !important;
        color:#2d5e05 !important;
        font-weight: 600;
    }

    .border-line{
        @include phone{
            position: unset;
        }
        position: absolute;
        right: 0;
        left: 0;
        padding-top: 5rem;    
        border-bottom: 1px solid #f0f0f0;
    }

    .ant-tabs-top > .ant-tabs-nav::before{
        border-bottom: none;
    }

    .course-desktop-banner{
        background-color:#89c540e0 ;
        width: 100%;
        height: 300px;

        @media screen and (max-width: 640px){
            height: auto;
        }
    }
}
.view-more {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.preview-video-modal {
    .plyr {
        height: 300px !important;
    }
    .ant-modal-title {
        span {
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: -0.02rem;
            font-size: 1.2rem;
        }
    }
}

.img-fluid,
.img-thumbnail {
    max-width: 100% !important;
    height: auto !important;
}

form.form.review-comment-form {
    .star-rating {
        display: inline-block;
        margin-bottom: 15px;
        input {
            display: none;
        }
        label {
            font-size: 30px;
            color: $color-text;
            margin-right: 5px;
            float: right;
            transition: all 0.1s ease;
            cursor: pointer;
        }
        input:checked ~ label,
        input:not(:checked) ~ label:hover,
        input:not(:checked) ~ label:hover ~ label {
            color: $color-green;
        }
    }
    p.form-control {
        padding: 0;
        width: auto;
        height: auto;
        background: transparent;
        border: none;
        margin-bottom: 30px;
        position: relative;
        textarea {
            width: 100%;
            height: 150px;
            background: transparent;
            border: 1px solid $color-border;
            font-size: 15px;
            padding: 15px 20px;
            color: $color-black;
            border-radius: 5px;
            &:focus {
                border-color: $color-green;
            }
            &::placeholder {
                font-size: 15px;
                color: $color-text;
            }
        }
        input {
            width: 100%;
            height: 50px;
            background: transparent;
            border: 1px solid $color-border;
            font-size: 15px;
            padding: 15px 20px;
            color: $color-black;
            border-radius: 5px;
            &:focus {
                border-color: $color-green;
            }
            &::placeholder {
                font-size: 15px;
                color: $color-text;
            }
        }
        span {
            color: $color-green;
            font-weight: 500;
            position: absolute;
            bottom: -22px;
            left: 0;
            visibility: hidden;
        }
    }
    p.form-control.success {
        textarea,
        input {
            border: 2px solid $color-green;
        }
        &::before {
            position: absolute;
            content: "\f058";
            font-family: "Line Awesome Free";
            font-size: 24px;
            color: $color-green;
            font-weight: 900;
            top: 8px;
            right: 10px;
        }
    }
    p.form-control.error {
        textarea,
        input {
            border: 2px solid $color-green;
        }
        &::before {
            position: absolute;
            content: "\f06a";
            font-family: "Line Awesome Free";
            font-size: 24px;
            color: $color-green;
            font-weight: 900;
            top: 8px;
            right: 10px;
        }
    }
    p.form-control.error {
        span {
            visibility: visible;
        }
    }
    button {
        font-size: 15px;
        color: #fff;
        background: $color-green;
        width: 200px;
        height: 50px;
        border: none;
        font-weight: 500;
        border-radius: 5px;
        margin-top: 5px;
        &:hover {
            background: $color-green;
        }
    }
}

.course-data-wrapper {
    .logo-wrapper {
        img {
            max-width: 100px;
            max-height: 110px;
        }
    }
    .actions-ellipsis {
        font-size: 18px;
    }
    .right-circle-filled {
        color: #283360;
        margin-top: 7px;
        font-size: 28px;
    }
}

.rating-star {
    cursor: pointer;
}

.loading-spin,
.ant-spin-text {
    top: 70%;
    left: 47%;
    position: absolute;
    z-index: 12;
}

.loading-spin {
    font-size: 64px;
    top: 60%;
    left: 47%;
    position: absolute;
    z-index: 12;
}

.content-duration {
    .content-title {
        span {
            text-decoration: underline;
            cursor: pointer;
            color: #283360;
        }
    }
}

.video-layout {
    .ant-layout-content {
        background: #fff !important;
    }
    .site-layout-background {
        background: #fff !important;
    }
}

.reply-btn {
    position: absolute;
    right: 0;
}

.video-next-btn {
    z-index: 100;
    position: absolute;
    right: 5%;
    svg {
        font-size: 10px;
    }
}

.video-next-btn:focus {
    box-shadow: none !important;
}

.play-button {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: url("../../images/playbtn.svg") no-repeat;
    background-size: auto 30%;
    background-position: center;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 250ms ease-in-out;
    -moz-transition: opacity 250ms ease-in-out;
    -ms-transition: opacity 250ms ease-in-out;
    -o-transition: opacity 250ms ease-in-out;
    transition: opacity 250ms ease-in-out;
    -webkit-transition: -webkit-transform 250ms ease-in-out;
    -moz-transition: -moz-transform 250ms ease-in-out;
    -o-transition: -o-transform 250ms ease-in-out;
    transition: transform 250ms ease-in-out;
}

.course-image:hover{
    .play-button {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1.11);
        -ms-transform: scale(1.11);
        -o-transform: scale(1.11);
        transform: scale(1.11);
    }
}

.course-image {
    height: auto;
    display: block;
    position: relative;
    background: #050505;
    overflow: hidden;
}

.course-image:hover {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
}

.quiz-section {
    .see-more {
        background-color: #cb3b56 !important;
        font-size: 13px;
    }
}

.border-right {
    border-right: 1px solid #000;
}

.question-section {
    .answer-section {
        display: flex;
        .option-count {
            margin-top: 10px;
            border-right: 1px solid #f0f2f5;
            padding: 10px;
        }
        .option-collection {
            padding: 5px;
            margin-top: 16px;
            width: 100%;
        }
        li {
            list-style: none;
            margin-top: 4px;
            color: #6c757d;
        }
    }
}

@media only screen and (max-width: 767px) {
    .quiz-summary-modal {
        h3 {
            font-size: 16px;
        }
    }
}

.quiz-summary-modal {
    margin: 0 auto !important;
    z-index: 1;
    position: relative !important;
    padding-top: 0px !important;
    border-radius: 8px !important;
}

.mocktest-list {
    .mocktest-item {
        background-color: rgb(247, 244, 244);
        &:hover{
            transform: scale(1.05);
            transition: 100ms;
        }
    }
    
    a {
        font-size: 16px;
        font-weight: bold;
        color: #284664;
        line-height: 1.2;
        &:hover {
            color: #283360 !important;
        }
    }
    span {
        font-weight: 500;
        font-size: 0.76rem;
        .course {
            &:hover {
                color: #283360;
            }
        }
    }
}

.time {
    min-width: 48px;
    text-align: center;
    background: rgb(102, 102, 102);
    color: #fff !important;
    line-height: 24px;
    height: 24px;
    margin-left: 8px;
    cursor: pointer;
}
.discount-badge {
    font-size: 11px;
    position: absolute;
    right: -5px;
    top: -258px;
    .ant-ribbon {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        background-color: #89c540 !important;
    }
    .ant-ribbon-text {
        color: #fff !important;
        font-weight: 550 !important;
    }
    .ant-ribbon-corner {
        display: none !important;
    }
}

.course-card {
    scroll-behavior: smooth;
    &:hover {
        box-shadow: 0 10px 30px rgb(0 0 0 / 9%);
    }
}
.note-card-item {
    .card {
        background: #ffe28c;
        .card-header-toolbar {
            background: transparent;
            border-radius: 0;
            padding: 10px;
            margin-bottom: 0;
            align-items: center !important;
        }
        .card-body {
            padding: 10px;
        }
    }
}

.text-control {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}
.ellipsis-clamp-2 {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}

.course-single-name {
    // @extend .text-control;
    // font-size: 1.1rem;
    // line-height: 1.5rem;
    // margin-bottom: 4px;
    color: #333333;
    font-size: $font-size-16 !important;
    @extend .montserrat-font-family;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}
.blog-single-name {
    @extend .text-control;
    font-size: 1.2rem;
}
.blog-description {
    @extend .text-control;
    font-family: "Roboto", sans-serif;
    -webkit-line-clamp: 3;
}
.course-preview-poster {
    position: relative;
}

.course-preview-play {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;
    font-size: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover,
    &:focus {
        transform: translateY(-3px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
}
.preview-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0.5em 0.5em 0.5em 1em;
    background: rgb(120 116 116 / 80%);
    color: #fffbfb;
    font-size: 16px;
    line-height: 1;
    margin-left: 1px;
    text-align: center;
    cursor: pointer;
}

.course-preview-thumbnail {
    .ant-upload.ant-upload-drag {
        height: auto;
    }
}

.course-sidebar {
    .vx-checkbox-con {
        input {
            &:checked {
                ~ .vx-checkbox {
                    border-color: #283360 !important;
                    .vx-checkbox--check {
                        background-color: #283360 !important;
                    }
                }
                &:active:checked + .vx-checkbox {
                    .vx-checkbox--check {
                        background-color: #283360 !important;
                        .vx-icon {
                            opacity: 1;
                            transform: translate(6px);
                        }
                    }
                }
            }
        }
    }
    @media (min-width: 821px) {
        margin-right: 20px;
    }
    @media (min-width: 1800px) {
        margin-left: 50px;
    }
}
.course-item-card {
    background-color: #fff;
    border: 1px solid #f8f6f6 !important;
    cursor: pointer;
    height: 320px;
    min-height: 0;
    position: relative;
    transition: 0.5s ease-in-out;
    width: 95%;
    @media (max-width: 767px) {
        width: 100%;
    }
    z-index: 1;
    box-shadow: 0 0 12px 0 rgb(0 0 0 / 9%) !important;
    .ant-card-body {
        padding: 12px 12px 12px 12px !important;
    }
    &:hover {
        z-index: 2;
        outline: none;
        .go-to-course {
            transition: 0.5s ease-in-out;
            display: block;
        }
        .heart-fill {
            display: block;
        }
        .heart-outline {
            display: block;
        }
    }
    .course-instructor {
        color: #333333;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        span {
            font-size: $font-size-14 !important;
        }
    }
    .price-text {
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        color: #3c4043;
        font-family: Google Sans, Roboto, Arial, sans-serif;
        font: 300 0.9rem/2.4rem Google Sans, Arial, sans-serif;
        letter-spacing: 0.5px;
        line-height: 12px;
        margin: 0;
    }
    .go-to-course {
        display: none;
    }
    .heart-fill {
        display: none;
    }
    .heart-outline {
        display: none;
    }
}

.course-description-contents {
    @extend .montserrat-font-family;
    p,
    ul,
    li {
        color: #646f79 !important;
        font-size: .875rem;
    }
}
.mock-test-course {
    height: 250px !important;
}
.transfer-video-modal {
    position: absolute;
    top: 40px;
    margin: auto;
    z-index: 100;
    left: 6px;
    display: none;
}
