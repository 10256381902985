/*
@File: Learnsic Template Styles


/* Default CSS
=================================================*/

@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300,400,500,600,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
$main-font-family: "Montserrat", "sans-serif";
$primary-color: #283360;
$secondary-color: #89c540;
$white-color: #ffffff;
$black-color: #000000;
$paragraph-color: #6a6c72;
$gray-600: #b8c2cc; // $gray
$font-size: 13px;
$transition: 0.5s;
$primary: $primary-color;
$secondary: $secondary-color;
$char-w: 1.1ch;
$gap: 0.5 * $char-w;
$n-char: 6;
$in-w: $n-char * ($char-w + $gap);
$n-char-ph: 8;
$in-w-ph: $n-char-ph * ($char-w + $gap);

body {
    padding: 0;
    margin: 0;
    @extend .montserrat-font-family;
    line-height: 1.75 !important;
    font-size: $font-size;
    color: $paragraph-color;
}
.bg-primary {
    background-color: $primary-color !important;
}
.bg-secondary {
    background-color: $secondary !important;
}

html {
    scroll-behavior: smooth !important;
    --webkit-scroll-behavior: smooth;
}

img {
    max-width: 100%;
    height: auto;
}

button {
    outline: 0 !important;
}

.d-table {
    width: 100%;
    height: 100%;
    margin-top: 7%;
    &-cell {
        vertical-align: middle;
    }
}

.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    }
}

.pt-100 {
    padding-top: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pb-0 {
    padding-bottom: 0;
}

.mb-30 {
    margin-bottom: 30px;
}
.mb-100 {
    margin-bottom: 200px;
}
.mt-50 {
    margin-top: 100px;
}
.mt-25 {
    margin-top: 50px;
}
.mtb-50 {
    margin-top: 100px;
    margin-bottom: 100px;
}

a {
    transition: $transition;
    color: $black-color;
    text-decoration: none;
    &:hover {
        text-decoration: none;
        color: $secondary-color;
    }
    &:focus {
        outline: 0;
    }
}

p,
ul {
    // font-size: $font-size;
    // line-height: 1.7;
    // color: $paragraph-color;
    // margin-bottom: 14px;
    font-size: 15px;
    color: #6a6c72;
    line-height: 25px;
    margin-bottom: 40px;
    &:last-child {
        margin-bottom: 0;
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    color: $primary-color;
}

h1 {
    font-size: 30px !important;
    font-weight: bold;
    color: $primary-color;

    @include phone {
        font-size: 24px !important;
    }
}

h1.title {
    font-size: 48px !important;
    color: $primary-color;
    font-style: normal !important;
    letter-spacing: -0.1px;
}

.text-primary {
    color: $primary !important;
}

.text-secondary {
    color: $secondary !important;
}

.text-black {
    color: $black-color !important;
}

.default-btn-one {
    display: inline-block;
    padding: 13px 30px;
    color: $white-color;
    text-transform: capitalize;
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    transition: $transition;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    text-decoration: none;
    &:hover {
        background-color: $white-color;
        color: $secondary-color;
    }
}

.section-title {
    text-align: center;
    margin-bottom: 50px;
    span {
        font-size: 14px;
        color: $secondary-color;
        font-weight: 500;
        text-transform: uppercase;
        display: block;
        margin-bottom: 5px;
    }
    h3 {
        font-size: 32px;
    }
    h4 {
        font-size: 28px;
    }
    h3,
    h4 {
        // color: $secondary;
        // font-weight: bold;
        // margin: 0;
        // position: relative;
        // padding-bottom: 20px;
        // font-family: "Poppins", "sans-serif";
        @extend .montserrat-font-family;
        font-size: $font-size-32 !important;
        color: #283360;
        font-weight: 700;
        // margin: 0 0 15px;
        position: relative;
        // padding-bottom: 20px;
        // &::before {
        //     position: absolute;
        //     content: "";
        //     height: 3px;
        //     width: 112px;
        //     background-color: $primary;
        //     bottom: 0;
        //     left: 0;
        //     right: 5px;
        //     margin: auto;
        // }
        // &::after {
        //     position: absolute;
        //     content: "";
        //     height: 3px;
        //     width: 32px;
        //     background-color: $primary;
        //     bottom: 0;
        //     margin: 0 auto 0;
        //     left: 95px;
        //     right: 0;
        // }
    }
}

.bg-fafafa {
    background-color: #fafafa;
}

/* Preloader Area CSS
=================================================*/

.preloader-deactivate {
    display: none;
}

.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999999999;
    background: $secondary-color;
    top: 0;
    left: 0;
    .loader {
        position: absolute;
        top: 43%;
        left: 0;
        right: 0;
        -webkit-transform: translateY(-43%);
        transform: translateY(-43%);
        text-align: center;
        margin: 0 auto;
        width: 50px;
        height: 50px;
    }
    .box {
        width: 100%;
        height: 100%;
        background: #ffffff;
        -webkit-animation: animate 0.5s linear infinite;
        animation: animate 0.5s linear infinite;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 3px;
    }
    .shadow {
        width: 100%;
        height: 5px;
        background: $white-color;
        opacity: 0.1;
        position: absolute;
        top: 59px;
        left: 0;
        border-radius: 50%;
        -webkit-animation: shadow 0.5s linear infinite;
        animation: shadow 0.5s linear infinite;
    }
}

@-webkit-keyframes loader {
    0% {
        left: -100px;
    }
    100% {
        left: 110%;
    }
}

@keyframes loader {
    0% {
        left: -100px;
    }
    100% {
        left: 110%;
    }
}

@-webkit-keyframes animate {
    17% {
        border-bottom-right-radius: 3px;
    }
    25% {
        -webkit-transform: translateY(9px) rotate(22.5deg);
        transform: translateY(9px) rotate(22.5deg);
    }
    50% {
        -webkit-transform: translateY(18px) scale(1, 0.9) rotate(45deg);
        transform: translateY(18px) scale(1, 0.9) rotate(45deg);
        border-bottom-right-radius: 40px;
    }
    75% {
        -webkit-transform: translateY(9px) rotate(67.5deg);
        transform: translateY(9px) rotate(67.5deg);
    }
    100% {
        -webkit-transform: translateY(0) rotate(90deg);
        transform: translateY(0) rotate(90deg);
    }
}

@keyframes animate {
    17% {
        border-bottom-right-radius: 3px;
    }
    25% {
        -webkit-transform: translateY(9px) rotate(22.5deg);
        transform: translateY(9px) rotate(22.5deg);
    }
    50% {
        -webkit-transform: translateY(18px) scale(1, 0.9) rotate(45deg);
        transform: translateY(18px) scale(1, 0.9) rotate(45deg);
        border-bottom-right-radius: 40px;
    }
    75% {
        -webkit-transform: translateY(9px) rotate(67.5deg);
        transform: translateY(9px) rotate(67.5deg);
    }
    100% {
        -webkit-transform: translateY(0) rotate(90deg);
        transform: translateY(0) rotate(90deg);
    }
}

@-webkit-keyframes shadow {
    50% {
        -webkit-transform: scale(1.2, 1);
        transform: scale(1.2, 1);
    }
}

@keyframes shadow {
    50% {
        -webkit-transform: scale(1.2, 1);
        transform: scale(1.2, 1);
    }
}

/* Navbar Area CSS
=================================================*/

.learnsic-nav {
    background-color: transparent;
    padding: {
        // top: 10px;
        // bottom: 10px;
        right: 0;
        left: 0;
    }
    .navbar {
        padding: {
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            margin: {
                left: auto;
                right: auto;
            }
            .nav-item {
                position: relative;
                padding: {
                    top: 15px;
                    bottom: 15px;
                    left: 0;
                    right: 0;
                }
                a {
                    font: {
                        size: $font-size;
                        weight: 500;
                    }
                    color: $secondary;
                    text-transform: capitalize;
                    text-decoration: none;
                    padding: {
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                    margin: {
                        right: 20px;
                    }
                    &:hover,
                    &:focus,
                    &.active {
                        color: $secondary-color;
                    }
                    i {
                        font-size: 10px;
                        position: relative;
                        top: -1px;
                        margin-left: 1px;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }
                &:hover,
                &:focus,
                &.active {
                    a {
                        color: $secondary-color;
                    }
                }
                // .dropdown-menu {
                //     box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                //     background: $white-color;
                //     position: absolute;
                //     border: none;
                //     top: 80px;
                //     left: 0;
                //     width: 230px;
                //     z-index: 99;
                //     display: block;
                //     opacity: 0;
                //     visibility: hidden;
                //     border-radius: 0;
                //     transition: all 0.3s ease-in-out;
                // padding: {
                //     top: 20px;
                //     left: 25px;
                //     right: 25px;
                //     bottom: 20px;
                // }
                // li {
                //     padding: 0;
                // a {
                //     text-transform: capitalize;
                //     padding: 8px 0;
                //     margin: 0;
                //     color: #131f58;
                //     text-decoration: none;
                //     font: {
                //         size: $font-size;
                //     }
                //     &:hover, &:focus, &.active {
                //         color: $secondary-color;
                //     }
                // }
                // .dropdown-menu {
                //     left: -245px;
                //     top: 0;
                //     opacity: 0;
                //     visibility: hidden;
                // li {
                // a {
                //     color: #131f58;
                //     &:hover, &:focus, &.active {
                //         color: $secondary-color;
                //     }
                // }
                // .dropdown-menu {
                //     left: -245px;
                //     top: 0;
                //     opacity: 0;
                //     visibility: hidden;
                // li {
                // a {
                //     color: #131f58;
                //     text-transform: capitalize;
                //     &:hover, &:focus, &.active {
                //         color: $secondary-color;
                //     }
                // }
                // .dropdown-menu {
                //     left: -245px;
                //     top: 0;
                //     opacity: 0;
                //     visibility: hidden;
                //     li {
                // a {
                //     color: #696997;
                //     text-transform: capitalize;
                //     &:hover, &:focus, &.active {
                //         color: $secondary-color;
                //     }
                // }
                // .dropdown-menu {
                //     left: 195px;
                //     top: 0;
                //     opacity: 0;
                //     visibility: hidden;
                //     li {
                // a {
                //     color: #696997;
                //     text-transform: capitalize;
                //     &:hover, &:focus, &.active {
                //         color: $secondary-color;
                //     }
                // }
                // .dropdown-menu {
                //     left: 195px;
                //     top: 0;
                //     opacity: 0;
                //     visibility: hidden;
                //     li {
                // a {
                //     color: #696997;
                //     text-transform: capitalize;
                //     &:hover, &:focus, &.active {
                //         color: $secondary-color;
                //     }
                // }
                // .dropdown-menu {
                //     left: 195px;
                //     top: 0;
                //     opacity: 0;
                //     visibility: hidden;
                //     li {
                //         a {
                //             color: #696997;
                //             text-transform: capitalize;
                //             &:hover, &:focus, &.active {
                //                 color: $secondary-color;
                //             }
                //         }
                // }
                // }
                // &.active {
                //     a {
                //         color: $secondary-color;
                //     }
                // }
                // &:hover {
                //     .dropdown-menu {
                //         opacity: 1;
                //         visibility: visible;
                //         top: -15px;
                //     }
                // }
                // }
                // }
                // &.active {
                //     a {
                //         color: $secondary-color;
                //     }
                // }
                // &:hover {
                //     .dropdown-menu {
                //         opacity: 1;
                //         visibility: visible;
                //         top: -15px;
                //     }
                // }
                //     }
                // }
                // &.active {
                //     a {
                //         color: $secondary-color;
                //     }
                // }
                // &:hover {
                //     .dropdown-menu {
                //         opacity: 1;
                //         visibility: visible;
                //         top: -15px;
                //     }
                // }
                //     }
                // }
                // &.active {
                //     a {
                //         color: $secondary-color;
                //     }
                // }
                // &:hover {
                //     .dropdown-menu {
                //         opacity: 1;
                //         visibility: visible;
                //         top: -15px;
                //     }
                // }
                //     }
                // }
                // &.active {
                //     a {
                //         color: $secondary-color;
                //     }
                // }
                // &:hover {
                //     .dropdown-menu {
                //         opacity: 1;
                //         visibility: visible;
                //         top: -15px;
                //     }
                // }
                //     }
                // }
                // &.active {
                //     a {
                //         color: $secondary-color;
                //     }
                // }
                // &:hover {
                //     .dropdown-menu {
                //         opacity: 1;
                //         visibility: visible;
                //         top: -15px;
                //     }
                // }
                //     }
                // }
                // &:hover {
                //     .dropdown-menu {
                //         opacity: 1;
                //         visibility: visible;
                //         top: 100%;
                //     }
                // }
            }
        }
        .others-options {
            // margin-left: 30px;
            // border-radius: 10px;
            .login-btn {
                cursor: pointer;
            }
            .dropdown-menu {
                border-radius: 10px;
                border-color: #e5e5e5;
                color: #000000;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
                .nav-item {
                    font-weight: 500;
                    font-size: 14px;
                    a.nav-link {
                        color: $secondary;
                    }
                    &:hover {
                        background: #f1eeee;
                        color: #000000;
                    }
                }
            }
            .user-dropdown label {
                color: #888;
                font-weight: normal;
            }
            .dropdown-menu.user-dropdown {
                width: 200px;
                left: auto;
                right: 0;
                color: #000000;
            }           
        }
    }
}

.navbar-area {
    background-color: transparent;
    transition: $transition;
    position: absolute;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        background: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        transition: $transition;
    }
}

/* Navbar toggler */

.navbar-toggler {
    border: none;
    border-radius: 0;
    padding: 0;
    &:focus {
        outline: 0;
        box-shadow: none;
    }
    .icon-bar {
        width: 35px;
        transition: all 0.3s;
        background: #221638;
        height: 4px;
        display: block;
        border-radius: 3px;
    }
    .top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
        left: 4px;
        position: relative;
    }
    .middle-bar {
        opacity: 0;
        margin: 5px 0;
    }
    .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
        left: 4px;
        position: relative;
    }
    &.collapsed {
        .top-bar {
            transform: rotate(0);
            left: 0;
        }
        .middle-bar {
            opacity: 1;
        }
        .bottom-bar {
            transform: rotate(0);
            left: 0;
        }
    }
}

.search-overlay {
    display: none;
    &.search-popup {
        position: absolute;
        top: 100%;
        width: 300px;
        right: 0;
        background: $white-color;
        z-index: 120;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        margin-top: 25px;
        .search-form {
            position: relative;
            .search-input {
                display: block;
                width: 100%;
                height: 50px;
                line-height: initial;
                border: 1px solid #eeeeee;
                color: $black-color;
                outline: 0;
                transition: $transition;
                padding: {
                    top: 4px;
                    left: 10px;
                }
                &:focus {
                    border-color: $secondary-color;
                }
            }
            .search-button {
                position: absolute;
                right: 0;
                top: 0;
                height: 50px;
                background: transparent;
                border: none;
                width: 50px;
                outline: 0;
                color: $paragraph-color;
                transition: $transition;
                padding: 0;
                &:hover,
                &:focus {
                    background: #ffffff;
                    border: 1px solid $primary;
                    color: $primary;
                }
            }
        }
    }
}

.sidebar-modal {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
    overflow: hidden;
    .sidebar-modal-inner {
        position: absolute;
        right: -100%;
        top: 0;
        width: 30%;
        overflow-y: scroll;
        height: 100%;
        background-color: $white-color;
        transition: 0.7s;
        z-index: 1;
        padding: {
            top: 60px;
            bottom: 40px;
            left: 40px;
            right: 40px;
        }
        .close-btn {
            display: inline-block;
            position: absolute;
            right: 35px;
            top: 20px;
            font-size: 20px;
            transition: $transition;
            color: $black-color;
            opacity: 0.66;
            cursor: pointer;
            &:hover {
                opacity: 1;
            }
        }
    }
    .sidebar-about-area {
        margin: {
            bottom: 40px;
        }
        .title {
            h2 {
                margin-bottom: 0;
                font: {
                    size: 24px;
                    weight: 700;
                }
            }
            p {
                margin-top: 15px;
                font-size: 15px;
            }
        }
    }
    .sidebar-instagram-feed {
        margin: {
            bottom: 40px;
        }
        h2 {
            margin-bottom: 25px;
            font: {
                size: 24px;
                weight: 700;
            }
        }
        ul {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            padding-left: 0;
            list-style-type: none;
            margin: {
                left: -5px;
                right: -5px;
                bottom: 0;
                top: -10px;
            }
            li {
                -ms-flex: 0 0 25%;
                flex: 0 0 25%;
                max-width: 25%;
                padding: {
                    left: 5px;
                    right: 5px;
                    top: 10px;
                }
                a {
                    display: block;
                    position: relative;
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #000000;
                        transition: $transition;
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:hover {
                        &::before {
                            opacity: 0.5;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    .sidebar-contact-area {
        .contact-info {
            text-align: center;
            .contact-info-content {
                h2 {
                    margin-bottom: 0;
                    font: {
                        size: 20px;
                        weight: 700;
                    }
                    .info {
                        display: inline-block;
                        color: $secondary-color;
                        &:hover {
                            color: $black-color;
                        }
                        &:not(:first-child) {
                            color: $black-color;
                            &:hover {
                                color: $secondary-color;
                            }
                        }
                    }
                    .or {
                        display: block;
                        color: $paragraph-color;
                        margin: {
                            top: 15px;
                            bottom: 10px;
                        }
                        font: {
                            size: 14px;
                            weight: 600;
                        }
                    }
                }
                .social {
                    padding-left: 0;
                    list-style-type: none;
                    margin: {
                        bottom: 0;
                        top: 20px;
                    }
                    li {
                        display: inline-block;
                        margin: 0 4px;
                        padding-left: 0;
                        a {
                            width: 30px;
                            height: 30px;
                            line-height: 29px;
                            border: 1px solid #6a6c72;
                            border-radius: 50%;
                            color: #6a6c72;
                            display: block;
                            i {
                                font-size: 11px;
                            }
                            &:hover {
                                color: $white-color;
                                border-color: $secondary-color;
                                background-color: $secondary-color;
                            }
                        }
                    }
                }
            }
        }
    }
    &.active {
        opacity: 1;
        visibility: visible;
        .sidebar-modal-inner {
            right: 0;
        }
    }
}

/*================================================
Navbar Area CSS
=================================================*/

.navbar-white {
    background-color: #ffffff !important;
    transition: $transition;
    position: relative;
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        background: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        transition: $transition;
    }
}

/*================================================
Main Banner Area CSS
=================================================*/

.main-banner {
    position: relative;
    height: 100%;
    overflow: hidden;
    padding-bottom: 70px;
    margin-top: 50px;
    z-index: 1 !important;
    // border-bottom: 1px solid #3d3737;
    .banner-image {
        position: relative;
    }

    .banner-form {
        background: #ffffff;
        // -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
        // box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
        padding: 40px 30px;
        border-radius: 5px;
        position: relative;
        .title {
            text-align: center;
            margin-bottom: 20px;
        }
        p.option {
            text-align: center;
        }
        .border-option {
            // width: 40%;
            background-color: #ccc;
            height: 1px;
        }
        form {
            .form-group {
                margin-bottom: 1.2rem;
                .validation {
                    position: absolute;
                    margin-bottom: 10px;
                    color: $secondary-color;
                    font-size: 12px;
                }
            }
            .form-control {
                height: 50px;
                padding: 10px;
                // font-size: 14px;
                color: #0e314c;
                border: 1px solid #eeeeee;
                border-radius: 4px;
                // background: #fafafa;
                -webkit-transition: 0.5s;
                transition: 0.5s;
                &:focus {
                    outline: 0;
                    box-shadow: none;
                }
            }
            .btn {
                font-weight: 400;
                font-size: 16px;
                border: none;
                padding: 15px 40px;
                position: relative;
                border-radius: 4px;
                z-index: 1;
                text-transform: capitalize;
                -webkit-transition: 0.5s;
                transition: 0.5s;
                width: 100%;
                margin-top: 10px;
            }
            .btn-primary {
                color: $white-color;
                background-color: $secondary-color;
                -webkit-box-shadow: 0 13px 27px 0 rgba(255, 255, 255, 0.25);
                box-shadow: 0 13px 27px 0 rgba(255, 255, 255, 0.25);
                transition: $transition;
                border: 1px solid $secondary-color;
                &:hover {
                    background-color: $white-color;
                    color: $secondary-color;
                }
            }
            label {
                color: $black-color;
                font-size: 15px;
                // font-weight: 500;
                margin-bottom: 4px;
                float: left;
                font-weight: normal;
            }
        }
    }
    .validate-otp-form {
        @extend .banner-form;
        form {
            label {
                float: unset;
            }
            .label-otp {
                color: $black-color;
                font-size: 15px;
                margin-bottom: 4px;
                font-weight: normal;
            }
        }
    }
}
.forgot-password {
    h3 {
        color: $secondary;
        font-weight: 500;
        line-height: normal;
        // font-family: "Poppins", "sans-serif";
        @extend .montserrat-font-family;
    }
}
.login-contents {
    .banner-form-title {
        color: $secondary;
        font-weight: 500;
        line-height: normal;
        // font-family: "Poppins", "sans-serif";
        @extend .montserrat-font-family;
    }
}
.main-banner-content {
    h1 {
        // font-size: 72px;
        text-overflow: ellipsis;
        // font: normal 2.8rem/3.8rem Google Sans, Arial, sans-serif;
        // font: normal normal 900 72px/82px Montserrat !important;
        color: $secondary;
        font-weight: 800;
        line-height: 1.125;
        margin: 0 0 20px 0;
        // font-family: "Poppins", "sans-serif";
    }
    p {
        font-size: $font-size-16;
        font-weight: 500;
        @extend .montserrat-font-family;
        @media (min-width: 767px) {
            margin: 0;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            overflow-y: hidden;
            text-overflow: ellipsis;
            color: #424242;
            // font: normal 400 1.2rem/2.4rem " Montserrat", "sans-serif";
            overflow: hidden;
            line-height: 2rem;
        }
    }
    .banner-btn {
        margin-top: 40px;
        margin-bottom: 10px;
        .popup-youtube {
            display: inline-block;
            font-size: 16px;
            color: #131f58;
            font-weight: 500;
            position: relative;
            z-index: 1;
            margin-left: 25px;
            transition: $transition;
            cursor: pointer;
            i {
                color: #e72f64;
                margin-left: 8px;
                position: relative;
                top: 2px;
                &::before {
                    font-size: 20px;
                    font-weight: normal;
                    font-weight: bold;
                }
            }
            &:hover {
                color: $secondary-color;
            }
        }
    }
}

.banner-bg-text {
    font-size: 200px;
    color: #f9f8fc;
    font-weight: 900;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 30%;
    line-height: 1;
}

.shape-img1 {
    position: absolute;
    left: 0;
    top: 14%;
    z-index: -1;
    width: 150px;
    animation: moveLeftBounce 3s linear infinite;
}

.shape-img2 {
    position: absolute;
    left: 30%;
    top: 17%;
    z-index: -1;
    -webkit-animation: moveScale 3s linear infinite;
    animation: moveScale 3s linear infinite;
}

.shape-img3 {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50px;
    animation: rotate3d 4s linear infinite;
}

.shape-img4 {
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
}

.shape-img5 {
    position: absolute;
    z-index: -1;
    bottom: 8%;
    left: 20%;
    -webkit-animation: moveLeftBounce 3s linear infinite;
    animation: moveLeftBounce 3s linear infinite;
}

.shape-img6 {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    z-index: -1;
    top: 20%;
    -webkit-animation: rotate3d 4s linear infinite;
    animation: rotate3d 4s linear infinite;
}

.shape-img7 {
    position: absolute;
    left: 50%;
    bottom: 28%;
    z-index: -1;
    -webkit-transform: translateY(-20%) translateX(-15%);
    transform: translateY(-20%) translateX(-15%);
    animation: animationFramesOne 20s infinite linear;
}

.shape-img8 {
    position: absolute;
    bottom: 12%;
    right: 25%;
    margin: 0 auto;
    text-align: center;
    z-index: -1;
    -webkit-animation: moveLeftBounce 3s linear infinite;
    animation: moveLeftBounce 3s linear infinite;
}

.shape-img9 {
    position: absolute;
    right: 8%;
    top: 15%;
    z-index: -1;
    -webkit-animation: moveLeftBounce 3s linear infinite;
    animation: moveLeftBounce 3s linear infinite;
}

.shape-img10 {
    position: absolute;
    left: 5%;
    z-index: -1;
    top: 5%;
    -webkit-animation: moveLeftBounce 3s linear infinite;
    animation: moveLeftBounce 3s linear infinite;
}

.shape-img11 {
    position: absolute;
    right: 0;
    z-index: -1;
    bottom: 5%;
    -webkit-animation: moveLeftBounce 3s linear infinite;
    animation: moveLeftBounce 3s linear infinite;
}

@keyframes rotateMe {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }
    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes animationFramesTwo {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        transform: translate(-73px, 1px) rotate(-36deg);
    }
    40% {
        transform: translate(-141px, -72px) rotate(-72deg);
    }
    60% {
        transform: translate(-83px, -122px) rotate(-108deg);
    }
    80% {
        transform: translate(40px, -72px) rotate(-144deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes rotate3d {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

@keyframes moveScale {
    0% {
        transform: scale(0.6);
    }
    50% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(0.6);
    }
}

@keyframes moveLeftBounce {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0px);
    }
}

@keyframes moveBounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}

/*================================================
Features Section CSS
=================================================*/

.features-section {
    padding: 100px 100px;
    position: relative;
    line-height: 1;
    overflow: hidden;
    margin-top: 0px;
    margin-bottom: 0px;
}

.features-content-area {
    @extend .montserrat-font-family;
    span {
        font-size: 14px;
        color: $secondary-color;
        font-weight: 500;
        text-transform: uppercase;
        display: block;
        margin-bottom: 5px;
    }
    h3.line-bg-secondary {
        &::before {
            background-color: $secondary;
        }
        &::after {
            background-color: $secondary;
        }
    }
    h3 {
        // font-family: "Poppins", "sans-serif";
        @extend .montserrat-font-family;
        font-size: 32px;
        color: $primary;
        font-weight: bold;
        margin: 0 0 15px;
        position: relative;
        padding-bottom: 20px;
        // &::before {
        //     position: absolute;
        //     content: "";
        //     height: 3px;
        //     width: 80px;
        //     background-color: $primary;
        //     bottom: 0;
        //     left: 0;
        // }
        // &::after {
        //     position: absolute;
        //     content: "";
        //     height: 3px;
        //     width: 32px;
        //     background-color: $primary;
        //     bottom: 0;
        //     margin: 0 auto 0;
        //     left: 95px;
        // }
    }
    p {
        margin: 0;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow-y: hidden;
        text-overflow: ellipsis;
        color: #424242;
        overflow: hidden;
        line-height: 2rem;
        font-size: 1rem;
    }
    .features-btn {
        margin-top: 25px;
        .features-btn-one {
            display: inline-block;
            padding: 12px 32px;
            color: $white-color;
            text-transform: capitalize;
            background-color: #e60459;
            border: 1px solid #e60459;
            transition: $transition;
            border-radius: 30px;
            font-size: 16px;
            font-weight: 500;
            &:hover {
                background-color: $white-color;
                color: #e60459;
            }
        }
    }
}

.col-lg-7 .col-lg-5:nth-child(2) .single-features-item {
    margin-top: 60px;
    @include phone{
        margin-top: 0px;
    }
}

.col-lg-7 .col-lg-5:nth-child(3) .single-features-item {
    margin-top: -40px;
    margin-bottom: 0;
      @include phone{
        margin-top: 0px;
        margin-bottom: 30px;

    }
}

.col-lg-7 .col-lg-5:nth-child(4) .single-features-item {
    margin-top: 0;
    margin-bottom: 0;
}

.single-features-item {
    @extend .montserrat-font-family;
    box-shadow: 0 0 30px rgba(56, 46, 46, 0.1);
    transition: 0.5s;
    text-align: center;
    padding: 40px 30px;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    margin-bottom: 30px;
    h3 {
        font-size: 20px;
        color: #3b3663;
        font-weight: 600;
        margin: 20px 0 10px 0;
    }
    p {
        margin: 0;
    }
    &.bg-0 {
        background: #f1eff8;
        border: 1px solid #f1eff8;
        h5 {
            color: #5d43bc;
            font-weight: bold;
        }
        .icon {
            i {
                display: inline-block;
                height: 65px;
                width: 65px;
                color: #5d43bc;
                border: 2px dotted #5d43bc;
                line-height: 65px;
                border-radius: 50px;
                &::before {
                    font-size: 30px;
                }
            }
        }
        &:hover {
            box-shadow: 1px 1px 1px 1px #5d43bc;
        }
    }
    &.bg-1 {
        background: #fbe6d3;
        border: 1px solid #fbe6d3;
        h5 {
            color: #f1760c;
            font-weight: bold;
        }
        .icon {
            i {
                display: inline-block;
                height: 65px;
                width: 65px;
                color: #f1760c;
                border: 2px dotted #f1760c;
                line-height: 65px;
                border-radius: 50px;
                &::before {
                    font-size: 30px;
                }
            }
        }
        &:hover {
            box-shadow: 1px 1px 1px 1px #f1760c;
        }
    }
    &.bg-2 {
        background: #f0fffc;
        border: 1px solid #f0fffc;
        h5 {
            color: #19b192;
            font-weight: bold;
        }
        .icon {
            i {
                display: inline-block;
                height: 65px;
                width: 65px;
                color: #19b192;
                border: 2px dotted #19b192;
                line-height: 65px;
                border-radius: 50px;
                &::before {
                    font-size: 30px;
                }
            }
        }
        &:hover {
            box-shadow: 1px 1px 1px 1px #19b192;
        }
    }
    &.bg-3 {
        background: #f8e1eb;
        border: 1px solid #f8e1eb;
        h5 {
            color: #f0116f;
            font-weight: bold;
        }
        .icon {
            i {
                display: inline-block;
                height: 65px;
                width: 65px;
                color: #f0116f;
                border: 2px dotted #f0116f;
                line-height: 65px;
                border-radius: 50px;
                &::before {
                    font-size: 30px;
                }
            }
        }
        &:hover {
            box-shadow: 1px 1px 1px 1px #f0116f;
        }
    }
    &:hover {
        .bg-0 {
            border: 2px dotted #5d43bc;
        }
        .bg-1 {
            border: 2px dotted #f1760c;
        }
        .bg-2 {
            border: 2px dotted #19b192;
        }
        .bg-3 {
            border: 2px dotted #f0116f;
        }
        background-color: #fff;
        // border: 1px dotted $secondary-color;
    }
}

.features-animation {
    .shape-img1 {
        position: absolute;
        left: 25%;
        top: 20%;
        z-index: -1;
        height: auto;
        animation: animationFramesOne 20s infinite linear;
        width: 12px;
    }
    .shape-img2 {
        position: absolute;
        left: 36%;
        top: 16%;
        z-index: -1;
        width: 100%;
        height: auto;
        -webkit-animation: moveLeftBounce 3s linear infinite;
        animation: moveLeftBounce 3s linear infinite;
    }
}

/*================================================
About Section CSS
=================================================*/

.about-area-content {
    span {
        font-size: 14px;
        color: $secondary-color;
        font-weight: 500;
        text-transform: uppercase;
        display: block;
        margin-bottom: 5px;
    }
    h3 {
        font-size: 38px;
        color: $black-color;
        font-weight: bold;
        margin: 0 0 25px;
        position: relative;
        padding-bottom: 25px;
        &::before {
            position: absolute;
            content: "";
            height: 3px;
            width: 80px;
            background-color: #fab4a2;
            bottom: 0;
            left: 0;
        }
        &::after {
            position: absolute;
            content: "";
            height: 3px;
            width: 32px;
            background-color: #ff724e;
            bottom: 0;
            margin: 0 auto 0;
            left: 95px;
        }
    }
    p {
        margin: 15px 0 0 0;
    }
    strong {
        color: $paragraph-color;
        font-weight: 500;
    }
}

.single-fun-facts {
    position: relative;
    margin-top: 18px;
    h3 {
        position: relative;
        color: #474545;
        margin-bottom: 0;
        font: {
            size: 35px;
            weight: 700;
        }
        .sign-icon {
            display: inline-block;
            font-size: 35px;
            margin-left: 5px;
            color: #ff724e;
        }
    }
    p {
        line-height: initial;
        margin: 6px 0 0 0;
        font: {
            weight: 500;
        }
    }
}

/*================================================
Services Section CSS
=================================================*/

.services-section {
    background-color: #f6f5fb;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.single-services-box {
    position: relative;
    background: #ffffff;
    border-radius: 2px;
    transition: 0.5s;
    text-align: center;
    padding: 30px;
    margin-bottom: 30px;
    h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: $black-color;
        font-weight: 600;
        transition: $transition;
        text-transform: capitalize;
        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
    p {
        margin: 0;
        transition: $transition;
    }
    .icon {
        text-align: center;
        width: 65px;
        height: 65px;
        line-height: 65px;
        border-radius: 50%;
        transition: 0.5s;
        display: inline-block;
        margin-bottom: 20px;
        &.bg-faddd4 {
            color: #fa9979;
            background: #faddd4;
        }
        &.bg-cafbf1 {
            color: #23b396;
            background: #cafbf1;
        }
        &.bg-ddd5fb {
            color: #8973d9;
            background: #ddd5fb;
        }
        &.bg-fcdeee {
            color: #ef52a6;
            background: #fcdeee;
        }
        &.bg-c5ebf9 {
            color: #37acd7;
            background: #c5ebf9;
        }
        &.bg-f8fbd5 {
            color: #eddf39;
            background: #f8fbd5;
        }
        i {
            &::before {
                font-size: 30px;
            }
        }
    }
    &:hover {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
        -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
        box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
        background-color: $secondary-color;
        .icon {
            background: $white-color;
            color: $secondary-color;
            &.bg-faddd4 {
                background: $white-color;
            }
        }
        h3 {
            color: $white-color;
            a {
                color: $white-color;
            }
        }
        p {
            color: $white-color;
        }
    }
}

/*================================================
Choose Section CSS
=================================================*/

.choose-section {
    padding-top: 100px;
    padding-bottom: 100px;
    line-height: 1;
}

.choose-content-area {
    span {
        font-size: 14px;
        color: $secondary-color;
        font-weight: 500;
        text-transform: uppercase;
        display: block;
        margin-bottom: 5px;
    }
    h3 {
        font-size: 38px;
        color: $black-color;
        font-weight: bold;
        margin: 0 0 25px;
        position: relative;
        padding-bottom: 20px;
        max-width: 600px;
        &::before {
            position: absolute;
            content: "";
            height: 3px;
            width: 80px;
            background-color: #fab4a2;
            bottom: 0;
            left: 0;
        }
        &::after {
            position: absolute;
            content: "";
            height: 3px;
            width: 32px;
            background-color: #ff724e;
            bottom: 0;
            margin: 0 auto 0;
            left: 95px;
        }
    }
    p {
        margin: 0;
    }
    .choose-text {
        margin-top: 20px;
        position: relative;
        padding-left: 45px;
        i {
            position: absolute;
            display: inline-block;
            height: 30px;
            width: 30px;
            line-height: 30px;
            background-color: #f1f1f1;
            text-align: center;
            color: $secondary-color;
            border-radius: 50px;
            top: 2px;
            left: 0;
            transition: $transition;
            &::before {
                font-size: 12px;
            }
        }
        p {
            margin: 0;
        }
        &:hover {
            i {
                background-color: $secondary-color;
                color: $white-color;
            }
        }
        h4 {
            font-size: 18px;
            color: $black-color;
            font-weight: 500;
            margin: 0 0 8px 0;
        }
    }
    .choose-btn {
        margin-top: 30px;
    }
}

/*================================================
Tab Section CSS
=================================================*/

.tab-section {
    line-height: 1;
    overflow: hidden;
    padding: 50px 0px;
}

.tab {
    .tabs_item {
        display: none;
        &:first-child {
            display: block;
        }
    }
}

.boosting-list-tab {
    .tabs {
        padding-left: 0;
        list-style-type: none;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            left: -5px;
            right: -5px;
            bottom: 20px;
        }
        li {
            -ms-flex: 0 0 16.6666666667%;
            -webkit-box-flex: 0;
            flex: 0 0 16.6666666667%;
            max-width: 15.6666666667%;
            text-align: center;
            cursor: pointer;
            margin: {
                right: 5px;
                left: 5px;
            }
            display: block;
            border-radius: 2px;
            color: $primary;
            box-shadow: 0px 10px 40px 0px rgba(107, 125, 179, 0.11);
            padding: {
                right: 18px;
                top: 15px;
                bottom: 18px;
                left: 18px;
            }
            font: {
                size: 15px;
                weight: 700;
            }
            i {
                color: $secondary-color;
                transition: $transition;
                font: {
                    weight: normal;
                    size: 40px;
                }
                line-height: normal;
            }
            span {
                // display: block;
                margin-top: 10px;
                margin-left: 3px;
            }
            &.bg-tab0 {
                background: linear-gradient(to right, #f3fbed, #f1f9eb, #f0f7e9, #eef5e8, #ecf3e6);
                i {
                    color: #53ba05;
                }
            }
            &.bg-tab1 {
                background: linear-gradient(to right, #fff8f0, #fdf6ee, #fbf4ec, #f9f2ea, #f7f0e8);
                i {
                    color: #e3841a;
                }
            }
            &.bg-tab2 {
                background: linear-gradient(to right, #ecfaf7, #eaf8f5, #e8f6f3, #e7f4f1, #e5f2ef);
                i {
                    color: #04b994;
                }
            }
            &.bg-tab3 {
                background: linear-gradient(to right, #f2f0fb, #f2f0fb, #f2f0fb, #f2f0fb, #f2f0fb);
                i {
                    color: #775dda;
                }
            }
            &.bg-tab4 {
                background: linear-gradient(to right, #c5ebf9, #c5ebf9, #c5ebf9, #c5ebf9, #c5ebf9);
                i {
                    color: #37acd7;
                }
            }
            &.current,
            &:hover {
                background: $secondary-color;
                color: $white-color;
                box-shadow: 0px 10px 40px 0px rgba(0, 0, 255, 0.22);
                i {
                    color: $white-color;
                }
            }
        }
    }
    .tab_content {
        -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        background-color: $white-color;
        padding: 50px 30px;
        position: relative;
        .tabs_item {
            .content {
                h2 {
                    // font-size: 18px;
                    color: $black-color;
                    font-weight: 600;
                    margin: 0 0 16px 0;
                    text-transform: uppercase;
                    // font-family: "Poppins", "sans-serif";
                    @extend .montserrat-font-family;
                    font-size: 1rem;
                }
                p {
                    margin: 0;
                    font-weight: 400;
                    font-size: 1rem;
                }
            }
            .tab-text-content {
                margin-top: 20px;
                position: relative;
                padding-left: 60px;
                i {
                    color: #e84f1c;
                    position: absolute;
                    left: 0;
                    top: 0;
                    &::before {
                        font-size: 40px;
                        line-height: normal;
                    }
                }
                p {
                    font-size: 14px;
                    margin: 0;
                }
            }
            .tab-btn {
                margin-top: 25px;
            }
            .tab-shape {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 165px;
            }
        }
    }
}

/*================================================
Testimonial Section CSS
=================================================*/

.testimonial-section {
    position: relative;
}

.testimonial-content-area {
    span {
        font-size: 14px;
        color: $secondary-color;
        font-weight: 500;
        text-transform: uppercase;
    }
    h3 {
        font-size: 32px;
        color: $secondary;
        font-weight: bold;
        margin: 10px 0 25px 0;
        position: relative;
        padding-bottom: 20px;
        &::before {
            position: absolute;
            content: "";
            height: 3px;
            width: 80px;
            background-color: $primary;
            bottom: 0;
            left: 0;
        }
        &::after {
            position: absolute;
            content: "";
            height: 3px;
            width: 32px;
            background-color: $primary;
            bottom: 0;
            margin: 0 auto 0;
            left: 95px;
        }
    }
    p {
        margin: 0;
    }
}

.testimonial-slides {
    .testimonial-single-item {
        text-align: center;
        background-color: #f6f5fb;
        padding: 30px 20px;
        position: relative;
        margin-top: 78px;
        border-radius: 10px;
        &::before {
            position: absolute;
            content: "";
            height: 130px;
            width: 130px;
            line-height: 130px;
            background-color: $secondary;
            border-radius: 100px;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: -76px;
        }
        .testimonial-image {
            position: absolute;
            left: 0;
            right: 0;
            top: -70px;
            img {
                display: inline-block;
                width: 125px;
                height: auto;
                border-radius: 100px;
            }
        }
        .testimonial-content-text {
            h3 {
                font-size: 20px;
                color: $black-color;
                font-weight: 500;
                margin: 40px 0 6px 0;
            }
            span {
                font-size: 13px;
            }
            .icon {
                margin-top: 8px;
                margin-bottom: 8px;
                i {
                    color: #848281;
                    &::before {
                        font-size: 20px;
                        line-height: normal;
                    }
                }
            }
            p {
                margin: 0;
            }
        }
    }
    &.owl-theme {
        .owl-dots {
            line-height: 1;
            margin: {
                top: 30px !important;
            }
            .owl-dot {
                span {
                    width: 15px;
                    height: 15px;
                    margin: 0 5px;
                    background-color: #dfdfdf;
                    transition: 0.6s;
                    border-radius: 50px;
                }
                &:hover {
                    span {
                        background-color: $secondary-color;
                    }
                }
                &.active {
                    span {
                        background-color: $secondary-color;
                    }
                }
            }
        }
    }
}

/*================================================
Section Card List CSS
=================================================*/

.more-card {
    position: relative;
    margin-bottom: 20px;
    .link {
        position: absolute;
        right: 10px;
        background: $primary;
        padding: 5px 10px;
        border-radius: 50px;
        color: #ffffff;
        font-size: 10px;
        font-weight: bold;
        a {
            color: #ffffff;
            text-decoration: none;

            &:hover {
                color: $secondary;
                text-decoration: none;
            }
        }
        &:hover {
            background: #ffffff;
            padding: 5px 10px;
            border-radius: 50px;
            border: 1px solid $primary;
            color: $primary;
        }
    }
}

.card-item {
    transition: $transition;
    margin-top: 30px;
    .card-image {
        height: 250px;
        border-left: 1px solid #e8e8e8;
        border-right: 1px solid #e8e8e8;
    }
    .single-card-item {
        background-color: #ffffff;
        border: 1px solid #e8e8e8;
        border-top: none;
        padding: 30px;
        transition: $transition;
        position: relative;
        margin-bottom: 30px;
        .duration {
            padding-left: 0;
            position: absolute;
            top: -40px;
            right: 10px;
            margin-bottom: 0;
            text-align: center;
            background-color: #1d2052;
            padding: 8px;
            li {
                list-style-type: none;
                color: #ffffff;
                font-size: 12px;
            }
        }
        .card-list {
            padding-left: 0;
            margin-bottom: 0;
            transition: $transition;
            li {
                list-style-type: none;
                display: inline-block;
                font-size: 13px;
                font-weight: 400;
                margin-right: 18px;
                color: #93908f;
                &:last-child {
                    margin-right: 0;
                }
                i {
                    &::before {
                        font-size: 13px;
                        margin-right: 5px;
                    }
                }
            }
            a {
                color: #93908f;
                text-decoration: none;
                &:hover {
                    color: $secondary-color;
                }
            }
            i {
                &::before {
                    font-size: 14px;
                    color: #93908f;
                    margin-right: 5px;
                }
            }
        }
        .card-content {
            transition: $transition;
            .category {
                position: absolute;
                background: #e8e8f1;
                padding: 3px 8px;
                border-radius: 5px;
                p {
                    font-size: 12px;
                    color: rgb(7, 7, 7);
                    font-weight: 500;
                }
            }
            .lesson {
                position: absolute;
                right: 10px;
            }
            .title {
                a {
                    margin-top: 20px;
                    text-decoration: none;
                }
            }
            h3 {
                color: #343846;
                font-size: 20px;
                margin: 10px 0px 10px 0;
                line-height: 1.4;
                transition: $transition;
                &:hover {
                    color: $secondary-color;
                    transition: 0.6s;
                    -webkit-transition: 0.5s;
                    text-decoration: none;
                }
            }
            p.desc {
                margin: 0px;
                height: 100px;
            }
            a {
                text-decoration: none;
            }
            .card-btn-one {
                font-size: 15px;
                border: 1px solid $secondary-color;
                padding: 7px 20px;
                display: inline-block;
                // margin-top: 10px;
                background-color: $secondary-color;
                color: $white-color;
                transition: 0.6s;
                text-transform: uppercase;
                text-decoration: none;
                &:hover {
                    background-color: $white-color;
                    color: $secondary-color;
                }
                i {
                    &::before {
                        font-size: 12px;
                        position: relative;
                        left: 2px;
                    }
                }
            }
        }
        .card-btn {
            margin-top: 50px;
            // position: absolute;
            .card-btn-one {
                display: inline-block;
                padding: 10px 30px;
                color: $white-color;
                text-transform: capitalize;
                background-color: $secondary-color;
                border: 1px solid $secondary-color;
                transition: $transition;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 500;
                text-decoration: none;
            }
        }
    }
    &:hover {
        -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        background-color: $white-color;
        .single-card-item {
            border: 1px solid $white-color;
            border-top: none;
        }
        .card-content {
            h3 {
                color: $secondary-color;
            }
        }
        .card-btn {
            .card-btn-one {
                background-color: transparent;
                color: $secondary-color;
            }
        }
    }
}

// Owl caraousel css
.owl-item-inner {
    padding: 0px;
}

.carousel-shadow {
    margin-right: 15px !important;
    margin-left: 15px !important;
    -webkit-box-shadow: 0px 0px 15px 0px #f0f2f5 !important;
    -moz-box-shadow: 0px 0px 15px 0px #f0f2f5;
    box-shadow: 0px 0px 15px 0px #f0f2f5;
}

.ant-carousel .slick-dots {
    position: relative !important;
}

.ant-carousel .slick-dots li {
    background: #125688 !important;
    margin-top: 20px !important;
}

h5.carousel-category-label {
    font-family: "Doris", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 20px;
    /* identical to box height, or 111% */
    letter-spacing: 0.05em;
    color: #125688;
    text-align: center;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    max-width: calc(100% - 25px * 2);
}

// @media (min-width: 767px) {
//     .slick-dots li {
//         display: none !important;
//     }
// }

.slick-dots li {
    width: 15px;
    height: 15px;
    margin: 0 5px;
    background-color: #dfdfdf;
    border-color: #125688;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    border-radius: 50px;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

/*================================================
Analysis Section CSS
=================================================*/

.analysis-section {
    position: relative;
    line-height: 1;
}

.analysis-area-content {
    span {
        font-size: 14px;
        color: $secondary-color;
        font-weight: 500;
        text-transform: uppercase;
    }
    h3 {
        font-size: 38px;
        color: $black-color;
        font-weight: bold;
        margin: 10px 0 25px 0;
        position: relative;
        padding-bottom: 20px;
        &::before {
            position: absolute;
            content: "";
            height: 3px;
            width: 80px;
            background-color: #fab4a2;
            bottom: 0;
            left: 0;
        }
        &::after {
            position: absolute;
            content: "";
            height: 3px;
            width: 32px;
            background-color: #ff724e;
            bottom: 0;
            margin: 0 auto 0;
            left: 95px;
        }
    }
    strong {
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
    }
}

.contactForm {
    margin-top: 20px;
    .form-group {
        margin-bottom: 20px;
        .form-control {
            height: 55px;
            box-shadow: unset;
            border-bottom: 1px solid #dbdbdc;
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: unset;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
            padding-bottom: 14px;
            font-size: 14px;
            color: #9a9b9f;
            font-weight: 400;
            &:focus {
                background-color: #fff;
                border-color: $secondary-color;
                outline: 0;
                box-shadow: none;
            }
        }
    }
    .send-btn {
        margin-top: 14px;
    }
}

/*================================================
Blog List Section CSS
=================================================*/

.blog-item {
    transition: $transition;
    margin-top: 50px;

    @include phone{
        margin-top: 10px;
    }
    &:hover {
        box-shadow: 0 2px 3px rgb(54 64 67 / 30%), 0 6px 10px 4px rgb(54 64 67 / 15%);
        border-radius: 16px !important;
        h3 {
            text-decoration-line: underline;
        }
    }
    .blog-image {
        // height: 250px;
        // border-left: 1px solid #e8e8e8;
        // border-right: 1px solid #e8e8e8;
        border-top-left-radius: 16px !important;
        border-top-right-radius: 16px !important;
        // border: 1px solid #e8eaed;
        border-bottom: none !important;
        // transition: transform 0.5s, filter 0.2s, box-shadow 0.4s;
        // &:hover {
        //     transform: scale(1.03);
        //     z-index: 2;
        //     outline: none;
        //     .go-to-course {
        //         transition: 0.5s ease-in-out;
        //         display: block;
        //     }
        // }

        background: #fff;
        img {
            border-top-left-radius: 16px !important;
            border-top-right-radius: 16px !important;
        }
    }
    .single-blog-item {
        border: 1px solid #e8eaed;
        // background-color: #ffffff;
        border-top: none;
        padding: 20px;
        transition: $transition;
        position: relative;
        margin-bottom: 30px;
        // height: 190px;
        border-radius: 0 0 16px 16px;
        background: #fff;
        .date {
            padding-left: 0;
            position: absolute;
            border-radius: 8px !important;
            top: -45px;
            right: 5px;
            margin-bottom: 0;
            text-align: center;
            background-color: $secondary;
            padding: 8px;
            color: #fff;
            li {
                list-style-type: none;
                color: #ffffff;
                font-size: 12px;
            }
        }
        .blog-list {
            padding-left: 0;
            margin-bottom: 0;
            transition: $transition;
            li {
                list-style-type: none;
                display: inline-block;
                line-height: 1.5714285714;
                font-size: 14px;
                letter-spacing: 0.2px;
                margin-right: 18px;
                color: #283360;
                &:last-child {
                    margin-right: 0;
                }
                i {
                    &::before {
                        font-size: 13px;
                        margin-right: 5px;
                    }
                }
            }
            a {
                color: #93908f;
                text-decoration: none;
                &:hover {
                    color: $secondary-color;
                }
            }
            i {
                &::before {
                    font-size: 14px;
                    color: #93908f;
                    margin-right: 5px;
                }
            }
        }
        .blog-content {
            transition: $transition;
            h3 {
                color: #343846;
                font-size: 14px;
                line-height: 28px;
                color: #202124;
                font-weight: 500;
                margin: 10px 0px 10px 0;
                line-height: 1.4;
                transition: $transition;
                // &:hover {
                //     color: $secondary;
                //     transition: 0.6s;
                //     -webkit-transition: 0.5s;
                //     text-decoration: none;
                // }
            }
            p.dec {
                margin: 0px;
                height: 100px;
            }
            a {
                text-decoration: none;
            }
            .blog-btn-one {
                font-size: 15px;
                border: 1px solid $secondary-color;
                padding: 7px 20px;
                display: inline-block;
                margin-top: 20px;
                background-color: $secondary-color;
                color: $white-color;
                transition: 0.6s;
                text-transform: uppercase;
                text-decoration: none;
                &:hover {
                    background-color: $white-color;
                    color: $secondary-color;
                }
                i {
                    &::before {
                        font-size: 12px;
                        position: relative;
                        left: 2px;
                    }
                }
            }
        }
        .blog-btn {
            margin-top: 16px;
            .blog-btn-one {
                display: inline-block;
                padding: 10px 30px;
                color: $white-color;
                text-transform: capitalize;
                background-color: $secondary-color;
                border: 1px solid $secondary-color;
                transition: $transition;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 500;
                text-decoration: none;
            }
            &:hover {
                .blog-btn-one {
                    background-color: transparent;
                    color: $secondary-color;
                }
            }
        }
    }
    &:hover {
        -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        background-color: $white-color;
        .single-blog-item {
            border: 1px solid $white-color;
            border-top: none;
        }
        // .blog-content {
        //     h3 {
        //         color: $secondary-color;
        //     }
        // }
        // .blog-btn {
        //     .blog-btn-one {
        //         background-color: transparent;
        //         color: $secondary-color;
        //     }
        // }
    }
}

.section {
    padding: 50px 0;
    position: relative;
}

// .blog {
//     transition: 0.4s ease-in-out;
//     height: 100%;
// }

.section-title {
    position: relative;
}

.section-title .main-title,
.section-title .title {
    letter-spacing: 0.5px;
    font-size: 30px;
    font-weight: 800;
}

// @media (min-width: 992px) {
//     .blog .img-wrapper {
//         height: 200px;
//         max-height: 200px;
//         min-height: 200px;
//     }
// }

// .blog .img-wrapper {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     overflow: hidden;
// }

// .blog .content h4 {
//     line-height: 1.2;
// }

// .blog .content .title {
//     font-size: 20px;
//     transition: all 0.5s ease;
//     overflow: hidden;
//     display: -webkit-box;
//     -webkit-line-clamp: 1;
//     -webkit-box-orient: vertical;
// }

.text-dark {
    color: #3c4858 !important;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

// .blog .content .post-meta .readmore {
//     font-size: 15px;
//     transition: all 0.5s ease;
//     font-family: "Poppins", "sans-serif";
// }

// .blog .img-wrapper .img-fluid {
//     flex-shrink: 0;
//     min-width: 100%;
//     min-height: 100%;
// }

// .blog .author {
//     position: absolute;
//     top: 1%;
//     right: 0;
//     left: 0;
//     z-index: 1;
//     opacity: 0;
//     -webkit-transition: all 0.5s ease;
//     transition: all 0.5s ease;
//     background-color: rgba(0, 0, 0, 0.6784313725490196);
// }

// .blog:hover {
//     -webkit-transform: translateY(-10px);
//     transform: translateY(-10px);
// }

// .blog:hover .author {
//     opacity: 1;
// }

// .blog .content .post-meta .readmore:hover {
//     color: #283360 !important;
// }

// .blog .small,
// small {
//     font-size: 90% !important;
//     letter-spacing: 2px !important;
// }

.btn-secondary:focus {
    outline: none !important;
    box-shadow: none !important;
}
.btn-secondary {
    // color: $secondary !important;
    background-color: $secondary-color !important;
    font-style: normal;
    font-weight: normal;
    // font-size: 18px;
    &:hover {
        background-color: $white-color !important;
        color: $secondary-color !important;
    }
}
footer {
    .btn-secondary {
        &:hover {
            border-color: $primary-color !important;
        }
    }
}

//course card
.course-card {
    transition: 0.4s ease-in-out;
    height: 100%;
    p {
        font-size: 12px;
        color: $black-color;
        background: #fff;
        position: absolute;
        right: 20px;
        bottom: 20px;
        padding: 8px 10px;
        font-weight: 500;
        border-radius: 5px;
    }
}

.course-card .img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.course-card .img-wrapper .img-fluid {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
}

.course-card .content h4 {
    line-height: 1.2;
}

.blog .small {
    font-size: 90% !important;
    letter-spacing: 2px !important;
}

.course-card .content .post-meta .readmore {
    font-size: 15px;
    font-weight: bold;
    transition: all 0.5s ease;
    // font-family: "Poppins", "sans-serif";
}

.course-card .content .post-meta .readmore:hover {
    color: #283360 !important;
}
.course-card .img-wrapper {
    height: 200px;
    max-height: 200px;
    min-height: 200px;
}

/*================================================
Blog Details Area CSS
=================================================*/
.bold-header-h2 {
    color: #283360;
    font-size: 1.6rem !important;
    font-weight: 400;
}
.blog-page-card {
    &:hover {
        transition: all 0.3s ease;
    }
    .blog-details-desc {
        margin-bottom: 20px;
        .article-content {
            margin-top: 30px;
            p {
                text-align: justify;
                line-height: 1.6;
                margin-bottom: 1rem;
            }
            .entry-meta {
                margin-bottom: -8px;
                ul {
                    font-size: 12px;
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style-type: none;
                    li {
                        position: relative;
                        display: inline-block;
                        color: $black-color;
                        margin-right: 25px;
                        span {
                            display: inline-block;
                            color: $black-color;
                            font-weight: 500;
                        }
                        a {
                            display: inline-block;
                            color: $paragraph-color;
                            text-decoration: none;
                            &:hover {
                                color: $secondary-color;
                            }
                        }
                        i {
                            color: $secondary-color;
                            margin-right: 2px;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            top: 12px;
                            right: -15px;
                            width: 6px;
                            height: 1px;
                            background: #6c757d;
                        }
                        &:last-child {
                            margin-right: 0;
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
            h3 {
                margin: {
                    bottom: 15px;
                    top: 25px;
                }
                font: {
                    size: 1.4rem;
                    weight: 400;
                }
            }
            h2 {
                @extend .bold-header-h2;
                strong {
                    @extend .bold-header-h2;
                }
            }
            strong {
                color: #283360;
                font-size: 1.3rem;
                font-weight: 400;
            }
            .wp-block-gallery {
                &.columns-3 {
                    padding-left: 0;
                    list-style-type: none;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    margin: {
                        right: -10px;
                        left: -10px;
                        bottom: 20px;
                        top: 20px;
                    }
                    li {
                        -ms-flex: 0 0 33.3333%;
                        flex: 0 0 33.3333%;
                        max-width: 33.3333%;
                        padding: {
                            right: 10px;
                            left: 10px;
                        }
                        figure {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            table td {
                border-bottom: 1px solid;
                border-right: 1px solid;
            }
        }
        .article-footer {
            display: -ms-flexbox;
            display: -webkit-box;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin: {
                top: 20px;
            }
            .article-tags {
                -ms-flex: 0 0 50%;
                -webkit-box-flex: 0;
                flex: 0 0 50%;
                max-width: 50%;
                span {
                    display: inline-block;
                    color: $black-color;
                    font-size: 20px;
                    margin-right: 10px;
                    position: relative;
                    top: 2px;
                }
                a {
                    display: inline-block;
                    margin-right: 5px;
                    &:hover {
                        color: $secondary-color;
                    }
                }
            }
            .article-share {
                -ms-flex: 0 0 50%;
                -webkit-box-flex: 0;
                flex: 0 0 50%;
                max-width: 50%;
                .social {
                    padding-left: 0;
                    list-style-type: none;
                    text-align: right;
                    margin: {
                        bottom: 0;
                    }
                    li {
                        display: inline-block;
                        margin-left: 5px;
                        span {
                            display: inline-block;
                            margin-right: 2px;
                            font-weight: 500;
                        }
                        a {
                            display: block;
                            color: $secondary-color;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            border-radius: 50%;
                            background-color: #eeeef0;
                            text-align: center;
                            font-size: 12px;
                            &:hover {
                                color: $white-color;
                                background-color: $secondary-color;
                                transform: translateY(-2px);
                            }
                        }
                    }
                }
            }
        }
        .post-navigation {
            margin-top: 30px;
        }
    }
}

blockquote,
.blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin: {
        bottom: 20px;
        top: 20px;
    }
    p {
        color: $black-color;
        line-height: 1.6;
        margin-bottom: 0;
        font: {
            style: italic;
            weight: 500;
            size: 24px !important;
        }
    }
    cite {
        display: none;
    }
    &::before {
        color: #efefef;
        content: "\f10e";
        position: absolute;
        left: 50px;
        top: -50px;
        z-index: -1;
        font: {
            family: "Font Awesome 5 Free";
            size: 140px;
            weight: 900;
        }
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: $secondary-color;
        margin: {
            top: 20px;
            bottom: 20px;
        }
    }
}

.post-navigation {
    border: {
        top: 1px solid #eeeeee;
        bottom: 1px solid #eeeeee;
    }
    padding: {
        top: 20px;
        bottom: 20px;
    }
    .navigation-links {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .nav-previous {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            a {
                i {
                    margin-right: 2px;
                    transition: $transition;
                    text-decoration: none;
                }
                &:hover {
                    i {
                        margin-right: 0;
                    }
                }
            }
        }
        .nav-next {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
            text-align: right;
            a {
                i {
                    margin-left: 2px;
                    transition: $transition;
                    text-decoration: none;
                }
                &:hover {
                    i {
                        margin-left: 0;
                    }
                }
            }
        }
        div {
            a {
                display: inline-block;
                text-decoration: none;
                font: {
                    weight: 600;
                }
            }
        }
    }
}

/*================================================
 Blog Sidebar  Area CSS
=================================================*/

.widget-area {
    padding-left: 15px;
    .widget {
        margin-top: 35px;
        &:first-child {
            margin-top: 0;
        }
        .widget-title {
            border-bottom: 1px solid rgba(109, 109, 109, 0.7);
            padding-bottom: 10px;
            margin-bottom: 25px;
            text-transform: capitalize;
            position: relative;
            font: {
                weight: 600;
                size: 22px;
            }
            &::before {
                content: "";
                position: absolute;
                // background: $secondary-color;
                bottom: -1px;
                left: 0;
                width: 48px;
                height: 1px;
            }
        }
    }
    .widget_search {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 20px;
        form {
            position: relative;
            label {
                display: block;
                margin-bottom: 0;
            }
            .screen-reader-text {
                display: none;
            }
            .search-field {
                background-color: transparent;
                height: 50px;
                padding: 6px 15px;
                border: 1px solid #eeeeee;
                width: 100%;
                display: block;
                outline: 0;
                transition: $transition;
                &:focus {
                    border-color: $primary;
                }
            }
            button {
                position: absolute;
                right: 0;
                outline: 0;
                bottom: 0;
                height: 50px;
                width: 50px;
                z-index: 1;
                border: none;
                color: $white-color;
                background-color: $secondary-color;
                transition: $transition;
                &:hover {
                    background: #ffffff;
                    border: 1px solid $primary;
                    color: $primary;
                }
            }
        }
    }
    .widget_zash_posts_thumb {
        position: relative;
        overflow: hidden;
        .item {
            overflow: hidden;
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 80px;
                margin-right: 15px;
                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    }
                    &.bg1 {
                        background-image: url(/images/blog-details/1.jpg);
                    }
                    &.bg2 {
                        background-image: url(/images/blog-details/2.jpg);
                    }
                    &.bg3 {
                        background-image: url(/images/blog-details/3.jpg);
                    }
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;
                time {
                    display: block;
                    color: $paragraph-color;
                    text-transform: uppercase;
                    margin: {
                        top: 5px;
                        bottom: 3px;
                    }
                    font: {
                        size: 11px;
                    }
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font: {
                        size: 17px;
                        weight: 500;
                    }
                    a {
                        display: inline-block;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                padding-left: 14px;
                font: {
                    size: 15.5px;
                    weight: 400;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $secondary-color;
                    height: 7px;
                    width: 7px;
                    content: "";
                    left: 0;
                    top: 7px;
                    position: absolute;
                }
                a {
                    color: $black-color;
                    display: block;
                    text-decoration: none;
                    &:hover {
                        color: $secondary-color;
                    }
                }
                .post-count {
                    float: right;
                    font-size: 15px;
                }
            }
        }
    }
    .widget_tag_cloud {
        .widget-title {
            margin-bottom: 12px;
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            color: $black-color;
            text-decoration: none;
            font: {
                weight: 400;
                size: 14.5px !important;
            }
            padding: 6px 13px;
            border: 1px dashed #eeeeee;
            margin: {
                top: 8px;
                right: 8px;
            }
            &:hover,
            &:focus {
                color: $white-color;
                background-color: $secondary-color;
                border-color: $secondary-color;
            }
        }
    }
    .widget_event_details {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                border-bottom: 1px solid #eeeeee;
                color: #888f96;
                padding: {
                    bottom: 10px;
                    top: 10px;
                }
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
                span {
                    display: inline-block;
                    color: $black-color;
                    font-weight: 400;
                }
                a {
                    display: inline-block;
                    color: #888f96;
                    text-decoration: none;
                    &:hover {
                        color: $secondary-color;
                    }
                }
            }
        }
    }
}

/*================================================
Pagination Area CSS
=================================================*/

.pagination-area {
    margin-top: 20px;
    text-align: center;
    .page-numbers {
        width: 40px;
        height: 40px;
        margin: 0 5px;
        display: inline-block;
        background-color: $white-color;
        line-height: 40px;
        color: $black-color;
        box-shadow: 0 2px 10px 0 #d8dde6;
        text-decoration: none;
        font: {
            size: 15px;
        }
        &.current,
        &:hover,
        &:focus {
            background: $secondary-color;
            color: $white-color;
            box-shadow: 0 2px 10px 0 #d8dde6;
        }
    }
}

/*================================================
Contact Section CSS
=================================================*/

.contact-box {
    background: #ffffff;
    padding: 25px 25px 25px 95px;
    border-radius: 5px;
    position: relative;
    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    .icon {
        position: absolute;
        left: 25px;
        top: 25px;
        width: 50px;
        height: 50px;
        text-align: center;
        color: #28406d;
        line-height: 47px;
        border: 1px dashed #28406d;
        border-radius: 50%;
        font-size: 22px;
        -ms-transition: 0.4s;
        -o-transition: 0.4s;
        -moz-transition: 0.4s;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
    &:hover {
        box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
        .icon {
            color: #ffffff;
            background: $secondary-color;
            border: 1px dashed $secondary-color;
        }
    }
    .content {
        h4 {
            font-size: 20px;
            margin-bottom: 12px;
            font-weight: 600;
        }
        p {
            margin-bottom: 0;
            font-size: 16px;
        }
        a {
            color: #777777;
            text-decoration: none;
            &:hover {
                color: #28406d;
            }
        }
    }
}

.contact-text {
    margin-top: 40px;
    h3 {
        font-size: 22px;
        margin-bottom: 14px;
        font-weight: 600;
    }
    p {
        margin-top: 0;
    }
    .social-links {
        padding: 0;
        margin: 0;
        list-style-type: none;
        margin-top: 20px;
        li {
            display: inline-block;
            margin-right: 10px;
        }
        a {
            display: inline-block;
            border: 1px solid $black-color;
            width: 35px;
            height: 35px;
            text-align: center;
            line-height: 34px;
            border-radius: 50%;
            font-size: 15px;
            &:hover {
                background: $secondary-color;
                color: #ffffff;
                border-color: $secondary-color;
            }
        }
    }
}

#contactForm, #ambassadorForm {
    margin-top: 40px;
    .form-group {
        margin-bottom: 20px;
        .form-control {
            height: 45px;
            font-size: 14px;
            font-weight: 500;
            padding: 10px 16px;
            border: 1px solid #e7e9f1;
            outline: 0;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            -moz-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            text-align: left;
            &:focus {
                color: #666666;
                background-color: $white-color;
                border-color: rgba(220, 31, 38, 0.5);
                outline: 0;
                box-shadow: none;
            }
        }
    }
    textarea {
        height: 150px !important;
    }
    .contact-send-btn {
        margin-top: 25px;
        .send-btn-one {
            display: inline-block;
            padding: 10px 30px;
            color: $white-color;
            text-transform: capitalize;
            background-color: $secondary-color;
            border: 1px solid $secondary-color;
            transition: $transition;
            border-radius: 30px;
            font-size: 16px;
            font-weight: 500;
            &:hover {
                background-color: $white-color;
                color: $secondary-color;
            }
        }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border: none;
        box-shadow: none;
        padding: 0;
        &:active, &:hover, &:focus {
            box-shadow: none;
        }
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
        color: #63686c
    }
}

#callbackForm {
    @extend #contactForm;
    margin-top: 0px;
}

/*================================================
Features Area CSS
=================================================*/

.features-area {
    position: relative;
    line-height: 1;
    overflow: hidden;
    .features-title {
        text-align: center;
        margin-bottom: 60px;
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;
        span {
            font-size: 18px;
            color: $secondary-color;
            font-weight: bold;
            text-transform: uppercase;
        }
        h3 {
            font-size: 38px;
            color: $black-color;
            font-weight: bold;
            margin: 10px 0 0 0;
            position: relative;
            padding-bottom: 20px;
            &::before {
                position: absolute;
                content: "";
                height: 3px;
                width: 80px;
                background-color: #fab4a2;
                bottom: 0;
                left: 0;
                right: 35px;
                margin: auto;
            }
            &::after {
                position: absolute;
                content: "";
                height: 3px;
                width: 32px;
                background-color: #ff724e;
                bottom: 0;
                margin: 0 auto 0;
                left: 95px;
                right: 0;
            }
        }
    }
    .single-features-item {
        text-align: center;
        padding: 45px 25px;
        position: relative;
        z-index: 1;
        border-radius: 5px;
        margin-bottom: 30px;
        box-shadow: unset;
        h3 {
            font-size: 18px;
            color: #3b3663;
            font-weight: 700;
            margin: 20px 0 10px 0;
            transition: $transition;
        }
        p {
            font-size: 14px;
            margin: 0;
            transition: $transition;
        }
        &.bg-b5a2f8 {
            background: transparent;
            border: 1px dotted #b5a2f8;
            transition: $transition;
            .icon {
                i {
                    display: inline-block;
                    height: 65px;
                    width: 65px;
                    color: #5d43bc;
                    border: 2px dotted #5d43bc;
                    line-height: 65px;
                    border-radius: 50px;
                    transition: $transition;
                    &::before {
                        font-size: 30px;
                    }
                }
            }
            &:hover {
                background-color: #b5a2f8;
                h3 {
                    color: $white-color;
                }
                p {
                    color: $white-color;
                }
                .icon {
                    i {
                        color: $white-color;
                        border: 2px dotted $white-color;
                    }
                }
            }
        }
        &.bg-f27e19 {
            background: transparent;
            border: 1px dotted #f27e19;
            transition: $transition;
            .icon {
                i {
                    display: inline-block;
                    height: 65px;
                    width: 65px;
                    color: #f27e19;
                    border: 2px dotted #f27e19;
                    line-height: 65px;
                    border-radius: 50px;
                    transition: $transition;
                    &::before {
                        font-size: 30px;
                    }
                }
            }
            &:hover {
                background-color: #f27e19;
                h3 {
                    color: $white-color;
                }
                p {
                    color: $white-color;
                }
                .icon {
                    i {
                        color: $white-color;
                        border: 2px dotted $white-color;
                    }
                }
            }
        }
        &.bg-1db294 {
            background: transparent;
            border: 1px dotted #1db294;
            transition: $transition;
            .icon {
                i {
                    display: inline-block;
                    height: 65px;
                    width: 65px;
                    color: #1db294;
                    border: 2px dotted #1db294;
                    line-height: 65px;
                    border-radius: 50px;
                    transition: $transition;
                    &::before {
                        font-size: 30px;
                    }
                }
            }
            &:hover {
                background-color: #1db294;
                h3 {
                    color: $white-color;
                }
                p {
                    color: $white-color;
                }
                .icon {
                    i {
                        color: $white-color;
                        border: 2px dotted $white-color;
                    }
                }
            }
        }
        &.bg-e80d82 {
            background: transparent;
            border: 1px dotted #e80d82;
            transition: $transition;
            .icon {
                i {
                    display: inline-block;
                    height: 65px;
                    width: 65px;
                    color: #e80d82;
                    border: 2px dotted #e80d82;
                    line-height: 65px;
                    border-radius: 50px;
                    transition: $transition;
                    &::before {
                        font-size: 30px;
                    }
                }
            }
            &:hover {
                background-color: #e80d82;
                h3 {
                    color: $white-color;
                }
                p {
                    color: $white-color;
                }
                .icon {
                    i {
                        color: $white-color;
                        border: 2px dotted $white-color;
                    }
                }
            }
        }
    }
}

/*================================================
Faq Section CSS
=================================================*/

.faq-section {
    position: relative;
    line-height: 1;
}

.faq-area-content {
    span {
        font-size: 18px;
        color: $secondary-color;
        font-weight: bold;
        text-transform: uppercase;
    }
    h3 {
        font-size: 38px;
        color: $black-color;
        font-weight: bold;
        margin: 10px 0 40px 0;
        position: relative;
        padding-bottom: 20px;
        &::before {
            position: absolute;
            content: "";
            height: 3px;
            width: 80px;
            background-color: #fab4a2;
            bottom: 0;
            left: 0;
        }
        &::after {
            position: absolute;
            content: "";
            height: 3px;
            width: 32px;
            background-color: #ff724e;
            bottom: 0;
            margin: 0 auto 0;
            left: 95px;
        }
    }
}

.faq-accordion {
    .accordion {
        border: none;
        .accordion__item {
            margin-bottom: 15px;
            border: none;
            .accordion__button {
                background-color: #f7f5fd;
                border-radius: 5px;
                color: #202647;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.5;
                &:focus {
                    outline: 0;
                }
            }
        }
        .accordion__panel {
            background-color: #f2f0f9;
        }
    }
}

// Error Page
.error-area {
    height: 100vh;
}

.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 700px;
    h3 {
        font: {
            size: 35px;
            weight: 700;
        }
        margin: {
            top: 30px;
            bottom: 18px;
        }
    }
    p {
        max-width: 520px;
        margin: 0 auto 20px;
        line-height: 30px;
    }
    .default-btn-one {
        display: inline-block;
        padding: 12px 30px;
        background: $secondary-color;
        border: 1px solid $secondary-color;
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        transition: 0.6s;
        text-decoration: none;
        &:hover {
            text-decoration: none;
            background-color: $white-color;
            color: $secondary-color;
        }
    }
}

/*================================================
Footer Section CSS
=================================================*/

.footer-section {
    background-color: #283360;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    overflow: hidden;
    @extend .montserrat-font-family;
}

.footer-heading {
    margin-bottom: 25px;
    position: relative;
    h3 {
        // font-size: 20px;
        color: $white-color;
        // font-weight: 600;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        margin: 0;
        border-bottom: 1px solid #3c4350;
        padding-bottom: 10px;
    }
}

.footer-area {
    margin-bottom: 10px;
    position: relative;
    .footer-social {
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 20px;
        li {
            display: inline-block;
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
            .icon {
                display: inline-block;
                height: 35px;
                width: 35px;
                line-height: 34px;
                border: 1px solid #d1cfcf;
                border-radius: 50px;
                transition: $transition;
                text-align: center;
                // color: #d1cfcf;
                color: #adb5bd;
                border-radius: 30%;
                font-size: 15px;
                &::before {
                    font-size: 13px;
                }
                &:hover {
                    // background-color: $secondary-color;
                    background-color: $primary-color;
                    color: $white-color;
                    // border: 1px solid $secondary-color;
                    border: 1px solid $primary-color;
                }
            }
        }
    }
    p {
        color: #d1cfcf;
    }
}

.footer-item-area {
    margin-bottom: 30px;
    position: relative;
    .footer-heading {
        margin-bottom: 25px;
        h3 {
            // font-size: 20px;
            color: $white-color;
            // font-weight: 600;
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            margin: 0 0 0 0;
        }
    }
    .footer-quick-links {
        padding-left: 0;
        margin-bottom: 0;
        li {
            list-style-type: none;
            padding-bottom: 16px;
            a {
                display: inline-block;
                text-decoration: none;
                color: #fff;
                &:hover {
                    color: #dbbbbb;
                    transition: $transition;
                }
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}

.rounded-social-buttons {
    .social-links {
        padding: 0;
        margin: 0;
        list-style-type: none;
        margin-top: 20px;
        li {
            display: inline-block;
            margin-right: 10px;
        }
        a {
            display: inline-block;
            border: 1px solid $black-color;
            width: 35px;
            height: 35px;
            text-align: center;
            line-height: 34px;
            border-radius: 50%;
            font-size: 15px;
            &:hover {
                background: $secondary-color;
                color: #ffffff;
                border-color: $secondary-color;
            }
        }
    }
}

/*================================================
Copyright Section CSS
=================================================*/

.copyright-area {
    background-color: #283360;
    padding: {
        top: 35px;
        bottom: 35px;
    }
    p {
        color: #d9d3d3;
        a {
            color: #d9d3d3;
            display: inline-block;
            font-weight: 600;
            &:hover {
                color: $secondary-color;
            }
        }
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        text-align: right;
        li {
            display: inline-block;
            color: #d9d3d3;
            position: relative;
            margin: {
                left: 10px;
                right: 10px;
            }
            a {
                display: inline-block;
                color: #d9d3d3;
                text-decoration: none;
                &:hover {
                    color: $secondary-color;
                }
            }
            &::before {
                content: "";
                position: absolute;
                top: 5px;
                right: -10px;
                width: 1px;
                height: 12px;
                background-color: $white-color;
            }
            &:last-child {
                margin-right: 0;
                &::before {
                    display: none;
                }
            }
            &:first-child {
                margin-left: 0;
            }
        }
    }
}

/*================================================
Go Top CSS
=================================================*/

.back-to-top {
    .top {
        position: fixed;
        cursor: pointer;
        bottom: 25px;
        left: 35px;
        color: $white-color;
        background-color: $secondary-color;
        z-index: 4000;
        width: 40px;
        text-align: center;
        height: 40px;
        line-height: 40px;
        transition: 0.9s;
        border-radius: 50%;
        i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin: 0 auto;
            transition: $transition;
            &:last-child {
                opacity: 0;
                visibility: hidden;
                top: 60%;
            }
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: $black-color;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            border-radius: 50%;
        }
        &:hover,
        &:focus {
            color: $white-color;
            &::before {
                opacity: 1;
                visibility: visible;
            }
            i {
                &:first-child {
                    opacity: 0;
                    top: 0;
                    visibility: hidden;
                }
                &:last-child {
                    opacity: 1;
                    visibility: visible;
                    top: 50%;
                }
            }
        }
    }
}

.contact-inquiry,
.course-request .ant-table-content,
.post-request,
.post-reports {
    .select-box {
        width: 140px;
        margin-left: 4px;
    }
    a {
        color: #283360 !important;
        font-weight: bold !important;
    }
}

.landing-actions-ellipsis {
    font-size: 18px;
}

p.forgot-password {
    text-align: center;
    color: #283360;
}

p.resend-button {
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
    &:hover a {
        cursor: pointer;
    }
}

#otps_code {
    display: block;
    margin: 0 auto;
    border: none;
    padding: 0;
    width: 9.25ch;
    background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey $char-w, transparent 0, transparent $char-w + $gap)
        0 100% / 9.8ch 2px no-repeat;
    font: 5ch droid sans mono, consolas, monospace;
    letter-spacing: $gap;
    &:focus {
        outline: none;
        color: $secondary-color;
    }
}

#otp_code {
    display: block;
    margin: 0 auto;
    border: none;
    padding: 0;
    width: $in-w;
    background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey $char-w, transparent 0, transparent $char-w + $gap)
        0 100% / #{$in-w - $gap} 2px no-repeat;
    font: 5ch droid sans mono, consolas, monospace;
    letter-spacing: $gap;
    &:focus {
        outline: none;
        color: $secondary-color;
    }
}
#phone_otp {
    @extend #otp_code;
    width: 9.989ch;
    background: repeating-linear-gradient(90deg, dimgrey 0, dimgrey $char-w, transparent 0, transparent $char-w + $gap)
        0 100% / #{$in-w-ph - $gap} 2px no-repeat;
}

.header-notifications {
    border-radius: 10px !important;
}

.notifications {
    h4 {
        font-weight: normal !important;
    }
    .ant-list-item {
        padding: 14px;
    }
    .notification-title {
        p {
            line-height: 1.5715 !important;
            color: #000000 !important;
        }
        &:hover {
            color: $primary-color;
        }
    }
    &:hover {
        cursor: pointer;
        background-color: #f0f8ff !important;
    }
}

.header-notifications .ant-spin-container {
    overflow: auto;
    max-height: 360px;
    min-height: 200px;
    width: 350px;
}

.header-notifications .ant-list-footer {
    padding-left: 1rem;
    padding-right: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.03);
}

.empty-box {
    padding: 80px;
}

.landing-notification {
    border-bottom: none !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: none !important;
}

.notification-unread-badge {
    right: 5% !important;
    position: absolute;
    .ant-scroll-number {
        font-size: 18px !important;
    }
}

.read-all-notification {
    &:hover {
        text-decoration: underline !important;
    }
}

#country_code {
    width: 80px;
}

.content-header {
    button {
        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }
    }
    .card-header {
        background-color: #fff !important;
        border-bottom: none !important;
    }
    .card-body {
        border-radius: 10px;
        padding: 20px;
    }
    .actions {
        span {
            cursor: pointer;
        }
    }
}

.btn-outline-primary {
    border-color: #283360 !important;
    color: #283360 !important;
    &:hover {
        color: #fff !important;
        background: #283360 !important;
    }
}

.heading {
    color: $primary !important;
}

.ant-avatar > img {
    height: '100%';
}

.avatar-height {
    height: 100% !important;
}

.content-description {
    height: 100% !important;
}

.tabs_item .tab-image img {
    height: 300px;
}

// FAQ
.faq-banner {
    background-size: 100% 100% !important;
    background: url(https://studyinfocentre.com/Banner/faq.png);
    &::before {
        opacity: 0 !important;
    }
}

.faq-page {
    @extend .montserrat-font-family;
    .ant-collapse-content {
        border-top: 0 !important;
    }
    .ant-collapse-expand-icon {
        svg {
            color: $secondary-color !important;
        }
    }
    .faq-area {
        padding: 70px 0 30px;
        .nav {
            margin-bottom: 55px;
            .nav-item {
                margin: 0 10px;
                a.nav-link {
                    font-size: 18px;
                    padding: 10px;
                    width: 110px;
                    font-weight: 500;
                    text-align: center;
                    color: $black-color;
                    border-radius: 5px;
                    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.09);
                    @media (max-width: 767px) {
                        font-size: 16px;
                        padding: 9px;
                        width: 92px;
                    }
                }
                a.nav-link.active {
                    background: $primary-color;
                    color: #ffffff;
                }
                @media (max-width: 767px) {
                    margin: 0 5px;
                }
            }
            @media (max-width: 575px) {
                margin-bottom: 45px;
            }
        }
        .faq-item {
            margin-bottom: 15px;
            .faq-title {
                .title-icon {
                    background: $secondary-color;
                    height: 14px;
                    text-align: center;
                    margin-right: 12px;
                    position: relative;
                    margin-top: 3px;
                    span {
                        font-size: 12px;
                        color: #ffffff;
                        width: 36px;
                        display: block;
                        line-height: 18px;
                        padding-left: 2px;
                        @media (max-width: 575px) {
                            font-size: 16px;
                            width: 32px;
                            padding-left: 0;
                        }
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        border-width: 10px 18px;
                        border-style: solid;
                        border-top-color: transparent;
                        border-right-color: transparent;
                        border-bottom-color: $secondary-color;
                        border-left-color: transparent;
                        top: -20px;
                        left: 0;
                        z-index: 1;
                        @media (max-width: 575px) {
                            border-width: 8px 16px;
                            top: -16px;
                        }
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        border-width: 10px 18px;
                        border-style: solid;
                        border-top-color: $secondary-color;
                        border-right-color: transparent;
                        border-bottom-color: transparent;
                        border-left-color: transparent;
                        bottom: -20px;
                        left: 0;
                        z-index: 1;
                        @media (max-width: 575px) {
                            border-width: 8px 16px;
                            bottom: -16px;
                        }
                    }
                    @media (max-width: 575px) {
                        height: 19px;
                        margin-right: 10px;
                    }
                }
                .title-text {
                    p {
                        font-size: 16px;
                        color: $paragraph-color;
                        line-height: 20px;
                        @media (max-width: 575px) {
                            font-size: 16px;
                        }
                    }
                }
            }
            .faq-desc {
                p {
                    font-size: 13px;
                    color: $paragraph-color;
                    line-height: 20px;
                    @extend .montserrat-font-family;
                }
            }
            @media (max-width: 575px) {
                margin-bottom: 25px;
            }
        }
        @media (max-width: 767px) {
            padding: 30px 0 0;
        }
    }
}

.card-section {
    padding: 30px 0;
    margin: 0px 0;
}

textarea::-webkit-scrollbar {
    display: none !important;
}

::-webkit-scrollbar:not(div.video-layout) // ::-webkit-scrollbar:not(div.mock-test__reading-component)
{
    display: block;
    width: 10px;
    cursor: pointer;
}

/* Track */

::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
}

/* Handle */

::-webkit-scrollbar-thumb {
    position: relative;
    top: 0px;
    width: 3px;
    height: auto;
    border-radius: 5px;
    scroll-behavior: smooth;
    padding-top: 4px;
}

::-webkit-scrollbar-track {
    cursor: pointer;
}

.testimonial-slides.owl-carousel {
    display: block;
}

.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

.rightControl > i,
.leftControl > i {
    color: #000000 !important;
    top: 50%;
}
@media (max-width: 767px) {
    .leftControl > i {
        left: 0px !important;
    }
    .rightControl > i {
        right: 2px !important;
    }
}
@media (min-width: 767px) {
    .leftControl > i {
        left: -24px !important;
    }
    .rightControl > i {
        right: -22px !important;
    }
}
.carousel-control-next:hover,
.carousel-control-prev:hover {
    background-image: none !important;
}
.course-carousel {
    .carousel-indicators {
        display: none !important;
    }
}

.ant-carousel {
    .slick-next {
        display: block;
        color: #000000;
        &::before {
            content: "";
        }
        @include phone {
            z-index: 100;
            right: 0px !important;
        }
    }
    .slick-prev {
        @media (min-width:1801px ) {
            left: -50px;
        }
        @media (min-width:1300px) and (max-width:1800px) {
            left: -45px;
        }
        @include phone {
            left: -20px !important;
            z-index: 100;
        }
        &::before {
            content: "";
        }
    }
}

.tablet-carousel {
    .slick-next {
        right: 15px !important;

         @include phone {
            right: -10px !important;
        }

    }
}

.badge-primary {
    background-color: rgb(35, 82, 170) !important;
}

.forgot-password-container {
    .card {
        @include media(laptop, tablet) {
            border-radius: 1.5rem !important;
        }
    }
}

.about-us-container {
    h1 {
        font-size: 72px;
        color: $primary;
        font-weight: 500;
        line-height: normal;
        margin: 0 0 20px 0;
        @extend .montserrat-font-family;
    }
    .img-responsive {
        margin: 0 auto;
    }
    img {
        transform: scale(1.8);
    }
    .pull-right {
        margin: 0 0 0 auto;
    }
    .content {
        p {
            line-height: 1.6;
            @extend .montserrat-font-family;
            margin-top: 0;
            margin-bottom: 1rem;
        }
    }
}

.rounded-15px {
    border-radius: 15px;
}

.rounded-top-custom {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.border-primary {
    border-right: 2px solid $primary-color !important;
}

.extra-content-banner {
    @media (max-width: 767px) {
        h6 {
            font-size: 14px !important;
        }
        img {
            width: 24px !important;
        }
    }
}

.custom-message-box{
    .ant-upload-list{
        position: absolute;
    }
}

.wishlist-icon {
    margin-top: 3px;
}