 .card-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: none;
    padding: 1.5rem 1.5rem 0;
    background-color: transparent;
    .card-title {
      margin-bottom: 0;
    }
}

.card-action {
    .card-header {
      padding-bottom: 1.5rem;
      svg {
        cursor: pointer;
      }
    }
    &.card-collapsed {
      .card-header {
        .collapse-icon {
          transform: rotate(180deg);
          transition: all 0.5s ease;
        }
      }
    }
    &.closing {
      .collapse-icon {
        transform: rotate(180deg);
        transition: all 0.5s ease;
      }
    }
    &.opening {
      .collapse-icon {
        transform: rotate(0deg);
        transition: all 0.5s ease;
      }
    }
    &.card-shown {
      .card-header {
        .collapse-icon {
          transform: rotate(0deg);
          transition: all 0.5s ease;
        }
      }
    }
    &.card-reload {
      .reload-spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
      }
      &.refreshing {
        .card-body {
          opacity: 0.5;
        }
      }
    }
  }