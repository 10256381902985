.validation {
  width: 100%;
  margin-top: 0.25rem;
  font-size: smaller;
  color: #ea5455;
}

.pagination-position {
  display: flex;
  justify-content: flex-end;
}

.blue-heading {
  color: #283360 !important;
  font-weight: 600;
}

.item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 16px 25px 12px 30px;
  min-height: 125px;
}

.list-wrapper {
  background-color: #fff;
  border: 2px solid #f6f7f9;
  -webkit-box-shadow: 0 1px 2px 0 #f6f7f9;
  box-shadow: 0 1px 2px 0 #dae1ef;
  margin-bottom: 15px;
  position: relative;
  -webkit-transition: border-color 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  @media screen and (min-width: 901px) and (max-width: 1200px) {
    width: 90%;
  }
  @media screen and (min-width: 1201px) {
    width: 80%;
  }
}

.list-wrapper:hover {
  border-color: #89c540;
  -webkit-animation: grow 1s linear 0s alternate;
  -moz-animation: grow 1s linear 0s alternate;
  -ms-animation: grow 1s linear 0s alternate;
  -o-animation: grow 1s linear 0s alternate;
  animation: grow 1s linear 0s alternate;
  .actions-wrapper {
    display: block;
    min-width: 60px;
  }
  .actions-ellipsis {
    display: none;
  }
}
.logo-wrapper {
  min-width: 160px;
  margin-right: 30px;
  border-radius: 3px;
  text-align: center;
  img {
    max-width: 160px;
    max-height: 110px;
  }
}
.profile-avatar-wrapper {
  min-width: 160px;
  margin-right: 30px;
  border-radius: 3px;
  text-align: center;
  img {
    max-width: 180px;
    max-height: 210px;
  }
}

.content-wrapper {
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.content-title {
  font-size: 16px;
  font-weight: 600;
  color: #42515f;
  margin-bottom: 8px;
  // white-space: nowrap;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-sub {
  font-size: 14px;
  font-weight: 400;
  color: #4d5964;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions-wrapper {
  display: none;
}
.actions-ellipsis {
  display: block;
}

//box style cards
.box-item {
  position: relative;
  // width: 100%;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // align-items: stretch;
  // align-content: stretch;
  margin: 5px;
  // flex: auto;
  background: #f0f0f0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  &:hover {
    // top: -2px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  }
}

.rollover {
  display: block;
  background: #e6eaea;
  transition: all 0.3s ease-in-out;
}
figure {
  position: relative;
  display: inline-block;
}

.rollover .box-photo {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.box-info {
  position: relative;
  clear: both;
  background-color: #ffffff;
  padding: 25px;
  // display: flex;
  // flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 255px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//content image hover effects

.content-hvr {
  position: relative;
  overflow: hidden;
  // max-height: 200px;
  max-width: 255px;
  min-height: 255px;
  min-width: 255px;
  .image-cover {
    object-fit: cover;
    height: 100%;
  }
}
.content-hvr .content-img-text {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
}
.content-hvr .content-img-text h4 {
  color: #fff;
}
.content-hvr .content-img-text p {
  color: #fff;
}

.content-hvr.hvr-effect .content-img-text {
  background: transparent;
  mix-blend-mode: hard-light;
  right: 0;
  text-align: center;
  top: 0;
  opacity: 0;
  transition: transform 0.2s ease-in-out;
}
.content-hvr.hvr-effect .content-img-text h4 {
  font-size: 60px;
  font-weight: bold;
  margin-top: 22%;
}
.content-hvr.hvr-effect:hover .content-img-text {
  opacity: 1;
  background: #153355;
  z-index: 10;
  .transfer-video-modal {
    display: block;
  }
}

.content-hover-icon {
  font-size: 18px;
  &:hover {
    -webkit-animation: grow 1s linear 0s alternate;
    -moz-animation: grow 1s linear 0s alternate;
    -ms-animation: grow 1s linear 0s alternate;
    -o-animation: grow 1s linear 0s alternate;
    animation: grow 1s linear 0s alternate;
  }
}

.content-hover-btn {
  border-radius: 4px;
  background: #fff;
  margin-right: 8px;
  transition: background-color 0.1s ease-in-out;
  width: 30px;
  height: 30px;
  &:hover {
    background: #283360;
    color: #fff;
    border-color: transparent;
    .content-edit-icon {
      color: #fff;
    }
    .content-delete-icon {
      color: #fff;
    }
  }
}

//information > blog

.blog-categories-sidebar {
  border: 1px solid rgba(232, 234, 237, 1);
  box-shadow: 0 0 10px rgb(232 234 237 / 50%);
  padding: 10px;
  background-color: #f9f9f9;
}

//blog try

.band {
  width: 90%;
  max-width: 1240px;
  margin: 0 auto;

  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;

  @media (min-width: 30em) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 60em) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.card-blog {
  background: white;
  text-decoration: none;
  color: #444;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  min-height: 100%;

  // sets up hover state
  position: relative;
  top: 0;
  transition: all 0.1s ease-in;

  &:hover {
    top: -2px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  }

  article {
    padding: 20px;
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h1 {
    font-size: 20px;
    margin: 0;
    color: #333;
  }

  p {
    flex: 1;
    line-height: 1.4;
  }

  span {
    font-size: 12px;
    font-weight: bold;
    color: #999;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin: 2em 0 0 0;
  }

  .thumb {
    padding-bottom: 60%;
    background-size: cover;
    background-position: center center;
  }
}

//cursor pointer
.cursor-pointer {
  cursor: pointer;
}

//model test

// .collapse-section-wrapper{
//     @media screen and (max-width:900px) {
//         width: 100%;
//      }
//      @media screen and (min-width:901px) and (max-width: 1200px) {
//         width: 70%;
//      }
//     @media screen and (min-width: 1201px) {
//           width: 60%;
//      }
// }

//model section

.model-section-wrapper {
  position: relative;
  clear: both;
  background-color: #ffffff;
  padding: 14px;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2px;
  border-radius: 4px;
  display: flex;

  &:hover {
    background-color: #dbe7ef;
    -webkit-animation: grow 1s linear 0s alternate;
    -moz-animation: grow 1s linear 0s alternate;
    -ms-animation: grow 1s linear 0s alternate;
    -o-animation: grow 1s linear 0s alternate;
    animation: grow 1s linear 0s alternate;
    .actions-wrapper {
      display: block;
    }
    .actions-ellipsis {
      display: none;
    }
  }
}

.spin-container {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  // background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.ck.ck-editor {
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}

.video-grid-wrapper {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
}

#video-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: calc(var(--width) * var(--cols));
  background: #202124;
}

.live-video-container {
  width: var(--width);
  height: var(--height);
  background-color: #3a3a3e;
  position: relative;

  &:hover {
    .video-controls {
      display: block;
    }
  }
  video {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 0;
    border: 1px solid #fff;
    object-fit: fill;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0.5em 0.5em 0.5em 1em;
    background: rgba(242, 47, 70, 0.8);
    color: #ede5e5;
    font-size: 16px;
    line-height: 1;
    margin-left: 1px;

    &:after {
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      top: 0;
      right: -2em;
      border: 1em solid rgba(242, 47, 70, 0.8);
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
  }

  .video-controls {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0.5em 0.5em 0.5em 1em;
    color: #ede5e5;
    font-size: 16px;
    line-height: 1;
    margin-left: 1px;
    .expand-button {
      background: transparent;
      border: none;
      color: white;
      font-size: 18px;
      height: 40px;
      border-radius: 50%;
      background: #5f6368;
      margin-left: 8px;
    }
  }
}

#live-class-other-menu {
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: black;
  }
}

.search-mycourse,
.search-mytest {
  margin-right: 38px;
  .ant-input-search {
    width: 260px;
  }
  .ant-input-search:hover,
  .ant-input:focus,
  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: 1px solid #89c540 !important;
    outline: #89c540 !important;
  }
}

.table {
  width: 100% !important;
  overflow-x: auto;
}

.course-doubt-comment {
  .comment-actions {
    display: none;
  }
  &:hover {
    .comment-actions {
      display: block;
    }
  }
}

// #whiteboard-wrapper{
.whiteboard {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border: 1px solid black;
  cursor: url("../images/white-circle.webp") !important;
}
// }

.whiteboard-button-grp {
  display: flex;
  position: absolute;
  top: 10px;
  left: 10px;

  .colors {
    position: fixed;
  }
  .color {
    display: inline-block;
    height: 48px;
    width: 48px;
  }
  .color.black {
    background-color: black;
  }
  .color.red {
    background-color: red;
  }
  .color.green {
    background-color: green;
  }
  .color.blue {
    background-color: blue;
  }
  .color.yellow {
    background-color: yellow;
  }
  .color.white {
    background-color: white;
  }
}

.preview-local-video {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100%;

  .local-video-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;

    #localVideoPreview {
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px rgb(60 64 67 / 30%), 0px 1px 3px 1px rgb(60 64 67 / 15%);
      flex: 0 1;
      margin: 16px 8px 16px 16px;
      // min-width: 448px;
      // overflow: hidden;
      position: relative;
    }

    .video-controls {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0.5em 0.5em 0.5em 1em;
      color: #ede5e5;
      font-size: 16px;
      line-height: 1;
      margin-left: 1px;
    }
  }
}

.wrapper-wboard {
  height: 500px;
  width: 500px;
  margin: 10px;
}

#cont {
  position: relative;
  width: 500px;
  height: 500px;

  .canvas-container {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }

  canvas {
    border: 1px solid;
  }
}

#cookieBanner {
  display: none;

  .cookie-banner {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
    align-items: center;
    transition: display 2s ease;
    z-index: 9999;
    width: 365px;
    @media (max-width: 590px){
       left: 0;
      right: 0;
      bottom: 0;
      width: fit-content;
      margin: 10px;
    }
  }
  .cookie-banner-message {
    font-size: 1rem;
    font-weight: 400;
  }
  @media print {
    .cookie-banner {
      display: none;
    }
  }
}

.alice-carousel__next-btn-item,
.alice-carousel__prev-btn-item {
  span {
    position: absolute;
    z-index: 1;
    width: 2.5rem;
    // margin: 0px 32px;
    cursor: pointer;
    background-color: #89c540;
    // transform: translateY(-20%);
    border: 1px solid #89c540;
    border-radius: 100%;
    padding: 14px;
    color: #fff;
    align-items: center !important;
    &:hover {
      box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);
      background-color: hsl(358, 75%, 47%);
      border: 1px solid hsl(358, 75%, 47%);
    }
    @media (max-width: 767px) {
      width: 1.8rem;
      height: 2rem;
    }
  }
}
.alice-carousel__next-btn-item {
  span {
    right: -10px;
    top: 38%;
  }
}
.alice-carousel__prev-btn-item {
  span {
    left: -10px;
    top: 38%;
  }
}

.question-section,
.app-sidebar-content {
  .anticon {
    vertical-align: 0rem !important;
  }
}

.ant-input {
  &:hover {
    border-color: rgba(25, 86, 156, 0.6) !important;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgb(25 86 156 / 20%);
  }
}

.title-overflow-control {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-outline-danger {
  &:hover {
    color: #fff !important;
  }
}

.circle-singleline {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  // background: black;
  color: #000;
  border: 1px solid black;
}

.poppins-sans-serif {
  font-family: "Poppins", "sans-serif";
}
.paymentModal-Stripe {
  #stripePayment {
    font-family: "Poppins", "sans-serif";
    .App {
      text-align: center;
    }

    .App-logo {
      height: 40vmin;
      pointer-events: none;
    }

    @media (prefers-reduced-motion: no-preference) {
      .App-logo {
        animation: App-logo-spin infinite 20s linear;
      }
    }

    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    label {
      color: rgba(26, 26, 26, 0.7);
      font-size: 13px;
      font-weight: 500;
    }
    input,
    .card-element {
      appearance: none;
      background: #fff;
      border: 0;
      box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
      color: rgba(26, 26, 26, 0.9);
      font-size: 16px;
      height: 40px;
      line-height: 1.5;
      position: relative;
      transition: box-shadow 0.08s ease-in, color 0.08s ease-in, filter 50000s;
      width: 100%;
      border-radius: 4px;
      padding: 10px 12px;
    }
    .StripeElement {
      box-sizing: border-box;
      height: 40px;
      padding: 10px 12px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      background-color: white;
      box-shadow: 0 1px 3px 0 #e6ebf1;
      -webkit-transition: box-shadow 150ms ease;
      transition: box-shadow 150ms ease;
    }

    .StripeElement--focus {
      box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
      border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
      background-color: #fefde5 !important;
    }

    .stripe-form {
      margin: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      /*align-items: center;*/
      width: 30%;
      border: 2px solid #32325d;
      border-radius: 5px;
      /*background: #61dafb;*/
    }

    .SubmitButton {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border: 0;
      border-radius: 6px;
      box-shadow: inset 0 0 0 1px rgb(50 50 93 / 10%), 0 2px 5px 0 rgb(50 50 93 / 10%), 0 1px 1px 0 rgb(0 0 0 / 7%);
      color: #fff;
      cursor: pointer;
      height: 44px;
      margin-top: 12px px;
      outline: none;
      overflow: hidden;
      padding: 0;
      position: relative;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      transition: all 0.2s ease, box-shadow 0.08s ease-in;
      width: 100%;
      background-color: #283360;
      color: rgba(255, 255, 255, 0.7);
    }

    .form-row {
      display: flex;
      flex-direction: column;
    }
    .form-input {
      margin: 10px 0;
      border: none;
      outline: none;
      padding: 0 15px;
      font-size: 15px;
    }

    .card-errors {
      color: red;
    }
  }
  .ant-modal-header,
  .ant-modal-content {
    border-radius: 12px;
    /* background: red; */
  }
  .ant-modal-body,
  .ant-modal-header {
    border-bottom: none !important;
  }
  .ant-modal-footer {
    border-top: none !important;
  }
}

@media print {
  .pdf-container {
    display: none;
  }
}

.react-pdf__Page__canvas {
  border: 1px solid black;
}

.ant-drawer {
  z-index: 1500 !important;
}

.reviewtip {
  position: relative;
  // display: inline-block;
  // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.reviewtip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgb(0, 0, 0);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.reviewtip:hover .tooltiptext {
  visibility: visible;
}

#audio-player {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  margin: 6px 0;
  background-color: #a6bfdb;
  border: #5bc3d2;

  .song {
    user-select: none;
    margin: 0 10px;

    .song__title {
      text-align: left;
      margin: 0;
      color: #000;
      font-family: "Permanent Marker", cursive;
      font-weight: normal;
      font-size: 3.5em;

      &:hover {
        color: #5bc3d2;
      }
    }

    .song__artist {
      margin: 0;
      color: #000;
      font-family: "Rock Salt", cursive;
      font-weight: normal;
      font-size: 1em;

      &:hover {
        color: #5bc3d2;
        cursor: pointer;
      }
    }
  }

  .controls {
    flex-grow: 1;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .player__button {
    width: fit-content;
    background-color: transparent;
    border: none;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;

      svg {
        color: #153355;
      }
    }

    svg {
      font-size: 4em;
      color: #153355;
    }
  }

  .bar {
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;

    .bar__time {
      color: #000;
      font-size: 16px;
    }

    .bar__progress {
      flex: 1;
      border-radius: 5px;
      margin: 0 20px;
      height: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .bar__progress__knob {
        position: relative;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: #153355;
      }
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;

    .controls {
      width: 100%;
      margin-top: 20px;
    }

    .bar {
      width: 90%;
    }
  }

  @media screen and (max-width: 500px) {
    .song {
      .song__title {
        font-size: 2.5em;
      }

      .song__artist {
        font-size: 0.8em;
      }
    }
  }
}
.link-preview-card {
  border-radius: 14px !important;
  &:hover {
    background-color: rgba(218, 225, 239, 0.14);
  }
  img {
    border-radius: 14px !important;
  }
  .card-text {
    text-overflow: ellipsis;
    font-size: "14px";
    white-space: "nowrap";
    overflow: "hidden";
    color: #000;
  }
  .link-website {
    color: #757575;
    font-size: 12px;
    line-height: 12px;
    word-break: break-word;
  }
  .close-button {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    position: absolute;
    left: 6px;
    // right: 16px;
    right: 0;
    top: -4px;
    min-width: 24px;
    span {
      padding: 4px;
      border-radius: 50%;
      min-width: 24px;
      object-fit: cover;
      overflow: hidden;
      width: 24px;
      background-color: #d9d9d9;
      svg {
        color: #000;
        margin: auto;
      }
    }
  }
}
.custom-text-area.ant-input {
  &:focus {
    box-shadow: none !important;
    border-color: #dee2e6 !important;
  }
  &:hover {
    border-color: #dee2e6 !important;
  }
}

.border-18 {
  border-radius: 18px !important;
}
.border-14 {
  border-radius: $border-radius-learnsic-sm !important;
}
.border-10 {
  border-radius: 10px !important;
}
.border-left-sm {
  border-bottom-left-radius: $border-radius-learnsic-sm !important;
  border-top-left-radius: $border-radius-learnsic-sm !important;
}
.border-right-sm {
  border-bottom-right-radius: $border-radius-learnsic-sm !important;
  border-top-right-radius: $border-radius-learnsic-sm !important;
}
.gray-da-text {
  color: #424242;
}
.country-code-selector {
  input {
    &:focus {
      border: none;
      // outline: none;
    }
  }
}

.arrow-common {
  top: 40% !important;
  transition: 0.3s ease-in-out;
  z-index: 1001 !important;
  svg {
    position: absolute;
    top: 8px;
    left: 12px;
    color: #283360 !important;
    font-size: 64px;
    transition: 0.3s ease-in-out;
    font-weight: normal !important;
    opacity: 0.8;
    @media (max-width: 767px) {
      font-size: 32px;
    }
  }
  img {
    position: absolute;
    top: 8px;
    left: 12px;
    opacity: 0.8;
  }
  &:hover {
    svg,
    img {
      opacity: 1;
    }
  }
}

.relatedCoursesCarousel {
  .arrow-right {
    right: -30px;
  }
  .arrow-left {
    left: -40px !important;
  }
}
.isDetailPageCarousel {
  .arrow-right {
    right: -32px;
    @media (max-width: 1181px) {
      right: -5px !important;
    }
  }
}
.arrow-right {
  @extend .arrow-common;
  right: -33px;
  @media (min-width: 1181px) {
    right: -36px;
  }
  @media (max-width: 767px) {
    right: -15px !important;
  }
}
// .arrow-right.slick-next {
//   width: 48px !important;
// }
// .arrow-left.slick-prev {
//   width: 48px !important;
// }
.arrow-left {
  @extend .arrow-common;
  @media (max-width: 1181px) {
    left: -37px !important;
  }
  @media (max-width: 767px) {
    left: -28px !important;
  }
}

.custom-display-text {
  margin: 0;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow-y: hidden;
  text-overflow: ellipsis;
  color: #424242;
  overflow: hidden;
  line-height: 2rem;
}
.custom-font-fw300-1314 {
  font: normal 300 1.3rem/1.4rem Roboto, Arial, Helvetica, serif;
}
.custom-font-fw500-1314 {
  @extend .montserrat-font-family;
  font-weight: 500 !important;
}

.tranform-up-hover {
  transition: all 0.6s ease;
  &:hover {
    transform: translateY(-10px);
  }
}

.border-bottom-dotted {
  border-top: 1px dashed #6a6c72;
  border-width: 2px;
}

.border-bottom-solid {
  border-top: 1px solid #6a6c72;
  border-width: 2px;
}

.gradient-text-style {
  -webkit-mask-image: linear-gradient(#f0f0f0, #f0f0f0, rgba(255, 255, 255, 0));
  mask-image: linear-gradient(#f0f0f0, #f0f0f0, rgba(255, 255, 255, 0));
}

.poppins-font-family {
  font-family: "Poppins", "sans-serif" !important;
}

.montserrat-font-family {
  font-family: "Montserrat", "sans-serif" !important;
}
.mobile-search-global {
  .ant-input {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
      border-color: #dee2e6 !important;
    }
    &:hover {
      border-color: #dee2e6 !important;
    }
  }
  .ant-select-dropdown {
    z-index: 1001 !important;
  }
}
.global-search-website,
.notes-sidebar {
  .ant-input {
    height: 40px !important;
    border: 1px solid #d3d3d3 !important;
    border-top-left-radius: $border-radius-learnsic-sm !important;
    border-bottom-left-radius: $border-radius-learnsic-sm !important;
  }
  .ant-input-search-button {
    background: #fff !important;
    color: #000 !important;
  }
  .ant-input-group-addon {
    background-color: transparent !important;
  }
  .ant-btn-primary {
    @extend .border-customize-rltb;
    height: 40px !important;
    box-shadow: none !important;
    &:hover {
      @extend .border-customize-rltb;
      height: 40px !important;
    }
  }
  .ant-btn-lg {
    font-size: 24px !important;
  }
  .anticon {
    vertical-align: 2px !important;
  }
  .anticon-search {
    color: #6c757d !important;
  }

  .ant-input:focus {
    outline: none !important;
    box-shadow: none !important;
    border-right: none !important;
  }
  .ant-select-dropdown {
    border-radius: $border-radius-learnsic-sm !important;
  }
}

.border-customize-rltb {
  border-left-color: transparent !important ;
  border-right-color: #d3d3d3;
  border-top-color: #d3d3d3;
  border-bottom-color: #d3d3d3;
  border-top-right-radius: $border-radius-learnsic-sm !important;
  border-bottom-right-radius: $border-radius-learnsic-sm !important;
}

.custom-tooltip {
  .ant-tooltip-inner {
    background-color: #f8f9fc !important;
    color: rgba(0, 0, 0, 0.85) !important;
  }
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: linear-gradient(to right bottom, #f8f9fc, #f8f9fc) !important;
  }
  p,
  ul,
  li {
    font-size: 12px !important;
  }
}
.banner-form {
  .ant-select-selector {
    border-radius: 10px !important;
  }
}

.ant-input::placeholder {
  color: #6c757d !important;
  @extend .montserrat-font-family;
  @extend .font-size-sm;
  font-weight: 500 !important;
}

.ant-select-selection-placeholder{
  color: #6c757d !important;
  @extend .montserrat-font-family;
  font-weight: 500 !important;
}

.country-code-selector {
  input:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.landing-video {
  .ant-modal-content {
    background-color: transparent !important;
  }
}

.custom-search {
  .ant-input-affix-wrapper {
    border-top-left-radius: 14px !important;
    border-bottom-left-radius: 14px !important;
  }
  .ant-input-group-addon {
    background-color: #fff !important;
  }
  .ant-btn-primary {
    @extend .border-customize-rltb;
    background-color: #fff !important;
    box-shadow: none !important;
    color: #000 !important;
    &:hover {
      @extend .border-customize-rltb;
    }
    &:focus {
      border-color: #d9d9d9 !important;
      border-left-color: #fff !important;
    }
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper::selection,
  .ant-input-affix-wrapper-focused {
    outline: none !important;
    box-shadow: none !important;
    border-color: #d9d9d9 !important;
    border-right: none !important;
  }
}
.ant-badge-multiple-words {
  padding: 0 2px !important;
  font-size: 10px !important;
}

.btn-danger,
.btn-primary,
.btn-secondary,
.btn-muted,
.btn-outline-danger,
.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-muted {
  border-radius: $border-radius-learnsic-sm !important;
  @media (max-width: 767px) {
    border-radius: $border-radius-learnsic-xs !important;
  }
}
.btn-danger,
.btn-primary,
.btn-secondary,
.btn-muted,
.btn-outline-danger,
.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-muted {
  &:focus {
    outline: none !important;
    box-shadow: none;
  }
}
.btn-danger, .ant-btn-danger,
.btn-secondary {
  background-color: #89c540 !important;
  &:hover {
    background-color: #89c540 !important;
  }
  border-color: #89c540 !important;
}
input.ant-input-lg {
  font-size: $font-size-14 !important;
}
.slick-dots {
  li {
    height: 15px !important;
    border-radius: 50% !important;
    width: 15px !important;
  }
  button {
    height: 15px !important;
    border-radius: 50% !important;
    padding: 0px !important;
    width: 15px !important;
  }
}
.ant-carousel .slick-dots li.slick-active button {
  background-color: $color-green !important;
  border-color: #283360 !important;
  padding: 0px !important;
  width: 15px !important;
  height: 15px !important;
}
.ant-modal-content {
  border-radius: 10px;
}
// #f65868
// #5375e2

.nav-drawer {
  .ant-drawer-header {
    background: #f8f9fa !important;
    padding: 20px 14px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4) !important;
  }
  .ant-drawer-body {
    padding: 0px !important;
  }
  .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.4) !important;
  }
}
.search-drawer {
  .ant-drawer-header {
    background: #ffffff !important;
    padding: 12px 8px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4) !important;
  }
  .ant-input:focus {
    border-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
  }
  .ant-drawer-body {
    padding: 0px !important;
  }
}
.ant-btn.btn-outline-danger:focus-visible {
  color: #89c540 !important;
}
.btn-outline-danger:hover {
  background-color: #89c540 !important;
}
.btn-outline-danger {
  border: 2px solid #89c540 !important;
  border-color: #89c540 !important;
  color: #89c540 !important;
  &:hover {
      transition: 0.4s ease-in-out;
      background-color: #89c540 !important;
      color: #fff !important;
  }
}

.ant-btn {
  span {
    font-weight: 500;
  }
}

.dashboard-layout {
  .ant-table-thead > tr > th {
    background: #283360 !important;
    color: #fff !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
}
// .slick-slide {
//   width: auto !important;
// }

.hover-zoom:hover{
  transform: scale(1.02);
}
.custom-container{
  max-width: 1600px;
  margin: 0 auto !important;
  padding: 0 68px !important;
 @media screen and (max-width: 640px){
    padding: 0 16px !important;
 }
}

///In order to display options, calander dropdown inside modal
.task{
  .ant-picker-dropdown{
    z-index: 9999;
  }
}

.ant-select-multiple .ant-select-selection-item {
  height: 100% !important;
}

.text-hover {
  &:hover{
    text-decoration: underline;
  }
}

.btn-hover-border{
  &:hover {
    border: 2px solid #283360 !important;
  }
}

.notes-html-content{
  & h1{
    font-size: 24px;
  }

  & h2{
    font-size: 18px;
  }

  & h3{
    font-size: 17px;
  }

  & h4{
    font-size: 16px;
  }

  & h5{
    font-size: 14px !important;
  }
}

.blur-background {
  filter: blur(10px); 
}

.ant-badge-count{
  background: #89c540;
  color: $black;
}


.ant-spin{
  color: #89c540 !important;
}

.ant-spin-dot-item{
  background-color: #89c540 !important;
}

.ant-progress-bg{
  background-color: #283360 !important;
}

.ant-menu-light .ant-menu-item:hover {
  color: $primary;
}

.ant-input:hover, .ant-picker:hover ,.ant-input:focus,.ant-picker:focus ,.ant-picker-focused ,.ant-input-focused,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector  {
  border-color: $primary !important;
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus{
  background-color: $white !important;
  border-color: $primary !important;
  color: $primary !important;
}

::selection{
  background-color: $primary;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today,
.ant-alert-info ,
.vx-checkbox,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover ,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child ,
.ant-pagination-item-active, .ant-pagination-item:hover{
  border-color: $primary;
}

.ant-tag-processing,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link {
  border-color: $primary;
  color: $primary;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today, .ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-alert-info ,
.ant-tag-processing {
  background-color:  #e5ebff;
}

.ant-alert-info .ant-alert-icon,
.ant-menu-item-selected
// .ant-btn:focus
 {
  color: $primary;
}

.ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child{
  border-right-color: $primary;
}

.ant-input-search-button  {
  border-left: none !important;
}

.ant-input:hover + .ant-input-group-addon .ant-input-search-button,
.ant-input:focus + .ant-input-group-addon .ant-input-search-button  {
  border-color: #283360 !important;
}