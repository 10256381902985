@keyframes bounce {
   0% {
      opacity: 1;
      transform: translateY(0px);
   }
   50% {
      opacity: 0.7;
      transform: translateY(-20px);
   }
   100% {
      opacity: 1;
      transform: translate(0px);
   }
}
.custom-antd-dragger:hover{
      .upload-icon{
              transform: translateY(-20px);
               animation: bounce 1s ease-in-out infinite;  
           
        }
    
}
.custom-antd-dragger{
  background:#a3d7e7;
  border-radius:0;
  color:#fff;
  position:relative;
  overflow:hidden;  
  &::before {
    background:lighten(#a3d7e7,20%);
    content:"";
    position:absolute;
    height:300%;
    width:120%;
    left:100%;
    bottom:100%;
    transition: left .2s linear .2s, bottom .2s linear .2s;
    transform:rotate(45deg);
    opacity:.4;
    z-index:0;
  }
  &::after {
    background:darken(#a3d7e7,5%);
    content:"";
    position:absolute;
    height:300%;
    width:120%;
    left:100%;
    bottom:100%;
    transition: left .2s linear, bottom .2s linear;
    transform:rotate(45deg);
    opacity:.6;
    z-index:0;   
    color:#fff
  }
  &:hover, &:focus {
    &::before, &::after {
      left:0;
      bottom:-100%;
    }
    &::after {
      transition: left .2s linear .4s, bottom .2s linear .4s;
    }
  }
  span{
    position:relative;
    z-index:1;
  }
}