@media only screen and (max-width: 767px) {
    // Navbar
    .navbar-area {
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        background-color: #fff;
        .navbar-brand {
            img {
                max-width: 120px;
            }
        }
    }
    .neemo-nav {
        .navbar {
            .navbar-nav {
                margin-top: 8px !important;
                max-height: 60vh;
                overflow-y: scroll;
                border-top: 1px solid #eee;
                padding-top: 5px;
                &::-webkit-scrollbar {
                    width: 7px;
                }
                &::-webkit-scrollbar-track {
                    background: #283360;
                }
                &::-webkit-scrollbar-thumb {
                    background: #888;
                }
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
                .nav-item {
                    padding-top: 6px;
                    padding-bottom: 6px;
                    > a {
                        margin: 0;
                        i {
                            display: none;
                        }
                    }
                    .dropdown-menu {
                        position: relative;
                        width: 97%;
                        top: 0;
                        opacity: 1;
                        visibility: visible;
                        margin-top: 5px;
                        padding-top: 10px;
                        padding-left: 15px;
                        padding-right: 15px;
                        padding-bottom: 10px;
                        box-shadow: none;
                        border: 1px solid #eee;
                        li {
                            a {
                                padding: 4px 0;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .others-options {
                position: absolute;
                top: 10px;
                right: 50px;
                .burger-menu {
                    margin-left: 20px;
                }
            }
        }
    }
    .sidebar-modal {
        .sidebar-modal-inner {
            width: 100%;
            padding-top: 60px;
            padding-bottom: 30px;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .search-overlay.search-popup {
        width: 250px;
        right: -58px;
        padding: 15px;
    }
    .section-title {
        margin-bottom: 40px;
        span {
            font-size: 13px;
        }
        h3 {
            font-size: 25px !important;
            margin: 8px 0 0 0;
        }
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    .default-btn-one {
        padding: 8px 20px;
        font-size: 14px;
    }
    .shape-img1 {
        display: none;
    }
    .shape-img2 {
        display: none;
    }
    .shape-img3 {
        display: none;
    }
    .shape-img4 {
        display: none;
    }
    .shape-img5 {
        display: none;
    }
    .shape-img6 {
        display: none;
    }
    .shape-img7 {
        display: none;
    }
    .shape-img8 {
        display: none;
    }
    .shape-img9 {
        display: none;
    }
    .shape-img10 {
        display: none;
    }
    .main-banner {
        height: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
    }
    .main-banner-content {
        h1 {
            font-size: 35px !important;
            margin: 0 0 10px 0;
        }
        p {
            font-size: 12px;
        }
        .banner-btn {
            margin-top: 25px;
            .popup-youtube {
                margin-left: 14px;
            }
        }
    }

    .banner-bg-text {
        font-size: 100px;
        left: 0;
        text-align: center;
    }
    .features-section {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .col-lg-7 .col-lg-6:nth-child(3) .single-features-item {
        margin-top: -40px;
        margin-bottom: 30px;
    }
    .col-lg-7 .col-lg-6:nth-child(2) .single-features-item {
        margin-top: 0;
    }
    .col-lg-7 .col-lg-6:nth-child(2) .single-features-item {
        margin-top: 0;
        margin-bottom: 75px;
    }
    .features-content-area {
        span {
            font-size: 13px;
        }
        h3 {
            font-size: 25px;
            margin: 10px 0 25px 0;
            text-align: center;
            &::before {
                left: 100px;
            }
            &::after {
                left: 190px;
            }
        }
        p {
            margin-bottom: 15px;
        }
        .features-btn {
            margin-top: 25px;
            margin-bottom: 35px;
        }
    }
    .single-features-item {
        padding: 25px 20px;
    }
    .about-area-content {
        margin-top: 30px;
        span {
            font-size: 13px;
        }
        h3 {
            font-size: 25px;
            margin: 10px 0 25px 0;
        }
        strong {
            font-size: 15px;
        }
        p {
            margin: 12px 0 0 0;
        }
    }
    .single-fun-facts {
        margin-top: 20px;
        text-align: center;
        h3 {
            font-size: 25px;
            .sign-icon {
                font-size: 25px;
            }
        }
        p {
            margin: 6px 0 0 0;
            font-size: 14px;
        }
    }
    .single-services-box {
        h3 {
            font-size: 18px;
        }
    }
    .choose-section {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .choose-content-area {
        span {
            font-size: 13px;
        }
        h3 {
            font-size: 22px;
            margin: 10px 0 20px 0;
        }
        .choose-btn {
            margin-top: 25px;
            margin-bottom: 30px;
        }
    }
    .boosting-list-tab {
        .tabs {
            li {
                -ms-flex: 0 0 50%;
                -webkit-box-flex: 0;
                flex: 0 0 48%;
                max-width: 46%;
                font-size: 15px;
                padding-right: 10px;
                padding-top: 12px;
                padding-bottom: 12px;
                padding-left: 10px;
                margin-top: 10px;
                span {
                    display: block;
                    margin-top: 4px;
                    font-size: 12px;
                }
                i {
                    font-size: 30px;
                }
                &.bg-eff7e9 {
                    background: unset;
                }
                &.bg-fff8f0 {
                    background: unset;
                }
                &.bg-ecfaf7 {
                    background: unset;
                }
                &.bg-f2f0fb {
                    background: unset;
                }
                &.bg-c5ebf9 {
                    background: unset;
                }
            }
        }
        .tab_content {
            padding: 10px 10px;
            box-shadow: none;
            .tabs_item {
                .content {
                    h2 {
                        margin: 30px 0 10px 0;
                    }
                }
                .tab-text-content {
                    margin-top: 25px;
                    padding-left: 45px;
                    i {
                        &::before {
                            font-size: 30px;
                        }
                    }
                }
                .tab-shape {
                    width: 0px;
                }
                .tab-btn {
                    margin-top: 20px;
                }
                .tab-image {
                    text-align: center;
                    margin: 0 auto;
                    img {
                        height: 200px;
                    }
                }
            }
        }
    }
    .project-section {
        padding-bottom: 20px;
    }
    .filter-menu {
        margin-bottom: 30px;
        li {
            font-size: 14px;
            margin-right: 18px;
        }
    }
    .testimonial-content-area {
        span {
            font-size: 13px;
        }
        h3 {
            font-size: 22px;
            margin: 10px 0 20px 0;
            text-align: center;
        }
    }
    .testimonial-slides {
        position: relative;
        .testimonial-single-item {
            margin-top: 100px;
        }
    }
    .testimonial-slides.owl-theme .owl-dots {
        position: relative;
        right: 0;
        left: 0;
    }
    .testimonial-slides.owl-theme .owl-nav {
        margin-top: 0;
    }
    .analysis-area-content {
        margin-top: 30px;
        span {
            font-size: 13px;
        }
        h3 {
            font-size: 22px;
            margin: 10px 0 20px 0;
        }
    }
    .contactForm {
        margin-top: 10px;
    }
    .blog-item {
        margin-bottom: 30px;
        .single-blog-item {
            .blog-content {
                h3 {
                    font-size: 18px;
                }
            }
        }
        @include phone{
            margin-bottom: 0px;
        }
    }
    .subscribe-content {
        text-align: center;
        margin-bottom: 30px;
        .sub-title {
            font-size: 14px;
        }
    }
    .newsletter-form {
        text-align: center;
        button {
            position: relative;
            margin-top: 30px;
        }
    }
    .footer-heading {
        margin-bottom: 15px;
        h3 {
            font-size: 20px;
        }
    }
    .footer-item-area {
        .footer-heading {
            margin-bottom: 15px;
            h3 {
                font-size: 20px;
            }
        }
    }
    .home-banner-two {
        height: 100%;
        padding-top: 130px;
        padding-bottom: 60px;
        .main-banner-content {
            h1 {
                font-size: 20px;
                margin: 0 0 10px 0;
            }
            p {
                font-size: 15px;
            }
            .banner-btn {
                margin-top: 30px;
                .popup-youtube {
                    font-size: 14px;
                    margin-left: 14px;
                }
            }
        }
        .banner-img-wrapper {
            .banner-img-1 {
                display: none;
            }
            .banner-img-2 {
                display: none;
            }
        }
        .banner-form {
            padding: 40px 20px;
            margin-top: 30px;
            form {
                .form-group {
                    margin-bottom: 20px;
                    .validation {
                        position: absolute;
                        margin-bottom: 10px;
                        color: $secondary-color;
                        font-size: 12px;
                    }
                }
                .form-control {
                    height: 50px;
                    padding: 10px;
                    font-size: 14px;
                    color: #0e314c;
                    border: 1px solid #eeeeee;
                    border-radius: 0;
                    background: #fafafa;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                    &:focus {
                        outline: 0;
                        box-shadow: none;
                    }
                }
                .btn {
                    font-weight: 400;
                    font-size: 16px;
                    border: none;
                    padding: 15px 40px;
                    position: relative;
                    border-radius: 4px;
                    z-index: 1;
                    text-transform: capitalize;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                    width: 100%;
                    margin-top: 10px;
                }
                .btn-primary {
                    color: $white-color;
                    background-color: $secondary-color;
                    -webkit-box-shadow: 0 13px 27px 0 rgba(255, 255, 255, 0.25);
                    box-shadow: 0 13px 27px 0 rgba(255, 255, 255, 0.25);
                    transition: $transition;
                    border: 1px solid $secondary-color;
                    &:hover {
                        background-color: $white-color;
                        color: $secondary-color;
                    }
                }
                label {
                    color: $black-color;
                    font-size: 15px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .partner-title {
        margin-bottom: 30px;
        h3 {
            font-size: 18px;
        }
    }
    .features-area {
        .features-title {
            margin-bottom: 30px;
            span {
                font-size: 13px;
            }
            h3 {
                font-size: 25px;
            }
        }
        .single-features-item {
            padding: 30px 20px;
        }
    }
    .tab-content {
        .tabs-item {
            .single-pricing-box {
                &.bg-f6f5fb {
                    margin-top: 0px;
                }
                &.bg-ed0678 {
                    margin-top: 30px;
                }
                &.bg-edfbf8 {
                    margin-top: 30px;
                }
            }
        }
    }
    .portfolio-section {
        padding-bottom: 20px;
    }
    .client-slides {
        .testimonial-single-item {
            .testimonial-content-text {
                .icon {
                    margin-top: 10px;
                    margin-bottom: 16px;
                }
            }
        }
    }
    .faq-area-content {
        span {
            font-size: 13px;
        }
        h3 {
            font-size: 26px;
        }
    }
    .subscribe-section {
        padding-top: 50px;
        padding-bottom: 50px;
        .subscribe-content {
            margin-bottom: 0;
            .sub-title {
                margin-bottom: 8px;
                font-size: 15px;
            }
            h2 {
                font-size: 25px;
            }
        }
        .newsletter-form {
            button {
                position: relative;
                margin-top: 0;
            }
        }
    }
    .single-portfolio-box {
        .portfolio-hover-content {
            padding: 25px 20px;
        }
    }
    .contact-box {
        margin-bottom: 30px;
    }
    .contact-text {
        margin-top: 0;
    }
    .copyright-area {
        text-align: center;
        ul {
            text-align: center;
            margin-top: 10px;
        }
    }
    .services-details-overview {
        margin-bottom: 0;
        .services-details-desc {
            h3 {
                font-size: 20px;
            }
        }
    }
    .services-details-image {
        img {
            margin-bottom: 20px;
            margin-top: 20px;
        }
    }
    .projects-details-desc {
        .project-details-info {
            .single-info-box {
                -ms-flex: unset;
                -webkit-box-flex: unset;
                flex: unset;
                max-width: unset;
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 15px;
            }
        }
    }
    .pagination-area {
        .page-numbers {
            width: 36px;
            height: 36px;
            line-height: 36px;
        }
    }
    .blog-details-desc {
        .article-content {
            h3 {
                margin-bottom: 10px;
                margin-top: 20px;
                font-size: 18px;
            }
        }
        .article-footer {
            text-align: center;
            .article-tags {
                -ms-flex: unset;
                -webkit-box-flex: unset;
                flex: unset;
                max-width: unset;
                margin-bottom: 20px;
            }
            .article-share {
                -ms-flex: unset;
                -webkit-box-flex: unset;
                flex: unset;
                max-width: unset;
            }
        }
    }
    blockquote,
    .blockquote {
        padding: 24px !important;
    }
    blockquote p,
    .blockquote p {
        font-size: 16px !important;
    }
    .comments-area {
        margin-bottom: 30px;
        .comment-respond {
            .form-submit {
                input {
                    padding: 10px 30px;
                    font-size: 14px;
                }
            }
        }
    }
    .team-page-section {
        padding-top: 50px;
        padding-bottom: 20px;
    }
    .woocommerce-result-count {
        margin-bottom: 16px;
    }
    .text-right {
        text-align: center !important;
        margin-top: 25px;
    }
    .faq-image {
        margin-top: 30px;
    }
    .coming-soon-area {
        height: 100%;
        padding-top: 200px;
        padding-bottom: 200px;
    }
    .coming-soon-content {
        h1 {
            margin-bottom: 10px;
            font-size: 25px;
        }
    }
    .coming-soon-content #timer div {
        margin-bottom: 10px;
    }
    .page-title-area {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .page-title-content {
        h2 {
            font-size: 25px;
        }
        ul {
            li {
                font-size: 15px;
            }
        }
    }
    .blog-details-desc {
        .article-content {
            .wp-block-gallery.columns-3 {
                margin-bottom: 20px;
                margin-top: 20px;
            }
        }
    }
    .comments-area {
        .comments-title {
            font-size: 20px;
        }
        .comment-author {
            font-size: 15px;
        }
        .comment-respond {
            .comment-reply-title {
                font-size: 20px;
            }
            .comment-form-email {
                width: 100%;
                padding-left: 0;
            }
            .comment-form-author {
                width: 100%;
                padding-right: 0;
            }
            label {
                font-size: 14px;
            }
        }
    }
    .partner-area {
        background-color: #f1f1f1ad;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .blog-item {
        .single-blog-item {
            padding: 20px;
        }
    }
    .team-item-area {
        .team-content {
            h3 {
                font-size: 18px;
            }
        }
    }
    .single-project-box {
        .project-hover-content {
            h3 {
                font-size: 16px;
            }
        }
    }
    .main-banner-content {
        text-align: center;
    }
    .pagination-area {
        margin-top: 0;
    }
    .error-content {
        h3 {
            font-size: 25px;
            margin-bottom: 10px;
        }
    }
    .widget-area {
        padding-left: 0;
        .widget {
            .widget-title {
                font-size: 20px;
            }
        }
        .widget_zash_posts_thumb {
            .item {
                .info {
                    .title {
                        font-size: 15px;
                    }
                }
            }
        }
    }
    .modal-video-body {
        padding: 0 10px;
        .modal-video-close-btn {
            right: 0;
        }
    }
    .home-banner-two {
        .main-banner-content {
            padding-right: 0;
        }
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .neemo-nav {
        .navbar {
            .navbar-collapse {
                overflow-y: auto;
            }
        }
    }
    .single-features-item {
        h3 {
            font-size: 18px;
        }
    }
    .col-lg-7 .col-lg-6:nth-child(2) .single-features-item {
        margin-top: 0;
        margin-bottom: 30px;
    }
    .col-lg-7 .col-lg-6:nth-child(3) .single-features-item {
        margin-top: 0;
        margin-bottom: 0;
    }
    .single-services-box {
        padding: 25px 16px;
        h3 {
            font-size: 16px;
            font-weight: bold;
        }
    }
    .boosting-list-tab {
        .tabs {
            li {
                -ms-flex: 0 0 50%;
                -webkit-box-flex: 0;
                flex: 0 0 50%;
                max-width: 48%;
                padding-top: 10px;
            }
        }
    }
    .footer-item-area {
        .footer-heading {
            margin-top: 0;
            margin-bottom: 15px;
        }
    }
    .portfolio-image {
        img {
            width: 100%;
        }
    }
    .copyright-area {
        text-align: left;
    }
    .article-footer {
        text-align: center;
        .article-share {
            padding-left: 50px;
        }
    }
    .text-right {
        text-align: center !important;
        margin-top: 0;
    }
    .copyright-area {
        text-align: center;
    }
    .boosting-list-tab {
        .tabs {
            li {
                span {
                    margin-top: 6px;
                    font-size: 14px;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-title {
        margin-bottom: 40px;
        span {
            font-size: 13px;
        }
        h3 {
            font-size: 26px;
            margin: 8px 0 0 0;
        }
    }
    .ptb-100 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .pt-100 {
        padding-top: 70px;
    }
    .pb-70 {
        padding-bottom: 40px;
    }
    .default-btn-one {
        padding: 10px 30px;
        font-size: 15px;
    }
    .navbar-area {
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        .navbar-brand {
            img {
                max-width: 100px;
            }
        }
    }
    .neemo-nav {
        .navbar {
            .navbar-nav {
                .nav-item {
                    a {
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                    &:nth-last-child(1),
                    &:nth-last-child(2),
                    &:nth-last-child(3) {
                        .dropdown-menu {
                            right: 0;
                            left: auto;
                        }
                    }
                }
            }
        }
    }
    .others-options {
        display: none;
    }
    .shape-img1 {
        display: none;
    }
    .shape-img2 {
        display: none;
    }
    .shape-img3 {
        display: none;
    }
    .shape-img4 {
        display: none;
    }
    .shape-img5 {
        display: none;
    }
    .shape-img6 {
        display: none;
    }
    .shape-img7 {
        display: none;
    }
    .shape-img8 {
        display: none;
    }
    .shape-img9 {
        display: none;
    }
    .shape-img10 {
        display: none;
    }
    .main-banner {
        height: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .main-banner-content {
        text-align: center;
        h1 {
            font-size: 25px;
            margin: 0 0 10px 0;
        }
        p {
            font-size: 14px;
        }
        .banner-btn {
            margin-top: 25px;
            .popup-youtube {
                margin-left: 16px;
            }
        }
    }
    // .banner-image {
    //     margin-top: 35px;
    //     text-align: center;
    // }
    .banner-bg-text {
        font-size: 130px;
        left: 0;
        text-align: center;
    }
    .features-section {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .features-content-area {
        h3 {
            margin: 10px 0 25px 0;
            font-size: 26px;
        }
        .features-btn {
            margin-top: 30px;
            margin-bottom: 40px;
        }
    }
    .about-image {
        text-align: center;
    }
    .about-area-content {
        margin-top: 40px;
        h3 {
            margin: 10px 0 25px 0;
            font-size: 26px;
        }
    }
    .single-fun-facts {
        text-align: center;
        h3 {
            font-size: 25px;
            .sign-icon {
                font-size: 25px;
            }
        }
    }
    .services-section {
        background-color: #f6f5fb;
    }
    .choose-section {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .choose-content-area {
        h3 {
            margin: 10px 0 25px 0;
            max-width: 700px;
            font-size: 26px;
        }
        .choose-btn {
            margin-top: 30px;
            margin-bottom: 50px;
        }
    }
    .choose-image {
        text-align: center;
    }
    .boosting-list-tab {
        .tabs {
            li {
                -ms-flex: 0 0 50%;
                -webkit-box-flex: 0;
                flex: 0 0 48%;
                max-width: 48%;
                font-size: 15px;
                padding-right: 10px;
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 10px;
                span {
                    display: block;
                    margin-top: 10px;
                    font-size: 16px;
                }
                i {
                    font-size: 30px;
                }
                &.bg-eff7e9 {
                    background: unset;
                }
                &.bg-fff8f0 {
                    background: unset;
                }
                &.bg-ecfaf7 {
                    background: unset;
                }
                &.bg-f2f0fb {
                    background: unset;
                }
                &.bg-c5ebf9 {
                    background: unset;
                }
            }
        }
        .tab_content {
            .tabs_item {
                .content {
                    h2 {
                        margin: 30px 0 10px 0;
                    }
                }
                .tab-text-content {
                    margin-top: 25px;
                    padding-left: 45px;
                    i {
                        &::before {
                            font-size: 30px;
                        }
                    }
                }
                .tab-shape {
                    width: 110px;
                }
                .tab-btn {
                    margin-top: 20px;
                }
                .tab-image {
                    text-align: center;
                }
            }
        }
    }
    .single-project-box .project-hover-content {
        padding: 25px 18px;
    }
    .project-section {
        padding-bottom: 40px;
    }
    .testimonial-section {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .testimonial-content-area {
        h3 {
            margin: 10px 0 25px 0;
            font-size: 26px;
        }
    }
    .testimonial-slides {
        .testimonial-single-item {
            margin-top: 100px;
        }
    }
    .testimonial-slides.owl-theme .owl-dots {
        position: relative;
        right: 0;
        left: 0;
    }
    .testimonial-slides.owl-theme .owl-nav {
        margin-top: 0;
    }
    .analysis-image {
        text-align: center;
    }
    .analysis-area-content {
        margin-top: 40px;
        h3 {
            margin: 10px 0 25px 0;
            font-size: 26px;
        }
    }
    .blog-item {
        margin-bottom: 30px;
    }
    .subscribe-area {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .newsletter-form {
        button {
            padding: 0 18px;
        }
    }
    .pl-5,
    .px-5 {
        padding-right: 3rem !important;
        padding-left: 0 !important;
    }
    .footer-item-area {
        .footer-heading {
            margin-bottom: 16px;
            h3 {
                font-size: 24px;
            }
        }
    }
    .footer-heading {
        margin-bottom: 16px;
        h3 {
            font-size: 24px;
        }
    }
    .home-banner-two {
        height: 100%;
        padding-top: 150px;
        padding-bottom: 70px;
        .main-banner-content {
            h1 {
                font-size: 40px;
                margin: 0 0 10px 0;
            }
            p {
                font-size: 15px;
            }
            .banner-btn {
                margin-top: 30px;
            }
        }
        .banner-img-wrapper {
            .banner-img-1 {
                display: none;
            }
            .banner-img-2 {
                display: none;
            }
        }
        .banner-form {
            padding: 40px;
            margin-top: 40px;
            form {
                .btn {
                    margin-top: 10px;
                }
            }
        }
    }
    .partner-title {
        margin-bottom: 30px;
        h3 {
            font-size: 20px;
        }
    }
    .features-area {
        .features-title {
            margin-bottom: 30px;
            span {
                font-size: 13px;
            }
            h3 {
                font-size: 25px;
            }
        }
        .single-features-item {
            padding: 30px 20px;
        }
    }
    .tab-content {
        .tabs-item {
            .single-pricing-box {
                &.bg-f6f5fb {
                    margin-top: 0px;
                }
                &.bg-edfbf8 {
                    margin-top: 30px;
                }
            }
        }
    }
    .portfolio-section {
        padding-bottom: 40px;
    }
    .client-slides {
        .testimonial-single-item {
            .testimonial-content-text {
                .icon {
                    margin-top: 10px;
                    margin-bottom: 16px;
                }
            }
        }
    }
    .faq-area-content {
        span {
            font-size: 13px;
        }
        h3 {
            font-size: 26px;
        }
    }
    .subscribe-section {
        padding-top: 70px;
        padding-bottom: 70px;
        .subscribe-content {
            margin-bottom: 0;
            .sub-title {
                margin-bottom: 8px;
                font-size: 15px;
            }
            h2 {
                font-size: 25px;
            }
        }
        .newsletter-form {
            button {
                position: relative;
                margin-top: 0;
            }
        }
    }
    .single-portfolio-box {
        .portfolio-hover-content {
            padding: 25px 20px;
        }
    }
    .faq-image {
        text-align: center;
        margin-top: 40px;
    }
    .client-image {
        text-align: center;
    }
    .contact-box {
        margin-bottom: 30px;
    }
    .contact-text {
        margin-top: 0;
    }
    .services-details-overview {
        margin-bottom: 0;
        .services-details-desc {
            h3 {
                font-size: 20px;
            }
        }
    }
    .services-details-image {
        img {
            margin-bottom: 20px;
            margin-top: 20px;
        }
    }
    .projects-details-desc {
        .project-details-info {
            .single-info-box {
                -ms-flex: 0 0 33%;
                flex: 0 0 33%;
                max-width: 33%;
            }
        }
    }
    .team-page-section {
        padding-top: 50px;
        padding-bottom: 20px;
    }
    .order-details {
        .title {
            font-size: 20px;
            margin-top: 30px;
        }
    }
    .partner-area {
        background-color: #f1f1f1ad;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pb-100 {
        padding-bottom: 70px;
    }
    .copyright-area {
        text-align: center;
        ul {
            margin-top: 10px;
            text-align: center;
        }
    }
    .widget-area {
        padding-left: 0;
    }
    .modal-video-body {
        padding: 0 30px;
        .modal-video-close-btn {
            right: 0;
        }
    }
    .home-banner-two {
        .main-banner-content {
            padding-right: 0;
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .modal-video-body {
        padding: 0 30px;
    }
    .neemo-nav {
        .navbar {
            .navbar-nav {
                .nav-item {
                    a {
                        margin-left: 15px;
                        margin-right: 15px;
                    }
                }
            }
        }
    }
    .main-banner-content {
        h1 {
            font-size: 50px;
            margin: 0 0 14px 0;
        }
        .banner-btn {
            margin-top: 30px;
        }
    }
    .features-content-area {
        h3 {
            font-size: 30px;
            margin: 10px 0 26px 0;
        }
    }
    .single-features-item {
        padding: 25px 20px;
        h3 {
            font-size: 18px;
        }
    }
    .about-area-content {
        h3 {
            font-size: 30px;
            margin: 10px 0 20px 0;
        }
        strong {
            font-size: 16px;
        }
    }
    .single-fun-facts {
        margin-top: 25px;
        h3 {
            font-size: 28px;
            .sign-icon {
                font-size: 28px;
            }
        }
    }
    .single-services-box {
        h3 {
            font-size: 18px;
        }
    }
    .choose-content-area {
        h3 {
            font-size: 30px;
            margin: 10px 0 26px 0;
            max-width: 100%;
        }
        .choose-btn {
            margin-top: 30px;
        }
    }
    .boosting-list-tab {
        .tabs {
            li {
                -ms-flex: 0 0 50%;
                -webkit-box-flex: 0;
                flex: 0 0 48%;
                max-width: 15%;
                font-size: 15px;
                padding-right: 10px;
                padding-top: 25px;
                padding-bottom: 25px;
                padding-left: 10px;
                span {
                    display: block;
                    margin-top: 10px;
                    font-size: 13px;
                }
                i {
                    font-size: 30px;
                }
                &.bg-eff7e9 {
                    background: unset;
                }
                &.bg-fff8f0 {
                    background: unset;
                }
                &.bg-ecfaf7 {
                    background: unset;
                }
                &.bg-f2f0fb {
                    background: unset;
                }
                &.bg-c5ebf9 {
                    background: unset;
                }
            }
        }
        .tab_content {
            .tabs_item {
                .content {
                    h2 {
                        margin: 0 0 10px 0;
                    }
                }
                .tab-text-content {
                    margin-top: 25px;
                    padding-left: 45px;
                    i {
                        &::before {
                            font-size: 30px;
                        }
                    }
                }
                .tab-shape {
                    width: 110px;
                }
                .tab-btn {
                    margin-top: 20px;
                }
            }
        }
    }
    .single-project-box {
        .project-hover-content {
            padding: 24px 25px;
        }
    }
    .testimonial-content-area {
        h3 {
            font-size: 30px;
            margin: 10px 0 20px 0;
            padding-bottom: 16px;
        }
    }
    .analysis-area-content {
        h3 {
            font-size: 30px;
            margin: 10px 0 20px 0;
            padding-bottom: 16px;
        }
    }
    .blog-item {
        .single-blog-item {
            .blog-content {
                h3 {
                    font-size: 18px;
                }
            }
        }
    }
    .footer-item-area {
        .footer-heading h3 {
            font-size: 16px;
        }
        .footer-quick-links {
            li {
                font-size: 15px;
            }
        }
    }
    .home-banner-two {
        .main-banner-content {
            h1 {
                font-size: 50px;
            }
        }
    }
    .sidebar-modal {
        .sidebar-modal-inner {
            padding-left: 30px;
            padding-right: 30px;
            width: 40%;
        }
    }
    .home-banner-two {
        .banner-img-wrapper {
            .banner-img-1 {
                top: 100px;
                width: 350px;
            }
            .banner-img-2 {
                top: 100px;
                width: 350px;
            }
        }
    }
    .features-area {
        .single-features-item {
            padding: 30px 16px;
            h3 {
                font-size: 16px;
                margin: 16px 0 6px 0;
            }
        }
    }
    .single-portfolio-box {
        .portfolio-hover-content {
            padding: 25px 20px;
        }
    }
    .contact-box {
        .content {
            p {
                margin-bottom: 4px;
                font-size: 15px;
            }
        }
    }

    .client-slides {
        .testimonial-single-item {
            .testimonial-content-text {
                .icon {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .section-title {
        h3 {
            font-size: 30px;
        }
    }
    .home-banner-two {
        .banner-form {
            padding: 35px 30px;
        }
    }
    .widget-area {
        padding-left: 0;
        .widget {
            .widget-title {
                font-size: 20px;
            }
        }
        .widget_zash_posts_thumb {
            .item {
                .info {
                    .title {
                        font-size: 15px;
                    }
                }
            }
        }
    }
    .home-banner-two {
        .main-banner-content {
            padding-right: 30px;
        }
    }
}

@media only screen and (min-width: 1800px) {
    .main-banner {
        // height: 900px;
        .banner-image {
            position: relative;
            margin-top: 0px;
        }
    }
    .shape-img1 {
        left: 12%;
    }
    .shape-img3 {
        left: 10%;
    }
    .shape-img9 {
        right: 10%;
    }
    .shape-img11 {
        right: 5%;
    }
}

@media (max-width: 600px) {
    .blog {
        .content {
            .title {
                -webkit-line-clamp: 2;
                font-size: small;
            }
            .content-description {
                -webkit-line-clamp: 2;
                font-size: x-small;
                font-weight: 100;
            }
            .post-meta .readmore {
                font-size: 10px;
            }
            .post-meta {
                padding: 0 !important;
            }
            p {
                font-size: 12px;
            }
        }
    }
    .clickable,
    span.content-title {
        font-size: 14px !important;
    }
    .features-section {
        margin-top: 20px;
        margin-bottom: 40px;
    }
}
