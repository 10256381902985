.answer-option-list{
    margin-bottom: 20px;
}

.list-type-none {
    list-style-type: none;
}

.answer-option-caption-wrapper {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin-bottom: 18px;
    padding-right: 36px;
}

.answer-option-caption {
    display: table-cell;
    width: 50%;
    line-height: 12px;
    color: #555;
    // font-family: 'Open Sans';
    font-weight: 600;
    text-align: center;
}

.answer-option-item {
    position: relative;
    box-sizing: border-box;
    border: 1px solid transparent;
}

.answer-options-list-item {
    display: flex;
    height: auto;

    // display: table;
    table-layout: fixed;
    // height: 30px;
    width: 100%;
}

.question-answer-text-wrapper:first-child {
    border-right-width: 1px;
    padding-right: 40px;
}

.question-answer-text-wrapper {
    flex: 1 0;
    display: block;
    position: relative;
    border: 2px solid #b7b7b7;
    border-radius: 7px;
    overflow: visible;
    padding: 20px 25px;
    word-wrap: break-word;
    word-break: break-all;

    
    // display: table-cell;
    vertical-align: top;
    background: #fff;
    // border: 1px solid #ddd;
    // border-radius: 3px;
    color: #666;
    font-size: 14px;
    // overflow: hidden;
}

.editarea-editable-text {
    height: auto;
    min-height: inherit;
    padding: 0;
    margin: 0;
    color: #555;
}

.editable-text-binding {
    position: relative;
    white-space: pre-wrap;
    word-wrap: break-word;
    z-index: 10;
}

.question-answer-text-wrapper .puzzle-item {
    height: 30px;
    width: 20px;
    border: 2px solid red;
    position: absolute;
    top: 50%;
    left: -19px;
    margin-top: -17px;
    border-radius: 20px/25px;
    border: 2px solid #b7b7b7;
    background-color: #fff;
}

.question-answer-text-wrapper .puzzle-item:after {
    content: '';
    width: 5px;
    height: 19px;
    background: #fff;
    position: absolute;
    right: -2px;
    top: 3px;
}

.edit-area{   
    height: auto;
    min-height: inherit;
    padding: 0;
    margin: 0;
    color: #555;

    border: transparent;
    width: 100%;
    &:focus{
        border: transparent;
        border-color: transparent;
         box-shadow: none;
    }
}
 .editarea-editable-text {
    height: auto;
    min-height: inherit;
    padding: 0;
    margin: 0;
    color: #555;
}

.editable-text-binding {
    position: relative;
    white-space: pre-wrap;
    word-wrap: break-word;
    z-index: 10;
}
.bg-ranking-card{
    background-color: #e6eff4 !important;
}