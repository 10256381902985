:root {
    --chatboxH: 45px;
    --headerH: 55px;
    --paddingF: 10px;
}

.messageBox .textA {
    position: relative;
    width: 100%;
    height: 100%;
}

.chatMessages {
    overflow-y: auto !important;
    padding-bottom: 50px;
} 

.button-s1 {
    width: 40px;
    padding: 5px;
    border-radius: 6rem;
    border: none;
    background: transparent;
    outline: none;
    color: #000;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-s1 span {
    font-size: calc(40px / 1.5) !important;
}

#genMeetLink a {
    color: #000;
}

.messageBox {
    background-color: white;
    position: fixed;
    bottom: 10px;
    left: 0;
    width: 100%;
    height: calc(var(--chatboxH) + 5px);
    display: flex;
    align-items: center;
    padding: 7px 27px;
}

.nameAlert {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 130px;
    border-radius: 20px;
}

.chatArea {

    /* height: calc(100vh - var(--chatboxH));
    width: 100%; */
    /* padding: 16px; */
    /* overflow-y: scroll; */
    overflow-y: scroll;
    height: 80vh;
    padding-top: 10px;
    padding-bottom: 10px;
}

.chatArea::-webkit-scrollbar {
    position: absolute;
    right: 0;
}

.prof {
    min-width: 35px;
    min-height: 35px;
    margin-top: 24px;
    margin-bottom: auto;
    /* margin: auto 10px 0 10px; */
    background: black;
    color: white;
    font-weight: 700;
    text-align: center;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.otherMess .prof{
    margin-right: 10px;
}
.selfMess .prof{
    margin-left: 10px;
}

.messArea {
    display: grid;
    grid-row-gap: 10px;
    font-size: 14px;
    /* margin-top: 20px; */
    border: 1px solid #d4d4d4;
    border-top-right-radius: 0px;
    border-radius: 10px;
    padding: 10px;
}

#sname,.sdate {
    font-weight: 500;
    font-size: "smaller";
}
#sname{
    font-size: small;
    font-weight: 600;
    color: #000;
}
.sdate{
    color: rgb(87, 87, 87);
    margin-left: auto;
    font-size: x-small;
}
.chat-author-info{
    margin-bottom: -48px;
}

.message {
    display: flex;
    width: fit-content;
    max-width: 40rem;
    margin-bottom: 8px;
}

.otherM {
    margin-right: auto;
    border-top-left-radius: 0px;
}

.mMess {
    margin-left: auto;
    overflow: hidden;
}

.mMess .messArea .textM {
    /* background-color: blue; */
    /* background-color: rgba(35, 82, 170,0.8); */
    /* background-color: rgba(222, 34, 41,0.8); */
    /* color: #000; */
    background-color: #fff;
    /* border: 1px solid rgb(87, 87, 87); */
    /* border-top-right-radius: 0px; */
    /* margin-left: auto; */
    transition: .6s cubic-bezier(.07, .76, .13, 1.02);
}

.newMmess {
    transform: translateY(100px) scale(3);
}

.otherM .messArea .textM {
    background-color: white;
}

.mMess .messArea .textM a {
    color: white;
}

.messArea .textM a {
    font-weight: 500;
}

.message .textM {
    width: fit-content;
    /* -webkit-box-shadow: 0px 5px 8px 5px rgba(0, 0, 0, 0.03); */
    /* box-shadow: 0px 5px 8px 5px rgba(0, 0, 0, 0.03); */
    font-size: 14px;
    /* background: #f0f2f5;
    color: #000; */
    background-color: #fff;    
    font-weight: 500;
    color: #000; 
}

.otherMess .textM{
border-top-left-radius: 0px;
}
.welcomeMess {
    text-align: center;
    margin-top: var(--headerH);
}

.welcomeMess h1 {
    font-weight: 600;
    font-size: 24px;
}

.welcomeMess .img video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.welcomeMess .img {
    width: 4rem;
    border-radius: 10%;
    overflow: hidden;
    height: 4rem;
    margin: auto;
}

.shareLink {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
    padding: 2rem;
    background-color: white;
    text-align: center;
    border-radius: 15px;
    transition: .4s;
    display: none;
    opacity: 0;
    z-index: 2;
}

.shareLink button {
    font-weight: 600;
    color: blue;
    width: 6rem;
    margin-top: 5px;
    border-radius: 11px;
    height: 2rem;
    border: none;
    background: rgb(230, 227, 255);
}

.shareLink h1 {
    font-weight: 500;
}

.headerDet {
    width: 100%;
    height: var(--headerH);
    position: fixed;
    top: 0;
    background-color: white;
    -webkit-box-shadow: 0px 5px 8px 5px rgba(0, 0, 0, 0.03);
    box-shadow: 0px 5px 8px 5px rgba(0, 0, 0, 0.03);
    display: flex;
    align-items: center;
    padding: 10px;
    transition: top 0.5s;
}

.moveTop {
    top: -100px;
}

.headerDet .img {
    overflow: hidden;
    width: calc(var(--headerH) - 15px);
    height: calc(var(--headerH) - 15px);
    border-radius: 100%;
}

.chatDety {
    display: flex;
    align-items: center;
    flex: 1;
}

.headerDet .chatDety .img video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.headerDet .chatDety, .nameC p, input {
    margin-left: 15px;
    font-weight: 500;
    font-size: 18px
}

.nameC {
    position: relative;
    width: 193px;
}

#titleFirst {
    width: 221px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.headerDet .chatDety .nameC input {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-2px, -2px);
}

.headerDet .tools {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
}

.headerDet .tools button {
    background: transparent;
    border: none;
    outline: none;
    transition: .5s;
}

#goToDown {
    height: 35px;
    position: fixed;
    bottom: var(--chatboxH);
    right: 0;
    margin-right: 12px;
    width: 35px;
    transition: 0.5s;
}

.downDowny {
    bottom: -70px !important;
}

#goToDown span {
    cursor: pointer;
    font-size: 30px;
}

.showItem {
    transform: translate(-50%, -50%);
    opacity: 1;
}

.blackout {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: .5s;
    z-index: 1;
}

.blackShow {
    opacity: 1;
}

.clickSettings {
    transform: rotate(180deg);
}

.changeW {
    transition: .7s;
}

.clickSettingsCont {
    width: 75%;
}

.settingsBar {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    height: 100%;
    overflow: hidden;
    width: 25%;
    transform: translateX(400px);
    transition: .67s;
    padding: 46px 10px 0px 10px;
    overflow-y: scroll;
}

.blueTarget {
    color: blue;
}

.clickSettingsBar {
    transform: translateX(0px);
}

.settingsBar .headerSet {
    border-bottom: 1px solid rgb(199, 199, 199);
    padding-bottom: 19px;
    margin-bottom: 15px;
}

.errorsSide {
    position: absolute;
    bottom: 80px;
    right: 30px;
}

.errorsSide .bubble {
    background: rgba(255, 0, 0, 0.7);
    color: white;
    padding: 10px;
    border-radius: 12px;
    width: 20rem;
    opacity: 0;
    transition: 1s;
    transform: translateY(50px);
    display: none;
    margin-bottom: 10px;
    z-index: 3;
}

.errorsSide #errorBubble {
    background: rgba(255, 0, 0, 0.7);
}

.errorsSide #tipsBubble {
    background: rgba(47, 0, 255, 0.7);
}


.errorsSide .bubble h1 {
    font-size: 20px;
    display: flex;
    align-items: center;
}

.errorsSide .bubble span {
    margin-right: 10px;
}

.errorsSide .bubble p {
    margin-right: 10px;
}

.bubbleAfter {
    opacity: 1 !important;
    transform: translateY(0px) !important;
}

.bubbleGone {
    opacity: 0 !important;
    transform: translateY(-50px) !important;
}

.timeId {
    text-align: center;
    margin: 12px;
    font-size: 13px;
}


::-webkit-scrollbar {
    width: 4px;
    height: 15px;
    border-left: #ededed solid 1px !important;
    background-color: #fcfcfc !important;
    border-top: #ededed solid 1px !important;
}

::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: #c7c7c7 !important;
    width: 8px;
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 10px;
}

::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar-thumb {
    background: #dbdbdb !important;
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: 10px;

}