
.header-content,.quiz-title{
  /* color: #283360; */
}
.header-text{
    font-weight:600;
    font-size: 1.125rem;
}

.correct-icon {
    padding: 0.375rem 1.5rem;
    line-height: 1.6;
    font-size: 1.125rem;
    text-align: center;
    bottom: 10px !important;
    margin-right: 30px;
  }
  .correct-icon  > span {
      font-weight: 510;
      margin-left:6px;
  }
  .cta {
      margin: 0 auto !important;
  }
  .main-content{
      min-height: 100vh;
  }
  .quiz-title {
      font-weight: 600;
      position: absolute;
    top: 12%;
    left: 28%;
  }
  .quiz-content{
    min-width:50vw !important;
    top:15%;
    left:45%;
    text-align: center;
  }
  .quiz {
      border-radius:8px !important;
  }
  .m-4{
    margin: 1.5rem!important;
  }
  .rounded-top{
    border-top-right-radius: 10px!important;
  }

  .quiz-content-heading > span {
     margin-left: auto; 
  }

.question-options {
    cursor: pointer;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    /* position: relative; */
    transition: all 0.5s ease-in-out;
}
.animate-answer:hover{
    transform: scale(1.01);
}

.question-options .option-content {
    font-size: .875rem;
    margin-top: -23px !important;
    margin-left: 2.5rem;
    -webkit-transition: all .3s cubic-bezier(.195,.84,.64,1);
    -o-transition: all .3s cubic-bezier(.195,.84,.64,1);
    transition: all .3s cubic-bezier(.195,.84,.64,1);
}


.p-label.p-size-2 {
    font-size: 16px;
    width: 24px;
    height: 24px;
}
.p-label.p-unattempted {
    border-color: #aab8c2;
    color: #b2b6c1;
}
.p-label {
    position: relative;
    border-radius: 50%;
    text-align: center;
    /* white-space: nowrap; */
    display: inline-block;
    border: 1px solid transparent;
    color: #fff;
    font-size: 100%;
    vertical-align: middle;
}
.tb-card{
    overflow: hidden;
    background: #fff;
    border-radius: 4px;
    -webkit-box-shadow: -1px 1px 4px 0 rgba(117,138,172,.12)!important;
}
.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}
.option-incorrect{
    border:2px solid #89c540 !important;
}
.option-correct{
    border:2px solid #28a745 !important;
}
.previous-icon{
    top:50% !important;
    left: 0 !important;
}
.btn-primary:focus{
    border: none;
    outline: none;
    display: inline-block;
}
.btn-primary{
    background-color: #283360!important;
    border: 1px solid #283360!important;
    color: #fff!important;
    -webkit-box-shadow: 0 3px 5px 0 rgb(28 89 159 / 30%);
    box-shadow: 0 3px 5px 0 rgb(28 89 159 / 30%);
}

.btn {
    padding: 8px 20px;
    outline: none;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: .5px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    font-weight: 600;
    border-radius: 10px;
}
.quiz-btn{
    right:3%;
    position: absolute;
}
.quiz-btn:focus{
    outline: 0 !important;
    box-shadow: none !important;
}
.quiz-loading{
    left:44%;
    position: absolute;
    margin-top: 40px;
}



