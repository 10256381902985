// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
.main-body {
    padding: 15px;
}

.card {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm > .col,
.gutters-sm > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}

.h-100 {
    height: 100% !important;
}

.shadow-none {
    box-shadow: none !important;
}

.my-course-header {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    h5 {
        text-align: center;
    }
}

.profile-tab-list {
    .nav {
        display: inline-block;
        border-radius: 5px;
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
        margin-bottom: 35px;
        .nav-item {
            display: inline-block;
            a.nav-link {
                font-size: 14px;
                color: #1a202c;
                font-weight: 500;
                text-transform: uppercase;
                padding: 12px 30px 10px;
                border-radius: 5px;
                @media (max-width: 991px) {
                    padding: 12px 16px 9px;
                }
            }
            a.nav-link.active {
                color: #fff;
                background: #283360;
            }
        }
    }
}

.edit-profile {
    background-color: #89c540 !important;
    border: 1px solid #89c540 !important;
    color: #fff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgb(28 89 159 / 30%);
    box-shadow: 0 3px 5px 0 rgb(28 89 159 / 30%);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
        "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    &:hover {
        background-color: #fff !important;
        color: #89c540 !important;
    }
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */

input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}

.dropdown-menu-profile {
    padding: 8px;
    min-width: 240px;
}

.user-profile-detail-dropdown {
    color: #3c3b37 !important;
    position: relative !important;
    align-self: center !important;
    display: inline-block !important;
    // font-family: "Poppins", sans-serif !important;
    display: flex !important;
}

.dropdown-tutor-verify {
    border-bottom: 1px solid #dcdacb !important;
    padding: 1.6rem;
}

.user-dropdown-detail {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 0.8rem;
    min-width: 1px;
    word-break: break-word;
    .dropdown-profile-name {
        line-height: 1.2;
        font-size: 0.9rem;
    }
    .dropdown-profile-email {
        font-weight: 400;
        line-height: 1.4;
        font-size: 0.6rem;
    }
}

.user-profile-detail-dropdown:hover .dropdown-profile-name,
.user-profile-detail-dropdown:hover small {
    color: #283360 !important;
}

.dropdown-profile-menu {
    color: #000 !important;
}

.dropdown-profile-menu:hover {
    color: #283360 !important;
}

.overview-tab {
    font-family: "Poppins", sans-serif !important;
}

.overview-tab h4 {
    font-size: 1.375rem !important;
}

.overview-tab p {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #283360 !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background: #e4e8ed !important;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #000 !important;
}

.overview-tab small {
    font-size: 13px !important;
    font-weight: 400;
    color: gray;
}

.overview-tab .des {
    font-size: 14px;
}

.experience-section .action-button,
.education-section .action-button {
    right: 0 !important;
    top: 0 !important;
    cursor: pointer;
    border-radius: 50%;
    padding: 6px;
}

.certificate-button {
    cursor: pointer;
    color: #000;
}

.overview-tab .ant-steps-item-title {
    display: block !important;
}

.experience-section .action-button:hover,
.education-section .action-button:hover,
.certificate-button:hover {
    color: #283360;
}

.ant-btn-primary {
    background: #283360 !important;
}

.ant-picker-suffix {
    color: #000 !important;
}
.tutor-form input:not(.ant-picker-input input),
.tutor-form textarea {
    border: 1px solid #000 !important;
}

.edit-content textarea {
    border: 1px solid #000 !important;
}

.loading {
    text-align: center;
    border-radius: 4px;
}

.form-check input {
    cursor: pointer;
}

.form-check:focus {
    outline: none !important;
}

.form-check input:checked {
    outline: none !important;
    background-color: #283360 !important;
    border-color: #283360 !important;
}

//   Slider
.related-courses {
    padding-top: 80px;
    padding-bottom: 80px;
    .course-slide {
        background: #fff;
        margin: 20px 15px 20px;
        border-radius: 15px;
        padding-top: 1px;
        box-shadow: 0px 14px 22px -9px #bbcbd8;
        cursor: pointer;
    }
    .course-slide .post-img {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        margin: -12px 15px 8px 15px;
    }
    .course-slide .post-img img {
        width: 100%;
        transform: scale(1, 1);
        transition: transform 0.2s linear;
        height: 200px;
    }
    .course-slide:hover .post-img img {
        transform: scale(1.05, 1.05);
    }
    .course-slide .over-layer {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        background: linear-gradient(-45deg, rgba(6, 190, 244, 0.75) 0%, rgba(45, 112, 253, 0.6) 100%);
        transition: all 0.5s linear;
    }
    .course-slide:hover .over-layer {
        opacity: 1;
        text-decoration: none;
    }
    .course-slide .over-layer i {
        position: relative;
        top: 45%;
        text-align: center;
        display: block;
        color: #fff;
        font-size: 25px;
    }
    .course-slide .post-content {
        background: #fff;
        padding: 2px 20px 40px;
        border-radius: 15px;
    }
    .course-slide .post-title a {
        font-size: 15px;
        font-weight: bold;
        color: #333;
        display: inline-block;
        text-transform: uppercase;
        transition: all 0.3s ease 0s;
    }
    .course-slide .post-title a:hover {
        text-decoration: none;
        color: #283360;
    }
    .course-slide .post-description {
        line-height: 24px;
        color: #808080;
        margin-bottom: 25px;
    }
    .course-slide .post-date {
        color: #a9a9a9;
        font-size: 14px;
    }
    .course-slide .post-date i {
        font-size: 20px;
        margin-right: 8px;
        color: #cfdace;
    }
    .course-slide .read-more {
        padding: 7px 20px;
        float: right;
        font-size: 12px;
        background: #283360;
        color: #ffffff;
        box-shadow: 0px 10px 20px -10px #283360;
        border-radius: 25px;
        text-transform: uppercase;
    }
    .course-slide .read-more:hover {
        background: #283360;
        text-decoration: none;
        color: #fff;
    }
    .owl-controls .owl-buttons {
        text-align: center;
    }
    .owl-prev {
        background: #f0f2f5;
        position: absolute;
        left: 0;
        top: 44%;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 50%;
        box-shadow: 3px 14px 25px -10px #283360;
        font-size: 24px;
    }
    .owl-next {
        background: #f0f2f5 !important;
        position: absolute !important;
        right: 0 !important;
        top: 44%;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 50%;
        box-shadow: 3px 14px 25px -10px #283360;
        font-size: 24px;
    }
    .owl-controls .owl-buttons .owl-next:after {
        content: "\f107";
    }
    @media only screen and (max-width: 1280px) {
        .course-slide .post-content {
            padding: 0px 15px 25px 15px;
        }
    }
    .owl-carousel {
        display: block;
    }
    .owl-carousel .owl-dots.disabled,
    .owl-carousel .owl-nav.disabled {
        display: block !important;
    }
}

.profile-container {
    .list-group-flush {
        li {
            span {
                .text-primary {
                    font-size: 14px;
                }
            }
        }
    }
    .verify-btn {
        font-size: 13px;
    }
}

.verified-badge {
    color: #3797f0 !important;
}

// css for profile avatar
.user-profile-img {
    position: relative;

    .user-img-overlay-icon {
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: cornflowerblue;
        box-shadow: 0 0 8px 3px #b8b8b8;
    }
}
.profile-layout {
    .profile-sidebar {
        .dropdown-profile-menu.text-secondary {
            color: #283360 !important;
        }
        .ant-menu-item {
            @extend .border-14;
        }
        .ant-menu-item:hover {
            .dropdown-profile-menu {
                color: #283360 !important;
            }
            background-color: transparent !important;
            @extend .border-14;
        }
    }
    .container {
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
    }
    .container::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
    .ant-menu-title-content {
        margin-left: 0px !important;
    }
}
