/* Add Doubts starts */

$color-border1: #c71a23;
$color-green: #283360;
$color: #ffffff;
$black: #000;
$color-balck: #202647;
$color-black: #202647;
$color-text: #6a6c72;
$color-yellow: #6a6c72;
$color-border: #6a6c72;
$font-size: 15px;
$transition: 0.5s;
$primary: #283360;
$color-gr-br: #283360;
$secondary: #89c540;
$white-color: $color;
$secondary-color: $color-border1;
$black-color: $black;
@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");
$main-green: #79dd09 !default;
$main-green-rgb-015: rgba(121, 221, 9, 0.1) !default;
$main-yellow: #bdbb49 !default;
$main-yellow-rgb-015: rgba(189, 187, 73, 0.1) !default;
$main-red: #bd150b !default;
$main-red-rgb-015: rgba(189, 21, 11, 0.1) !default;
$main-blue: #0076bd !default;
$main-blue-rgb-015: rgba(0, 118, 189, 0.1) !default;
.add-doubt-btn {
    height: 40px;
}

@media (max-width: 767px) {
    .doubt-modalbox {
        margin: 10px !important;
    }
}

a:hover {
    text-decoration: none !important;
}

/* Add doubts ends */

.doubt-modalbox {
    margin-bottom: 16px;
    --T68779821: 0 1px 2px var(rgba(0, 0, 0, 0.2)) !important;
    box-shadow: 0 1px 2px var(rgba(0, 0, 0, 0.2)) !important;
    padding-top: 12px !important;
    padding-bottom: 10px !important;
    padding-right: 16px;
    padding-left: 16px;
    border-radius: max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) / 8px !important;
    overflow-y: hidden;
}

.doubt-modalbox-inner-box {
    padding-top: 12px;
    padding-bottom: 10px;
    flex-wrap: wrap;
    padding-right: 16px;
    padding-left: 16px;
    align-items: center;
    width: 100%;
}

.add-question__inner-box__top > img,
.modal-image {
    display: inline-block;
    vertical-align: bottom;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    padding-bottom: 0;
}

.add-image-icon {
    display: inline-block;
    vertical-align: bottom;
    height: 36px;
    width: 36px;
    padding-bottom: 0;
    cursor: pointer;
}

.add-question__inner-box__top > textarea {
    padding-left: 16px;
    padding-bottom: 8px;
    border-top-left-radius: 20px;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    min-height: 40px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-right: 12px;
    border-top-right-radius: 20px;
    margin-right: 0;
    padding-top: 8px;
    max-width: 400px !important;
}

.doubt-modalbox.edit-content .ant-modal-body {
    overflow-y: scroll !important;
}

.height-bigger-edit-content .ant-modal-body {
    height: 400px !important;
}

.doubt-modalbox .ant-modal-body::-webkit-scrollbar {
    display: block;
    width: 10px;
    cursor: pointer;
}

/* Track */

// ::-webkit-scrollbar-track {
//     display: none;
// }
// /* Handle */
// ::-webkit-scrollbar-thumb {
//     position: relative;
//     top: 0px;
//     /* float: right; */
//     /* width: 6px; */
//     height: auto;
//     background-color: #e4e6eb !important;
//     border: 1px solid #ffffff;
//     /* background-clip: padding-box; */
//     border-radius: 5px;
// }
// ::-webkit-scrollbar-track {
//     cursor: pointer;
// }
.doubt-modalbox .mytextbox {
    height: 40px !important;
    // margin-bottom: 16px !important;
    // width: 100% !important;
    background-color: #fff;
}

// Modal Add doubt starts
.ant-modal.doubt-modalbox > .ant-modal-content {
    border-radius: 0.4rem !important;
}

.doubt-modalbox > .ant-modal-content > .ant-modal-footer > .ant-btn-primary {
    background-color: #283360 !important;
    &:hover {
        background: #fff !important;
        color: #283360;
    }
}

.doubt-modalbox > .ant-modal-content > .ant-modal-footer > button {
    border-radius: 0.3rem;
}

// .doubt-modalbox > .ant-modal-content > .ant-modal-body {
//     padding: 12px !important;
// }

.doubt-modalbox > .ant-modal-content > .ant-modal-body > form textarea {
    font-size: 0.9rem !important;
    // border: none !important;
    line-height: 1.1667;
    color: #050505;
    font-weight: 400;
    margin-top: 14px;
    // height: 100px !important;
}

.doubt-modalbox.ant-modal {
    border-radius: max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) / 8px !important;
}

// .doubt-modalbox > .ant-modal-content > .ant-modal-body > form textarea:hover {
//     border-color: #050505 !important;
// }

.doubt-modalbox > .ant-modal-content > .ant-modal-body > form textarea:focus {
    box-shadow: none !important;
}

.doubt-modalbox > .ant-modal-content > .ant-modal-body > form textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #050505;
    font-size: 1rem;
    line-height: 1.1667;
    font-weight: 400;
}

.doubt-modalbox > .ant-modal-content > .ant-modal-body > form textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #050505;
    font-size: 1rem;
    line-height: 1.1667;
    font-weight: 400;
}

.doubt-modalbox > .ant-modal-content > .ant-modal-body > form textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: #050505;
    font-size: 1rem;
    line-height: 1.1667;
    font-weight: 400;
}

.doubt-modalbox > .ant-modal-content > .ant-modal-body > form textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #050505;
    font-size: 1rem;
    line-height: 1.1667;
    font-weight: 400;
}

.doubt-modalbox > .ant-modal-content > .ant-modal-header > .ant-modal-title {
    text-align: center !important;
    padding-bottom: 2px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
    color: #050505 !important;
    font-size: 1.25rem !important;
    font-weight: 700;
}

.doubt-modalbox > .ant-modal-content > .ant-modal-close > .ant-modal-close-x > .ant-modal-close-icon {
    border-radius: 50%;
    padding: 10px;
}

.back-arrow {
    position: absolute;
    top: 3%;
    left: 2%;
    z-index: 10;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    display: block;
    width: 46px;
    height: 46px;
    font-size: 16px;
    font-style: normal;
    line-height: 46px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    border-radius: 50%;
}

.add-poll {
    cursor: pointer;
    border-radius: 50%;
}

.poll-container {
    padding-left: 8px;
    padding-right: 8px;
    justify-content: center;
    overflow-y: hidden;
    border-radius: 8px;
    margin-right: 8px;
    overflow-x: hidden;
    padding-bottom: 8px;
    padding-top: 8px;
    margin-left: 8px;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 32px;
    border: solid 1px #ced0d4;
    position: relative;
    height: 200px;
    overflow: scroll;
}

.poll-container-inner-box {
    width: 100%;
    padding: 16px;
}

.poll-container-inner-box .poll-header h5 {
    padding-bottom: 8px;
    font-size: 1rem !important;
}

.poll-collection .poll-item {
    padding: 8px;
    border: solid 1px #ced0d4;
    border-radius: 8px;
    position: relative;
    height: 60px;
    margin-top: 10px;
}

.poll-item label {
    transition-duration: 200ms;
    margin-bottom: -0.5rem !important;
    width: 100%;
}

.label-text {
    left: 16px;
    // position: absolute;
    transition-duration: 200ms;
    color: #050505;
    text-overflow: ellipsis;
    font-size: 1rem;
}

.poll-item input[type="text"] {
    font-size: 1rem !important;
    color: #050505;
    padding-left: 16px;
    line-height: 1.25;
    padding-right: 16px;
    border: none !important;
    display: block !important;
    width: 100%;
    font-size: 12px;
    box-sizing: border-box;
    padding-bottom: 10px;
}

// .poll-item input:focus ~ .poll-item {
//     top: -14px;
//     font-size: 12px;
//     color: #89c540;
// }
.poll-item input:focus {
    outline: none !important;
}

.poll-option-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    text-decoration: none;
    background: transparent;
    border: 0;
    transition: color 0.3s;
    display: block;
    width: 46px;
    height: 46px;
    font-size: 16px;
    font-style: normal;
    line-height: 46px;
    text-align: center;
    border-radius: 50%;
}

.poll-option-close:hover {
    background-color: #f0f2f5;
}

.add-poll-btn {
    background-color: #89c540 !important;
    // height: 10px;
}

.poll-feed-container {
    padding: 12px;
}

.poll-feed .ant-radio-group,
.poll-feed .ant-space,
.poll-feed .ant-progress {
    max-width: 100% !important;
}

.poll-feed .option-progress {
    width: 200px !important;
}

.poll-feed h6 {
    font-size: 12px;
}

@media (max-width: 1199px) {
    .poll-card {
        width: 24rem !important;
    }
}

@media (max-width: 1024px) {
    .poll-card {
        width: 20rem !important;
    }
}

@media (max-width: 767px) {
    .poll-card {
        width: 14rem !important;
    }
}

@media (max-width: 320px) {
    .poll-card {
        width: 11rem !important;
    }
}

.doubt-modalbox > .ant-modal-content > .ant-modal-close > .ant-modal-close-x > .ant-modal-close-icon:hover,
.back-arrow:hover {
    background-color: #f0f2f5;
}

.add-image-form-item {
    display: none;
}

.doubt-modalbox .ant-form-item-explain-error > div {
    color: #89c540 !important;
}

.loggedin-username {
    word-break: break-word;
    color: #050505;
    font-weight: 600;
    font-size: 0.875rem;
    word-wrap: break-word;
    line-height: 1.3333;
    margin-left: 10px;
    padding-top: 50px;
}

.add-topic-btn {
    margin-right: 4px;
    border-radius: 0.4rem !important;
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
    font-size: 0.9rem;
    line-height: 0.7rem;
    background-color: #ffffff;
    color: #89c540;
}

.view-more:hover {
    background-color: #f0f2f5;
}

.menu-item--action .share-button {
    color: #050505;
    font-size: 12px;
    // line-height: 17px;
    text-align: center;
    font-weight: bold;
}

.menu-item--action ul {
    border-radius: 0.4rem !important;
}

.menu-item--action.share-action {
    display: flex;
    flex-direction: column;
}

// Report post
.report-modal .ant-modal-body {
    padding-bottom: 16px !important;
    padding-top: 16px !important;
}

.report-modal .report-body {
    margin-top: -8px;
    margin-bottom: -8px;
    margin-left: -6px;
    margin-right: -6px;
    padding-right: 16px;
    padding-left: 16px;
    align-items: stretch;
}

.report-modal .report-body h4 {
    font-weight: normal;
    color: #050505 !important;
    word-break: break-word;
    font-size: 1.25rem;
    line-height: 1.2;
}

.report-modal .report-body span {
    font-size: 0.875rem !important;
    line-height: 1.3333;
}

.report-modal .report-select-problem {
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 16px;
    padding-top: 16px;
    margin-left: -2px;
}

.report-modal .ok-btn.ant-btn-primary {
    color: #000 !important;
    background-color: #ffffff !important;
    border-color: #000;
}

.report-modal .ok-btn.ant-btn-primary:hover {
    color: #ffffff !important;
    background-color: #89c540 !important;
    border-color: #89c540;
}

.report-select-problem .button-problem-select {
    margin-right: 5px !important;
    height: 40px !important;
    background-color: #ffffff !important;
    color: #89c540;
    margin: 8px;
    box-shadow: 3px 3px 3px grey, -3px -3px 3px white !important;
    font-weight: 600;
    word-break: break-word;
    font-size: 0.875rem;
    min-width: 0;
    word-wrap: break-word;
    line-height: 1.3333;
}

.button-problem-select.problem-selected,
.report-select-problem .button-problem-select:hover {
    color: #ffffff;
    background-color: #89c540 !important;
    // border-color: #ffffff;
}

.comment-form.ant-form {
    width: 80% !important;
}

.comment-form .ant-row.ant-form-item {
    width: 100% !important;
}

.comment-form .ant-form-item {
    margin-bottom: 0 !important;
}

.comment-form textarea {
    background-color: #f0f2f5;
}

.comment-form textarea:focus,
.comment-form textarea:hover {
    border-color: #fff;
}

.alertbox {
    box-shadow: 0 1px 3px 0 rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%);
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    letter-spacing: 0.2px;
    -webkit-align-items: center;
    align-items: center;
    background-color: #202124;
    border: none;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    bottom: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    font-weight: 400;
    left: 0;
    margin: 24px;
    max-width: 640px;
    min-height: 52px;
    padding: 8px 24px;
    padding-right: 52px;
    position: fixed;
    right: auto;
    text-align: left;
    top: auto;
    white-space: normal;
    z-index: 1000;
}

.alertbox .ant-alert-message,
.alertbox .ant-alert-close-icon .anticon-close {
    color: #fff !important;
}

.alertbox .ant-alert-close-icon {
    right: 0 !important;
}

.alert-hide {
    opacity: 0;
    animation: fadeOut ease 2s !important;
    -webkit-animation: fadeOut ease 2s !important;
    -moz-animation: fadeOut ease 2s !important;
    -o-animation: fadeOut ease 2s !important;
    -ms-animation: fadeOut ease 2s !important;
}

@keyframes fadeOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.menu-item--action {
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%) !important;
    border-radius: 2px !important;
    margin: 0 !important;
    padding: 4px 0 !important;
    text-align: left !important;
    background-color: #fff;
    background-clip: padding-box;
}

.action-modal .ant-btn-primary {
    // background-color: #283360 !important;
    border-radius: 0.3rem;
}

.action-modal .ant-modal-close-icon {
    border-radius: 50%;
    padding: 10px;
}

.action-modal .ant-modal-close-icon:hover {
    background-color: #f0f2f5;
}

.action-modal .ant-modal-title {
    padding-bottom: 2px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-word;
    color: #050505 !important;
    font-size: 1.25rem !important;
    font-weight: 700;
}

.ant-menu-item:hover {
    background-color: #f0f2f5 !important;
}

.add-comment-btn {
    background-color: #89c540;
    color: #fff;
    font-size: 0.7rem;
    margin-top: 8px !important;
    padding: 0.2rem 0.8rem !important;
    line-height: 1.4rem !important;
}

textarea.ant-input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #050505;
}

textarea.ant-input::-moz-placeholder {
    /* Firefox 19+ */
    color: #050505;
}

textarea.ant-input:-ms-input-placeholder {
    /* IE 10+ */
    color: #050505;
}

textarea.ant-input:-moz-placeholder {
    /* Firefox 18- */
    color: #050505;
}

.add-topic-btn:focus,
.add-comment-btn:focus {
    box-shadow: none !important;
}

.add-topic-btn:hover,
.add-comment-btn:hover {
    background-color: #89c540;
    color: #050505;
}

.topic-add-input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #050505;
}

.topic-add-input::-moz-placeholder {
    /* Firefox 19+ */
    color: #050505;
}

.topic-add-input:-ms-input-placeholder {
    /* IE 10+ */
    color: #050505;
}

.topic-add-input:-moz-placeholder {
    /* Firefox 18- */
    color: #050505;
}

// Modal add doubt ends
.doubt-feed_container {
    margin-bottom: 16px !important;
    max-width: 82%;
    padding: 10px;
}

.ant-dropdown-show-arrow li:hover {
    background-color: #f0f2f5 !important;
}

.ant-menu-item-selected {
    background: none !important;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottom,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
    z-index: 10 !important;
}

li.ant-menu:not(.ant-menu-horizontal):hover {
    background-color: #f0f2f5 !important;
}

.feed > div {
    z-index: 10;
}

.doubt-feed_container > .feed {
    border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px !important;
}

.feed-image {
    text-align: center;
    display: block;
}

.feed-image > img {
    width: 90%;
    border-radius: 2px !important;
    margin-bottom: 24px !important;
    overflow: hidden;
    /* width: inherit; */
    min-height: 254px !important;
}

.feed-info {
    padding: 20px;
    display: flex;
    justify-content: space-around;
    text-align: center;
}

.feed-info i {
    color: #283360 !important;
}

.feed-info-vote {
    justify-content: space-between;
    color: #283360;
    padding: 5px 15px 5px 15px;
    border-radius: 0.45rem;
    &:hover {
        background-color: #f0f2f5;
    }
}

.feed-info-share {
    justify-content: space-between;
    color: #283360;
    padding: 5px 15px 5px 15px;
    border-radius: 0.45rem;
    &:hover {
        background-color: #f0f2f5;
    }
}

.comments-wrapper {
    padding: 10px;
}

.comment {
    animation-duration: 0.3s;
    animation-name: fadeIn;
    display: flex;
    align-items: flex-start;
    border-top: 1px solid #f0f2f5;
    /* padding: 2px 3px; */
}

.comment .user-data {
    max-width: 75%;
    /* overflow: hidden; */
}

.comment {
    padding: 10px;
}

.comment-section.hide {
    display: none;
}

.comment-section.show {
    display: block;
}

.show-comment-update {
    display: block;
    border-color: #89c540 !important;
}

.user-data {
    margin-left: 10px;
}

.comment-text {
    color: #050505;
    font-size: 0.85rem;
    background: #f0f2f5;
    border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px !important;
    text-align: start !important;
    padding: 8px;
}

.comment-text .username span {
    font-size: 10px;
    color: #89c540;
}

.comment .user-avatar {
    width: 35px;
    height: 35px;
}

.comment-box > span:hover {
    cursor: pointer;
}

.add-post-button {
    background-color: #283360 !important;
    border: 1px solid #283360 !important;
    color: #fff !important;
    -webkit-box-shadow: 0 3px 5px 0 rgb(28 89 159 / 30%);
    box-shadow: 0 3px 5px 0 rgb(28 89 159 / 30%);
    float: right;
    font-size: 0.8rem;
}

.add-doubt-photo {
    cursor: pointer;
    border-radius: max(0px, min(8px, calc((100vw - 4px - 100%) * 9999))) / 8px !important;
    padding: 3px;
}

.add-doubt-photo:hover {
    background: #f0f2f5;
}

.feed-icon {
    cursor: pointer;
}

.feed-icon:hover {
    border-radius: 50%;
    background-color: #f0f2f5;
    padding: 2px 2px 2px 2px;
}

// Doubt Actions css starts
#components-dropdown-demo-arrow .ant-btn {
    margin-right: 8px;
    margin-bottom: 8px;
}

.ant-row-rtl #components-dropdown-demo-arrow .ant-btn {
    margin-right: 0;
    margin-bottom: 8px;
    margin-left: 8px;
}

.action-button {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}

.action-button:hover {
    background-color: #f0f2f5 !important;
}

.action-button.ant-btn:hover,
.action-button.ant-btn:focus {
    color: #050505 !important;
}

.action-button:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

// Doubt action css ends
.edit-delete-list-comment {
    position: absolute;
    padding: 12px;
    height: 20px !important;
    width: 180px;
    right: 27px;
    display: none;
    z-index: 100;
    font-size: 0.7rem;
}

.edit-delete-list-comment li:hover {
    cursor: pointer;
}

.edit-delete-list-comment ul {
    overflow-y: auto;
    overflow-x: auto;
    pointer-events: all;
}

.show-edit-box {
    display: block !important;
}
@media (max-width: 767px) {
    .comment-btn {
        right: 0;
    }
    .add-question__custom-button {
        height: 33px !important;
        font-size: 0.7rem !important;
        float: right !important;
        margin-right: 50px !important;
    }
    .fa-paper-plane {
        font-size: 1.1rem !important;
    }
}

.share-icons {
    position: absolute;
    padding: 28px;
    height: 50px;
    width: 260px;
    right: -29px;
    display: none;
    z-index: 100;
}

.share-icons ul {
    overflow-y: auto;
    overflow-x: auto;
    padding-top: 12px;
    padding-bottom: 12px;
    pointer-events: all;
}

.share-icons ul li {
    padding-left: 8px;
    padding-right: 8px;
}

.textarea-comment,
.textarea-add-doubt {
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
}

.comment-box textarea {
    font-size: 1.4rem;
    line-height: 1.42857;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
    flex: 1 1 auto;
    min-width: 0;
    padding: 9.5px;
}

.textarea-comment {
    max-width: 70%;
    // height: 30px !important;
}

.comment-button {
    padding: 6px;
    color: #283360;
}

.post-comment-count {
    color: #283360;
    padding: 5px 15px 5px 15px;
    border-radius: 0.45rem;
    &:hover {
        background-color: #f0f2f5;
    }
    small {
        margin-left: 5px;
    }
}

@media (max-width: 767px) {
    .comment-button {
        margin-left: 0 !important;
        margin-top: 0 !important;
    }
}

.fa-paper-plane {
    font-size: 1.2rem;
}

.show-share-icons {
    display: block;
}

.share-buttons .list-group-item:hover,
.edit-delete-list-comment li:hover {
    background: #f0f2f5;
    color: #050505;
}

.share-buttons .twitter-item:hover {
    background: #f0f2f5;
    color: #050505;
}

.edit-comment-dot {
    z-index: 200;
}
.left-sidebar {
    top: 70px;
    max-width: 360px;
    flex-shrink: 9999;
    // max-height: 0;
    flex-basis: 360px;
    overflow-x: hidden;
    min-width: 280px;
    overflow-y: hidden;
}

.left-sidebar__inner-div {
    min-height: 100%;
    margin-top: 16px;
}

.left-sidebar__course-list {
    // list-style-type: none;
    margin: 0;
    padding: 0;
    li {
        list-style: none;
    }
}

.left-sidebar__single--item {
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 0.6rem;
}

.left-sidebar-course-link {
    padding-left: 8px;
    min-height: 44px;
    padding-right: 8px;
    display: flex;
    text-decoration: none;
}

.left-sidebar-course-link > .inner-title {
    padding-bottom: 8px;
    margin-top: 9px;
    word-break: break-word;
    color: #050505;
    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    max-width: 100%;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
}

.left-sidebar-course-link > .inner-count {
    margin-top: 9px;
    word-break: break-word;
    color: #e4e4e4;
    font-size: 0.775rem;
    font-weight: 400;
}

.left-sidebar__course-list > li > .left-sidebar__single--item:hover,
.left-sidebar__course-list > li > .left-sidebar__single--item.active {
    background-color: #f0f2f5;
}

@media (min-width: 768px) {
    #sidebar-wrapper {
        width: 200px;
        display: block;
        height: 200px;
    }
}

@media (min-width: 1000px) {
    .study-info-ad img,
    .study-info-ad__text {
        display: block;
    }
    .study-info-ad img {
        height: 150px !important;
    }
}

@media (max-width: 768px) {
    #sidebar-wrapper {
        width: 150px;
        left: 210px;
        top: 18%;
        height: 250px;
    }
}

/* Doubt sidebar ends */

.sidebar-right {
    position: fixed;
    top: 70px;
    right: 20px;
    width: 300px;
    /* z-index: 1000; */
    // margin-top: 4%;
}

@media (max-width: 1160px) {
    .sidebar-right {
        display: none !important;
    }
}

.sidebar-right .card {
    cursor: pointer;
    transition: all 0.3s ease;
}

.sidebar-right .card:hover {
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    transform: translateY(-5px);
}

.sidebar-right__card .card-text {
    border-radius: 0.8rem !important;
    font-size: 0.7rem !important;
    height: 64px !important;
}

.back-to-top {
    z-index: 10005 !important;
}

.post-like > svg {
    color: #89c540 !important;
    transition: 0.5s ease-in !important;
    font-size: 25px !important;
}

.post-unlike > svg {
    color: #283360 !important;
    transition: 0.5s ease-in !important;
    font-size: 20px !important;
}

.liked-text {
    position: absolute;
    bottom: 160px;
    left: 80px;
    /* right: 0; */
    /* visibility: hidden; */
    transition: 0.6s;
    z-index: 10;
    font-size: 16px;
    color: #000;
    font-weight: 400;
}

@keyframes fade {
    0% {
        color: transparent;
    }
    50% {
        color: #e23b3b;
    }
    100% {
        color: transparent;
    }
}

.read-or-hide {
    text-decoration: none;
    color: #050505;
    font-weight: bold;
}

.topic-item {
    background-color: #f0f2f5;
    padding: 5px;
    cursor: pointer;
    font-size: 13px;
    border-radius: 0.2rem;
    color: #050505;
}

.search-container {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 12px 16px;
    background: rgba(22, 24, 35, 0.06);
    border-radius: 92px;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.search-container-input {
    font-weight: 400;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    border: none;
    background: transparent;
    outline: none;
    padding: 10px;
    width: 292px;
    caret-color: #283360;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

// Doubts Admin
.request-list-area {
    .outer-box {
        box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
        margin: 1rem;
        padding: 1.6rem;
    }
    a,
    a:hover {
        text-decoration: none;
        transition: color 0.3s ease-in-out;
    }
    #pageHeaderTitle {
        margin: 2rem 0;
        text-transform: uppercase;
        text-align: center;
        font-size: 2.5rem;
    }
    .report {
        box-shadow: none !important;
        padding: 0 !important;
    }
    /* Cards */
    .request {
        flex-wrap: wrap;
        display: flex;
        box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
        border-radius: 10px;
        margin: 0 0 2rem 0;
        overflow: hidden;
        position: relative;
        color: #ffffff;
        padding: 1.6rem;
        .t-dark {
            color: #000;
        }
        a {
            color: inherit;
        }
        h4,
        .h4 {
            margin-bottom: 0.5rem;
            font-weight: 500;
            // line-height: 1.2;
        }
        .small {
            font-size: 80%;
        }
        .request__img {
            max-height: 180px;
            width: 100%;
            // object-fit: cover;
            // position: relative;
        }
        .request__img_link {
            display: contents;
        }
        .request__bar {
            width: 50px;
            height: 10px;
            margin: 10px 0;
            border-radius: 5px;
            background-color: #424242;
            transition: width 0.2s ease;
        }
        .request__text {
            padding: 2.5rem;
            position: relative;
            display: flex;
            flex-direction: column;
        }
        .request__preview-txt {
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: justify;
            height: 100%;
        }
        .request__tagbox {
            display: flex;
            flex-flow: row wrap;
            font-size: 14px;
            margin: 20px 0 0 0;
            padding: 0;
            justify-content: center;
            .tag__item {
                display: inline-block;
                background: rgba(83, 83, 83, 0.4);
                border-radius: 3px;
                padding: 2.5px 10px;
                margin: 0 5px 5px 0;
                cursor: default;
                user-select: none;
                transition: background-color 0.3s;
                &:hover {
                    background: rgba(83, 83, 83, 0.8);
                    cursor: pointer;
                }
            }
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            // background-image: linear-gradient(-70deg, #424242, transparent 50%);
            opacity: 1;
            border-radius: 10px;
        }
        &:hover .request__bar {
            width: 100px;
        }
    }
    @media screen and (min-width: 769px) {
        .request {
            flex-wrap: inherit;
            .request__tagbox {
                justify-content: start;
            }
            .request__img {
                max-width: 300px;
                max-height: 100%;
                transition: transform 0.3s ease;
            }
            .request__text {
                padding: 1rem;
                width: 100%;
            }
            .media.request__text:before {
                content: "";
                position: absolute;
                display: block;
                background: #18151f;
                top: -20%;
                height: 130%;
                width: 55px;
            }
            &:nth-child(2n + 1) {
                flex-direction: row;
            }
            &:nth-child(2n + 0) {
                flex-direction: row-reverse;
            }
            &:nth-child(2n + 1) .request__text::before {
                left: -12px !important;
                transform: rotate(4deg);
            }
            &:nth-child(2n + 0) .request__text::before {
                right: -12px !important;
                transform: rotate(-4deg);
            }
        }
    }
    @media screen and (min-width: 1024px) {
        .request__text {
            padding: 2rem 3.5rem;
        }
        .request__text:before {
            content: "";
            position: absolute;
            display: block;
            top: -20%;
            height: 130%;
            width: 55px;
        }
        .request.dark {
            .request__text:before {
                background: #18151f;
            }
        }
        // .request.light {
        //     // .request__text:before {
        //     //     background: #e1e5ea;
        //     // }
        // }
    }
    /* COLORS */
    .request .request__tagbox .green.play:hover {
        background: $main-green;
        color: black;
    }
    .request__text {
        .ant-select {
            position: absolute;
            right: 0;
        }
    }
    .request .request__tagbox .yellow.play:hover {
        background: $main-yellow;
        color: black;
    }
    .keep-remove {
        .add-topic-btn {
            box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
        }
    }
}

.modal-remove {
    // box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
    padding: 1.6rem;
    .border-header {
        height: 1px;
        margin: 10px 0;
        border-radius: 5px;
        background-color: #424242;
        transition: width 0.2s ease;
    }
}

// My Doubts Filter
.filter-my-doubts {
    padding: 15px 20px 12px;
    border-radius: 5px;
    margin: auto;
    h5 {
        color: #000;
        font-weight: 600;
        padding-bottom: 10px;
        margin-bottom: 20px;
        position: relative;
        // &:before {
        //     position: absolute;
        //     content: "";
        //     background: #283360;
        //     width: 50px;
        //     height: 2px;
        //     bottom: 0;
        //     left: 0;
        //     // align-items: center;
        // }
        @media (max-width: 575px) {
            font-size: 17px;
        }
    }
    .tag-box {
        display: table;
        margin: 0 auto;
        button {
            // font-size: 13px;
            // color: #000 !important;
            border: 1px solid #f0f2f5;
            padding: 6px 8px 5px;
            margin: 0 13px 16px 0;
            display: inline-block;
            border-radius: 5px;
            &:focus {
                outline: 0 !important;
                box-shadow: none !important;
            }
        }
        .current {
            background: #283360;
            color: #fff !important;
        }
    }
    @media (max-width: 1199px) {
        padding: 12px 15px 6px;
    }
    @media (max-width: 575px) {
        margin-bottom: 30px;
    }
}

.forum-questions {
    font-family: "Roboto", "sans-serif";
    float: left;
    width: 100%;
    background-color: #fff;
    font-size: 13px;
    .usr-question {
        float: left;
        width: 100%;
        position: relative;
        padding: 25px;
        // border-bottom: 1px solid #e5e5e5;
        h3 {
            font-family: "Poppins", sans-serif;
        }
    }
    .usr_img {
        float: left;
        width: 60px;
    }
    .usr_quest {
        // float: left;
        // width: 90%;
        padding-left: 15px;
        h3 {
            color: #000000;
            font-weight: 600;
        }
    }
    .react-links {
        float: left;
        width: 100%;
        li {
            display: inline-block;
        }
    }
    .quest-tags {
        float: left;
        width: 100%;
        margin-top: 20px;
        li {
            display: inline-block;
            margin-right: 10px;
            a {
                display: inline-block;
                color: #ffffff;
                background-color: #53d690;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                border-radius: 30px;
                padding: 7px 25px;
            }
        }
    }
    .quest-posted-time {
        color: #b2b2b2;
        font-size: 12px;
        svg {
            padding-right: 5px;
        }
    }
    p {
        font-style: normal;
        background: none;
        line-height: 1.4;
    }
}


