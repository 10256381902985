.pulse-icon {
  width: 50px;
  height: 50px;
  background: url(/images/play.svg) no-repeat;
  background-size: 50px 50px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;

  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  border-radius: 999px;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
  //     box-shadow:
  //       0 0 0 10px   rgba(255,255,255,.2),
  //       0 0 25px 2px rgba(0,0,0,.4),
  // inset 0 0 0 15px   rgba(255,255,155,.4);
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(238, 102, 102, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(235, 101, 101, 0.2);
  }
}

.pulse-text {
  @extend .montserrat-font-family;
  position: absolute;
  margin-left: 71px;
  margin-top: -35px;
  font-size: 18px;
  font-weight: 500;
  color: #c71a23 !important;
}
.pulse-text-phone {
  position: absolute;
  margin-left: -28px;
  margin-top: 18px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Robotto", "sans-serif";
}

.landing-video-wrapper {
  position: relative;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-close-btn {
  position: absolute;
  z-index: 10001;
  right: -45px;
  // top: -20px;
  color: #fff;
  font-size: 27px;
}
.explore-courses-btn {
  position: absolute;
  margin-left: 188px;
  margin-top: 58px;
}
