.content-type-radio {
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;

  label {
    padding: 12px 30px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: "";
      background-color: #283360;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 32px;
      height: 32px;
      content: "";
      border: 2px solid #d1d7dc;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }

  input:checked ~ label {
    color: #fff;

    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }

    &:after {
      background-color: #283360;
      border-color: #ffffff;
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}

.quiz-input-question-title {
  border: transparent;
  background: transparent;
  border-width: 2px;
  border-bottom-color: #e2e0de;
  font-weight: 600;
}
.content-modal {
  top: 20;
}

.course-list-card {
  @media screen and (min-width: 1500px) {
    width: 365px;
  }
  .ant-card-body {
    height: 190px;
  }
}
.my-course-list-card {
  @media screen and (min-width: 1500px) {
    width: 365px;
  }
  .ant-card-body {
    height: 190px;
    padding: 0px;
  }
}

.guppy_osk {
  position: sticky;
  z-index: 1001;
}

.guppy_zIndex {
  z-index: 1001;
}

.katex-html {
  overflow-x: auto;
  width: 100%;
  overflow-y: hidden;
  overflow-wrap: break-word;

  &::-webkit-scrollbar {
    margin-top: 15px;
    padding-top: 15px;
    width: 5px;
    height: 8px;
    border-left: #ededed solid 1px !important;
    background-color: #fcfcfc !important;
    border-top: #ededed solid 1px !important;
  }

  .text {
    all: unset;
  }
}

.katex {
  display: grid;
}

#mathinputdiv {
  padding: 10px;
  overflow-x: auto;
  border: 1px solid #3c454c;

  &::-webkit-scrollbar {
    margin-top: 15px;
    padding-top: 15px;
    width: 5px;
    height: 8px;
    border-left: #ededed solid 1px !important;
    background-color: #fcfcfc !important;
    border-top: #ededed solid 1px !important;
  }

  .guppy_active,
  .guppy_inactive {
    border: none;
    box-shadow: none;
    padding: 10px;

    &:focus {
      outline: none;
      border: none;
    }
  }
  .katex-html {
    all: unset;
  }
}

.sample-course-list {
  box-shadow: 0 1px 2px 0 #dae1ef;
}

.selected-sample {
  background-color: #3e4143;
  border-radius: 2px;
  // color: #fff;
  .circle-singleline {
    border: 1px solid white;
    color: #fff;
  }
}

#course-edit-form .tox-tinymce {
  height: 450px !important;
}
