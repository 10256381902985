// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
// .tutor-container{
//     padding-left: 0 !important;
//     padding-right: 0 !important;

// }

$color-border1: #89c540;
$color-green: #283360;
$color: #ffffff;
$black: #000;
$color-balck: #202647;
$color-black: #202647;
$color-text: #6a6c72;
$color-yellow: #6a6c72;
$color-border: #6a6c72;
$font-size: 15px;
$transition: 0.5s;
$primary: #283360;
$color-gr-br: #283360;
$secondary: #c71a23;
$white-color: $color;
$secondary-color: $color-border1;
$black-color: $black;

.width-4rem {
    margin-top: 8px !important;
    width: 6rem !important;
}

@media (min-width: 768px) {
    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }
}

@media (min-width: 768px) {
    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }
}

.header-text {
    letter-spacing: -0.2px;
    color: #120f2d;
    margin: 0px 0px 20px 0px;
    font-size: 39px;
    font-weight: 800;
    line-height: 49px;
}
.heading-description {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}
.heading-description p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    padding: 18px;
}
.gutter-16 {
    margin-left: -8px !important;
    margin-right: -8px !important;
}
@media (max-width: 991px) {
    .md-pb-64 {
        padding-bottom: 64px !important;
    }
}
@media (max-width: 991px) {
    .md-pt-80 {
        padding-top: 80px !important;
    }
}
@media (max-width: 991px) {
    .md-pb-64 {
        padding-bottom: 64px !important;
    }
}
@media (max-width: 991px) {
    .md-pt-80 {
        padding-top: 80px !important;
    }
}

@media (max-width: 768px) {
    .banner-button {
        left: 44% !important;
        padding: 15px 16px;
    }
}

@media (max-width: 767px) {
    .banner-button {
        left: 44% !important;
        font-size: 0.7em;
        padding: 5px 6px;
    }
}

.form-control-underlined {
    border-width: 0 !important;
    padding-left: 0;
}
.modal,
.fade,
.show {
    padding-left: 15px;
    padding-right: 15px;
}

h2 {
    font-weight: bold;
    margin-bottom: 15px;
    color: #000;
}

.modal-content {
    border: none;
    background: transparent;
    padding: 0 19px;
}

.close {
    position: relative;
    top: 48px;
    left: 13px;
    z-index: 1;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    color: black;
}

.modal-header {
    border: none;
}

.modal-header .close {
    padding: 0rem 1rem !important;
    margin: -1rem -1rem -1rem auto;
}

.modal-body {
    border: none;
    background-color: white;
    padding-bottom: 5px;
}

.close.focus,
.close:focus {
    outline: 0;
    box-shadow: none !important;
}

.form-control {
    width: 80%;
    height: 50px;
    border: none;
    // border-radius: 20px;
    box-shadow: 0px 0.5px 0px 0px #dae0e5 !important;
    color: #63686c;
    // font-weight: bold;
    border: 1px solid #63686c;
    font-size: 12px;
    select {
        option:disabled {
            font-weight: 400;
        }
    }
}

.course-request-form {
    .form-control {
        &::placeholder {
            font-style: italic;
            font-weight: 400;
        }
    }
}

.form-control.focus {
    border: none;
    border-color: #fff;
    border-bottom: 1px solid #000;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
}

@media (min-width: 599px) {
    .modal-dialog {
        max-width: 47rem;
    }

    .details {
        padding: 60px 0 40px 50px;
    }

    .text-muted a {
        color: #c0bfbf;
        font-weight: bold;
        text-decoration: underline;
    }

    small.para {
        font-weight: bold;
        font-size: 14px;
        color: #63686c;
    }
}

.course-request-form::-webkit-input-placeholder {
    font-size: 14px !important;
}

.course-request-form:-moz-placeholder {
    /* Firefox 18- */
    font-size: 14px !important;
}

.course-request-form::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 14px !important;
}

.show-nav .owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
    display: block;
}
.hide-nav .owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
    display: none;
}

/* Course Tutor */
.tutor-sidebar {
    .banner-button {
        background-color: #89c540 !important;
        color: #fff;
        margin: auto;
        position: relative !important;
    }
    .tutor-search {
        h5 {
            color: $color-green;
            font-weight: 600;

            @media (max-width: 575px) {
                font-size: 17px;
            }
        }
        form {
            position: relative;
            input {
                width: 100%;
                height: 45px;
                font-size: 15px;
                border: 1px solid #c4c1c1;
                color: #363232;
                padding-left: 15px;
                font-weight: 500;
                &:focus-within {
                    outline: none;
                }
                &::placeholder {
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
    }
}
.viewall-btn {
    a {
        font-size: 15px;
        color: #fff !important;
        background: $secondary;
        display: inline-block;
        width: 200px;
        height: 48px;
        text-transform: uppercase;
        font-weight: 500;
        text-align: center;
        padding: 15px 20px 20px !important;
        border-radius: 5px;
        margin-top: 4px;
        margin-bottom: 30px;

        &:hover {
            background: $secondary;
        }

        @media (max-width: 575px) {
            font-size: 13px;
            width: 170px;
            height: 42px;
            padding: 12px;
            margin-top: 10px;
        }
    }
}

.verify-textarea textarea {
    resize: none;
}

.tutor-social-icons {
    font-size: 24px;
    color: #283360 !important;
}

.ant-steps-item-icon {
    background-color: #283360 !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
    .ant-steps-icon {
        color: #fff !important;
    }
}

.ant-steps-item-active {
    .ant-steps-item-icon {
        background-color: #89c540 !important;
        border-color: #89c540 !important;
    }
}

.ant-steps-item-wait {
    .ant-steps-item-icon {
        background-color: #283360 !important;
    }

    .ant-steps-icon {
        color: #fff !important;
    }
}

.instructor-details-page {
    .instructor-details-area {
        padding: 30px 0 63px;
        .ant-avatar-string {
            font-size: 76px !important;
        }
        .instructor-img {
            margin-bottom: 55px;
            ul.getintouch {
                margin-bottom: 30px;
                li {
                    font-size: 20px;
                    color: rgb(68, 68, 68);
                    font-weight: 500;
                    margin-bottom: 5px;
                    i {
                        font-size: 26px;
                        color: $primary;
                        vertical-align: text-bottom;
                        margin-right: 5px;

                        @media (max-width: 991px) {
                            font-size: 20px;
                        }
                    }

                    @media (max-width: 991px) {
                        font-size: 16px;
                    }
                }
            }

            @media (max-width: 575px) {
                margin-bottom: 30px;
            }
        }
        .btn-outline-danger {
            border-color: #89c540 !important;
            color: #89c540 !important;
            &:hover {
                transition: 0.4s ease-in-out;
                background-color: #89c540 !important;
                color: #fff !important;
            }
        }

        .instructor-content {
            .ins-name {
                font-weight: 500;
                font-size: 2rem;
                line-height: 1.15;
                letter-spacing: -0.05rem;
            }
            h4 {
                font-size: 1.2rem;
                color: $black;
            }

            @media (max-width: 575px) {
                font-size: 20px;
            }
        }
        span:not(.ant-ribbon-text) {
            font-size: 15px;
            font-weight: 500;
            display: inline-block;

            @media (max-width: 575px) {
                font-size: 15px;
            }
        }
        p {
            font-size: 15px;
            color: rgb(102, 102, 102);
            font-weight: normal;
            line-height: 25px;
        }
        margin-bottom: 40px;

        @media (max-width: 575px) {
            font-size: 14px;
        }
    }

    .qual-expe {
        h5 {
            color: rgb(68, 68, 68);
            font-weight: 600;
            padding-bottom: 10px;
            margin-bottom: 20px;
            position: relative;
            &:before {
                position: absolute;
                content: "";
                background: $primary;
                width: 50px;
                height: 2px;
                bottom: 0;
                left: 0;
            }
        }
        .qual-expe-box {
            margin-bottom: 20px;
            h6 {
                color: rgb(68, 68, 68);
                margin-bottom: 8px;
                font-weight: 600;

                @media (max-width: 575px) {
                    font-size: 15px;
                }
            }
            p {
                font-size: 15px;
                color: rgb(102, 102, 102);
                @media (max-width: 575px) {
                    font-size: 14px;
                }
            }
        }

        @media (max-width: 360px) {
            display: block !important;
        }
    }
    .instructor-course-title {
        h5 {
            color: rgb(102, 102, 102);
            font-weight: 600;
            padding-bottom: 10px;
            margin-bottom: 35px;
            position: relative;
            &:before {
                position: absolute;
                content: "";
                background: $primary;
                width: 50px;
                height: 2px;
                bottom: 0;
                left: 0;
            }
        }
    }
    .instructor-course-slider {
        position: relative;
    }
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #283360 !important;
}
.ant-steps-item-title::after {
    height: 4px;
}

.tnc-scrollbar {
    margin-left: 0px;
    position: relative;
    border: 1px solid rgb(221, 221, 221);
    padding: 5px;
    overflow: auto;
    white-space: pre-wrap;
    width: auto;
    height: 300px;
}
