.dashboard-button {
    border-radius: 50%;
    width: 33px;
    height: 33px;
    line-height: 13px;
    padding: 6px;
    border: 2px solid #f0f2f5;
    &:hover {
        background: #283360;
        svg {
            color: #fff;
        }
    }
}

.client-drawer {
    &:not(.voucher-details) {
        dd {
            float: left;
            width: 50px;
            border-bottom: solid 1px #000;
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 10px;
            text-align: center;
        }
        dt {
            float: left;
            clear: left;
            width: 80px;
            border-bottom: solid 1px #000;
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: center;
        }
    }
}

.contact-tabs .ant-tabs-tab-active {
    border-bottom: none !important;
}

.contact-tabs .ant-tabs-tab:hover {
    color: #283360 !important;
}

.contact-tabs .ant-tabs-nav-wrap {
    border-bottom: 0px ;
}

.contact-tabs .ant-tabs-ink-bar {
    background: #89c540 !important;
}