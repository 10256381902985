 .course-details{
    .nav {
        display: inline-block;
        border-radius: 5px;
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
        margin-bottom: 35px;
        .nav-item {
            display: inline-block;
            a.nav-link {
                font-size: 14px;
                color: $color-black;
                font-weight: 500;
                text-transform: uppercase;
                border-radius: 5px;

                @media (max-width: 991px) {
                    padding: 12px 16px 9px;
                }
            }
            a.nav-link.active {
                background: $color-gr-br;
                color: #ffffff;
            }
        }
    }
 }

//  course details page

.banner-h1{
    font-size: 2rem !important;
    font-weight: 600;
    color: #fff !important;
    margin-bottom: 0.5rem;
    line-height: 1.4; 

    @media screen and (max-width: 640px){
        font-size: 1.2rem !important;
    }  
}

.banner-tags{
    font-size: 0.875rem;
    border-radius: 0.25rem;
    padding: 0.25rem 0.75rem;
    font-weight: 400;
    @include phone{
        padding: 0 0.4rem;
    }
}

.course-details-nav{
    ul::-webkit-scrollbar {
            display: none;
        }
   
    .ant-tabs-nav{
        height: 66px;
    }

    .ant-tabs-content-holder{
        @include phone{
            padding-top: 4px;
        }
        padding-top: 2rem;
    }

    .nav-sub-heading{
        font-size: 1.125rem;
        margin-bottom: 1.25rem;
        color:#000;
    }
    .ant-menu-item{
        &:hover{
            background: none !important;
            color: #283360 !important
        }
        &::after{
            border-bottom: none !important;
        }
    }
    .ant-menu-item-selected{  
        color: #283360!important;
        font-weight: 600;
        &::before{
            border-bottom: 1px solid #283360;
        }
    }
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after{
        border-bottom: 2px solid #283360 ;
        left:0px;
        right:35px;   
        @include phone{
            width: 100%;
        }
    }
}
.curriculum-menu{
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after{
        border-bottom: none !important;
    }

    .active-subject{
        border: 1px solid #283360 !important;
        color: #283360;
    }

    .ant-collapse-ghost > .ant-collapse-item{
        border-top: 1px solid #ecf0f2;
    }
}

.curriculum-li-content{
  &:hover,
  &:focus {
        transform: translateY(-5px);
        padding: 5px;
        &::after {
        opacity: 1;
        }
    }

    @include phone{
        padding: 1rem 2px !important;
        border-top: 1px solid #ecf0f2;
    }
}

.curriculum-page{
    @include phone{
        border: 1px solid #ecf0f2;
        box-shadow: 0 1px 2px 0 #dae1ef;
    }
    .ant-menu-horizontal{
        border-bottom: none;
    }
}

.sticky-footer-card {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 1rem .75rem;
    box-shadow: 0 -16px 29px #0000001a;
    background: $white-color !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    transition: $transition;
    min-height: 75px;
    height: auto;
}