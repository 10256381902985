.discussion-feeds {
    .add-text-area {
        border-color: #e0e0e0 !important;
        font-family: "Roboto", "sans-serif";
        line-height: 1rem;
        transition: all 0.3s;
        color: #363636;
        background-color: #ffffff;
        resize: none;
        border-radius: 0.6rem;
        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }
        &:focus-visible {
            outline: none !important;
        }
    }

    .post-field, .reply-write-content {
        margin-bottom: 0;
        .text-area {
            border-color: #e0e0e0;
            font-size: 0.9rem;
            transition: all 0.3s;
            box-shadow: none !important;
            display: block;
            color: #000;
            background-color: #fff;
            &:focus {
                outline: none !important;
            }
            &:focus-visible {
                outline: none !important;
            }
        }
    }

    .write-content {
        background-color: transparent;
        align-items: stretch;
        display: flex;
        &:last-child {
            border-bottom-left-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }
        .post-compose {
            align-items: flex-start;
            display: flex;
            text-align: inherit;
            width: 100%;
        }
        .post-content {
            flex-basis: auto;
            flex-grow: 1;
            flex-shrink: 1;
            text-align: inherit;

            .actions {
                border-left: 1px solid #e8e8e8;
                border-right: 1px solid #e8e8e8;
                border-bottom: 1px solid #e8e8e8;
                border-radius: 0 0 0.65rem 0.65rem;
            }
        }
    }

    .discussion-feed {
        background: #fff;
        .d-time {
            font-size: 0.67rem;
        }
        .user-name {
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            color: #393a4f;
            font-size: 0.82rem;
        }
        .edit-post-button {
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 32px;
            width: 32px;
            border: none;
            border-radius: 50%;
            transition: all 0.3s;
            cursor: pointer;
            &:hover {
                background: #e8e8e8;
            }
            .dropdown-menu {
                border: 1px solid #e8e8e8 !important;
                box-shadow: none !important;
                border-radius: 0.65rem !important;
                min-width: 280px;
                .span {
                    line-height: 1.2;
                    flex-basis: auto;
                    flex-grow: 1;
                    flex-shrink: 1;
                    text-align: inherit;
                    font-weight: 500;
                    font-size: 0.85rem;
                    color: #393a4f;
                    transition: all 0.3s;
                    line-height: 1.2;
                }
            }
        }

        .discussion-content {
            .discussion-image {
                .action-wrapper {
                    position: absolute;
                    bottom: -27px;
                    left: 0;
                    max-height: 34px;
                    .like-button,
                    .cmt-button {
                        width: 34px;
                        height: 34px;
                        border-radius: 50%;
                        background: #fff;
                        text-decoration: none;
                        opacity: 1 !important;
                        overflow: hidden;
                    }
                }
            }
            .action-wrapper-no-image {
                border-top: 1px solid rgb(216 216 216);
                font-weight: 700;
                font-size: 0.9rem;

                .like-button {
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    // background: #fff;
                    text-decoration: none;
                    opacity: 1 !important;
                    overflow: hidden;
                }
            }
            p {
                font-family: "Roboto", sans-serif;
                font-weight: 400;
                font-size: 0.82rem;
                // max-width: 680px;
                line-height: 1.07rem;
                margin-bottom: 15px;
            }
            .tags {
                .tag {
                    border-radius: 100px;
                    margin-bottom: 0.5rem;
                    line-height: 1;
                    font-weight: 500;
                    &:not(:last-child) {
                        margin-right: 0.5rem;
                    }
                    &:not(body) {
                        align-items: center;
                        background-color: #adb5bd;
                        // adb5bd
                        // f8f9fc
                        color: #f8f9fc;
                        border-radius: 4px;
                        display: inline-flex;
                        font-size: 0.75rem;
                        height: 2em;
                        justify-content: center;
                        line-height: 1.5;
                        padding-left: 0.75em;
                        padding-right: 0.75em;
                        white-space: nowrap;
                    }
                }
            }
            .comment-count,
            .like-count {
                font-family: "Montserrat", sans-serif;
                font-weight: 600;
                color: #393a4f;
                font-size: 0.8rem;
            }
            .social-count {
                display: block;
                font-size: 0.9rem;
                span {
                    font-family: "Montserrat", sans-serif;
                    font-weight: 600;
                    color: #393a4f;
                    font-size: 0.9rem;
                }
            }
        }
    }
}
.comments-wrap {
    padding: 14px 16px 16px 16px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    background: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    h4 {
        font-size: 1rem;
        font-weight: 500;
        color: #757a91;
        small {
            font-size: 0.875em;
        }
    }
    .comment-body {
        padding: 12px 25px 0 0;
        max-height: 450px;
        overflow-y: auto;
        .comment-left,
        .comment-right {
            flex-basis: auto;
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: .5rem;
        }

        .comment-content {
            flex-basis: auto;
            flex-grow: 1;
            flex-shrink: 1;
            text-align: inherit;
            a {
                font-size: 0.85rem;
                font-weight: 500 !important;
                display: block;
                color: #393a4f;
                line-height: 1.2;
            }
            .d-time {
                display: block;
                font-size: 0.7rem;
                color: #888da8;
            }
            p {
                font-size: 0.9rem !important;
                color: #000;
            }
            .comment-controls {
                span {
                    display: block;
                    font-size: 0.8rem;
                    color: #65676B;
                }
                .like-icon {
                    &:hover {
                        color: #dc3545;
                    }
                }
            }
        }
    }

    .close-comment {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background: transparent;
        cursor: pointer;
        transition: background 0.3s;
        &:hover {
            background: #f0f2f5;
        }
    }
}

.feed-report-modal {
    h3 {
        color: #393a4f;
        font-weight: 500;
        font-size: 1.1rem;
        margin-left: 8px;
    }
    .ant-radio-wrapper {
        span {
            font-size: 0.9rem;
            font-weight: 500;
            color: #393a4f;
        }
    }
}

.ant-modal-content{
  .ant-modal-body{
    .add-modal-selector{
    margin-bottom : -40px;
    }
  }
}

.ant-modal-header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    .ant-modal-title {
        display: flex;
        justify-content: center;
        font-weight: bold;
    }
}

.discussion--like__react {
    display: none;
    bottom: 19px;
    border-radius: 15px;
    left: 50%;
    transform: translateX(-50%);
   }

.reply--like__react,.comment--like__react {
    display: none;
    bottom: 17.5px;
    border-radius: 15px;
    left: 50%;
    transform: translateX(-50%);
   }

.comment--like:hover {
    .comment--like__react {
        display: flex ;
    }
}

.reply--like:hover {
    .reply--like__react {
        display: flex;
    }
}

.like--react__icons {
    transition: transform 0.3s ease-in-out;
}

.like--react__icons:hover {
    transform: scale(1.3) translateY(-2px);
}

.comment--count:hover, .like--count:hover {
    text-decoration: underline;
}

.edit-comment-button,.edit-reply-button {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border: none;
    border-radius: 50%;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
        background: #e8e8e8;
    }
}

.input-hover-border:hover, .input-hover-border:focus {
    border-color: #d9d9d9 !important;
    outline: none !important;
    box-shadow: none !important;
}

.button-hover-border:hover{
    border-color: #d9d9d9 !important;
    // color: black !important;
    outline: none !important;
    box-shadow: none !important;
}

.button-hover-border:focus {
    color: black !important;
    outline: none !important;
    background: #E4E6EB !important;
    box-shadow: none !important;
}

.font-placeholder::placeholder {
    font-size: 15px !important;
}

.font-bold-placeholder::placeholder {
    font-size: 16px !important;
    font-weight: 500;
}

.input-placeholder::placeholder {
    font-size: 14px !important;
}

.cursor-disabled {
    cursor: not-allowed !important;
}

.hover-bg:hover {
    background: #F0F2F5;
    border-radius: 8px;
}

.hover-icon-bg {
    background: #f0f2f5;
    border-radius: 15px;
}

.discussion-content {
    p {
        color: #050505;
        font-weight: 400;
    }
}

.discussion-member {
    .content-type-radio input:checked ~ label:before{
        background-color: white !important;
    }
    .content-type-radio input:checked ~ label {
        color: #000 !important;
    }
    .content-type-radio input:checked ~ label:before {
        opacity: 0 !important;
        transform: none !important;
    }
}

.discussion--replies__hover:hover{
    span {
        text-decoration: underline;
    } 
}

.emojis--tab__list{
    .nav {
        display: inline-block;
        border-radius: 18px;
        // box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
        margin-bottom: 35px;
        @include phone {
            margin-bottom: 0px !important;
        }
        .nav-item {
            display: inline-block;
            a.nav-link {
                font-size: .9375rem;
                color: $color-black;
                font-weight: 600;
                font-family: inherit;
                @media (max-width: 991px) {
                    padding: 12px 16px 9px;
                }
                &:hover {
                    background: #F0F2F5;
                    border-radius: 8px;
                }
            }
            a.nav-link.active {
                color: #2a72c3;
                border-bottom: 3px solid #2a72c3;
                // border-radius: 18px;
                &:hover {
                    background: none;
                    border-radius: unset;
                }
            }
        }
    }
}