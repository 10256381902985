@use "variables/variables";

.line-spacing-learnsic {
    line-height: $line-spacing !important;
}
.font-size-sm {
    @extend .line-spacing-learnsic;
    font-size: $font-size-12 !important;
    @media (min-width: 1200px) {
        font-size: $font-size-14 !important;
    }
}
.font-size-small {
    font-size: $font-size-16 !important;
}
.font-size-md {
    font-size: $font-size-18 !important;
}
.font-md {
    font-size: $font-size-20 !important;
    p{
    font-size: $font-size-20 !important;
    }
}
.font-size-medium {
    font-size: $font-size-24 !important;
}
.font-size-lg {
    font-size: $font-size-32 !important;
}
.font-size-large {
    font-size: $font-size-48 !important;
}
.font-size-xlg {
    font-size: $font-size-72 !important;
}
.font-weight-500 {
    font-weight: 500 !important;
}
