.doubt-comment::before,
.doubt-comment::after,
.doubt-comment-form::before,
.doubt-comment-form::after{
    content: "";
	display: table;
	clear: both;
}

.doubt-comment ul{
	list-style-type: none;
	padding: 0;
}

.doubt-comment img{
	opacity: 1;
	filter: Alpha(opacity=100);
	-webkit-border-radius: 4px;
	   -moz-border-radius: 4px;
	  	 -o-border-radius: 4px;
			border-radius: 4px;
}

.doubt-comment img.avatar{
	position: relative;
	float: left;
	margin-left: 0;
	margin-top: 0;
	width: 65px;
	height: 65px;
}
.doubt-comment .user-avatar{
	position: relative;
	float: left;
	margin-left: 0;
	margin-top: 0;
}

.doubt-comment .post-comments{
	border: 1px solid #eee;
    margin-bottom: 20px;
    margin-left: 55px;
	margin-right: 0px;
    padding: 10px 20px;
    position: relative;
    -webkit-border-radius: 4px;
       -moz-border-radius: 4px;
       	 -o-border-radius: 4px;
    		border-radius: 4px;
	background: #fff;
	color: #6b6e80;
	position: relative;
}

.doubt-comment .meta {
	font-size: 13px;
	color: #aaaaaa;
	padding-bottom: 8px;
	margin-bottom: 10px !important;
	border-bottom: 1px solid #eee;
}

.doubt-comment ul.comments ul{
	list-style-type: none;
	padding: 0;
	margin-left: 85px;
}

.doubt-comment-form{
	padding-left: 15%;
	padding-right: 15%;
	padding-top: 40px;
}

.doubt-comment h3,
.doubt-comment-form h3{
	margin-bottom: 40px;
	font-size: 26px;
	line-height: 30px;
	font-weight: 800;
}

.course-doubt-comment {
    .comment-actions{
      display: none;
      float: right;
    }
    &:hover{
      .comment-actions{
        display: block;
      }
    }
  }