$color-border1: #c71a23;
$color-green: #283360;
$color: #ffffff;
$black: #000;
$color-balck: #202647;
$color-black: #202647;
$color-text: #6a6c72;
$color-yellow: #6a6c72;
$color-border: #6a6c72;
$font-size: 15px;
$transition: 0.5s;
$primary: #283360;
$color-gr-br: #283360;
$secondary: #c71a23;
$white-color: $color;
$secondary-color: $color-border1;
$black-color: $black;
.input-question-title {
    // border: transparent;
    // border-bottom: dotted;
    border-width: 2px;
    border-bottom-color: #e2e0de;

    &:focus {
        border: transparent;
        border-bottom: solid;
        border-width: 2px;
        border-bottom-color: #bde4f3;
        box-shadow: none;
    }
}

.custom-radio {
    width: 30px !important;
    height: 30px !important;
}

.custom-label {
    width: 95%;
    padding-left: 30px;
    padding-right: 10px;
}

.custom-input {
    height: 45px;
}

.question-cards {
    border-top-color: transparent;
    border-right-color: #eaeef0;
    border-bottom-color: #c5c8ca;
    border-left-color: #eaeef0;
    border-radius: 5px;
    background-color: #ffffff;
    &:hover {
        .actions-wrapper {
            display: block;
        }
        .actions-ellipsis {
            display: none;
        }
    }
}

.card-shadow {
    border-bottom-color: #c5c8ca;
}

.ranking-cards {
    position: relative;
    clear: both;
    background-color: #e6eff4;
    padding: 14px;
    justify-content: flex-start;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 2px;
    border-radius: 4px;
    display: flex;
    &:hover {
        background-color: #dbe7ef;
        -webkit-animation: grow 1s linear 0s alternate;
        -moz-animation: grow 1s linear 0s alternate;
        -ms-animation: grow 1s linear 0s alternate;
        -o-animation: grow 1s linear 0s alternate;
        animation: grow 1s linear 0s alternate;
    }
}

/* mock-test Search */
.mock-test-search {
    // border : 1px solid $color-green;
    padding: 15px 20px 20px;
    // border-radius : 5px;
    // background-color: #f8f6f6;
    margin-bottom: 30px;
    h5 {
        color: $color-green;
        font-weight: 600;
        margin-bottom: 20px;
        position: relative;
        // &:before {
        //     position: absolute;
        //     content: "";
        //     background: $color-green;
        //     width: 50px;
        //     height: 2px;
        //     bottom: 0;
        //     left: 0;
        // }

        @media (max-width: 575px) {
            font-size: 17px;
        }
    }
    form {
        position: relative;
        input {
            width: 100%;
            border: 1px solid $color-green;
            color: $color-green;
            font-size: 15px;
            &:focus {
                border-color: $color-green;
                outline: none;
            }
            &::placeholder {
                font-size: 15px;
                // font-style: italic;
                color: $color-green;
                font-weight: 500;
            }
        }
        button {
            position: absolute;
            width: 45px;
            height: 100%;
            top: 0;
            right: 0;
            background: transparent;
            padding: 0;
            border: none;
            font-size: 20px;
            color: $color-green;
        }
    }
}

.mock-test-category {
    // border : 1px solid  $color-border;
    padding: 15px 20px 20px;
    // border-radius : 5px;
    margin-bottom: 30px;
    background-color: #f8f6f6;
    h5 {
        color: $color-green;
        font-weight: 600;
        padding-bottom: 10px;
        margin-bottom: 20px;
        position: relative;
        &:before {
            position: absolute;
            content: "";
            background: $color-green;
            width: 50px;
            height: 2px;
            bottom: 0;
            left: 0;
        }

        @media (max-width: 575px) {
            font-size: 17px;
        }
    }
    ul.category-item {
        li.check-btn {
            border-top: 1px dashed $color-green;
            padding: 10px 0;

            &:last-child {
                border-bottom: 1px dashed $color-green;
            }

            label {
                font-size: 14px;
                color: $color-green;
                display: block;
                margin-bottom: 4px;
                cursor: pointer;

                input[type="checkbox"] {
                    border: 2px solid $color-green;
                    appearance: none;
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                    margin-right: 6px;
                    position: relative;
                    top: 4px;

                    &:focus {
                        outline: none;
                    }

                    &:checked {
                        background-color: $color-green;
                        background: $color-green
                            url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==")
                            2px 2px no-repeat;
                        border-color: $color-green;
                    }
                }

                span {
                    float: right;
                    font-size: 12px;
                    color: $color-green;
                    line-height: 27px;
                }
            }
        }
    }
}

/* mock-test Grid */
.mock-test-grid-area {
    // padding: 70px 0;
    .mock-test-items {
        .mock-test-item {
            border: 1px solid #bdbfc3;
            border-radius: 5px;
            transition: all 0.2s ease;
            overflow: hidden;
            margin-bottom: 30px;

            .a {
                text-decoration: none;
            }

            // .category {
            //     background: #e7e4e4;
            //     padding   : 3px 8px;
            //     border-radius : 5px;
            //     margin-bottom: 10px;

            //     p {
            //         font-size    : 12px;
            //         color: rgb(14, 13, 13);
            //         font-weight  : 500;
            //     }
            // }

            .mock-test-image {
                width: 100%;
                height: 240px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 5px 5px 0 0;
                position: relative;

                .duration {
                    position: absolute;
                    left: 20px;
                    bottom: 20px;

                    .title {
                        background: #ffffff;
                        padding: 3px 8px;
                        border-radius: 5px;

                        p {
                            font-size: 12px;
                            color: $color-balck;
                            font-weight: 500;
                            margin-bottom: -4px;
                        }

                        span {
                            font-size: 11px;
                            color: $color-text;
                            font-weight: 500;
                        }
                    }
                }

                .mock-test-price {
                    p {
                        font-size: 16px;
                        color: #ffffff;
                        background: $color-balck;
                        position: absolute;
                        right: 20px;
                        bottom: 10px;
                        padding: 8px 10px;
                        font-weight: 500;
                        border-radius: 5px;
                    }
                }

                @media (max-width: 767px) {
                    height: 185px;
                }
            }

            .mock-test-content {
                background: #fff;
                padding: 20px 25px;

                .category {
                    position: absolute;
                    background: #e8e8f1;
                    padding: 3px 8px;
                    border-radius: 5px;

                    p {
                        font-size: 12px;
                        color: rgb(7, 7, 7);
                        font-weight: 500;
                    }
                }

                .lesson {
                    position: absolute;
                    right: 10px;
                }

                .content-detail {
                    margin-top: 50px;

                    h6.heading {
                        a {
                            color: $color-balck;
                            font-weight: 600;
                            display: inline-block;
                            margin-bottom: 12px;
                            margin-top: 100px;

                            &:hover {
                                color: $color-green;
                            }
                        }
                    }

                    p.desc {
                        font-size: 14px;
                        color: $color-text;
                        line-height: 25px;
                        // border-bottom : 1px solid  $color-border1;
                        padding-bottom: 10px;
                        margin-bottom: 12px;
                    }
                }

                .mock-test-face {
                    .duration,
                    .student {
                        p {
                            font-size: 13px;
                            color: $color-text;

                            i {
                                font-size: 16px;
                                color: $color-green;
                                vertical-align: text-bottom;
                                margin-right: 3px;
                            }
                        }
                    }
                }
            }

            &:hover {
                box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
            }
        }
    }

    @media (max-width: 767px) {
        padding: 40px 0 30px;
    }
}
