.notes-course-list {
    .ant-collapse-header-text {
        font-size: 18px;
        font-weight: 600;
        color: $primary;
    }
}
.note-card-image {
    width: 100%;
    height: 140px;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    position: relative;
    &:hover {
        .content-img-text {
            opacity: 1;
            z-index: 10;
        }
    }
    .content-img-text {
        background: transparent;
        mix-blend-mode: hard-light;
        right: 0;
        text-align: end;
        top: 0;
        opacity: 0;
        transition: transform 0.2s ease-in-out;
    }
}

.notes-content {
    h6 {
        color: black !important;
    }
    li::marker {
        font-weight: bolder;
        color: #89c540 !important;
    }
    .notes-unordered-list {
        list-style-type: disclosure-closed;
    }
    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0px !important;
    }
    .ant-collapse-expand-icon span {
        margin-top: 0px !important;
    }
    .ant-collapse-header {
        display: block !important;
    }
    .notes-content-sider {
        .active-chapter {
            text-decoration: underline;
        }
        .active-content {
            color: $primary;
            font-weight: 600;
            text-decoration: underline;
        }
        .content-list {
            &:hover {
                color: $primary;
                font-weight: 600;
            }
        }
        .sider-title {
            font-size: 18px;
            font-weight: 700;
            background: $primary;
            color: #ffffff;
        }
        .sider-body {
            background: #f3f5f7;
            .sider-header,
            .content-list {
                color: #89c540 !important;
            }
        }
    }
    .content-body {
        background: #f9f9f9 0% 0% no-repeat padding-box;
    }
}

.mjx-math {
    white-space: normal !important;
}
