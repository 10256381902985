.media-list {
    .media {
        padding: 1rem;
        width: 100%;
        margin-top: 0;
        border-bottom: 1px solid #dae1e7;
        .media-left {
            padding-right: 1rem;
        }
        .media-right {
            padding-left: 1rem;
            padding-top: 0.5rem;
        }
        .media-body {
            padding-top: 0.5rem;
        }
    }
    a.media {
        color: gray !important;
    }
    .status {
        background: aliceblue;
    }
}

.notification-text {
    margin-bottom: 0.25rem;
    font-size: smaller;
}
.dropdown-menu-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: $primary;
    color: #fff;
    text-align: center;
    .dropdown-header h3 {
        margin-bottom: 0.25rem;
    }
}
.notification-content {
    color: #626262;
    .notification-title {
        p {
            font-size: 11px;
        }
    }
}
.notification-time {
    font-size: 9px;
    font-weight: 400;
}
.ant-menu-submenu-popup {
    position: fixed !important;
}

.landing-notification {
    .ant-menu-submenu-title {
        &:hover {
            color: #fff !important;
        }
    }
}

.live-notification {
    p {
        font-size: 12px !important;
    }
}
.websocket-notification.ant-notification-notice {
    padding: 0px !important;
    border: 1px solid rgba(98, 98, 98, 0.2) !important;
    box-shadow: none !important;
    padding-left: 16px !important;
    border-radius: 14px !important;
    &:hover {
        background-color: #dae1e7 !important;
    }
}

.websocket-notification {
    .ant-notification-notice-closable .ant-notification-notice-message {
        padding: 0px !important;
    }
}
