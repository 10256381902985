$primary: #283360;
$secondary: #89c540;
.room-body {
    font-family: "Poppins", "arial" !important;
    .room-header {
        background-image: linear-gradient(
            left,
            #fff,
            rgba(255, 255, 255.9),
            rgba(255, 255, 255, 0.7),
            rgba(255, 255, 255, 0.4),
            rgba(255, 255, 255, 0)
        ) !important;
    }
    .room-title {
        font-size: 25px;
        font-weight: 800;
    }
    .cover-image {
        background-color: #f5f0f0;
        padding: 20px 20px 50px 20px;
        box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        height: 300px;
        background-position: center !important;
        background-size: 100% 100% !important;
        background-repeat: no-repeat !important;
        position: relative;
        span {
            display: block;
            font-size: 14px;
            font-weight: 800;
            padding-left: 5px;
            padding-top: 30px;
        }
        .input-control {
            height: 50px;
            padding: 10px;
            font-size: 14px;
            color: #0e314c;
            border: 1px solid #eeeeee;
            border-radius: 0;
            background: #fafafa;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            margin-right: 10px;
            &:focus {
                outline: 0;
                box-shadow: none;
            }
        }
        .btn {
            font-weight: 400;
            font-size: 16px;
            border: none;
            padding: 5px 10px;
            position: relative;
            border-radius: 4px;
            z-index: 1;
            text-transform: capitalize;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            display: inline;
        }
    }
    .summary {
        background-color: #f5f0f0;
        border-radius: 50%;
        height: 100%;
        width: 85%;
        padding: 3%;
        .circle {
            border: 4px solid #ffffff;
            height: 100%;
            border-radius: 50%;
            .payout {
                margin-left: 30%;
            }
        }
        h5 {
            // margin: auto;
            padding-left: 30%;
            padding-top: 12%;
        }
        ul {
            margin-right: 20px;
            list-style-type: none;
        }
        li {
            // padding-left: 10%;
            padding-top: 5%;
            padding-right: 1%;
            border-bottom: 2px solid #ffffff;
            span {
                float: right;
            }
        }
    }
    .info {
        margin-top: 1%;
        .member {
            font-size: 12px;
            font-weight: 400;
            color: $primary;
        }
        .history {
            margin-bottom: 10px;
        }
    }
}
#add-assessment .nav{
    width: fit-content;
}

.room-tab, #add-assessment {
    .nav {
        display: inline-block;
        border-radius: 5px;
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
        margin-bottom: 35px;
        .nav-item {
            display: inline-block;
            a.nav-link {
                font-size: 14px;
                color: #1a202c;
                font-weight: 500;
                text-transform: uppercase;
                padding: 12px 30px 10px;
                border-radius: 5px;
                @media (max-width: 991px) {
                    padding: 12px 16px 9px;
                }
            }
            a.nav-link.active {
                color: #fff;
                background: #283360;
            }
        }
    }
    .room-card {
        margin-top: 19px;
        margin-bottom: 40px;
        .live-videos {
            &:hover {
                cursor: pointer;
                .play-button {
                    visibility: visible;
                    opacity: 1;
                    -webkit-transform: scale(1.11);
                    transform: scale(1.11);
                }
            }
        }
        .card {
            border-radius: 0.4rem;
            background-color: rgb(255, 255, 255, 0.09) !important;
            .topic-item {
                background: rgb(237, 224, 242, 0.5);
                color: #8b869d;
            }
            .thumbnail {
                background-color: #f5f0f0;
                box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
                border-radius: 0.4rem;
                background-position: center !important;
                background-size: 100% 100% !important;
                background-repeat: no-repeat !important;
                position: relative;
                padding-top: 80px;
                padding-bottom: 80px;
                position: relative;
                &::after {
                    content: "";
                    width: 100%;
                    height: 100%;
                    background-color: #000;
                    opacity: 0.4;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                }
                .btn-live {
                    position: absolute;
                    top: 2%;
                    right: 1%;
                    padding: 0 25px 0 30px;
                    z-index: 3;
                    background: #fff;
                    cursor: default;
                }
                .event-title {
                    position: absolute;
                    bottom: 0;
                    transform: translateY(-50%);
                    left: 0;
                    width: 100%;
                    padding: 0 20px 0 30px;
                    z-index: 3;
                    h5 {
                        color: #fff;
                        font-weight: bold;
                    }
                }
            }
            .event-body {
                span {
                    font-size: 14px;
                }
                svg {
                    color: #283360;
                }
            }
        }
    }
}

.schedule-body {
    padding-top: 100px;
    padding-bottom: 100px;
    .body {
        align-items: center;
        display: -webkit-inline-box;
        justify-content: space-evenly;
        min-height: 100%;
        width: 100vw;
    }
    @media screen and (min-width: 1024px) {
        .body {
            border-right: 1px solid gray;
        }
    }
    @media screen and (max-width: 768px) {
        .left-container {
            align-items: center;
            text-align: center;
        }
        .schedule-title {
            max-width: 100%;
        }
    }
    .left-container {
        display: inline-flex;
        flex-basis: 35rem;
        flex-direction: column;
        flex-shrink: 1;
        max-width: 35rem;
        padding: 1em 2em;
    }
    .right-container {
        align-items: center;
        display: -webkit-inline-box;
        display: inline-flex;
        flex-direction: column;
        flex-basis: 45%;
        overflow: hidden;
        padding: 1em 3em;
    }
    .schedule-title {
        font-family: "Google Sans Display", Roboto, Arial, sans-serif;
        font-size: 1.75rem;
        font-weight: 400;
        letter-spacing: 0;
        padding-bottom: 0.5em;
    }
    .schedule-info {
        font-family: "Google Sans", Roboto, Arial, sans-serif;
        font-size: 1.125rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.5rem;
        color: #5f6368;
        max-width: 30rem;
        padding-bottom: 3em;
    }
    .card {
        width: 38rem;
        .actions-wrapper {
            position: absolute;
            right: 0;
            top: 0;
            .start-meeting {
                font-size: 10px;
            }
        }
        &:hover {
            .actions-wrapper {
                display: block;
            }
        }
    }
}

.live-class-container {
    height: 100%;
    width: 100%;
    background: #202124;
    // overflow: hidden;
}

.live-class-container {
    font-family: "Roboto", sans-serif;
    // Sidebar
    .app-sidebar {
        right: 16px;
        align-items: center;
        background-color: #fff;
        display: flex;
        justify-content: center;
        position: absolute;
        transform: translateX(0);
        transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        min-width: 300px !important;
        @media screen and (min-width: 475px) {
            border: 1px solid #f0f2f5;
        }
        .sidebar-container {
            background: #fff;
            border-left: 1px solid #f1f3f4;
            display: flex;
            flex-direction: column;
            height: 100%;
            opacity: 1;
            right: 0;
            top: 0;
            width: 360px !important;
            will-change: opacity;
            .live-header {
                align-items: center;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                height: 40px;
                min-height: 40px;
                padding-left: 24px;
                h5 {
                    align-items: center;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    height: 40px;
                    min-height: 40px;
                    padding-left: 24px;
                }
                .anticon-close {
                    border-radius: 50%;
                    padding: 10px;
                    &:hover {
                        background: #f0f2f5;
                    }
                }
            }
            .foot {
                left: 0;
                position: absolute;
                bottom: 0px;
                display: flex;
                flex-grow: 1;
                min-height: 48px;
                background-color: transparent;
                box-sizing: border-box;
                padding: 0;
                flex-direction: row;
                height: 48px;
                padding-left: 20px;
                padding-right: 20px;
                width: 100%;
                div,
                label {
                    cursor: pointer;
                    svg {
                        color: #283360;
                    }
                }
                input,
                button {
                    border: none;
                    &:focus-visible {
                        border: none;
                        outline: none;
                    }
                }
                border: none;
                .msg {
                    flex-grow: 1;
                }
            }
            .chatbox-container {
                flex-grow: 1;
                // padding: 14px 24px;
                // padding-left: 24px;
                font-family: "Poppins", sans-serif;
                @keyframes typeanimation {
                    25% {
                        transform: translate(0, 5px);
                    }
                    100% {
                        transform: translate(0, 0);
                    }
                }
                .ellipsis {
                    display: inline-block;
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    background-color: rgba(32, 33, 36, 0.361);
                }
                .dot_1 {
                    /* animation: name duration timing-function delay iteration-count */
                    animation: typeanimation 0.8s linear 0.1s infinite;
                }
                .dot_2 {
                    animation: typeanimation 0.8s linear 0.2s infinite;
                }
                .dot_3 {
                    animation: typeanimation 0.8s linear 0.3s infinite;
                }
                .chatbox {
                    .bubble {
                        background-color: #79c7c5;
                    }
                    .bubble {
                        display: inline-block;
                        padding: 10px;
                        margin-bottom: 5px;
                        border-radius: 15px;
                        p {
                            color: #050505;
                            font-size: 12px;
                            text-align: left;
                            line-height: 1.4;
                        }
                    }
                    .incoming {
                        text-align: left;
                        .chat-author {
                            font-size: small;
                            // font-style: italic;
                        }
                        .bubble {
                            background-color: rgba(32, 33, 36, 0.039);
                        }
                    }
                    .outgoing {
                        text-align: right;
                        .bubble {
                            p {
                                color: #050505;
                            }
                            background-color: #c6e4da;
                        }
                    }
                }
            }
            ::-webkit-scrollbar {
                display: block;
                width: 10px;
                cursor: pointer;
            }
            /* Track */
            ::-webkit-scrollbar-track {
                background: #f1f1f1 !important;
            }
            /* Handle */
            ::-webkit-scrollbar-thumb {
                position: relative;
                top: 0px;
                width: 6px;
                height: auto;
                background-color: rgba(32, 33, 36, 0.361) !important;
                border: 1px solid rgb(255, 255, 255) !important;
                /* background-clip: padding-box; */
                border-radius: 5px;
            }
        }
    }
    // Main Content
    .header--live-details {
        padding-left: 24px;
        font-size: 20px;
        font-weight: bold;
    }
    .live-video-body {
        height: calc(100% - 180px);
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        .btn-live {
            position: absolute;
            top: 2%;
            left: 3%;
            padding: 0 25px 0 30px;
            z-index: 3;
            background: #fff;
            cursor: default;
            .live-icon {
                display: inline-block;
                position: relative;
                top: calc(50% - 5px);
                background-color: #f0f2f5;
                width: 10px;
                height: 10px;
                margin-left: 4px;
                border: 1px solid rgba(black, 0.1);
                border-radius: 50%;
                z-index: 1;
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    background-color: rgba(#f0f2f5, 0.6);
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    animation: live 2s ease-in-out infinite;
                    z-index: -1;
                }
            }
            @keyframes live {
                0% {
                    transform: scale(1, 1);
                }
                100% {
                    transform: scale(3.5, 3.5);
                    background-color: rgba(#f0f2f5, 0);
                }
            }
        }
        .actions-button {
            background-color: #202124;
            color: #fff;
            -webkit-box-align: center;
            box-align: center;
            align-items: center;
            background-color: #fff;
            color: #202124;
            bottom: 5%;
            box-sizing: border-box;
            display: flex;
            margin-bottom: 0;
            position: fixed;
            text-align: center;
            white-space: nowrap;
            width: 100vw;
            z-index: 100;
        }
    }
}

.icons-list {
    z-index: 2;
    position: fixed;
    bottom: 25px;
    width: 100%;
    height: auto;
    .single-icon {
        border-radius: 50%;
        padding: 12px;
        cursor: pointer;
        margin-left: 8px;
        svg {
            font-size: 20px;
        }
        &:hover {
            background: #f0f2f5;
        }
    }
}

.sidebar {
    height: 100vh;
    position: fixed;
    z-index: 101;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    width: 0px;
}

.sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidebar a:hover {
    color: #f1f1f1;
}

.sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

.openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
}

.openbtn:hover {
    background-color: #444;
}

#main {
    transition: margin-left 0.5s;
    padding: 16px;
    margin-right: 400px;
    height: 90vh;
    // overflow: hidden;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */

@media screen and (max-height: 450px) {
    .sidebar {
        padding-top: 15px;
    }
    .sidebar a {
        font-size: 18px;
    }
}

.video-container {
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    max-height: 100vh;
    align-items: center;
    justify-content: center;
    text-align: center;
    .flex-container {
        height: calc(100% - 150px);
        width: 100%;
        /* margin: 0 auto; */
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
}

#my-video {
    margin: 10px;
    object-fit: fill;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

// Members
.members-list {
    display: table-cell;
    flex-direction: column;
    box-flex: 1;
    flex-grow: 1;
    min-height: 0;
    h2 {
        margin-bottom: 4px;
        margin-top: 12px;
        font-size: 0.975rem;
        font-weight: 500;
        line-height: 1.25rem;
        color: #5f6368;
        margin: 0;
        padding: 0 24px;
    }
    .members-wrapper {
        align-items: center;
        display: flex;
        height: 56px;
        justify-content: space-between;
        line-height: normal;
        border-bottom: 1px solid #f0f2f5;
        margin-top: 8px;
        .member-details {
            align-items: center;
            display: inline-flex;
            flex-direction: row;
            min-width: 0;
            .ant-avatar {
                height: 32px;
                margin-right: 16px;
                min-width: 32px;
            }
            .name {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                overflow: hidden;
            }
        }
    }
}

.search-bar {
    flex-direction: row;
    height: 46px;
    overflow: visible;
    border: 2px solid #f0f2f5;
    margin: 10px;
    padding: 8px;
    background: #283360;
    color: #fff;
    svg {
        text-align: center;
    }
    input {
        border: none;
        width: 100%;
        background: none;
        &::placeholder {
            color: rgb(240, 238, 238);
            font-weight: 500;
        }
    }
    input:focus-visible {
        border: none;
        outline: none;
    }
    &:hover,
    &:focus {
        border: 1px solid transparent;
        border-bottom: 1px solid #283360;
        border-radius: 0;
        background-position: 100% center;
        background: rgb(255, 253, 253);
        color: #283360;
        input {
            &::placeholder {
                color: #283360;
            }
        }
        svg {
            color: #283360 !important;
            text-align: center;
        }
    }
}

/* room Grid */

.room-grid-area {
    padding: 70px 0;
    .room-items {
        .room-item {
            border: 1px solid #bdbfc3;
            border-radius: 5px;
            transition: all 0.2s ease;
            overflow: hidden;
            margin-bottom: 30px;
            .a {
                text-decoration: none;
            }
            // .category {
            //     background: #e7e4e4;
            //     padding   : 3px 8px;
            //     border-radius : 5px;
            //     margin-bottom: 10px;
            //     p {
            //         font-size    : 12px;
            //         color: rgb(14, 13, 13);
            //         font-weight  : 500;
            //     }
            // }
            .room-image {
                width: 100%;
                height: 240px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border-radius: 5px 5px 0 0;
                position: relative;
                .duration {
                    position: absolute;
                    left: 20px;
                    bottom: 20px;
                    .title {
                        background: #ffffff;
                        padding: 3px 8px;
                        border-radius: 5px;
                        p {
                            font-size: 12px;
                            color: $color-balck;
                            font-weight: 500;
                            margin-bottom: -4px;
                        }
                        span {
                            font-size: 11px;
                            color: $color-text;
                            font-weight: 500;
                        }
                    }
                }
                .room-price {
                    p {
                        font-size: 16px;
                        color: #ffffff;
                        background: $color-balck;
                        position: absolute;
                        right: 20px;
                        bottom: 10px;
                        padding: 8px 10px;
                        font-weight: 500;
                        border-radius: 5px;
                    }
                }
                @media (max-width: 767px) {
                    height: 185px;
                }
            }
            .room-content {
                background: #fff;
                padding: 20px 25px;
                .category {
                    position: absolute;
                    background: #e8e8f1;
                    padding: 3px 8px;
                    border-radius: 5px;
                    p {
                        font-size: 12px;
                        color: rgb(7, 7, 7);
                        font-weight: 500;
                    }
                }
                .lesson {
                    position: absolute;
                    right: 10px;
                }
                .content-detail {
                    margin-bottom: 20px;
                    h6.heading {
                        a {
                            color: $color-balck;
                            font-weight: 600;
                            display: inline-block;
                            margin-bottom: 12px;
                            margin-top: 100px;
                            &:hover {
                                color: $color-green;
                            }
                        }
                    }
                    p.desc {
                        font-size: 14px;
                        color: $color-text;
                        line-height: 25px;
                        // border-bottom : 1px solid  $color-border1;
                        padding-bottom: 10px;
                        margin-bottom: 12px;
                    }
                }
                .room-face {
                    .duration,
                    .student {
                        p {
                            font-size: 13px;
                            color: $color-text;
                            i {
                                font-size: 16px;
                                color: $color-green;
                                vertical-align: text-bottom;
                                margin-right: 3px;
                            }
                        }
                    }
                }
            }
            &:hover {
                box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
            }
        }
    }
    @media (max-width: 767px) {
        padding: 40px 0 30px;
    }
    button {
        text-align: center;
        color: #fff;
        display: inline-block;
        width: 100%;
        max-width: 100%;
        height: 40px;
        line-height: 40px;
        background-color: #32b4c8;
        -moz-transition: all ease 0.2s;
        -o-transition: all ease 0.2s;
        -webkit-transition: all ease 0.2s;
        transition: all ease 0.2s;
        border-radius: 4px;
    }
}

.live-icon {
    display: inline-block;
    position: relative;
    top: calc(50% - 5px);
    background-color: #f0f2f5;
    width: 10px;
    height: 10px;
    margin-left: 4px;
    border: 1px solid rgba(black, 0.1);
    border-radius: 50%;
    z-index: 1;
    &:before {
        content: "";
        display: block;
        position: absolute;
        background-color: rgba(#f0f2f5, 0.6);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        animation: live 2s ease-in-out infinite;
        z-index: -1;
    }
}

@keyframes live {
    0% {
        transform: scale(1, 1);
    }
    100% {
        transform: scale(3.5, 3.5);
        background-color: rgba(#a30a0a, 0);
    }
}

.in-call-messages {
    background: #fff;
    display: flex;
    flex-direction: column;
    font-family: "Popinns", sans-serif !important;
    .chat-header {
        .heading {
            font-size: 1.125rem;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 1.5rem;
            box-flex: 1;
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .chat-info-section {
        letter-spacing: 0.025em;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1rem;
    }
    .message-body {
        overflow-y: auto !important;

        .chat-section {
            flex: 1;
            width: 100%;
            overflow-y: auto;
            // height: 40vh;
            height: 60vh;
            overflow-wrap: anywhere;
            scroll-behavior: smooth;
        }
    }
    .messageTextArea {
        // align-items: center;
        // border-radius: 25px;
        // min-height: 36px;
        // margin: 15px;
        overflow-x: hidden;
    }
}

.custom-message-box {
    flex: 0 1 42px;
    // width: 90%;
    background: #fff;
    // margin: 2px auto;
    /*-webkit-box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.4);
  box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.4);*/
    /*background: rgba(0, 0, 0, 0.3);
      border-top:1px solid #e3e3e3;*/
    padding: 10px;
    position: relative;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    // height:14px;
    border: 1px solid #ccc;

    & .message-input {
        background: none;
        border: none;
        outline: none !important;
        resize: none;
        /* color: rgba(255, 255, 255, .8);*/
        font-size: 15px;
        height: 24px;
        margin: 0;
        // padding-right: 20px;
        width: 100%;
        color: #202124;
        font-size: 13px;
        line-height: 24px;
        box-shadow: none;
    }
    & .message-input:focus,
    .message-input:active {
        box-shadow: none !important;
    }
    textarea:focus:-webkit-placeholder {
        color: transparent;
    }
    & .message-submit {
        position: absolute;
        z-index: 1;
        top: 9px;
        right: 10px;
        color: #4a90e2;
        border: none;
        /* background: #c29d5f;*/
        background: #fff;
        font-size: 16px;
        text-transform: uppercase;
        line-height: 1;
        padding: 6px 10px;
        border-radius: 5px;
        outline: none !important;
        transition: background 0.2s ease;
        cursor: pointer;
        &:hover {
            background: #fff;
            color: #333;
        }
    }
}

.live-class-header {
    z-index: 2;
    position: fixed;
    top: 5px;
    width: 100%;
    height: auto;
    background-color: #f1efef;
    opacity: 0.8;
}

#member-sidebar {
    height: 80vh;
    overflow: auto;
}

.new-schedule {
    .btn {
        display: inline-block;
        font-family: "Work Sans", sans-serif;
        font-weight: 400;
        color: #535f6b;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0.313rem 1.25rem;
        font-size: 1rem;
        border-radius: 5px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
    }
    .add-btn {
        padding: 5px 5px 5px 10px;
        height: 24px;
        text-align: center;
        position: absolute;
        right: 0;
        top: -1px;
        -webkit-border-radius: 0 5px 5px 0;
        -moz-border-radius: 0 5px 5px 0;
        -ms-border-radius: 0 5px 5px 0;
        -o-border-radius: 0 5px 5px 0;
        border-radius: 0 5px 5px 0;
    }
}
// .live-tab {
//     a.active:hover {
//         background-color: #283360 !important;
//     }
// }
.list-action .badge {
    background-color: #f0f2f5;
    color: #535f6b;
    font-size: 16px;
    padding-top: 8px;
}
.btn-live {
    font-weight: bold;
}
.filter-search input {
    border-color: #edeef2;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    &:focus {
        outline: none !important;
    }
    &:focus-visible {
        outline: none !important;
    }
}
// Auto Suggestion
.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 84.4%;
}
.search-users {
    width: 90%;
}

.suggestions li {
    padding: 0.1rem;
}

.navbar-list li .iq-sub-dropdown .iq-sub-card {
    font-size: inherit;
    padding-bottom: 4px;
    line-height: normal;
    color: inherit;
    display: inline-block;
    width: 100%;
}
h6 {
    font-weight: 500;
    margin: 0px;
    line-height: 1.5;
    letter-spacing: -0.02em;
    color: #1b2734;
    font-size: 0.9rem;
}

.suggestions li:hover:not(.sug-loader) {
    background: #f5f6fa;
    cursor: pointer;
}
.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
}
.navbar-list li .iq-sub-dropdown .iq-sub-card:last-child {
    padding-bottom: 0;
}
.navbar-list li .iq-sub-dropdown .iq-sub-card.iq-sub-card-big {
    width: auto;
}
.media-body {
    flex: 1;
}

.public-profile {
    font-family: "Poppins", "sans-serif" !important;
    .row {
        display: flex !important;
        justify-content: center;
        .mt-4 {
            margin-top: 1rem !important;
        }
        .title {
            font-size: 1rem;
        }
    }
    h1 {
        font-size: 1.1rem !important;
        line-height: 1.8rem !important;
    }
}

.animation-card {
    animation: blink 2s linear 3;
}

@keyframes blink {
    0% {
        opacity: 0.2;
        background-color: rgba(0, 0, 0, 0.1) !important;
        border: 1px solid red;
    }

    50% {
        opacity: 0.5;
        background-color: rgba(0, 0, 0, 0.1) !important;
        border: 1px solid red;
    }

    100% {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.1) !important;
        border: 1px solid red;
    }
}

.room-image {
    width: 100%;
    height: 160px;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    position: relative;
}

.room-single-item {
    &:hover {
        .card {
            box-shadow: 0 2px 3px rgb(54 64 67 / 30%), 0 6px 10px 4px rgb(54 64 67 / 15%);
        }
    }
    h5 {
        font-size: 1rem;
        font-weight: normal;
        &:hover {
            text-decoration: underline;
        }
    }
    .card {
        border-radius: 16px !important;
    }
    img {
        border-top-left-radius: 16px !important;
        border-top-right-radius: 16px !important;
    }
}

.room-banner-container {
    position: relative;
}
.room-banner {
    object-fit: cover;
    vertical-align: center;
    height: 16rem;
}

.room-banner-overlay {
    position: absolute;
    bottom: 25px;
    right: 20px;
    color: #fffbfb;
}
