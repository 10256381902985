#Certificate {
    position: absolute;
    width: 841px;
    height: 595px;
    background-color: rgba(255, 255, 255, 1);
    overflow: hidden;
    --web-view-name: Certificate;
    --web-view-id: Certificate;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#Group_1 {
    position: absolute;
    width: 858.898px;
    height: 612.284px;
    left: -8px;
    top: -8px;
    overflow: visible;
}

#Path_1 {
    fill: rgba(230, 11, 33, 1);
}

.Path_1 {
    overflow: visible;
    position: absolute;
    width: 125.166px;
    height: 206.606px;
    left: 733.732px;
    top: 405.677px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_2 {
    fill: rgba(0, 74, 144, 1);
}

.Path_2 {
    overflow: visible;
    position: absolute;
    width: 135.968px;
    height: 128.534px;
    left: 691.388px;
    top: 483.749px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_3 {
    fill: rgba(194, 216, 233, 1);
}

.Path_3 {
    overflow: visible;
    position: absolute;
    width: 197.796px;
    height: 326.493px;
    left: 661.102px;
    top: 285.79px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_4 {
    fill: rgba(194, 216, 233, 1);
}

.Path_4 {
    overflow: visible;
    position: absolute;
    width: 161.469px;
    height: 266.531px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_5 {
    fill: rgba(230, 11, 33, 1);
}

.Path_5 {
    overflow: visible;
    position: absolute;
    width: 128.535px;
    height: 212.349px;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_6 {
    fill: rgba(0, 74, 144, 1);
}

.Path_6 {
    overflow: visible;
    position: absolute;
    width: 156.184px;
    height: 147.647px;
    left: 86.118px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_7 {
    fill: rgba(194, 216, 233, 1);
}

.Path_7 {
    overflow: visible;
    position: absolute;
    width: 76.097px;
    height: 71.938px;
    left: 47.559px;
    top: 178.028px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Path_8 {
    fill: rgba(194, 216, 233, 1);
}

.Path_8 {
    overflow: visible;
    position: absolute;
    width: 46.473px;
    height: 43.933px;
    left: 736.763px;
    top: 370.218px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_3 {
    position: absolute;
    width: 147.913px;
    height: 47.262px;
    left: 655px;
    top: 42px;
    overflow: visible;
}

#Group_2 {
    position: absolute;
    width: 147.913px;
    height: 47.262px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Group_8 {
    position: absolute;
    width: 107.78px;
    height: 129.266px;
    left: 367px;
    top: 443px;
    overflow: visible;
}

#Group_5 {
    position: absolute;
    width: 107.78px;
    height: 129.266px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Group_4 {
    position: absolute;
    width: 107.78px;
    height: 129.266px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#C {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 39.1324, 28.5364) rotate(-30.85deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 6px;
    white-space: nowrap;
    line-height: 8.400045394897461px;
    margin-top: -0.7000041007995605px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 7.00003719329834px;
    color: rgba(0, 72, 143, 1);
}

#o {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 44.0243, 26.2993) rotate(-18.341deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 6px;
    white-space: nowrap;
    line-height: 8.399994850158691px;
    margin-top: -0.6999995708465576px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 6.999995708465576px;
    color: rgba(0, 72, 143, 1);
}

#u {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 48.9373, 25.2618) rotate(-5.98deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 6px;
    white-space: nowrap;
    line-height: 8.399994850158691px;
    margin-top: -0.6999998092651367px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 6.999995231628418px;
    color: rgba(0, 72, 143, 1);
}

#r {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 54.0694, 25.195) rotate(4.215deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 4px;
    white-space: nowrap;
    line-height: 8.400040626525879px;
    margin-top: -0.7000036239624023px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 7.000033378601074px;
    color: rgba(0, 72, 143, 1);
}

#s {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 57.462, 25.7928) rotate(13.446deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 5px;
    white-space: nowrap;
    line-height: 8.399969100952148px;
    margin-top: -0.6999976634979248px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 6.999973773956299px;
    color: rgba(0, 72, 143, 1);
}

#e {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 61.3976, 27.3381) rotate(24.417deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 6px;
    white-space: nowrap;
    line-height: 8.399959564208984px;
    margin-top: -0.6999969482421875px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 6.999965667724609px;
    color: rgba(0, 72, 143, 1);
}

#C_ {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 33.7283, 63.4376) rotate(50.382deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 6px;
    white-space: nowrap;
    line-height: 8.399961471557617px;
    margin-top: -0.6999969482421875px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 6.999967575073242px;
    color: rgba(0, 72, 143, 1);
}

#o_ {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 37.4251, 67.0332) rotate(38.077deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 6px;
    white-space: nowrap;
    line-height: 8.400065422058105px;
    margin-top: -0.7000055313110352px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 7.000054359436035px;
    color: rgba(0, 72, 143, 1);
}

#m {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 41.4083, 69.9679) rotate(22.837deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 8px;
    white-space: nowrap;
    line-height: 8.400023460388184px;
    margin-top: -0.7000019550323486px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 7.000019550323486px;
    color: rgba(0, 72, 143, 1);
}

#p {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 48.5258, 71.6237) rotate(7.245deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 6px;
    white-space: nowrap;
    line-height: 8.399988174438477px;
    margin-top: -0.6999990940093994px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 6.999989986419678px;
    color: rgba(0, 72, 143, 1);
}

#l {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 53.5238, 71.7918) rotate(-1.903deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 3px;
    white-space: nowrap;
    line-height: 8.399954795837402px;
    margin-top: -0.6999962329864502px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 6.999962329864502px;
    color: rgba(0, 72, 143, 1);
}

#e_ {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 55.8572, 71.3649) rotate(-10.668deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 6px;
    white-space: nowrap;
    line-height: 8.399977684020996px;
    margin-top: -0.699998140335083px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 6.99998140335083px;
    color: rgba(0, 72, 143, 1);
}

#t {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 60.3803, 70.3644) rotate(-20.771deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 4px;
    white-space: nowrap;
    line-height: 8.400055885314941px;
    margin-top: -0.7000048160552979px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 7.000046253204346px;
    color: rgba(0, 72, 143, 1);
}

#i {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 63.4619, 69.2117) rotate(-27.991deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 3px;
    white-space: nowrap;
    line-height: 8.400036811828613px;
    margin-top: -0.7000031471252441px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 7.000030517578125px;
    color: rgba(0, 72, 143, 1);
}

#o_ba {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 65.2295, 67.1295) rotate(-37.048deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 6px;
    white-space: nowrap;
    line-height: 8.399993896484375px;
    margin-top: -0.6999998092651367px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 6.999994277954102px;
    color: rgba(0, 72, 143, 1);
}

#n {
    transform: translate(0px, 0px) matrix(1, 0, 0, 1, 68.7258, 63.8263) rotate(-49.272deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 6px;
    white-space: nowrap;
    line-height: 8.400006294250488px;
    margin-top: -0.7000007629394531px;
    text-align: left;
    font-family: Montserrat-Black;
    font-style: normal;
    font-weight: normal;
    font-size: 7.000004768371582px;
    color: rgba(0, 72, 143, 1);
}

#Group_7 {
    position: absolute;
    width: 107.78px;
    height: 129.266px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Group_6 {
    position: absolute;
    width: 107.78px;
    height: 129.266px;
    left: 0px;
    top: 0px;
    overflow: visible;
}

#Certificate_bc {
    left: 239px;
    top: 121px;
    position: absolute;
    overflow: visible;
    width: 364px;
    white-space: nowrap;
    text-align: left;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 52px;
    color: rgba(0, 74, 144, 1);
    text-transform: uppercase;
}

#Path_33 {
    fill: rgba(208, 0, 28, 1);
}

.Path_33 {
    overflow: visible;
    position: absolute;
    width: 280.565px;
    height: 30.172px;
    left: 281px;
    top: 184px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#of_completion {
    left: 338px;
    top: 189px;
    position: absolute;
    overflow: visible;
    width: 180px;
    white-space: nowrap;
    text-align: left;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    letter-spacing: 2px;
    text-transform: uppercase;
}

#this_certificate_is_proudly_pr {
    left: 159px;
    top: 250px;
    position: absolute;
    overflow: visible;
    width: 510px;
    white-space: nowrap;
    text-align: left;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
    letter-spacing: 2px;
    text-transform: uppercase;
}

#Lorem_ipsum_dolor_sit_amet_con {
    left: 164px;
    top: 314px;
    position: absolute;
    overflow: visible;
    width: 515px;
    white-space: nowrap;
    line-height: 20px;
    margin-top: -4px;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(51, 51, 51, 1);
    letter-spacing: 0.2px;
}

#Date {
    left: 406px;
    top: 418px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    line-height: 20px;
    margin-top: -4px;
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(51, 51, 51, 1);
    letter-spacing: 0.2px;
}

#Line_1 {
    fill: transparent;
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Line_1 {
    overflow: visible;
    position: absolute;
    width: 397px;
    height: 1px;
    left: 222.5px;
    top: 302.5px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

#Line_2 {
    fill: transparent;
    stroke: rgba(112, 112, 112, 1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Line_2 {
    overflow: visible;
    position: absolute;
    width: 99px;
    height: 1px;
    left: 371.5px;
    top: 406px;
    transform: matrix(1, 0, 0, 1, 0, 0);
}
