/* DOM elements video, webcam_canvas and boxes_canvas are exactly overlapping */
#pinned_video,
#webcam_canvas,
#boxes_canvas {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;
  /* Prevent the browser from stretching or squishing the video */
  width: auto;
  height: auto;
  /* Center and horizontally flip the video */
  position: absolute;
}

/* Hide video DOM element, webcam canvas shows the video stream */
#pinned_video {
  visibility: hidden;
}

.pinned-video-container {
  width: var(--width);
  height: var(--height);
  background-color: #3a3a3e;
  position: relative;
  text-align: center;

  &:hover {
    .video-controls {
      display: block;
    }
  }
  video {
    position: relative;
    z-index: 0;
    //  border: 1px solid #fff;
    object-fit: fill;
    height: inherit;
    width: inherit;
  }

  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0.5em 0.5em 0.5em 1em;
    background: rgba(242, 47, 70, 0.8);
    color: #ede5e5;
    line-height: 1;
    margin-left: 1px;

    &:after {
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      top: 0;
      right: -2em;
      border: 1em solid rgba(242, 47, 70, 0.8);
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
  }

  .video-controls {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0.5em 0.5em 0.5em 1em;
    color: #ede5e5;
    font-size: 16px;
    line-height: 1;
    margin-left: 1px;
    .expand-button {
      background: transparent;
      border: none;
      color: white;
      font-size: 18px;
      height: 40px;
      border-radius: 50%;
      background: #5f6368;
      margin-left: 8px;
    }
  }
}

.live-host-menu {
  border: 1px solid #f0f0f0;
}

.pinned-screen {
  display: flex;
  justify-content: center;
}
.pinned-other-screens {
  padding: 0px;
}

.pinnedvideoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.pinnedvideoWrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#other-pinned-grid {
  background: rgb(24, 23, 23);
}

.member-menu-ctrl-btn {
  background: transparent;
  &:hover {
    background: transparent;
  }
}
