$color-green: #283360;
$color-border: #606264;
    /* Course Price */
    .course-price {
        // border : 1px solid  $color-border;
        padding: 15px 20px 20px;
        border-radius : 5px;
        h5 {
            color :  $color-green;
            font-weight : 600;
            padding-bottom: 10px;
            margin-bottom: 20px;
            position : relative;
            &:before {
                position : absolute;
                content : "";
                background :  $color-green;
                width : 50px;
                height: 2px;
                bottom : 0;
                left : 0;
            }

            @media(max-width: 575px) {
                font-size  : 17px;
            }
        }
        
        ul.price-item {
            li.check-btn {
                border-top : 1px dashed  $color-green;
                padding: 10px 0;

                &:last-child {
                    border-bottom : 1px dashed  $color-green;
                }

                label {
                    font-size: 14px;
                    color:  $color-green;
                    display: block;
                    margin-bottom : 4px;
                    cursor: pointer;

                    input[type=checkbox] {
                        border : 2px solid  $color-green;
                        appearance: none;
                        width: 18px;
                        height: 18px;
                        cursor: pointer;
                        margin-right: 6px;
                        position: relative;
                        top: 4px;

                        &:focus {
                            outline: none;
                        }

                        &:checked {
                            background-color:  $color-green;
                            background:  $color-green url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 2px 2px no-repeat;
                            border-color :  $color-green;
                        }
                    }

                    span {
                        float : right;
                        font-size: 12px;
                        color:  $color-green;
                        line-height: 27px;
                    }
                }
            }
        }


    }