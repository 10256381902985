.ant-form-horizontal .ant-form-item-label{
    width: 100%;
    text-align: left;
}

.ant-form-item textarea.ant-input{
    margin: 0px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #283360;
    border-color: #283360;
}

.ant-comment-inner{
    padding: 0px;
}


.ant-form-item-control-input textarea:focus{
    border-color: #283360;
}

.ant-form-horizontal .ant-form-item-control{
    min-width: auto;
}

.ant-select-lg{
    width: 100%;
}
.custom-textarea{
    &::placeholder {
                font-size: 15px;
                color: #9598a2 !important;
            }
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
    color: black;
}