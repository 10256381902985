.edit-icon{
    color: #283360 !important;
    font-size: 1rem;
}

.delete-icon{
    color: #283360 !important;
    font-size: 1rem;
}

.social-icon{
    font-size: 1rem;
    li a {
    color: #3c4858;
    border: 1px solid #3c4858;
    display: inline-block;
    height: 32px;
    text-align: center;
    font-size: 15px;
    width: 32px;
    line-height: 30px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    overflow: hidden;
    position: relative;
    &:hover{
        background-color: #283360;
        color: #ffffff;
    }
}
}