.drag-box{
    background: #fff;
    border: 1px solid #999;
    border-radius: 3px;
    // width: 180px;
    // height: 180px;
    margin: 10px;
    padding: 10px;
    float: left;
}

.move-cursor{
    cursor: move;
}

.drag-and-drop-designer-empty {
    width: 100%;
    padding: 45px 0;
    border: 2px dashed #a5c0d2;
    text-align: center;
    color: #80a6c6;
    cursor: default;
}

.drag-and-drop-designer-empty-title {
    line-height: 1;
    font: 600 18px 'Open Sans';
    text-transform: uppercase;
}
.drag-and-drop-designer-empty-title {
    line-height: 1;
    font: 600 18px 'Open Sans';
    text-transform: uppercase;
}

.drag-and-drop-designer-background-wrapper {
    position: relative;
    // border: 3px solid #dadddf;
    overflow: auto;
    display: inline-block;
    align-items: center;
}

.drag-and-drop-designer-background {
    position: relative;
}