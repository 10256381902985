.chat_avator_img {
    position: relative;
}
.group_img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    position: relative;
    display: inline-block;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
}
.dreams_chat {
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.voice_pop {
    padding-right: 17px;
}
.status_carousel {
    margin-top: 50px;
}
.carousel_img {
    width: 650px;
    height: 434px;
}
.video_content {
    min-height: 200px;
    margin-top: 70px;
}
.voice-call-content {
    min-height: 200px;
}
.chatimage {
    img {
        width: 120px;
        height: 120px;
    }
}
.send-btn_status {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    background-color: #ffa977 !important;
    margin-left: 15px;
    font-size: 22px;
    &:focus {
        box-shadow: none !important;
        border-color: #ffa977 !important;
    }
}
.status_telegram {
    color: #fff;
}
.media-lists {
    display: flex;
    justify-content: flex-start;
    margin: 0 -5px 0px;
    flex-wrap: wrap;
    .media-image {
        margin: 5px;
        flex-basis: 74px;
        min-width: 74px;
        img {
            max-width: 100%;
        }
    }
}
.avatar-group {
    .avatar + .avatar {
        margin-left: -0.75rem;
        margin-left: -0.75rem;
    }
    display: flex;
    display: -webkit-flex;
    padding-left: 15px;
    margin: 0 auto 0 0;
    .avatar-xs + .avatar-xs {
        margin-left: -0.40625rem;
    }
    .avatar-sm + .avatar-sm {
        margin-left: -0.625rem;
    }
    .avatar-lg + .avatar-lg {
        margin-left: -1rem;
    }
    .avatar-xl + .avatar-xl {
        margin-left: -1.28125rem;
    }
    .avatar {
        &:hover {
            z-index: 1;
        }
    }
}
.list-group-item {
    background-color: inherit;
    border-color: #ebebeb;
}
ul.list-inline {
    .list-inline-item {
        margin-bottom: 0.5rem;
    }
}
.custom-control-label {
    line-height: 25px;
}
.custom-control-input {
    &:checked ~ .custom-control-label {
        &::before {
            background-color: #0a80ff;
        }
    }
}
.open_drop {
    color: #fff !important;
}
.avatar {
    display: inline-block;
    margin-bottom: 0;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    .avatar-title {
        color: #fff;
        background: #ee00ab;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 19px;
        font-weight: 600;
        font-size: 18px;
    }
    > a {
        width: 100%;
        height: 100%;
        display: block;
        transition: color 0.3s;
        color: #0a80ff;
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .border {
        border-width: 3px !important;
    }
    .rounded {
        border-radius: 6px !important;
    }
}
.avatar.avatar-sm {
    height: 1.3rem;
    width: 1.3rem;
    .avatar-title {
        font-size: 14px;
    }
}
.avatar.avatar-lg {
    height: 3.8rem;
    width: 3.8rem;
    .avatar-title {
        font-size: 29px;
    }
}
.avatar.avatar-xl {
    height: 6.1rem;
    width: 6.1rem;
    .avatar-title {
        font-size: 39px;
    }
}
.header_drop_icon {
    border-radius: 6px;
}
.accordion-col {
    .accordion-title {
        cursor: pointer;
        padding: 15px 0 8px;
        .primary-title {
            i {
                transition: all 0.5s ease;
                -webkit-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
            }
        }
    }
    .accordion-title.active {
        .primary-title {
            i {
                transition: all 0.5s ease;
                -webkit-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                transform: rotate(270deg);
                -webkit-transform: rotate(270deg);
                -ms-transform: rotate(270deg);
            }
        }
    }
    .accordion-content {
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 15px;
        &:last-child {
            border-bottom: 0;
        }
        h6 {
            font-size: 14px;
        }
    }
}
.status-right {
    font-weight: 500;
    color: #444444;
    font-size: 16px;
    text-align: center;
    p {
        margin-top: 20px;
        margin-bottom: 0;
    }
}
.menus-col {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    height: calc(100vh - 76px);
}
.sidebar-menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 85px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(66, 11, 161, 0.2);
    z-index: 999;
    padding: 20px;
    .logo-col {
        text-align: center;
    }
}
.chat-menus {
    margin-top: 50px;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            display: block;
            position: relative;
            a {
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: center;
                -webkit-justify-content: center;
                width: 46px;
                height: 42px;
                background-color: #f4f4fa;
                border-radius: 5px;
                &:hover {
                    background: #f9f1ff;
                }
                span.material-icons {
                    color: #420ba1;
                    font-size: 22px;
                    padding: 0;
                    position: static;
                    opacity: 1;
                    visibility: visible;
                    background-color: transparent;
                    box-shadow: none;
                    border-radius: 0;
                    transform: none;
                    -webkit-transform: none;
                    -ms-transform: none;
                    min-width: inherit;
                    &:before {
                        display: none;
                    }
                }
            }
            a.chat-unread {
                &:before {
                    content: "";
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    width: 5px;
                    height: 5px;
                    border-radius: 10px;
                    background-color: transparent;
                }
            }
            a.chat-unread.blue {
                &:before {
                    background-color: #0057ff;
                }
            }
            a.chat-unread.pink {
                &:before {
                    background-color: #ee00ab;
                }
            }
        }
        li + li {
            margin-top: 30px;
        }
    }
    > ul {
        > li {
            > a {
                > span {
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    left: 120%;
                    top: 50%;
                    background-color: #420ba1;
                    box-shadow: 4px 4px 4px rgba(200, 198, 198, 0.25);
                    border-radius: 2px;
                    display: inline-block;
                    padding: 6px 19px;
                    color: #fff;
                    font-size: 12px;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transition: ease all 0.5s;
                    -webkit-transition: ease all 0.5s;
                    -ms-transition: ease all 0.5s;
                    &:before {
                        content: "";
                        width: 0;
                        height: 0;
                        border-top: 7px solid transparent;
                        border-right: 7px solid #420ba1;
                        border-bottom: 7px solid transparent;
                        position: absolute;
                        left: -6px;
                        top: 50%;
                        transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        transition: ease all 0.5s;
                        -webkit-transition: ease all 0.5s;
                        -ms-transition: ease all 0.5s;
                    }
                }
                &:hover {
                    > span {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}
.dark-mode-toggle {
    width: 35px;
    height: 32px;
    background-color: rgb(0, 87, 255);
    color: rgb(255, 255, 255);
    font-size: 16px;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(251, 251, 251);
    border-image: initial;
    border-radius: 5px;
    margin: 0px auto;
    &:hover {
        color: rgb(255, 255, 255);
    }
    i {
        color: rgb(255, 255, 255);
    }
}
.bottom-menus {
    ul {
        li {
            a {
                span.material-icons {
                    color: #420ba1;
                    font-size: 22px;
                    padding: 0;
                    position: static;
                    opacity: 1;
                    visibility: visible;
                    background-color: transparent;
                    box-shadow: none;
                    border-radius: 0;
                    transform: none;
                    -webkit-transform: none;
                    -ms-transform: none;
                    min-width: inherit;
                    &:before {
                        display: none;
                    }
                }
            }
        }
        li + li {
            margin-top: 30px;
        }
    }
    > ul {
        > li {
            > a {
                > span {
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    left: 120%;
                    top: 50%;
                    background-color: #420ba1;
                    box-shadow: 4px 4px 4px rgba(200, 198, 198, 0.25);
                    border-radius: 2px;
                    display: inline-block;
                    padding: 6px 19px;
                    color: #fff;
                    font-size: 12px;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transition: ease all 0.5s;
                    -webkit-transition: ease all 0.5s;
                    -ms-transition: ease all 0.5s;
                    min-width: 130px;
                    &:before {
                        content: "";
                        width: 0;
                        height: 0;
                        border-top: 7px solid transparent;
                        border-right: 7px solid #420ba1;
                        border-bottom: 7px solid transparent;
                        position: absolute;
                        left: -6px;
                        top: 50%;
                        transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        transition: ease all 0.5s;
                        -webkit-transition: ease all 0.5s;
                        -ms-transition: ease all 0.5s;
                    }
                }
                &:hover {
                    > span {
                        opacity: 1;
                        visibility: visible;
                    }
                }
                text-align: center;
            }
            display: block;
            text-align: center;
            position: relative;
        }
        list-style: none;
        padding: 0;
        margin: 0;
    }
    width: 100%;
    display: flex;
    display: -webkit-flex;
    align-self: flex-end;
    -webkit-align-self: flex-end;
    justify-content: center;
    -webkit-justify-content: center;
    .chat-profile-icon {
        width: 36px;
        height: 36px;
        border-radius: 36px;
        img {
            max-width: 100%;
            border-radius: 36px;
            border: 2px solid #eeeeee;
        }
    }
}
.left-chat-title {
    padding: 10px 25px;
    .add-section {
        > a {
            width: 30px;
            height: 30px;
            background-color: #ee00ab;
            border-radius: 30px;
            color: #fff;
            display: flex;
            display: -webkit-flex;
            align-items: center;
            -webkit-align-items: center;
            justify-content: center;
            -webkit-justify-content: center;
            font-size: 13px;
        }
    }
}
.left-chat-title.with-bg {
    background-color: #fafbff;
}
.chat-title {
    h4 {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        color: #4b0973;
        margin-bottom: 0;
        a {
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            color: #4b0973;
            margin-bottom: 0;
        }
    }
}
.add-section {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            float: left;
            a {
                width: 24px;
                height: 24px;
                border-radius: 3px;
                border: 1px solid #d3dbee;
                background-color: #fff;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: center;
                -webkit-justify-content: center;
                box-shadow: 0px 4px 4px #f5f8ff;
                font-size: 10px;
                color: #420ba1;
            }
        }
        li + li {
            margin-left: 10px;
        }
    }
}
.online {
    color: #008024;
    font-weight: 500;
}
.top-online-contacts {
    padding: 0 25px;
    .swiper-container {
        padding-top: 6px;
    }
}
.top-contacts-box {
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 4px 4px #f5f8ff;
    border-radius: 5px;
    padding: 0 6px 8px 6px;
    .profile-img {
        position: relative;
        top: -6px;
        img {
            max-width: 100%;
            height: auto;
            border-radius: 5px;
        }
    }
    .profile-img.online {
        &:before {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            bottom: -5px;
            background-color: #49e073;
            width: 10px;
            height: 10px;
            border: 2px solid #fff;
            border-radius: 50px;
        }
    }
}
.profile-name {
    span {
        color: #585858;
        font-size: 12px;
        display: inline-block;
        margin-top: 9px;
    }
    color: #5a078b;
    font-size: 20px;
    font-weight: 600;
}
.avatar-title {
    width: 100%;
    height: 100%;
    background-color: #650681;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatar-away {
    &::before {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 25%;
        height: 25%;
        border-radius: 50%;
        content: "";
        border: 2px solid #fff;
        background-color: #ffbc34;
        content: "";
        position: absolute;
        display: block;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        bottom: 0px;
        right: 2px;
        border: 2px solid #fff;
        z-index: 9;
        background-color: #ffe600;
    }
}
.avatar-offline {
    &::before {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 25%;
        height: 25%;
        border-radius: 50%;
        content: "";
        border: 2px solid #fff;
        background-color: #ff0100;
        content: "";
        position: absolute;
        display: block;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        bottom: 0px;
        right: 2px;
        border: 2px solid #fff;
        z-index: 9;
    }
}
.avatar-online {
    &::before {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 25%;
        height: 25%;
        border-radius: 50%;
        content: "";
        border: 2px solid #fff;
        background-color: #00e65b;
        content: "";
        position: absolute;
        display: block;
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        bottom: 0px;
        right: 2px;
        border: 2px solid #fff;
        z-index: 9;
        background-color: #34d859;
    }
}
.avatar-xs {
    width: 1.65rem;
    height: 1.65rem;
    .border {
        border-width: 2px !important;
    }
    .rounded {
        border-radius: 4px !important;
    }
    .avatar-title {
        font-size: 10px;
    }
}
.avatar-xs.avatar-away {
    &::before {
        border-width: 1px;
    }
}
.avatar-xs.avatar-offline {
    &::before {
        border-width: 1px;
    }
}
.avatar-xs.avatar-online {
    &::before {
        border-width: 1px;
    }
}
.avatar-sm {
    width: 2.5rem;
    height: 2.5rem;
    width: 2.5rem;
    height: 2.5rem;
    width: 2.5rem;
    height: 2.5rem;
    .border {
        border-width: 3px !important;
    }
    .rounded {
        border-radius: 4px !important;
    }
    .avatar-title {
        font-size: 15px;
    }
}
.avatar-sm.avatar-away {
    &::before {
        border-width: 2px;
    }
}
.avatar-sm.avatar-offline {
    &::before {
        border-width: 2px;
    }
}
.avatar-sm.avatar-online {
    &::before {
        border-width: 2px;
    }
}
.avatar-lg {
    width: 3.75rem;
    height: 3.75rem;
    .border {
        border-width: 3px !important;
    }
    .rounded {
        border-radius: 8px !important;
    }
    .avatar-title {
        font-size: 24px;
    }
}
.avatar-lg.avatar-away {
    &::before {
        border-width: 3px;
    }
}
.avatar-lg.avatar-offline {
    &::before {
        border-width: 3px;
    }
}
.avatar-lg.avatar-online {
    &::before {
        border-width: 3px;
    }
}
.avatar-xl {
    width: 5rem;
    height: 5rem;
    .border {
        border-width: 4px !important;
    }
    .rounded {
        border-radius: 8px !important;
    }
    .avatar-title {
        font-size: 28px;
    }
}
.avatar-xl.avatar-away {
    &::before {
        border-width: 4px;
    }
}
.avatar-xl.avatar-offline {
    &::before {
        border-width: 4px;
    }
}
.avatar-xl.avatar-online {
    &::before {
        border-width: 4px;
    }
}
.avatar-xxl {
    width: 5.125rem;
    height: 5.125rem;
    .border {
        border-width: 6px !important;
    }
    .rounded {
        border-radius: 8px !important;
    }
    .avatar-title {
        font-size: 30px;
    }
}
.avatar-xxl.avatar-away {
    &::before {
        border-width: 4px;
    }
}
.avatar-xxl.avatar-offline {
    &::before {
        border-width: 4px;
    }
}
.avatar-xxl.avatar-online {
    &::before {
        border-width: 4px;
    }
}
.avatar-group.group-col {
    display: block;
    > div {
        display: flex;
        display: -webkit-flex;
        padding-bottom: 5px;
    }
    .avatar {
        margin-right: 0;
    }
}
.contact-close {
    float: right;
    left: 95%;
    right: 0px;
    position: relative;
    top: 5%;
    a {
        color: #292f4c;
        opacity: 0.5;
        border-color: #e6e6e6;
    }
}
.status_contactclose {
    float: right;
    left: 95%;
    right: 0px;
    position: relative;
    top: 1%;
}
.status-active {
    border: 4px solid #650681;
}
.nav.nav-tabs {
    border: none;
    line-height: 2;
}
.badge {
    padding: 5px 10px;
    font-size: 11px;
}
.badge.badge-success {
    background: #0abb87;
}
.badge.badge-danger {
    background: #fd397a;
}
.badge.badge-secondary {
    background: dimgray;
}
.badge.badge-info {
    background: #00bcd4;
}
.badge.badge-warning {
    background: #ffb822;
}
.badge.badge-dark {
    background: #3a3f51;
}
.badge.badge-primary {
    background: #0a80ff;
}
.badge.badge-light {
    background: #e6e6e6;
}
.input-group {
    .input-group-text {
        font-size: 14px;
    }
}
.tooltip {
    .arrow {
        display: none;
    }
    .tooltip-inner {
        background-color: rgba(0, 0, 0, 0.5);
    }
}
.sticky-top {
    z-index: auto;
}
.tab-content {
    // padding-top: 30px;
    // padding-top: 20px;
    .form-item {
        margin-bottom: 0.8rem;
    }
    .list-group-item {
        padding-left: 0;
        .text-truncate {
            font-size: 14px;
        }
        .text-truncate + span {
            font-size: 12px;
        }
    }
}
.meeting-wrapper {
    height: 100vh;
    display: flex;
    display: -webkit-flex;
    overflow: hidden;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    .content {
        display: flex;
        display: -webkit-flex;
        .sidebar-group {
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            position: relative;
            z-index: 99;
            width: 379px;
            .sidebar {
                background: #fafbff;
                overflow: hidden;
                width: 379px;
                display: flex;
                flex-direction: column;
                flex: 1;
                &:not(.active) {
                    display: none;
                }
            }
        }
        .sidebar-group.right-sidebar {
            .sidebar {
                width: 310px;
            }
        }
    }
}
.header-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    font-weight: 600;
    background-color: #680a83;
    .logo {
        margin-top: 0 !important;
    }
    > span {
        font-size: 22px;
    }
    ul {
        margin-bottom: 0;
        display: flex;
        li {
            a {
                color: #000;
                font-size: 18px;
            }
        }
    }
    ul.header-action {
        margin-top: 14px !important;
    }
}
.chat-header {
    ul {
        margin-bottom: 0;
        li {
            &:not(.list-inline-item) {
                margin-bottom: 0;
                width: 48px;
                margin-right: 15px !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
        }
    }
}
.sidebar-body {
    flex: 1;
    overflow: auto;
    padding: 10px 25px;
    width: 100%;
    .profile-name {
        font-weight: 600;
        color: #4b0973;
        font-size: 18px;
        margin-bottom: 0;
    }
}
.online-profile {
    position: relative;
    span {
        position: relative;
        color: #565656;
        font-size: 13px;
        padding-left: 10px;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 7px;
            background-color: #49e073;
            width: 8px;
            height: 8px;
            border-radius: 50px;
        }
    }
}
.close_profile4 {
    color: #000;
    border-color: #e6e6e6;
    margin-top: 4px !important;
}
.sidebar {
    .user-list {
        li {
            padding: 10px 20px;
            display: flex;
            display: -webkit-flex;
            cursor: pointer;
            background-color: #fff;
            margin-bottom: 3px;
            border: 0;
            background: #ffffff;
            box-shadow: 0px 4px 4px #f5f8ff;
            border-radius: 3px;
            position: relative;
            transition: ease all 0.5s;
            -webkit-transition: ease all 0.5s;
            -ms-transition: ease all 0.5s;
            &:hover {
                background-color: #f1f6ff;
                transition: ease all 0.5s;
                -webkit-transition: ease all 0.5s;
                -ms-transition: ease all 0.5s;
            }
            figure {
                margin-right: 1rem;
            }
            .users-list-body {
                flex: 1;
                position: relative;
                min-width: 0px;
                display: flex;
                > div {
                    &:first-child {
                        min-width: 0;
                        display: flex;
                        flex-direction: column;
                        flex: 1;
                    }
                }
                h5 {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-size: 15px;
                    font-weight: 600;
                    margin-bottom: 7px;
                    color: #4b0973;
                }
                p {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin-bottom: 0;
                    color: #232323;
                    font-size: 13px;
                }
                .last-chat-time {
                    padding-left: 15px;
                    position: relative;
                    .text-muted {
                        color: #9f9f9f !important;
                        font-weight: 500;
                    }
                    [data-toggle="dropdown"] {
                        i {
                            font-size: 18px;
                        }
                    }
                    .contact-toggle {
                        color: #ee00ab;
                    }
                    .new-message-count {
                        width: 20px;
                        height: 20px;
                        display: flex;
                        display: -webkit-flex;
                        align-items: center;
                        -webkit-align-items: center;
                        justify-content: center;
                        -webkit-justify-content: center;
                        line-height: 0;
                        font-size: 11px;
                        background-color: #0057ff;
                        color: #fff;
                        border-radius: 50%;
                        margin-left: auto;
                        margin-top: 10px;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                    .chat-toggle {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        opacity: 0;
                        text-align: right;
                        transition: all 0.5s ease;
                        -webkit-transition: all 0.5s ease;
                        -ms-transition: all 0.5s ease;
                        width: 18px;
                        height: 24px;
                    }
                }
                p.missed-call-col {
                    color: #ff0000;
                }
            }
        }
        li.item-typing {
            background-color: #f1f6ff;
        }
        li.unread {
            h5 {
                color: #fff;
            }
            p {
                color: #fff;
            }
            .text-muted {
                color: rgba(255, 255, 255, 0.4) !important;
            }
        }
        li.user-list-item.item-typing {
            .users-list-body {
                p {
                    color: #0d9b35;
                }
            }
        }
    }
    .list-group-item.unread {
        h5 {
            color: #fff;
        }
        p {
            color: #fff;
        }
        .text-muted {
            color: rgba(255, 255, 255, 0.4) !important;
        }
    }
}
.lock-icon {
    color: #000000;
}
.rightside_tab {
    padding-top: 5px !important;
}
.chat {
    flex: 1;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    width: 100%;
    padding: 0px 10px 0px 15px;
    background-repeat: repeat;
    height: 100%;
    .chat-header {
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        padding: 12px 15px;
        align-items: center;
        // box-shadow: 0px 4px 4px #f5f8ff;
        border-radius: 5px;
        background: #ffffff;
        border: 1px solid #f4eeff;
        margin-top: 10px;
        position: relative;
        .user-details {
            display: flex;
            display: -webkit-flex;
            align-items: center;
            -webkit-align-items: center;
            figure {
                margin-right: 1rem;
            }
            h5 {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 0;
                line-height: 1;
                color: #4b0973;
            }
        }
        .chat-options {
            ul {
                margin-bottom: 0;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                > li {
                    > a {
                        font-size: 14px;
                        color: #c8c8d8;
                        border-color: #f4f4fa;
                        cursor: pointer;
                        width: 35px;
                        height: 35px;
                        background-color: #f4f4fa;
                        padding: 0;
                        border-radius: 50px;
                        display: flex;
                        display: -webkit-flex;
                        align-items: center;
                        -webkit-align-items: center;
                        justify-content: center;
                        -webkit-justify-content: center;
                        &:not(.no-bg) {
                            &:hover {
                                background-color: #4b0973;
                                border-color: #4b0973;
                                color: #fff;
                            }
                        }
                    }
                    > a.no-bg {
                        background-color: transparent;
                        border-color: transparent;
                    }
                }
            }
        }
    }
    .chat-body {
        flex: 1;
        padding: 40px 0px 20px 0px;
        padding-bottom: 90px;
        &:not(.no-message) {
            overflow: auto;
        }
        .messages {
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            -webkit-flex-direction: column;
            align-items: flex-start;
            -webkit-align-items: flex-start;
            .chats {
                max-width: 75%;
                margin-bottom: 1.5rem;
                display: flex;
                display: -webkit-flex;
                &:last-child {
                    margin-bottom: 0;
                }
                .chat-time {
                    display: flex;
                    display: -webkit-flex;
                    align-items: center;
                    -webkit-align-items: center;
                    margin-bottom: 0;
                    .avatar {
                        margin-right: 1rem;
                    }
                    h5 {
                        font-size: 15px;
                        margin-bottom: 0;
                    }
                    .time {
                        color: #fff;
                        margin-top: 2px;
                        font-size: 12px;
                        font-weight: 400;
                        i {
                            color: rgba(247, 247, 247, 0.5);
                            img {
                                width: 14px;
                                height: auto;
                                margin-left: 2px;
                                vertical-align: 0px;
                            }
                        }
                    }
                }
                .message-content {
                    background-color: #5a078b;
                    border-radius: 20px 20px 20px 0;
                    padding: 14px 20px;
                    color: #fff;
                    font-weight: 500;
                }
            }
            .chat-profile-name {
                h6 {
                    margin-bottom: 0;
                    color: #8345a8;
                    font-weight: 600;
                    font-size: 15px;
                    margin-top: 5px;
                }
            }
            .chats.chats-right {
                margin-left: auto;
                .chat-time {
                    justify-content: flex-end;
                }
                .message-content {
                    background-color: #e8efff;
                    color: #232323;
                    border-radius: 20px 20px 0 20px;
                    .chat-time {
                        justify-content: flex-start;
                        -webkit-justify-content: flex-start;
                        .time {
                            color: #909090;
                            i {
                                color: #a3c2ff;
                            }
                        }
                    }
                }
                .chat-action-btns {
                    order: 2;
                    -webkit-order: 2;
                    display: flex;
                    display: -webkit-flex;
                    flex-wrap: wrap;
                    -webkit-flex-wrap: wrap;
                    justify-content: center;
                    -webkit-justify-content: center;
                    .chat-action-col {
                        width: 100%;
                        text-align: center;
                    }
                    .chat-read-col {
                        display: flex;
                        display: -webkit-flex;
                        align-items: flex-end;
                        -webkit-align-items: flex-end;
                        padding-bottom: 22px;
                        color: #008024;
                    }
                }
                .chat-content {
                    order: 1;
                    -webkit-order: 1;
                }
                .chat-avatar {
                    padding: 8px 0px 0 15px;
                }
            }
            .download-col {
                position: relative;
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0 0 5px 0;
                    display: flex;
                    display: -webkit-flex;
                    li {
                        margin: 5px;
                    }
                }
                .image-download-col {
                    position: relative;
                    a {
                        > img {
                            border: 1px solid transparent;
                        }
                    }
                }
                .image-download-col.image-not-download {
                    a {
                        > img {
                            -webkit-filter: blur(4px);
                            filter: blur(4px);
                            border-color: #000;
                        }
                    }
                }
                .download-action {
                    position: absolute;
                    right: 8px;
                    bottom: 8px;
                    line-height: 0;
                    a {
                        color: #fff;
                        font-size: 10px;
                    }
                    div + div {
                        margin-left: 8px;
                    }
                    div {
                        img {
                            width: 13px;
                        }
                    }
                }
            }
            .file-download {
                .download-action {
                    position: absolute;
                    right: 8px;
                    bottom: 8px;
                    line-height: 0;
                    bottom: 14px;
                    a {
                        color: #fff;
                        font-size: 10px;
                        color: #5a078b;
                    }
                    div + div {
                        margin-left: 8px;
                    }
                    div {
                        img {
                            width: 13px;
                        }
                    }
                }
                position: relative;
                background: #ffffff;
                border-radius: 10px;
                padding: 10px;
                margin-bottom: 7px;
                .file-type {
                    width: 40px;
                    height: 40px;
                    background-color: rgba(0, 87, 255, 0.1);
                    color: #d7bce8;
                }
                .file-details {
                    span.file-name {
                        color: #5a078b;
                        display: inline-block;
                        width: 100%;
                    }
                    span.file-size {
                        color: #909090;
                        display: inline-block;
                        width: 100%;
                    }
                }
            }
        }
    }
    .chat-body.no-message {
        user-select: none;
    }
    .chat-footer {
        padding: 10px 0px 15px;
        form {
            display: flex;
            display: -webkit-flex;
            align-items: center;
            -webkit-align-items: center;
            position: relative;
            .form-buttons {
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                display: flex;
                align-items: center;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                .btn {
                    margin-left: 0px;
                    color: #bdbfc7;
                    font-size: 20px;
                    padding: 0;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                .btn.send-btn {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff !important;
                    background-color: #ee00ab;
                    &:focus {
                        box-shadow: none;
                    }
                }
            }
            .specker-col {
                position: absolute;
                right: 70px;
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                span {
                    color: #5a078b;
                    font-size: 25px;
                }
            }
            .smile-col {
                position: absolute;
                right: 70px;
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                right: inherit;
                left: 15px;
                i {
                    color: #5a078b;
                    font-size: 20px;
                    font-size: 22px;
                }
            }
            .attach-col {
                position: absolute;
                right: 70px;
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                right: inherit;
                left: 60px;
                i {
                    color: #5a078b;
                    font-size: 20px;
                }
            }
            .form-control {
                &::-webkit-input-placeholder {
                    color: rgb(0, 0, 0, 0.2);
                    opacity: 1;
                }
                &::-moz-placeholder {
                    color: rgb(0, 0, 0, 0.2);
                    opacity: 1;
                }
                &:-ms-input-placeholder {
                    color: rgb(0, 0, 0, 0.2);
                    opacity: 1;
                }
                &:-moz-placeholder {
                    color: rgb(0, 0, 0, 0.2);
                    opacity: 1;
                }
            }
        }
    }
}
.chat-options {
    ul.list-inline {
        .list-inline-item {
            margin-bottom: 0;
        }
    }
}
.user_callog {
    display: flex;
    margin-top: 0px;
}
.calllog_p {
    margin-left: 5px;
}
.header_button {
    position: absolute;
    right: 5px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
    color: white;
    text-align: center;
    background: #ffa977;
    bottom: 40px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    &:hover {
        color: #fff;
        box-shadow: none;
    }
    &:focus {
        box-shadow: none;
    }
}
.btn-warning {
    &:hover {
        color: #fff;
        background-color: #ffa977;
        border-color: #ffa977;
    }
}
.right_sidebar_profile {
    margin-top: -15px;
}
.account_details {
    background-color: #edeef6;
    border-color: #d9d7d8;
    border-radius: 5px !important;
    border-bottom: 0 !important;
}
.security_details {
    background-color: #edeef6;
    border-color: #d9d7d8;
    border-radius: 5px !important;
    border-bottom: 0 !important;
}
.close_icon {
    font-size: 18px;
}
.button_plus {
    margin-left: 0px;
}
.chat-search {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    transition: ease all 0.5s;
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
    opacity: 0;
    visibility: hidden;
    input[type="text"] {
        padding: 8px 8px 8px 35px;
        width: 100%;
        min-height: 47px;
        border-radius: 0px 0px 5px 5px;
        border: 1px solid #f3f3f3;
        font-size: 13px;
        background-color: #fff;
        &:hover {
            border-color: #f3f3f3;
        }
        &:focus {
            border-color: #f3f3f3;
        }
        &:focus-visible {
            border-color: #f3f3f3;
        }
    }
}
.chat-search.visible-chat {
    opacity: 1;
    visibility: visible;
    top: 100%;
    transition: ease all 0.5s;
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
}
.close-btn-chat {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    color: #fff;
    background-color: #ee00ab;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    position: absolute;
    right: 12px;
    top: 9px;
    cursor: pointer;
}
.main_content {
    width: 100% !important;
}
.avatar-main {
    height: 50px;
    width: 50px;
}
.archive-btn {
    border: 1px solid #008024;
    color: #008024;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    padding: 2px 6px;
    margin-right: 1.5rem;
}
.archive-btn-grey {
    border: 1px solid #909090;
    color: #909090;
    border-radius: 4px;
    font-size: 9px;
    font-weight: 500;
    padding: 2px 6px;
    margin-top: 0.25rem;
}
.right_sidebar_icon {
    list-style: none;
}
.btn.btn-outline-light {
    &:hover {
        background: #e6e6e6;
    }
}
.chat-avatar {
    padding: 8px 15px 0 8px;
    display: flex;
    display: -webkit-flex;
    align-items: flex-end;
    -webkit-align-items: flex-end;
}
.file-download-col {
    .download-action {
        position: absolute;
        right: 8px;
        bottom: 8px;
        line-height: 0;
        a {
            color: #fff;
            font-size: 10px;
        }
        div + div {
            margin-left: 8px;
        }
        div {
            img {
                width: 13px;
            }
        }
    }
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            float: left;
            width: 50%;
            padding: 5px;
            .image-download-col {
                position: relative;
                a {
                    > img {
                        width: 100%;
                    }
                }
            }
        }
        li.full-width {
            width: 100%;
        }
    }
}
.chat-line {
    margin: 0px 0 20px;
    position: relative;
    text-align: center;
    width: 100%;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        background-color: #eeeeee;
        width: 100%;
        height: 1px;
    }
}
.chat-date {
    background-color: #eeeeee;
    color: #5a078b;
    font-size: 13px;
    padding: 4px 20px;
    border-radius: 3px;
    display: inline-block;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
}
.chat + .sidebar-group {
    margin-right: 0px;
    .sidebar {
        margin-right: 0px;
    }
}
.header {
    position: relative;
    width: 100%;
    height: 190px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: initial;
    padding: 20px 20px;
}
.nav.nav-tabs.chat-tabs {
    .nav-item {
        .nav-link {
            font-size: 16px;
            font-weight: bold;
            color: #fff;
            background-color: transparent;
            border: 0;
        }
        .nav-link.active {
            font-size: 16px;
            font-weight: bold;
            color: #ffa977;
        }
    }
}
.header-action {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: inline-block;
        margin-left: 10px;
        a {
            color: #1b1a1a;
            font-size: 18px;
        }
    }
}
.user-list {
    border-radius: 0.25rem;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    font-size: 14px;
    li {
        padding: 17px 20px;
        display: flex;
        display: -webkit-flex;
        cursor: pointer;
        border-radius: 6px;
        background-color: #fff;
        margin-bottom: 10px;
        border: 0;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
        .avatar {
            margin-right: 0.8rem;
            display: inline-block;
            margin-bottom: 0;
            height: 35px;
            width: 35px;
            border-radius: 50%;
            position: relative;
            .letter-avatar {
                width: 45px;
                height: 45px;
                border-radius: 45px;
                margin-right: 0.8rem;
                margin-bottom: 0;
                position: relative;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: center;
                -webkit-justify-content: center;
                background-color: #e8dbff;
                font-size: 16px;
                font-weight: 600;
                color: #420ba1;
            }
        }
    }
    .avatar {
        > a {
            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.chat_form {
    border-radius: 20px;
    margin-right: 0px;
    background: #ffffff;
    border: 1px solid #f4eeff;
    box-shadow: 0px 4px 4px #f5f8ff;
    border-radius: 175px;
    height: 48px;
    padding: 10px 100px;
}
.primary-title {
    color: #ffa977;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    i {
        font-size: 14px;
    }
}
.contact-action {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
        padding: 11px 0;
        border-bottom: 1px solid #f1f1f1;
        a {
            color: #181c2f;
            color: #181c2f;
            font-size: 14px;
            font-weight: bold;
        }
    }
    li.report-contact {
        a {
            color: #f00;
        }
    }
    li.delete-chat {
        a {
            color: #f00;
        }
    }
    li.sign-out {
        a {
            color: #f00;
        }
    }
}
.left-sidebar {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
}
.left-sidebar-wrap {
    background: #fbfbfb;
    overflow: hidden;
    width: 448px;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    flex: 1;
}
.right-sidebar {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    background-color: #fafbff;
    opacity: 100%;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    position: relative;
    width: 320px;
    padding: 15px 20px 0;
    .right-sidebar-wrap {
        background-color: #fff;
        border: 1px solid #f4eeff;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 15px;
        height: calc(100vh - 10px);
        .sidebar-body {
            padding: 0;
        }
        .right-sidebar-profile {
            background-color: #fafbff;
            padding: 17px;
        }
    }
    .contact-close_call {
        .close_profile {
            width: 25px;
            height: 25px;
            border-radius: 25px;
            color: #fff;
            background-color: #ee00ab;
            display: flex;
            display: -webkit-flex;
            justify-content: center;
            -webkit-justify-content: center;
            align-items: center;
            -webkit-align-items: center;
        }
    }
    .mCSB_inside {
        > .mCSB_container {
            margin-right: 0;
        }
    }
}
.right-sidebar.video-right-sidebar {
    width: 420px;
    .right-sidebar-wrap {
        padding: 8px;
        height: calc(100vh - 20px);
    }
    .chat {
        .chat-body {
            padding-bottom: 110px;
        }
        margin-left: 0 !important;
        display: block;
    }
}
.right-sidebar.show-right-sidebar {
    margin-right: 0;
}
.right-sidebar.video-right-sidebar.show-right-sidebar {
    margin-right: 0;
}
.right-sidebar.hide-right-sidebar {
    margin-right: -320px;
}
.right-sidebar.video-right-sidebar.hide-right-sidebar {
    margin-right: -400px;
}
.status-modal {
    .custom-status-close {
        width: 25px;
        height: 25px;
        border-radius: 25px;
        color: #fff;
        background-color: #ee00ab;
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        -webkit-justify-content: center;
        align-items: center;
        -webkit-align-items: center;
        text-align: right;
        opacity: 1;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
        text-shadow: none;
        z-index: 9;
    }
    button.close {
        font-size: 34px;
        margin: 20px 20px 20px auto;
        text-align: right;
        width: 34px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    .modal-dialog {
        max-width: 90%;
        padding: 20px;
        margin-top: 0;
        margin-bottom: 0;
        height: 100%;
        .modal-content {
            border: 0;
            background-color: transparent;
            height: 100%;
            flex-direction: inherit;
            -webkit-flex-direction: inherit;
            align-items: center;
            -webkit-align-items: center;
            flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            .inner-popup {
                margin: 0 auto;
                width: 100%;
            }
        }
    }
    .carousel-indicators {
        top: 25px;
    }
}
.list_group_notread {
    color: #ffa977 !important;
}
.right-sidebar-profile {
    > .avatar {
        background: rgba(66, 11, 161, 0.1);
        padding: 7px;
    }
}
.group_header {
    width: 2rem !important;
    height: 2rem !important;
}
.last-chat-time {
    i.missed-col {
        width: 17px;
        display: inline-block;
        margin-top: 10px;
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
.about-media-tabs {
    padding: 12px;
    .nav-tabs {
        .nav-link {
            text-transform: uppercase;
            border: 0;
            color: #000;
            border-bottom: 1px solid transparent;
            padding: 3px 9px;
            font-size: 13px;
        }
        .nav-link.active {
            color: #0057ff;
            font-weight: 700;
            border-color: #0057ff;
        }
    }
    p {
        font-weight: 500;
        color: #939393;
    }
    .member-details {
        margin-top: 30px;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                display: block;
                width: 100%;
                h6 {
                    font-weight: 600;
                    color: #000;
                    font-size: 15px;
                }
                span {
                    color: #939393;
                    font-weight: 500;
                }
            }
            li + li {
                margin-top: 15px;
            }
        }
    }
}
.social-media-col {
    margin-top: 35px;
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            float: left;
            a {
                color: #420ba1;
                font-size: 18px;
            }
        }
        li + li {
            margin-left: 10px;
        }
    }
    h6 {
        font-weight: 600;
        color: #000;
        font-size: 15px;
        margin-bottom: 20px;
    }
}
.settings-col {
    h6 {
        font-weight: 600;
        color: #000;
        font-size: 15px;
        margin-bottom: 20px;
    }
    margin-top: 35px;
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            color: #939393;
            font-weight: 500;
        }
        li + li {
            margin-top: 15px;
        }
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 23px;
        margin-bottom: 0;
        margin-right: 10px;
        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &:before {
            position: absolute;
            content: "";
            height: 15px;
            width: 15px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }
    }
    input {
        &:checked + .slider {
            background-color: #2196f3;
            &:before {
                -webkit-transform: translateX(16px);
                -ms-transform: translateX(16px);
                transform: translateX(16px);
            }
        }
        &:focus + .slider {
            box-shadow: 0 0 1px #2196f3;
        }
    }
    .slider.round {
        border-radius: 34px;
        &:before {
            border-radius: 50%;
        }
    }
}
.report-col {
    margin-top: 35px;
    padding: 12px;
    padding-bottom: 50px;
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        &:after {
            content: "";
            display: table;
            clear: both;
        }
        li {
            display: block;
            width: 100%;
            font-weight: 500;
            position: relative;
            a {
                color: #ff0000;
                padding-left: 25px;
                span {
                    position: absolute;
                    left: 0;
                    top: 0px;
                    font-size: 16px;
                }
            }
        }
        li + li {
            margin-top: 19px;
        }
    }
}
.load-more-btn {
    font-weight: 600;
    margin-top: 10px;
    i {
        background: #0057ff;
        border-radius: 50%;
        height: 12px;
        width: 12px;
        color: #fff;
        line-height: 8px;
        font-size: 12px;
        vertical-align: middle;
    }
}
.participants-list {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            border: 1px solid #f1f1f1;
            box-shadow: 0px 4px 4px #f5f8ff;
            border-radius: 3px;
            background-color: #fff;
            padding: 10px;
        }
        li + li {
            margin-top: 3px;
        }
    }
    .users-list-body {
        h5 {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 7px;
            color: #4b0973;
        }
        p {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 0;
            color: #000;
            font-size: 13px;
        }
        flex: 1;
        position: relative;
        min-width: 0px;
        display: flex;
        display: -webkit-flex;
        width: 100%;
    }
    .admin-profiles {
        display: inline-block;
        color: #008024;
        border: 1px solid #008024;
        border-radius: 50px;
        font-size: 12px;
        padding: 2px 10px;
        font-weight: 500;
    }
}
.grp-delete {
    .chat-action-col {
        display: flex;
        align-items: center;
        height: 100%;
        > a {
            color: #c8c8d8;
        }
    }
}
.chat-action-col {
    > a {
        color: #420ba1;
        > i {
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
        }
    }
}
.darkmode-btn {
    width: 35px;
    height: 32px;
    background-color: #0057ff;
    border: 1px solid #fbfbfb;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    margin: 0 auto;
    &:hover {
        color: #fff;
    }
    &:focus {
        color: #fff;
    }
}
.add-contacts-btn {
    &:hover {
        color: #fff;
    }
    &:focus {
        color: #fff;
    }
    width: 25px;
    height: 25px;
    border-radius: 30px;
    background-color: #ee00ab;
    color: #fff;
    font-size: 10px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    margin: 0 auto;
}
.group-call-tab {
    .nav-tabs {
        .nav-link {
            color: #5a078b;
            border: 0;
            border-radius: 10px;
            font-weight: 500;
            padding: 6px 25px;
            &:hover {
                color: #5a078b;
            }
        }
        .nav-link + .nav-link {
            margin-left: 10px;
        }
        .nav-link.active {
            background-color: rgba(90, 7, 139, 0.1);
            color: #5a078b;
        }
    }
    nav {
        padding: 0 15px 20px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: #e2e2e2;
            height: 1px;
        }
    }
}
.nav-tabs {
    border-bottom: 1px solid #f0f0f0;
    > li {
        > a {
            margin-right: 0;
            color: #333;
            border-radius: 0;
            &:hover {
                border-color: transparent;
                color: #272b41;
            }
            &:focus {
                border-color: transparent;
                color: #272b41;
            }
        }
    }
    .nav-link {
        border-radius: 0;
        &:focus {
            border-color: transparent;
            color: #000;
        }
        &:hover {
            border-color: transparent;
            color: #000;
        }
    }
}
.card-header-tabs {
    border-bottom: 0;
}
.nav-tabs.nav-tabs-solid {
    > li {
        > a {
            color: #272b41;
            color: #272b41;
            border-color: transparent;
            &:hover {
                background-color: #f5f5f5;
            }
            &:focus {
                background-color: #f5f5f5;
            }
        }
        > a.active {
            background-color: #650681;
            color: #fff;
            &:hover {
                background-color: #650681;
                color: #fff;
            }
            &:focus {
                background-color: #650681;
                color: #fff;
            }
        }
        margin-bottom: 0;
    }
    > .active {
        > a {
            background-color: #650681;
            color: #fff;
            &:hover {
                background-color: #650681;
                color: #fff;
            }
            &:focus {
                background-color: #650681;
                color: #fff;
            }
        }
    }
    background-color: #fafafa;
    border: 0;
    > .open {
        &:not(.active) {
            > a {
                background-color: #f5f5f5;
                border-color: transparent;
            }
        }
    }
}
.left_newgroup {
    width: 100% !important;
    height: 100%;
    padding: 10px;
}
.newgroup_search {
    color: #7f808c;
    background-color: #edeef6;
    height: 42px;
    padding: 10px;
    font-size: 14px;
    line-height: 1.6;
    border-radius: 0.375rem;
    border-color: #edeef6;
}
.newgroup_search_btn {
    background-color: #660881;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
}
.newgroup_ul {
    margin-top: 13px;
    li {
        border-bottom: 1px solid #edeef6;
        a.active {
            background-color: #680a83 !important;
            border-color: transparent;
            color: #fff !important;
            border-radius: 5px 5px 0 0 !important;
        }
    }
}
.group_formcontrol {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ede2ff;
    height: 49px;
    padding: 10px;
    font-size: 14px;
    line-height: 1.6;
    border-radius: 2px;
}
.group_control_text {
    background-color: #edeef6;
    border: 1px solid #edeef6;
}
.button_group {
    background-color: #edeef6;
    color: #000;
}
.carousel {
    .carousel-inner {
        max-width: 100%;
        width: 100%;
        margin: 50px auto 0;
        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }
    .carousel-item {
        width: 100%;
    }
    margin: 0 auto;
}
.carousel-control-prev-icon {
    width: 50px;
    height: 50px;
    opacity: 1;
    background-image: none;
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 50px;
    font-size: 15px;
    color: #ee00ab;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
}
.carousel-control-next-icon {
    width: 50px;
    height: 50px;
    opacity: 1;
    background-image: none;
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 50px;
    font-size: 15px;
    color: #ee00ab;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
}
.group_card_mb {
    background-color: #edeef6;
    border-color: #fff;
}
.group_card_media {
    margin-left: -30px;
}
.group_image {
    border-radius: 35px;
}
.newgroup_create {
    padding: 10px 20px;
    line-height: 1.6;
    border-radius: 0.375rem;
    background-color: #680a83;
    box-shadow: none;
    border-color: #680a83;
    margin: 0px auto 15px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    &:hover {
        background-color: #680a83;
        color: #fff;
        border-color: #680a83;
        opacity: 0.9;
    }
    &:focus {
        outline: 0;
        box-shadow: none;
    }
}
.newgroup_create.previous {
    background-color: #420ba1;
    border-color: #420ba1;
}
.newgroup_fa_search {
    font-size: 15px;
}
.profile_Card {
    background-color: #edeef6;
    background-clip: border-box;
    border: 1px solid rgba(245, 246, 250, 0.85);
    border-radius: 0.375rem;
}
.nav-tabs.nav-justified {
    > li {
        > a {
            border-radius: 0;
            margin-bottom: 0;
            &:hover {
                border-bottom-color: transparent;
            }
            &:focus {
                border-bottom-color: transparent;
            }
        }
    }
}
.nav-tabs.nav-justified.nav-tabs-solid {
    > li {
        > a {
            border-color: transparent;
        }
    }
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
    border-radius: 50px;
    > li {
        > a {
            border-radius: 50px;
        }
        > a.active {
            border-radius: 50px;
            &:hover {
                border-radius: 50px;
            }
            &:focus {
                border-radius: 50px;
            }
        }
    }
}
.nav-tabs-justified {
    > li {
        > a {
            border-radius: 0;
            margin-bottom: 0;
            &:hover {
                border-bottom-color: #ddd;
            }
            &:focus {
                border-bottom-color: #ddd;
            }
        }
    }
}
.nav-tabs-justified.nav-tabs-solid {
    > li {
        > a {
            border-color: transparent;
        }
    }
}
.nav-tabs.nav-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
    > li {
        > a {
            border-width: 2px 0 0 0;
            &:hover {
                border-width: 2px 0 0 0;
            }
            &:focus {
                border-width: 2px 0 0 0;
            }
        }
    }
}
.nav-tabs.nav-tabs-top {
    > li {
        margin-bottom: 0;
        > a {
            border-width: 2px 0 0 0;
            &:hover {
                border-width: 2px 0 0 0;
                border-top-color: #ddd;
            }
            &:focus {
                border-width: 2px 0 0 0;
                border-top-color: #ddd;
            }
        }
        > a.active {
            border-top-color: #650681;
            &:hover {
                border-top-color: #650681;
            }
            &:focus {
                border-top-color: #650681;
            }
        }
    }
    > li.open {
        > a {
            border-top-color: #ddd;
        }
    }
    > li + li {
        > a {
            margin-left: 1px;
        }
    }
}
.nav-tabs.nav-tabs-bottom {
    > li {
        > a.active {
            border-bottom-width: 2px;
            border-bottom-color: #650681;
            background-color: transparent;
            transition: none 0s ease 0s;
            &:hover {
                border-bottom-width: 2px;
                border-bottom-color: #650681;
                background-color: transparent;
                transition: none 0s ease 0s;
            }
            &:focus {
                border-bottom-width: 2px;
                border-bottom-color: #650681;
                background-color: transparent;
                transition: none 0s ease 0s;
            }
        }
    }
}
.nav-tabs-justified.nav-tabs-top {
    border-bottom: 1px solid #ddd;
    > li {
        > a {
            border-width: 2px 0 0 0;
            &:hover {
                border-width: 2px 0 0 0;
            }
            &:focus {
                border-width: 2px 0 0 0;
            }
        }
    }
}
.nav-tabs.nav-tabs-solid.profile-tabs {
    > li + li {
        margin-left: 20px;
    }
    > li {
        > a {
            border: 1px dashed #650681;
            font-size: 14px;
            font-weight: bold;
            color: #650681;
            padding: 5px 10px;
        }
        > a.active {
            color: #fff;
        }
    }
}
.view-more {
    a {
        color: #bec0c9;
        font-size: 14px;
    }
}
// .modal-header {
//     padding: 20px 15px;
//     background-color: #fff;
//     color: #484848;
//     border-radius: 10px 10px 0 0;
//     border-bottom: 0;
//     .modal-title {
//         font-size: 18px;
//         font-weight: 600;
//         i {
//             font-size: 20px;
//             color: #420ba1;
//             margin-right: 5px;
//         }
//         span.material-icons {
//             font-size: 25px;
//             color: #420ba1;
//             margin-right: 5px;
//         }
//     }
//     .close {
//         color: #fff;
//         text-shadow: none;
//         opacity: 1;
//         width: 25px;
//         height: 25px;
//         border-radius: 25px;
//         color: #fff;
//         background-color: #ee00ab;
//         display: flex;
//         display: -webkit-flex;
//         justify-content: center;
//         -webkit-justify-content: center;
//         align-items: center;
//         -webkit-align-items: center;
//         padding: 0;
//         margin: 0;
//     }
// }
.modal-body {
    background-color: #fafbff;
    border-radius: 10px;
    .form-group {
        label {
            font-weight: 600;
            color: #464646;
        }
    }
}
.create-group-members {
    .group_card_mb {
        .card-body {
            padding: 15px;
            border-radius: 8px;
        }
        .card-body + label {
            margin: 0;
        }
    }
}
.contact-name-letter {
    font-weight: 600;
    color: #5f0f8e;
    font-size: 16px;
    text-transform: uppercase;
    display: inline-block;
}
.cancel-btn {
    a {
        color: #b7b7b7;
        font-weight: 500;
    }
}
.animate-typing-col {
    .dot {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin-right: -1px;
        background: #0d9b35;
        -webkit-animation: wave 1.3s linear infinite;
        animation: wave 1.3s linear infinite;
        opacity: 0.6;
        &:nth-child(2) {
            -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
        }
        &:nth-child(3) {
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
        }
    }
}
.create-group-date {
    color: #000000;
    margin-top: 10px;
    font-size: 13px;
}
.edit-group {
    margin-top: 10px;
    font-size: 13px;
    span {
        color: #ee00ab;
    }
    a {
        color: #6d6d6d;
        font-weight: 500;
        span {
            margin-right: 5px;
        }
    }
}
.custom-input-file {
    position: relative;
    input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 9;
    }
    span.browse-btn {
        position: absolute;
        right: 0;
        top: 0;
        background-color: #420ba1;
        display: inline-block;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        padding: 13px 25px;
        height: 47px;
        border-radius: 1px;
    }
}
.img-edit {
    position: relative;
    margin-top: 10px;
    img {
        width: 80px;
        border-radius: 4px;
    }
    a {
        position: absolute;
        padding: 3px;
        left: 58px;
        font-size: 12px;
        top: 3px;
    }
}
.custom-radio {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked ~ .checkmark {
            background-color: #680a83;
            border-color: #680a83;
            &:after {
                display: block;
            }
        }
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border: 1px solid #ede2ff;
        border-radius: 50%;
        &:after {
            content: "";
            position: absolute;
            display: none;
            top: 5px;
            left: 5px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: white;
        }
    }
    &:hover {
        input ~ .checkmark {
            background-color: #fff;
        }
    }
}
.custom-border-modal {
    .sidebar {
        border: 1px solid #f3ecff;
        border-radius: 10px;
        padding: 15px;
        .user-list {
            li {
                background: #ffffff;
                border: 1px solid #eaeaea;
                padding: 13px 15px;
            }
        }
    }
}
.custom-check {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
            background-color: #420ba1;
            &:after {
                display: block;
            }
        }
    }
    .checkmark {
        position: absolute;
        top: -6px;
        right: 0;
        height: 20px;
        width: 23px;
        border-radius: 8px;
        background-color: #f0f0f0;
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 10px;
            top: 4px;
            width: 5px;
            height: 12px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(65deg);
            -ms-transform: rotate(65deg);
            transform: rotate(65deg);
        }
        &::after {
            left: 8px;
            border-color: #fff;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    &:hover {
        input ~ .checkmark {
            background-color: #f0f0f0;
        }
    }
}
.status-title {
    font-size: 16px;
    font-weight: 600;
    color: #888;
    margin-bottom: 20px;
}
.carousel-indicators {
    li {
        border: 0;
        background-color: #c4c4c4;
        width: 145px;
        height: 5px;
        border-radius: 50px;
    }
    li.active {
        background-color: #f240c0;
    }
}
.status-message {
    display: flex;
    align-items: center;
    width: 770px;
    margin: 50px auto 0;
    .form-control {
        background-color: rgba(24, 28, 47, 0.32);
        border-radius: 50px;
        border: 0;
        height: 52px;
        padding: 10px 20px;
        margin-right: 15px;
        color: #fff;
    }
    .form-buttons {
        .btn.send-btn {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: #fff !important;
            background-color: #ffa977;
            font-size: 22px;
            box-shadow: unset;
        }
    }
}
body.custom-model-open {
    .modal-backdrop.show {
        background-color: rgba(0, 0, 0, 0.4);
    }
}
.modal-content {
    border-radius: 10px;
}
.bottom-message-col {
    max-width: 85%;
    width: 100%;
    margin: -25px auto 0 !important;
    .form_status {
        border-radius: 24px;
        background-color: #d9dadd;
        height: 48px;
        padding: 10px 25px;
        color: #000;
        width: 100%;
        font-size: 14px;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}
.upload-drop-zone {
    height: 150px;
    border-width: 2px;
    margin-bottom: 20px;
    color: #888;
    border-style: dashed;
    border-color: #adb7be;
    border-radius: 10px;
    line-height: 150px;
    text-align: center;
    background-color: #fff;
    height: 150px;
    border-width: 2px;
    margin-bottom: 20px;
    color: #888;
    border-style: dashed;
    border-color: #adb7be;
    line-height: 150px;
    text-align: center;
    background-color: #fff;
}
.call-card {
    border: 0;
    margin-bottom: 30px;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
}
.call-log-header {
    h4 {
        color: #ffa977;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 20px;
    }
    .clear-all {
        color: #8b8d97;
        font-size: 16px;
        font-weight: 600;
    }
}
.call-view-more {
    text-align: center;
    margin-bottom: 30px;
    margin-top: -45px;
    z-index: 9;
    position: relative;
    a {
        display: inline-block;
        background-color: #650681;
        color: #fff;
        padding: 10px 25px;
        border-radius: 32px;
        font-size: 13px;
        font-weight: bold;
    }
}
.callog_chat {
    margin-top: 40px;
}
.missed-call-widget {
    .card-body {
        hr {
            border-color: rgba(77, 77, 77, 0.1);
        }
        h6 {
            font-size: 14px;
        }
        i.missed-col {
            width: 17px;
            display: inline-block;
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
}
.other-call-widget {
    .card-body {
        hr {
            border-color: rgba(77, 77, 77, 0.1);
        }
        h6 {
            font-size: 14px;
        }
        i.incoming-col {
            width: 17px;
            display: inline-block;
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0 auto 30px;
    width: 120px;
    border-radius: 50%;
    cursor: pointer;
    input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}
.upload-icon {
    color: #ffa977;
    background-color: #f2f2f2;
    font-size: 40px;
    width: 120px;
    height: 120px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;
    cursor: pointer;
}
.chat_input {
    border-radius: 6px;
    padding: 8px 8px 8px 35px;
    width: 100%;
    min-height: 47px;
    box-shadow: 0px 4px 4px #f5f8ff;
    border-radius: 5px;
    border: 1px solid #f3f3f3;
    font-size: 13px;
    &::-webkit-input-placeholder {
        color: #9d92a6;
        opacity: 1;
    }
    &::-moz-placeholder {
        color: #9d92a6;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color: #9d92a6;
        opacity: 1;
    }
    &:-moz-placeholder {
        color: #9d92a6;
        opacity: 1;
    }
}
.search_chat {
    position: relative;
    margin: 0px 25px 17px;
}
a.btn.btn-outline-light {
    background: 0 0;
}
.form-control-feedback {
    z-index: 2;
    display: block;
    text-align: center;
    pointer-events: none;
    color: #680a83;
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}
.chat_cal {
    color: #680a83;
}
.members-call {
    .list-inline {
        margin: 0;
    }
    .list-inline-item {
        margin: 0 !important;
        border: 0;
        width: 35px;
        height: 35px;
        margin-right: 7px !important;
        &:last-child {
            margin-right: 0 !important;
        }
        .btn {
            color: #595959;
            padding: 10px;
            font-size: 12px;
            border: 1px solid #595959;
            display: flex;
            display: -webkit-flex;
            width: 35px;
            height: 35px;
        }
    }
}
.group {
    background-color: #fff;
}
.phone_icon {
    transform: rotate(140deg) !important;
    -webkit-transform: rotate(140deg) !important;
}
.group_img_li {
    padding: 0.375rem;
    list-style: none;
}
.avator {
    position: relative;
}
.avator_img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}
.upload-list {
    padding: 0;
    list-style: none;
    margin: 0;
    .file-list {
        &:first-child {
            border-top: none;
        }
        background-color: #fff;
        border-top: 1px solid #d8e2e7;
        padding: 10px;
    }
    .upload-wrap {
        position: relative;
        padding: 0 20px 0 0;
        margin: 0 0 5px;
    }
    .file-name {
        padding-right: 15px;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        i {
            color: #fda75c;
            margin: 0 5px 0 0;
            vertical-align: middle;
        }
    }
    .file-size {
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        color: #888;
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
    }
    .upload-process {
        font-size: 10px;
        color: #888;
    }
    .file-close {
        &:hover {
            color: #f62d51;
        }
        border: none;
        background: none;
        color: #ccc;
        position: absolute;
        right: 0;
        top: 2px;
    }
}
.submit-btn {
    border-radius: 50px;
    color: #fff;
    text-transform: uppercase;
    background-color: #650780 !important;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}
.chat-right {
    .chat-action-btns {
        float: right;
    }
}
.chat-action-btns {
    ul {
        list-style: none;
        padding: 0;
        margin: 15px 15px 0 15px;
        > li {
            display: inline-block;
            margin-left: 5px;
            font-size: 18px;
        }
    }
}
.chats {
    display: block;
    width: 100%;
    float: left;
    margin-bottom: 10px;
}
input {
    transition: all 0.4s ease;
}
button {
    transition: all 0.4s ease;
}
a.del-msg {
    font-size: 15px;
}
a.edit-msg {
    font-size: 15px;
}
a.share-msg {
    font-size: 15px;
}
.chats.chats-right {
    flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
}
i.fas.fa-arrow-left {
    font-size: 16px;
}
.avatar-group.mt-3 {
    margin-left: 80px;
}
.chatlive {
    filter: blur(32px);
}
i.fas.fa-search.ellipse_header {
    color: #ffa977;
}
i.fas.fa-ellipsis-h.ellipse_header {
    color: #ffa977;
}
.arrow {
    color: #fff;
}
.send-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
    color: #fff !important;
    background-color: #ffa977;
}
.chat_status {
    display: flex;
    display: -webkit-flex;
}
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    color: #fff;
    text-align: center;
    opacity: 1;
    transition: opacity 0.15s ease;
    right: -15px;
}
.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    color: #fff;
    text-align: center;
    opacity: 1;
    transition: opacity 0.15s ease;
    left: -15px;
}
.text_carousel {
    line-height: 4;
}
.send-btn1 {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    background-color: #ffa977;
}
.call-box {
    .call-wrapper {
        height: auto;
        text-align: center;
        .call-user {
            margin-bottom: 30px;
            h4 {
                font-weight: 500;
                font-size: 20px;
                color: #686868;
            }
            span {
                display: inline-block;
                text-align: center;
                font-weight: 600;
                color: #10e599;
            }
            span + span {
                margin-left: 5px;
            }
        }
        .call-avatar {
            margin-bottom: 30px;
            cursor: pointer;
        }
        .call-items {
            .call-item.call-start {
                background: #10e599;
                color: #fff;
                line-height: 0;
                border-radius: 100%;
                width: 60px;
                height: 60px;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: center;
                -webkit-justify-content: center;
                cursor: pointer;
                font-size: 18px;
            }
            .call-item.call-end {
                background: #10e599;
                color: #fff;
                line-height: 0;
                border-radius: 100%;
                width: 60px;
                height: 60px;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: center;
                -webkit-justify-content: center;
                cursor: pointer;
                font-size: 18px;
                background: #fd6286;
            }
        }
    }
    .call-avatar {
        border-radius: 100%;
        position: relative;
        border: 7px solid rgba(66, 11, 161, 0.1);
        height: 6.1rem;
        width: 6.1rem;
    }
}
.call-wrapper {
    position: relative;
    position: relative;
    height: calc(100vh - 145px);
    .call-items {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        -webkit-justify-content: center;
    }
}
.call-modal {
    .modal-content {
        border: 0;
        border-radius: 10px;
    }
}
.call-box.incoming-box {
    .call-wrapper {
        .call-items {
            .call-item.call-start {
                margin: 0 10px;
                -webkit-animation: pulse-secondary 2s infinite;
                animation: pulse-secondary 2s infinite;
                -webkit-box-shadow: 0 0 0 rgba(16, 229, 153, 0.3);
                box-shadow: 0 0 0 rgba(16, 229, 153, 0.3);
            }
            .call-item.call-end {
                margin: 0 10px;
                .material-icons {
                    font-size: 30px;
                }
            }
        }
    }
}
.voice_content {
    border: 0;
    border-radius: 0px !important;
}
.voice_body {
    padding: 50px 0;
}
.voice_chat_phone {
    font-size: 14px;
}
.call-view {
    display: table-cell;
    height: 100%;
    float: none;
    padding: 0;
    position: static;
    vertical-align: top;
    width: 75%;
    display: table-cell;
    height: 100%;
    float: none;
    padding: 0;
    position: static;
    vertical-align: top;
    width: 75%;
}
.user-img {
    display: inline-block;
    position: relative;
    display: inline-block;
    position: relative;
}
.last-seen {
    color: #888;
    display: block;
    font-size: 12px;
}
.voice-call-avatar {
    .call-avatar {
        margin: 15px;
        width: 150px;
        height: 150px;
        border-radius: 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 3px;
        background-color: #fff;
    }
    .username {
        font-size: 20px;
        font-weight: 500;
    }
    .call-timing-count {
        padding: 5px;
    }
}
.call-icons {
    .call-items {
        border-radius: 5px;
        padding: 0;
        margin: 0;
        list-style: none;
        display: inline-block;
        border-radius: 5px;
        padding: 0;
        margin: 0;
        list-style: none;
        display: inline-block;
        .call-item {
            display: inline-block;
            text-align: center;
            margin-right: 5px;
            a {
                color: #fff;
                border: 1px solid #ddd;
                width: 50px;
                height: 50px;
                line-height: 50px;
                border-radius: 50px;
                display: inline-block;
                font-size: 20px;
                background-color: #9c27b0;
                i {
                    width: 18px;
                    height: 18px;
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    text-align: center;
    position: relative;
}
.end-call {
    position: absolute;
    top: 7px;
    right: 0;
    a {
        background-color: #f06060;
        border-radius: 50px;
        color: #fff;
        display: inline-block;
        line-height: 10px;
        padding: 8px 25px;
        text-transform: uppercase;
    }
}
.voice_header {
    width: 2.5rem !important;
    height: 2.5rem !important;
}
// .container-fluid {
//     padding-left: 30px;
//     padding-right: 30px;
// }
.bottom-message-col.chat {
    display: block;
    .chat_form {
        box-shadow: none;
    }
    .chat-footer {
        position: static;
        background-color: transparent;
    }
}
.status_content {
    z-index: 4000;
    position: fixed;
    top: 40%;
    left: 60%;
}
.status_content_h3 {
    z-index: 4000;
    position: fixed;
    top: 65%;
    left: 45%;
}
.call-duration {
    display: inline-block;
    font-size: 30px;
    margin-top: 4px;
    position: absolute;
    left: 0;
}
.submit-section {
    text-align: center;
    margin-top: 40px;
}
.video-screen {
    .chat-header {
        margin-left: 15px;
    }
    .chat-body {
        margin-left: 15px;
    }
    .chat-options {
        .in-a-call {
            span.icon-call {
                width: 34px;
                height: 30px;
                background-color: #fd6286;
                color: #fff;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: center;
                -webkit-justify-content: center;
                border-radius: 4px;
            }
            span.call-text {
                color: #fd6286;
                font-weight: 600;
                margin-left: 8px;
                display: inline-block;
            }
        }
        .add-person-call {
            span.icon-call {
                width: 34px;
                height: 30px;
                background-color: #fd6286;
                color: #fff;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: center;
                -webkit-justify-content: center;
                border-radius: 4px;
                background-color: #00a389;
            }
            span.call-text {
                color: #fd6286;
                font-weight: 600;
                margin-left: 8px;
                display: inline-block;
                color: #00a389;
            }
            height: auto !important;
            width: auto !important;
        }
    }
}
.video-screen-inner {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: calc(100vh - 130px);
    border-radius: 10px;
    padding: 20px;
    position: relative;
}
.video-screen-inner.blur-bg {
    z-index: 1;
    &:after {
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
}
.chat.video-screen {
    .chat-header {
        .chat-options {
            margin-top: 0;
        }
    }
}
.call-user-avatar {
    width: 110px;
    height: 117px;
    position: absolute;
    left: 20px;
    top: 20px;
    .avatar-col {
        border-radius: 10px;
        border: 2px solid #fff;
        img {
            max-width: 100%;
            border-radius: 10px;
        }
    }
}
.record-time {
    position: absolute;
    top: 20px;
    right: 20px;
    span {
        background-color: rgba(250, 250, 250, 0.3);
        display: inline-block;
        border-radius: 130px;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        width: 140px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        position: relative;
        padding-left: 15px;
        &:before {
            content: "";
            position: absolute;
            left: 17px;
            top: 15px;
            background-color: #fff;
            width: 20px;
            height: 20px;
            border-radius: 50px;
        }
        &:after {
            content: "";
            position: absolute;
            left: 22px;
            top: 20px;
            background-color: #ff0000;
            width: 10px;
            height: 10px;
            border-radius: 50px;
            animation: blink-animation 1.5s steps(5, start) infinite;
            -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
        }
    }
}
.volume-col {
    position: absolute;
    left: 20px;
    bottom: 20px;
    .inner-volume-col {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 130px;
        width: 60px;
        height: 150px;
        position: relative;
        z-index: 9;
    }
    #volume {
        position: absolute;
        left: 50%;
        top: 15px;
        margin: 0 auto;
        height: 80px;
        width: 5px;
        background: rgba(196, 196, 196, 0.8);
        border-radius: 15px;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        .ui-slider-range-min {
            height: 5px;
            width: 100%;
            position: absolute;
            bottom: 0;
            background: #fff;
            border: none;
            border-radius: 10px;
            outline: none;
        }
        .ui-slider-handle {
            width: 20px;
            height: 20px;
            border-radius: 20px;
            background: #fff;
            position: absolute;
            left: 50%;
            margin-top: -8px;
            cursor: pointer;
            outline: none;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
        }
    }
    #player {
        width: auto;
        height: 100px;
        position: relative;
        margin: 0 auto;
        top: 20px;
        i {
            position: absolute;
            margin-top: -6px;
            color: #666;
        }
    }
    .material-icons {
        color: #fff;
        margin-left: 0px;
        font-size: 18px;
        margin-top: 22px;
    }
}
.video-call-action {
    width: 100%;
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: center;
        li {
            display: inline-block;
            margin: 0 4px;
            a {
                width: 50px;
                height: 50px;
                border-radius: 50px;
                background-color: rgba(106, 106, 106, 0.3);
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: center;
                -webkit-justify-content: center;
                color: #fff;
                .material-icons {
                    font-size: 24px;
                }
            }
            a.call-end {
                background-color: #ff0000;
            }
            a.call-mute {
                background-color: #0057ff;
            }
        }
    }
}
.video-group-member {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            line-height: 0;
        }
    }
    .call-user-avatar {
        position: relative;
        left: inherit;
        width: 80px;
        height: 87px;
    }
    a.call-action-group.call-mute {
        background-color: #0057ff;
    }
}
.call-action-group {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    background-color: rgba(106, 106, 106, 0.3);
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    color: #fff;
    position: absolute;
    right: 8px;
    bottom: 12px;
    &:hover {
        color: #fff;
    }
    &:focus {
        color: #fff;
    }
    .material-icons {
        font-size: 12px;
    }
}
.status-text {
    position: absolute;
    max-width: 424px;
    width: 100%;
    margin: 0 auto;
    line-height: 1.5;
    left: 50%;
    bottom: 30px;
    text-align: center;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    p {
        color: #fff;
        margin-bottom: 0;
    }
}
.status_update {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
}
.status_update + div {
    -webkit-filter: blur(32px);
    -moz-filter: blur(32px);
    -ms-filter: blur(32px);
    -o-filter: blur(32px);
    filter: blur(32px);
}
.mCSB_scrollTools {
    opacity: 1 !important;
    .mCSB_draggerRail {
        background-color: rgb(206, 212, 218);
    }
    .mCSB_dragger {
        .mCSB_dragger_bar {
            background-color: #680a83;
        }
        &:active {
            .mCSB_dragger_bar {
                background-color: #680a83;
            }
        }
        &:hover {
            .mCSB_dragger_bar {
                background-color: #680a83;
            }
        }
    }
    .mCSB_dragger.mCSB_dragger_onDrag {
        .mCSB_dragger_bar {
            background-color: #680a83;
        }
    }
}
.account-page {
    background-color: #fff;
    padding-left: 0;
    .content {
        padding: 0;
        width: 100%;
    }
}
.login-header {
    padding-bottom: 40px;
    p {
        margin-bottom: 0;
    }
    h3 {
        font-size: 22px;
        margin-bottom: 3px;
        font-weight: 500;
        text-align: center;
        span {
            font-size: 18px;
        }
        a {
            color: #0de0fe;
            float: right;
            font-size: 15px;
            margin-top: 2px;
        }
    }
}
.login-right {
    .dont-have {
        color: #3d3d3d;
        margin-top: 20px;
        font-size: 14px;
        a {
            color: #680a83;
        }
    }
    float: left;
    background-color: #4b0973;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 60%;
    height: 100vh;
}
.login-btn {
    font-size: 18px;
    font-weight: 500;
}
.login-or {
    color: #555;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    position: relative;
}
.or-line {
    background-color: #d9d9d9;
    height: 1px;
    margin-bottom: 0;
    margin-top: 0;
    display: block;
}
.span-or {
    background-color: #fff;
    display: block;
    left: 50%;
    margin-left: -20px;
    position: absolute;
    text-align: center;
    top: -3px;
    width: 42px;
}
.forgot-link {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    color: #ee00ab;
    a {
        color: #ee00ab;
        font-weight: 600;
    }
}
.btn-facebook {
    background-color: #3a559f;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    width: 100%;
    display: block;
}
.btn-google {
    background-color: #dd4b39;
    color: #fff;
    font-size: 14px;
    padding: 10px 12px;
    width: 100%;
    display: block;
}
.social-login {
    .btn {
        &:hover {
            color: #fff;
        }
        &:focus {
            color: #fff;
        }
    }
}
.dont-have {
    a {
        color: #ee00ab;
        font-weight: 600;
    }
}
.back-btn-col {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    display: -webkit-flex;
    align-items: flex-end;
    -webkit-align-items: flex-end;
    justify-content: center;
    -webkit-justify-content: center;
    a {
        color: #000;
        width: 124px;
        height: 37px;
        background: #f9f9f9;
        border-radius: 130px;
        line-height: 37px;
    }
    span {
        margin-right: 5px;
        display: inline-block;
    }
}
.form-col {
    width: 100%;
}
.login-left {
    float: left;
    width: 40%;
    height: 100vh;
    overflow-y: auto;
}
.login-text-details {
    padding-bottom: 25px;
    h3 {
        font-size: 20px;
        color: #4b0973;
        font-weight: 600;
        margin-bottom: 20px;
    }
    p {
        color: #000;
        line-height: 1.5;
    }
}
.account-content {
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0;
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    height: 100%;
    .form-group {
        label {
            font-weight: 600;
            color: #464646;
        }
        label.custom-check {
            font-weight: 400;
            padding-left: 32px;
            font-size: 12px;
            a {
                font-weight: 700;
                color: #0057ff;
            }
        }
        .group_formcontrol {
            background: #ffffff;
            border: 1px solid #ede2ff;
            border-radius: 2px;
        }
        .newgroup_create {
            border-radius: 2px;
        }
        .custom-check {
            .checkmark {
                left: 0;
                top: 0;
                right: inherit;
                background-color: #fff;
                border: 1px solid #ede2ff;
                border-radius: 2px;
                height: 23px;
            }
        }
    }
}
.otp-box {
    .digit-group {
        input {
            width: 54px;
            height: 49px;
            background-color: #fcfcfc;
            border: none;
            text-align: center;
            font-size: 16px;
            color: white;
            margin: 0 15px;
            border-radius: 2px;
            color: #000;
            border: 1px solid #ede2ff;
            &:focus {
                background-color: #fff;
                outline: 0;
            }
        }
    }
    .splitter {
        padding: 0 5px;
        color: white;
        font-size: 24px;
    }
    .prompt {
        margin-bottom: 20px;
        font-size: 20px;
        color: white;
    }
    input[type="text"].active {
        background-color: #fff;
        color: #000;
    }
}
.device-details {
    padding-right: 15px;
    h5 {
        font-weight: 700;
        color: #420ba1;
        font-size: 16px;
        margin-bottom: 0.25rem;
    }
    p {
        font-weight: 500;
        color: #9b9b9b;
    }
}
.settings-option {
    display: none;
    padding: 0px 25px 15px;
    text-align: right;
    a {
        color: #5a078b;
        font-weight: 600;
    }
}
.settings-delete {
    .btn-delete {
        float: right;
    }
    h5 {
        color: #5a078b;
        font-size: 18px;
        font-weight: 600;
    }
    p {
        color: #949494;
        font-size: 12px;
    }
}
.success-icon {
    padding: 60px 0;
    p {
        font-weight: 500;
        font-size: 16px;
    }
    i {
        background: #49e073;
        color: #fff;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        font-size: 20px;
        line-height: 45px;
    }
    .close-icon {
        background: #ff0000;
    }
}
.close-modal {
    span {
        color: #939393;
        cursor: pointer;
    }
}
.terms-card {
    background: #ffffff;
    border: 1px solid #ede2ff;
    border-radius: 10px;
    padding: 15px;
}
.close-btn {
    padding: 8px 50px;
}
.chat-scroll {
    display: inline-flex;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    overflow-y: hidden;
    flex-wrap: wrap-reverse;
    flex-flow: nowrap;
    .nav-link {
        color: #0057ff;
        border: 1px solid #0057ff;
        border-radius: 40px;
        font-size: 12px;
        font-weight: 600;
        padding: 5px 10px;
        display: inline-table;
        margin-right: 5px;
        margin-bottom: 5px;
        white-space: nowrap;
        &:hover {
            background: #420ba1;
            border: 1px solid #420ba1;
            border-radius: 40px;
            color: #fafbff;
        }
    }
    .nav-link.active {
        background: #420ba1;
        border: 1px solid #420ba1;
        border-radius: 40px;
        color: #fafbff;
    }
}
.chat-audio {
    p {
        color: #444444;
        font-weight: 700;
    }
}
.text-highlight {
    color: #5a078b;
    font-weight: 700;
}
.blur-img {
    filter: blur(2px);
    -webkit-filter: blur(2px);
}
.tab-content.settings-form {
    padding-top: 0px;
}
.incoming-icon {
    color: #49e073;
    font-size: 22px;
}
.outgoing-icon {
    color: #49e073;
    font-size: 22px;
}
.videocall-icon {
    color: #8345a8;
    font-size: 22px;
}
.calling-icon {
    color: #fd6286;
    font-size: 22px;
}
.callmissed-icon {
    color: #ff0000;
    font-size: 22px;
}
.side_bar {
    position: sticky;
    top: 0;
    overflow-x: scroll;
    scrollbar-color: #c3c3c3 transparent;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: rgb(234 234 234);
        border-radius: 10px;
        &:hover {
            background: #c3c3c3;
        }
    }
}
.logout-btn {
    background: #ee00ab;
    border-radius: 6px;
    color: #fff;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 500;
    &:focus {
        outline: 0;
        box-shadow: none;
    }
    &:hover {
        background: #5a078b;
        color: #fff;
        outline: 0;
        box-shadow: none;
    }
}
.profile-cover-avatar {
    display: -ms-flexbox;
    display: flex;
    margin: auto;
    border-radius: 50%;
    position: relative;
    width: 7rem;
    height: 7rem;
    .avatar-img {
        display: block;
        max-width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        pointer-events: none;
        border-radius: 50%;
    }
    input[type="file"] {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(19, 33, 68, 0.25);
        border-radius: 50%;
        transition: 0.2s;
    }
}
.avatar-edit {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    background-color: #5a078b;
    border-radius: 50%;
    transition: 0.2s;
    svg {
        width: 15px;
        height: 15px;
    }
}
.profile-email {
    color: #9b9b9b;
    font-size: 13px;
}
.profile-country {
    color: #ee00ab;
    font-size: 1rem;
}
.profile-card {
    margin: 0px 25px 17px;
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #f3f3f3;
}
.profile-info {
    background: #fafbff;
    padding: 15px;
}
.info-title {
    color: #5a078b;
    font-weight: 600;
}
.info-text {
    font-weight: 500;
}
.social-nav {
    li {
        list-style: none;
        display: inline-block;
        padding-right: 15px;
        &:last-child {
            padding-right: 0;
        }
        a {
            i {
                font-size: 16px;
                color: #420ba1;
                &:hover {
                    color: #ee00ab;
                }
            }
        }
    }
}
.settings-card {
    margin: 0px 25px 17px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #f3f3f3;
}
.settings-control {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            color: #5a078b;
            font-weight: 600;
            border-bottom: 1px solid #fafbff;
            padding: 15px;
        }
    }
    .switch {
        position: relative;
        display: inline-block;
        width: 35px;
        height: 17px;
        margin-bottom: 0;
        input {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &:before {
            position: absolute;
            content: "";
            height: 10px;
            width: 10px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
        }
    }
    input {
        &:checked + .slider {
            background-color: #2196f3;
            &:before {
                -webkit-transform: translateX(16px);
                -ms-transform: translateX(16px);
                transform: translateX(16px);
            }
        }
        &:focus + .slider {
            box-shadow: 0 0 1px #2196f3;
        }
    }
    .slider.round {
        border-radius: 34px;
        &:before {
            border-radius: 50%;
        }
    }
    border: 1px solid #f9f9f9;
}
.btn-update {
    background-color: #420ba1;
    border-color: #420ba1;
    color: #fff;
    padding: 12px 18px;
    border-radius: 10px;
    &:hover {
        background-color: transparent;
        border-color: #420ba1;
        color: #420ba1;
    }
    &:focus {
        outline: 0;
    }
}
.btn-delete {
    &:focus {
        outline: 0;
    }
    background-color: #ee00ab;
    border-color: #ee00ab;
    color: #fff;
    padding: 12px 18px;
    border-radius: 10px;
    &:hover {
        background-color: transparent;
        border-color: #ee00ab;
        color: #ee00ab;
    }
}
.settings-footer {
    ul {
        li {
            a {
                i {
                    font-size: 12px;
                }
                color: #008024;
            }
            list-style: none;
            padding: 15px 15px 0;
        }
    }
}
.right-sidebar.full-width {
    width: 900px;
    background: #fff;
    position: relative;
}
.page-header {
    padding: 12px 15px;
    align-items: center;
    box-shadow: 0px 4px 4px #f5f8ff;
    border-radius: 5px;
    background: #ffffff;
    border: 1px solid #f4eeff;
    p {
        font-size: 13px;
    }
    h5 {
        font-weight: 700;
        color: #420ba1;
        margin-bottom: 0.25rem;
    }
}
.full-width {
    .right-sidebar-wrap {
        border: 0;
    }
}
.settings-tab {
    .nav-tabs {
        .nav-link.active {
            color: #0057ff;
            font-weight: 500;
            border-color: #0057ff;
        }
        .nav-link {
            border: 0;
            color: #000;
            border-bottom: 2px solid transparent;
            padding: 3px 9px;
            font-size: 15px;
            font-weight: 500;
            margin-right: 30px;
        }
    }
    .nav.nav-tabs {
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 1.5rem;
    }
}
.form-body {
    .form-group {
        label {
            font-weight: 600;
            color: #464646;
        }
    }
    input {
        &::-webkit-input-placeholder {
            color: #000000;
        }
        &:-ms-input-placeholder {
            color: #000000;
        }
        &::placeholder {
            color: #000000;
        }
    }
}
.social-settings {
    h4 {
        color: #5a078b;
        font-size: 18px;
        font-weight: 600;
    }
}
.settings-header {
    background: #f9f9f9;
    padding: 15px;
    h5 {
        color: #5a078b;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 7px;
    }
    p {
        color: #949494;
        font-size: 12px;
    }
}
.settings-control.full-options {
    padding: 0 0 25px;
    ul {
        li {
            color: #515151;
            font-weight: 500;
            border-bottom: 0;
            padding: 25px 20px 0;
        }
    }
}
.logged-btn {
    a {
        color: #ee00ab;
        font-weight: 500;
        display: inline-block;
    }
}
.logged-devices-settings {
    border: 1px solid #f9f9f9;
    padding: 20px;
}
.logged-device {
    padding: 12px 15px;
    box-shadow: 0px 4px 4px #f5f8ff;
    border-radius: 5px;
    background: #ffffff;
    border: 1px solid #f4eeff;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
.password-updation {
    padding: 20px;
}
.requirment-card {
    padding: 25px;
    background: rgba(237, 226, 255, 0.1);
    h4 {
        font-size: 16px;
        font-weight: 600;
        color: #420ba1;
    }
}
.requirements-list {
    p {
        margin-bottom: 0.5rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.authentication {
    .custom-radio {
        color: #b9b9b9;
        .checkmark {
            height: 15px;
            width: 15px;
            margin-top: 3px;
            background-color: #eeeeee;
            border: 1px solid #eeeeee;
            &:after {
                top: 3px;
                left: 3px;
                width: 7px;
                background: #ee00ab;
            }
        }
        span {
            a {
                color: #ee00ab;
                font-weight: 500;
            }
        }
        input {
            &:checked ~ .checkmark {
                background-color: #fff;
                border-color: #eeeeee;
            }
        }
    }
    h4 {
        color: #420ba1;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 1rem;
    }
    padding: 20px;
}
.security-settings {
    border: 1px solid #f9f9f9;
}
.success-icon.delete-tab {
    padding: 40px 0;
    p {
        font-size: 20px;
    }
}
p {
    &:last-child {
        margin-bottom: 0;
    }
}
.darkmode {
    background: #1d262e;
    color: #e5eeff;
    .chat {
        .chat-header {
            background: #36404a;
            border: 1px solid #36404a;
            box-shadow: none;
            .user-details {
                h5 {
                    color: #e5eeff;
                }
            }
            .chat-options {
                ul {
                    > li {
                        > a {
                            color: #c8c8d8;
                            border-color: #36404a;
                            background-color: #36404a;
                            &:not(.no-bg) {
                                &:hover {
                                    background-color: #c8c8d8;
                                    border-color: #c8c8d8;
                                    color: #36404a;
                                }
                            }
                        }
                    }
                }
            }
        }
        .chat-body {
            .messages {
                .chats {
                    .message-content {
                        background-color: #8480ff;
                    }
                }
                .chat-profile-name {
                    h6 {
                        color: #e5eeff;
                    }
                }
                .chats.chats-right {
                    .message-content {
                        background-color: #485563;
                        color: #e5eeff;
                        .chat-time {
                            .time {
                                i {
                                    color: #fd6286;
                                }
                            }
                        }
                    }
                    .chat-action-btns {
                        .chat-read-col {
                            color: #0dd845;
                        }
                    }
                }
                .file-download {
                    background: #303841;
                    .file-details {
                        span.file-name {
                            color: #f4f4fa;
                        }
                    }
                    .download-action {
                        a {
                            color: #e5eeff;
                        }
                    }
                }
            }
        }
        .chat-footer {
            form {
                .form-control {
                    &::-webkit-input-placeholder {
                        color: #e5eeff;
                        opacity: 1;
                    }
                    &::-moz-placeholder {
                        color: #e5eeff;
                        opacity: 1;
                    }
                    &:-ms-input-placeholder {
                        color: #e5eeff;
                        opacity: 1;
                    }
                    &:-moz-placeholder {
                        color: #e5eeff;
                        opacity: 1;
                    }
                }
                .attach-col {
                    i {
                        color: #e5eeff;
                    }
                }
                .smile-col {
                    i {
                        color: #e5eeff;
                    }
                }
                .specker-col {
                    span {
                        color: #e5eeff;
                    }
                }
            }
        }
    }
    .online {
        color: #0dd845;
    }
    .chat-action-col {
        > a {
            color: #e5e5e5;
        }
    }
    .dropdown-menu {
        background: #313a43;
        border: 1px solid #3e4851;
        box-shadow: none;
        .dropdown-item {
            color: #d8e1f2;
            span {
                color: #e5eeff;
            }
        }
    }
    .sidebar-menu {
        background-color: #36404a;
    }
    .chat-menus {
        ul {
            li {
                a {
                    background: #36404a;
                    border: 1px solid #4d5a66;
                    span.material-icons {
                        color: #e5eeff;
                    }
                }
            }
        }
        > ul {
            > li {
                > a {
                    > span {
                        &:before {
                            border-right: 7px solid #4d5a66;
                        }
                        background-color: #36404a;
                        box-shadow: none;
                    }
                }
            }
        }
    }
    .main-wrapper {
        .content {
            .sidebar-group {
                .sidebar {
                    background: #303841;
                }
            }
        }
    }
    .chat_input {
        border: 1px solid #4d5a66;
        box-shadow: none;
        background: #36404a;
    }
    .chat-title {
        h4 {
            color: #e5eeff;
        }
    }
    .add-section {
        ul {
            li {
                a {
                    border: 1px solid #3e4a56;
                    background-color: #3e4a56;
                    box-shadow: none;
                    color: #e5eeff;
                }
            }
        }
    }
    .form-control-feedback {
        color: #9d92a6;
    }
    .top-contacts-box {
        background-color: #36404a;
        box-shadow: none;
    }
    .profile-name {
        span {
            color: #e5eeff;
        }
        color: #e5eeff;
    }
    .sidebar {
        .user-list {
            li {
                background: #36404a;
                border: 1px solid #4d5a66;
                box-shadow: none;
                .users-list-body {
                    h5 {
                        color: #e5eeff;
                    }
                    p {
                        color: #ffffff;
                    }
                    .last-chat-time {
                        .new-message-count {
                            background-color: #fd6286;
                        }
                    }
                }
            }
        }
    }
    .chat-line {
        &:before {
            background-color: #36404a;
        }
    }
    .chat-date {
        background-color: #36404a;
        color: #ede2ff;
    }
    .chat_form {
        background: #36404a;
        border: 1px solid #4d5a66;
        box-shadow: none;
    }
    .form-control {
        color: #e5eeff;
    }
    .right-sidebar {
        background: #303841;
        .right-sidebar-wrap {
            background-color: #36404a;
            border: 1px solid #4d5a66;
            .right-sidebar-profile {
                background-color: #4d5a66;
            }
        }
    }
    .sidebar-body {
        .profile-name {
            color: #e5eeff;
        }
    }
    .online-profile {
        span {
            color: #e5eeff;
        }
    }
    .about-media-tabs {
        .nav-tabs {
            .nav-link.active {
                color: #fd6286;
                border-color: #fd6286;
                background: transparent;
            }
            .nav-link {
                color: #e5eeff;
            }
        }
        p {
            color: #e5eeff;
        }
        .member-details {
            ul {
                li {
                    h6 {
                        color: #e5eeff;
                    }
                }
            }
        }
    }
    .social-media-col {
        h6 {
            color: #e5eeff;
        }
        ul {
            li {
                a {
                    color: #fd6286;
                }
            }
        }
    }
    .settings-col {
        h6 {
            color: #e5eeff;
        }
        input {
            &:checked + .slider {
                background-color: #fd6286;
            }
        }
        .slider {
            &:before {
                background-color: #36404a;
            }
        }
    }
    .report-col {
        ul {
            li {
                a {
                    color: #fd6286;
                }
            }
        }
    }
    .bottom-menus {
        > ul {
            > li {
                > a {
                    > span {
                        &:before {
                            border-right: 7px solid #4d5a66;
                        }
                        background-color: #36404a;
                        box-shadow: none;
                    }
                }
            }
        }
        ul {
            li {
                a {
                    span.material-icons {
                        color: #e5eeff;
                    }
                }
            }
        }
    }
    .modal-header {
        background-color: #0057ff;
        color: #e5e5e5;
        .close {
            color: #0057ff;
            background-color: #fff;
        }
        .modal-title {
            span.material-icons {
                color: #e5eeff;
            }
        }
    }
    .modal-body {
        background-color: #303841;
        .form-group {
            label {
                color: #e5eeff;
            }
        }
    }
    .modal-content {
        background-color: #303841;
    }
    .group_formcontrol {
        background: #36404a;
        border: 1px solid #4d5a66;
    }
    .newgroup_create {
        background-color: #0057ff;
        border-color: #0057ff;
    }
    .custom-input-file {
        span.browse-btn {
            background-color: #0057ff;
        }
    }
    .custom-radio {
        input {
            &:checked ~ .checkmark {
                background-color: #e5eeff;
                border-color: #e5eeff;
            }
        }
        .checkmark {
            &:after {
                background: #ee00ab;
            }
        }
    }
    .custom-border-modal {
        .sidebar {
            border: 1px solid #4d5a66;
        }
    }
    .newgroup_create.previous {
        background-color: #fd6286;
        border-color: #fd6286;
    }
    .custom-check {
        input {
            &:checked ~ .checkmark {
                background-color: #fd6286;
            }
        }
    }
    .contact-name-letter {
        color: #e5eeff;
    }
    .create-group-date {
        color: #e5eeff;
    }
    .edit-group {
        a {
            color: #e5eeff;
        }
    }
    .participants-list {
        ul {
            li {
                box-shadow: none;
                background: #36404a;
                border: 1px solid #4d5a66;
            }
        }
        .users-list-body {
            h5 {
                color: #e5eeff;
            }
            p {
                color: #ffffff;
            }
        }
        .admin-profiles {
            color: #49e073;
            border: 1px solid #49e073;
        }
    }
    .load-more-btn {
        color: #fd6286;
        i {
            background: #fd6286;
        }
    }
    .status-right {
        color: #e5eeff;
    }
    .profile-card {
        background: #36404a;
        border: 0;
    }
    .avatar-edit {
        background-color: #fd6286;
    }
    .profile-email {
        color: #ede2ff;
    }
    .profile-country {
        color: #e5eeff;
    }
    .profile-info {
        background: #4d5a66;
    }
    .info-title {
        color: #e5eeff;
    }
    .social-nav {
        li {
            a {
                i {
                    color: #fd6286;
                }
            }
        }
    }
    .settings-card {
        background: #36404a;
        border: 1px solid #4d5a66;
    }
    .settings-control {
        border: 1px solid #4d5a66;
        ul {
            li {
                color: #e5eeff;
                border-bottom: 1px solid #4d5a66;
            }
        }
        input {
            &:checked + .slider {
                background-color: #fd6286;
            }
        }
        .slider {
            &:before {
                background-color: #36404a;
            }
        }
    }
    .settings-footer {
        ul {
            li {
                a {
                    color: #49e073;
                }
            }
        }
    }
    .btn-update {
        background-color: #ee00ab;
        border-color: #ee00ab;
        &:hover {
            color: #ee00ab;
            border-color: #ee00ab;
            background: transparent;
        }
    }
    .page-header {
        box-shadow: none;
        background: #36404a;
        border: 1px solid #4d5a66;
        h5 {
            color: #e5eeff;
        }
        p {
            color: #49e073;
        }
    }
    .settings-tab {
        .nav-tabs {
            .nav-link.active {
                color: #fd6286;
                border-color: #fd6286;
                background: transparent;
            }
            .nav-link {
                color: #e5eeff;
            }
        }
        .nav.nav-tabs {
            border-bottom: 1px solid #36404a;
        }
    }
    .settings-header {
        background: #3a4550;
        p {
            color: #e5eeff;
        }
        h5 {
            color: #e5eeff;
        }
    }
    .form-body {
        .form-group {
            label {
                color: #e5eeff;
            }
        }
    }
    .social-settings {
        h4 {
            color: #e5eeff;
        }
    }
    .settings-delete {
        h5 {
            color: #e5eeff;
        }
    }
    .settings-control.full-options {
        ul {
            li {
                border-bottom: 0;
            }
        }
    }
    .logged-device {
        box-shadow: none;
        background: #36404a;
        border: 1px solid #4d5a66;
    }
    .device-details {
        h5 {
            color: #e5eeff;
        }
        p {
            color: #9b9b9b;
        }
    }
    .requirment-card {
        h4 {
            color: #e5eeff;
        }
    }
    .authentication {
        h4 {
            color: #e5eeff;
        }
    }
    .left-chat-title.with-bg {
        background-color: #4d5a66;
    }
    .group-call-tab {
        .nav-tabs {
            .nav-link.active {
                background-color: #fd6286;
                color: #dfe2e5;
            }
            .nav-link {
                color: #e5eeff;
            }
        }
    }
    .video-screen {
        .chat-options {
            .add-person-call {
                span.call-text {
                    color: #49e073;
                }
                span.icon-call {
                    background-color: #49e073;
                }
            }
        }
    }
    .search_chat {
        .form-control {
            &::placeholder {
                color: #9d92a6;
            }
        }
    }
    .form-group {
        .form-control {
            &::placeholder {
                color: #e5eeff;
            }
        }
    }
    .logged-devices-settings {
        border: 1px solid #4d5a66;
    }
    .security-settings {
        border: 1px solid #4d5a66;
    }
    .chat-scroll {
        .nav-link.active {
            background: #fd6286;
            border: 1px solid #fd6286;
        }
        .nav-link {
            color: #ffffff;
            border: 1px solid #f9f4f4;
            &:hover {
                background: #fd6286;
                border: 1px solid #fd6286;
            }
        }
    }
    .text-highlight {
        color: #fd6286;
    }
    .chat-search {
        input[type="text"] {
            border: 1px solid #4d5a66;
            background-color: #36404a;
        }
    }
    .darkmode-btn {
        background-color: #36404a;
        border: 1px solid #4d5a66;
    }
    .lock-icon {
        color: #fd6286;
    }
    .settings-option {
        a {
            color: #ee00ab;
        }
    }
}
@media screen and (max-width: 1200px) {
    .profile_wrap {
        width: 320px !important;
    }
    .status_content_h3 {
        font-size: 24px;
    }
    .sidebar-group.mobile-open {
        opacity: 1;
        right: 0;
        visibility: visible;
    }
    .right-sidebar {
        margin-right: -320px;
    }
    .right-sidebar.video-right-sidebar {
        margin-right: -400px;
    }
    .login-left {
        width: 50%;
    }
    .login-right {
        width: 50%;
    }
    .account-content {
        max-width: 300px;
    }
    .settings-tab {
        .nav-tabs {
            .nav-link {
                margin-right: 20px;
            }
        }
    }
}
@media screen and (max-width: 991.98px) {
    body {
        font-size: 13px;
        .sidebar-group {
            .sidebar {
                .list-group-item {
                    .users-list-body {
                        h5 {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    .main-wrapper {
        .content {
            flex-flow: column wrap;
            .sidebar-group {
                width: 100%;
                .sidebar {
                    width: 100%;
                }
            }
        }
    }
    .chat {
        display: none;
        width: 100%;
        .chat-header {
            padding: 15px 15px 10px;
            .chat-options {
                margin-top: 15px;
                margin-left: 35px;
            }
        }
        .chat-body {
            padding: 20px 15px 58px;
            .messages {
                .chats {
                    margin-bottom: 1rem;
                    max-width: 100%;
                }
            }
        }
        .chat-footer {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: #fff;
            padding: 10px 15px;
            height: 58px;
            top: inherit;
            padding-left: 75px;
        }
    }
    .right-sidebar {
        display: none;
        width: 100%;
        width: 100%;
        padding-right: 0;
        .sidebar-body {
            .pl-4 {
                padding: 0 !important;
            }
            .pr-4 {
                padding: 0 !important;
            }
        }
    }
    .sidebar-body {
        padding: 10px 15px;
    }
    .header {
        padding: 15px 15px;
        height: 130px;
        overflow: initial;
        background-color: #650681;
        background-image: none;
    }
    .header-top {
        margin-bottom: 20px;
    }
    .header_button {
        bottom: -18px;
    }
    .nav.nav-tabs.chat-tabs {
        .nav-item {
            .nav-link {
                font-size: 14px;
                padding-right: 8px;
            }
            .nav-link.active {
                font-size: 14px;
                padding-right: 8px;
            }
        }
    }
    .status_update {
        display: none;
    }
    .contact_profile_icon {
        top: 44px;
        left: 50px;
    }
    .account-page {
        .container-fluid {
            height: 100%;
            > div {
                align-items: center;
                -webkit-align-items: center;
                height: 100%;
            }
        }
    }
    .left-sidebar.hide-left-sidebar {
        margin-left: -991px;
    }
    .chat.show-chatbar {
        display: block;
        margin-left: 0;
    }
    .chat.hide-chatbar {
        margin-left: -991px;
        display: none;
    }
    .right-sidebar.hide-right-sidebar {
        margin-right: -991px;
    }
    .right-sidebar.show-right-sidebar {
        display: block;
        padding-right: 20px;
    }
    .sidebar-menu {
        width: 65px;
        padding: 20px 10px;
    }
    .chat.video-screen {
        display: flex;
        display: -webkit-flex;
        padding: 5px 15px;
        .chat-body {
            padding: 0;
        }
    }
    .right-sidebar.video-right-sidebar {
        width: 100%;
    }
    .settings-option {
        display: block;
    }
    .chat.status-middle-bar {
        display: none !important;
    }
}
@media screen and (max-width: 1400px) {
    .chat-menus {
        margin-top: 35px;
        ul {
            li + li {
                margin-top: 20px;
            }
        }
    }
    .bottom-menus {
        ul {
            li + li {
                margin-top: 20px;
            }
        }
    }
}
@media screen and (max-width: 767.98px) {
    body {
        padding-left: 0;
    }
    .left-chat-title {
        padding: 10px 15px;
    }
    .main-wrapper {
        overflow: auto;
    }
    .login-right {
        margin-bottom: 40px;
        display: none;
    }
    .search_chat {
        margin-left: 15px;
        margin-right: 15px;
    }
    .chat {
        .chat-header {
            display: block;
        }
        .mob-auto {
            max-width: 25%;
        }
        .chat-body {
            padding: 20px 0px 120px;
            .messages {
                .chat-profile-name {
                    h6 {
                        font-size: 14px;
                    }
                }
                .download-col {
                    ul {
                        flex-wrap: wrap;
                        -webkit-flex-wrap: wrap;
                    }
                }
            }
        }
        .chat-footer {
            bottom: 58px;
            padding-left: 0;
            padding-right: 0;
        }
    }
    .avatar-group {
        padding-top: 20px;
        padding-left: 35px;
    }
    .status-title {
        margin-left: 0 !important;
    }
    .status-modal {
        .modal-dialog {
            padding: 0;
            max-width: 100%;
            .modal-content {
                .inner-popup {
                    padding: 0 10px;
                }
            }
        }
    }
    .send-btn_status {
        width: 48px;
        height: 48px;
        margin-left: 8px;
        font-size: 20px;
    }
    .missed-call-widget {
        .card-body {
            padding: 30px 8px;
            h6 {
                font-size: 13px;
            }
        }
    }
    .other-call-widget {
        .card-body {
            padding: 30px 8px;
            h6 {
                font-size: 13px;
            }
        }
    }
    .avatar-group.group-col {
        padding-top: 0;
        padding-left: 15px;
    }
    .sidebar-menu {
        width: 100%;
        height: 58px;
        top: inherit;
        bottom: 0;
        box-shadow: 0px 0px 5px 2px rgba(66, 11, 161, 0.2);
        padding: 10px 15px;
        .logo-col {
            display: none;
        }
    }
    .menus-col {
        height: auto;
        justify-content: space-around;
        -webkit-justify-content: space-around;
    }
    .chat-menus {
        margin-top: 0;
        ul {
            li {
                a {
                    span {
                        display: none;
                    }
                    span.material-icons {
                        display: block;
                    }
                    width: 40px;
                    height: 40px;
                    img {
                        width: 20px;
                    }
                }
                display: inline-block;
            }
            li + li {
                margin-left: 10px;
                margin-top: 0;
            }
        }
    }
    .bottom-menus {
        ul {
            > li {
                > a {
                    > span {
                        display: none;
                    }
                }
            }
            li {
                a {
                    span.material-icons {
                        display: block;
                    }
                }
                display: none;
                float: left;
                &:last-child {
                    display: flex;
                    display: -webkit-flex;
                }
            }
            li + li {
                margin-left: 10px;
                margin-top: 0;
            }
        }
        width: auto;
        display: block;
        .chat-profile-icon {
            width: 40px;
            height: 40px;
        }
    }
    .sidebar-body {
        padding-bottom: 60px;
    }
    .right-sidebar.video-right-sidebar {
        .chat {
            .chat-footer {
                bottom: 48px;
            }
        }
    }
    .right-sidebar.show-right-sidebar {
        padding: 15px;
    }
    .report-col {
        margin-top: 15px;
    }
    .login-left {
        width: 100%;
    }
    .account-content {
        max-width: 100%;
        padding: 15px;
    }
    .otp-box {
        .digit-group {
            input {
                margin: 0 10px;
            }
        }
    }
    .chat_form {
        border-radius: 0;
    }
    .right-sidebar {
        .right-sidebar-wrap {
            padding-bottom: 60px;
            margin-bottom: 0;
            height: auto;
        }
    }
    .call-user-avatar {
        width: 50px;
        height: 57px;
        left: 15px;
        top: 15px;
    }
    .record-time {
        span {
            width: 100px;
            height: 40px;
            font-size: 13px;
            line-height: 40px;
            &:before {
                width: 15px;
                height: 15px;
                left: 16px;
                top: 13px;
            }
            &:after {
                width: 7px;
                height: 7px;
                left: 20px;
                top: 17px;
            }
        }
        top: 15px;
        right: 15px;
    }
    .chat.video-screen {
        .chat-header {
            .chat-options {
                margin-top: 20px;
                margin-left: 0;
                .mr-5 {
                    margin-right: 20px !important;
                }
            }
        }
        padding-left: 0;
    }
    .video-screen-inner {
        padding: 15px;
        height: calc(100vh - 255px);
    }
    .video-call-action {
        ul {
            li {
                a {
                    width: 40px;
                    height: 40px;
                    .material-icons {
                        font-size: 18px;
                    }
                }
                margin: 0 2px;
            }
        }
    }
    .video-group-member {
        .call-user-avatar {
            width: 50px;
            height: 57px;
        }
    }
    .call-action-group {
        width: 20px;
        height: 20px;
        .material-icons {
            font-size: 10px;
        }
    }
    .group-call-tab {
        .nav-tabs {
            .nav-link {
                padding: 6px 20px;
            }
            .nav-link + .nav-link {
                margin-left: 5px;
            }
        }
    }
    .profile-card {
        margin: 0px 15px 17px;
    }
    .settings-card {
        margin: 0px 15px 17px;
        margin-bottom: 80px;
    }
    .settings-option {
        padding: 0px 15px 15px;
    }
    .chat.settings-main {
        .settings-form {
            padding-bottom: 60px;
        }
    }
    .page-header {
        h5 {
            font-size: 18px;
        }
    }
    .settings-delete {
        .btn-delete {
            margin-top: 15px;
            float: left;
        }
    }
    .volume-col {
        .inner-volume-col {
            width: 40px;
            height: 140px;
        }
        #volume {
            .ui-slider-handle {
                width: 15px;
                height: 15px;
            }
        }
        #player {
            top: 10px;
        }
        .material-icons {
            margin-top: 10px;
        }
        bottom: 80px;
    }
    .status-text {
        max-width: 90%;
        font-size: 12px;
    }
    .carousel-indicators {
        li {
            width: 33px;
        }
    }
    .bottom-message-col {
        max-width: 100%;
        padding: 0;
        margin: 0;
    }
    .logout-btn {
        margin-top: 10px;
    }
}
@media screen and (max-width: 575.98px) {
    .chat-menus {
        ul {
            li + li {
                margin-left: 7px;
            }
        }
    }
    .bottom-menus {
        ul {
            li + li {
                margin-left: 7px;
            }
        }
    }
    .otp-box {
        .digit-group {
            input {
                margin: 0 7px;
            }
        }
    }
}
