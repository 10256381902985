$primary: #283360;
$secondary: #89c540;

.refer-body {
    .refer-title {
        h1 {
            font-style: normal;
            font-weight: bold;
            font-size: 50px !important;
            @media (max-width: 767px) {
                font-size: 40px !important;
            }
        }
    }
    .how-refer-works {
        h1 {
            font-style: normal;
            font-weight: bold;
            font-size: 30px !important;
        }
    }
    .card {
        border-top: none !important;
    }

    .invite {
        .refer-info {
            .link-box {
                background-color: #f0f2f5;
                -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
                box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
            }
        }
        p {
            font-size: 16px;
            font-weight: 500;
            margin: 0;
        }
        h6,
        h1 {
            font-size: 14px;
            font-weight: bold;
            margin: 0;
        }

        .input-control {
            height: 50px;
            padding: 10px;
            font-size: 14px;
            color: #0e314c;
            border: 1px solid #eeeeee;
            border-radius: 0;
            background: #fafafa;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            margin-right: 10px;

            &:focus {
                outline: 0;
                box-shadow: none;
            }
        }
        .btn {
            font-weight: 400;
            font-size: 16px;
            border: none;
            padding: 5px 10px;
            position: relative;
            border-radius: 4px;
            z-index: 1;
            text-transform: capitalize;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            display: inline;
        }
    }

    .summary {
        background-color: #f0f2f5;
        border-radius: 50%;
        height: 100%;
        width: 100%;
        padding: 3%;

        .circle {
            border: 4px solid #ffffff;
            height: 100%;
            border-radius: 50%;

            .payout {
                margin-left: 30%;
            }
        }

        h5 {
            padding-top: 12%;
            @media (min-width: 768px) {
                padding-left: 30%;
            }
        }

        ul {
            list-style-type: none;
            padding: 10%;
        }

        li {
            padding-top: 5%;
            padding-right: 1%;
            border-bottom: 2px solid #ffffff;

            span {
                float: right;
            }
        }
    }

    .works {
        padding-left: 10px;

        .title {
            font-size: 24px;
            font-weight: 500;
            color: $primary;
        }

        .content {
            margin-top: 10px;

            .icon {
                margin-left: 10px;
                font-size: 30px;
            }

            .sub-title {
                font-size: 16px;
                font-weight: 400;
                color: $primary;
            }

            .description {
                margin-top: 20px;
                font-size: 16px;
            }
        }
    }

    .history {
        margin-top: 5%;

        .title {
            font-size: 22px;
            font-weight: 500;
            color: $primary;
        }

        .history {
            margin-bottom: 10px;
        }
    }
}