.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
    display: block;
  }
  &:before {
    content: "";
    display: table;
  }
}
a {
  color: inherit;
  text-decoration: none;
}
.login-wrap {
  width: 100%;
  margin: auto;
  max-width: 525px;
  min-height: 670px;
  position: relative;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.hr {
  height: 2px;
  margin: 60px 0 50px 0;
  background: rgba(255, 255, 255, 0.2);
}
.login-content {
  background: url("../../images/login_banner.webp");
  @media (min-width: 767px) {
    padding: 1.5rem 4.5rem;
    background-size: 80% 100%;
    background-attachment: fixed;
  }

  background-repeat: no-repeat;
}
