$color-border1: #c71a23;
$color-green: #283360;
$color: #ffffff;
$black: #000;
$color-balck: #202647;
$color-black: #202647;
$color-text: #6a6c72;
$color-yellow: #6a6c72;
$color-border: #6a6c72;
$font-size: 15px;
$transition: 0.5s;
$primary: #283360;
$color-gr-br: #283360;
$secondary: #89c540;
$white-color: $color;
$secondary-color: $color-border1;
$black-color: $black;
.tutor-landing {
    h1 {
        font-size: 32px !important;
        font-weight: 550 !important;
    }
    h5 {
        font-size: 1rem;
        font-weight: normal;
    }
    .tutor-landing-details {
        padding: 0px 0px 55px;
        background: #fff;
        @include phone {
            padding: 15px 0px 85px;
            background: #f8f9fa;
        }
        .about-content {
            p.about-para {
                font-size: 16px;
                color: #333333;
                line-height: 25px;
                margin-bottom: 40px;

                span {
                    display: block;
                    margin-top: 20px;
                }

                @media (max-width: 575px) {
                    margin-bottom: 20px;
                }
            }
            @media (max-width: 767px) {
                padding: 30px 0 40px;
            }
        }
    }
    .icon-box-area {
        padding-top: 20px;
        p {
            font-weight: 400;
            font-size: 0.9rem;
        }

        @media (max-width: 991px) {
            padding: 25px 10px 8px;
        }

        @media (max-width: 767px) {
            padding: 35px 20px;
            position: unset;
            margin-bottom: 25px;
        }

        @media (max-width: 767px) {
            padding: 40px 0 0;
        }
    }

    .tab-section {
        // margin-top: 70px;
        padding-bottom: 70px;
        background: #fff;

        .nav {
            background-color: #ffffff;
            border-radius: 5px;
            border: 1px solid $border1;
        }
        overflow: hidden;

        .nav-item {
            a.nav-link {
                font-size: 15px;
                color: $color-black;
                // border-bottom: 1px solid $border1;
                padding: 18px 25px;
            }

            i {
                color: $primary;
            }
        }

        a.nav-link.active {
            background: $primary;
            color: #ffffff;
            &:hover {
                background: #283360 !important;
            }

            i {
                color: #ffffff;
            }
        }

        &:last-child {
            a.nav-link {
                border-bottom: none;
            }
        }

        @media (max-width: 767px) {
            margin-bottom: 30px;
        }

        .tab-content {
            .tab-pane {
                h4.tab-title {
                    color: $color-black;
                    font-weight: 500;
                    margin-bottom: 25px;

                    @media (max-width: 575px) {
                        margin-bottom: 15px;
                        font-size: 20px;
                    }
                }

                p.tab-desc {
                    font-weight: 400;
                    line-height: 1.4;
                    font-size: $font-size-18;

                    @media (max-width: 575px) {
                        font-size: 14px;
                    }
                }
            }
        }

        @media (max-width: 767px) {
            padding: 35px 0 30px;
        }
    }
    .tutor-slider {
        p {
            font-weight: 400;
            line-height: 1.4;
            font-size: 1.2rem;
        }
    }
}
