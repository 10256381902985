.ant-tooltip a{
    color: #fff;
    &:hover{
        color: #fff;
    }
}

.ant-breadcrumb a{
    &:hover{
      color: #283360;
    }
  }

.ant-drawer-close, .ant-drawer-close:hover{
    color: #fff;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs-tab:hover{
  color: #283360;
}

.ant-collapse-header-text{
  width: 100% !important;
}

.ant-card-head-title{
  font-weight: 600;
}

// .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
//   background-color: transparent !important;
// }