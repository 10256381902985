 .item-card {
    display: flex;
    flex-direction: column;

    height: auto;
    margin: 0 1rem 2rem 1rem;

    @include phone {
      margin: 1rem 0;
    }
    align-items: center;
    justify-content: center;
    img {
      padding: 0.5rem;
    }
    .detail {
      padding: 0.5rem;
      font-weight: 700;
      font-size: 22px;
      text-align: center;
      margin-top: 1.4rem;
    }
  }

.collapser {
  .card-header {
    // padding-right: 40px !important;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);

    .widget-title {
      font-size: 18px;
      font-weight: 600;
    }
  }
}