$primary:#283360;
$secondary:#89c540;

.verify-body {
    background: #ffffff;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 40px 30px;
    border-radius: 5px;
    margin: auto;

    .steps {
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
        margin: auto;
      }
      
    .steps strong {
        font-size: 16px;
        display: block;
        color: rgb(191, 191, 195);
        margin-bottom: 20px;
    }

    .error{
        text-align: center;
        color: $secondary;
    }

    .message{
        text-align: center;
        color: #064725;
        font-weight: 800;
    }

    .resend-email{
        p{
            display: inline;
        }

        h5{
            text-decoration-line: none;
            display: inline;
            color: $primary;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;

            &:hover{
                color: $secondary;
            }
        }
    }

    .verify-submit{
        margin-top: 10px;
        background-color: $secondary;
        width: 100%;
        color: #ffffff;
        border-radius: 5px;
        border-style: solid;
        border-color: $secondary;

        &:hover{
            background-color: $primary;
            border-style: solid;
            border-color: $primary;
        }
    }
}