/* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */

// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap");
// .nav-tab-link {
//   display: block;
//   position: relative;
//   white-space: nowrap;
//   text-align: center;
//   font-weight: 600;
//   color: #213246 !important;
//   // transition: background 0.8s;
//   text-decoration: none !important;
//   line-height: 1.7;
// }
// Video section navbar
.video-page-header {
    height: 56px !important;
}

.video-page-header {
    color: #fff;
    background-color: #29303b;
    align-items: center;
    height: 100%;
    padding: 0 16px;
    width: 100%;
}

.video-page-header .logo-learnsic {
    align-self: center;
    margin: 0 24px 0 0;
}

.video-page-header header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    font-weight: 400;
    height: 100%;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1.25rem;
    height: 56px;
    background-color: #fff;
    border-bottom: 1px solid #ecf0f2;
}

.logo {
    cursor: pointer;
}

.nav__links a,
.cta,
.overlay__content a {
    font-weight: 500;
    color: #edf0f1;
    text-decoration: none;
    margin-right: 50px;
}

.right-nav {
    right: 1.25rem;
    /* display: flex; */
    align-items: center;
    @include phone{
     position: absolute;
    }
}

.cta {
    cursor: pointer;
    outline: none;
    text-transform: capitalize;
    min-width: 100px;
    text-decoration: none !important;
    display: inline-block;
    background-color: #89c540 !important;
    border: 1px solid #89c540 !important;
    color: #fff;
    padding: 0.375rem 1.5rem;
    line-height: 1.6;
    font-size: 0.875rem;
    text-align: center;
    bottom: 10px !important;
}

.cta:hover {
    background-color: #af191e !important;
    border-color: #af191e !important;
    color: #fff !important;
}

.header--course-details {
    flex: 1 1 auto;
    text-align: center;
    margin: 0 24px 0 0;
}

.header--course-details .course-link {
    display: inline-block;
    // padding-right: 3px;
    // @include phone {
    padding-right: 55px;
    //   }
}

.header--course-details .course-link {
    font-size: 1.2rem;
    font-weight: 600 !important;
    line-height: 1.43em !important;
    color: #89c540 !important;
    text-overflow: ellipsis;
    text-align: center;
}

.header--course-details > * {
    vertical-align: middle;
}

.header-progress {
    align-self: center;
    margin: 0 24px 0 0;
}

.header-progress span {
    font-size: 12px;
    cursor: pointer;
    font-weight: 600;
}

.header-progress .popover-content {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.33em;
}

.header-progress .popover-content span {
    margin-right: 4px;
    font-weight: 700;
}

.notebar {
    right: 0px;
    // width: 350px;
    // margin-left: 5%;
}

// .video-layout{
//   // position: -webkit-sticky;
//   position: sticky;
//   overflow: scroll;
//   top: 0;
//   transition: left 0.4s ease;
//   min-width: 350px;
//   // max-height: 700px;
//   background-color: #fff;
//   color: #000;
//   transition: all 0.3s;
// }
// .video-layout{
//   // position: -webkit-sticky;
//   position: sticky;
//   overflow: scroll;
//   top: 0;
//   transition: left 0.4s ease;
//   min-width: 350px;
//   // max-height: 700px;
//   background-color: #fff;
//   color: #000;
//   transition: all 0.3s;
// }
.video-layout::-webkit-scrollbar {
    display: block;
    width: 10px;
    cursor: pointer;
}

.video-layout.show {
    // position: absolute;
    // left: -350px;
    display: none;
    transition: all 1s;
    -webkit-transform: translate3d(-100%, 0px, 0px);
    transform: translate3d(-100%, 0px, 0px);
    visibility: visible;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    // overflow: scroll;
}

.video-layout .text {
    color: #303f9f;
    font-size: 18px;
    font-weight: 600;
    line-height: 65px;
    text-align: center;
    border-bottom: 1px solid rgba(15, 15, 15, 0.1);
}

.video-layout ul li {
    // align-items: center;
    // cursor: pointer;
    // line-height: 60px;
    // color: #adadad;
    // background: #f0f2f5;
    // font-size: 15px;
    // padding-left: 10px;
    // font-weight: 600;
    // display: block;
    // // width: 100%;
    // border-left: 3px solid transparent;
    span.dropdown {
        float: right;
        font-size: 18px;
        font-weight: 400;
    }
}

// VIdeo learn start
#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.ant-layout {
    overflow-y: auto !important;
    background: #fff !important;
    overflow-x: hidden;
}

.video-layout {
    height: 90vh !important;
}

.video-layout .site-layout-background {
    margin: 0 !important;
    padding: 0 !important;
}

// Video learn ends
// .lesson {
//   margin: 0 !important;
//   padding: 0 !important;
//   overflow: auto;
//   display: flex;
// }
.video-layout .ant-layout-sider-collapsed {
    flex: 0 0 0px !important;
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;
}

// flex: 0 0 80px; max-width: 80px; min-width: 80px; width: 80px;
// .video-layout .ant-layout-sider-children {
//   overflow: auto !important;
// }
.video-layout aside {
    // flex: 0 0 340px !important;
    // max-width: 340px !important;
    // min-width: 340px !important;
    // width: 340px !important;
    flex: 0 0 20vw !important;
    max-width: 25vw !important;
    min-width: 25vw !important;
    width: 25vw !important;
    padding-bottom: 20px !important;
}

.video-layout aside::-webkit-scrollbar {
    display: block;
    width: 10px;
    cursor: pointer;
}

// /* Track */
// ::-webkit-scrollbar-track {
//   display: none;
// }
// /* Handle */
// ::-webkit-scrollbar-thumb {
//   position: relative;
//   top: 0px;
//   /* float: right; */
//   /* width: 6px; */
//   height: auto;
//   background-color: #050505 !important;
//   border: 1px solid #867d7d;
//   /* background-clip: padding-box; */
//   border-radius: 5px;
// }
// ::-webkit-scrollbar-track {
//   cursor: pointer;
// }
.video-layout section {
    padding: 10px !important;
}

.video-layout li {
    padding: 10px;
    // border-radius: 0.4rem;
    list-style: none;
}

.video-layout li.active {
    background-color: #f0f2f5;
}

.video-layout li:hover {
    background-color: #f0f2f5;
    cursor: pointer;
}

// .video-layout h5 {
//   padding-right: 8px;
//   display: flex;
// }
.video-layout ul .feat-show.show {
    display: block;
}

.video-layout ul .feat-show {
    display: none;
    transition: 0.8s ease-in-out;
}

// .video-layout ul{
//   padding: 10px !important;
// }
.time-duration > small,
.time-duration {
    float: right;
    font-size: 12px;
}

// }
// .video-layout ul li:hover{
//   color: rgb(121, 120, 128);
//   background: #f1ecec;
//   border-left-color: rgb(97, 110, 110);
// }
// .video-layout ul ul li.active{
//   color: rgb(45, 38, 112);
//   background: #f1ecec;
//   border-left-color: rgb(38, 34, 93);
// }
.video-content {
    width: 100vw;
    padding: 15px !important;
}

.video-loader {
    width: 80vw;
    padding: 15px !important;
}

.course-video {
    height: 60vh !important;
    width: 70vw;
    margin: 10px;
    margin-top: 10px;
}

.sider-toggle:not(:hover) .sider-toggle-text {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

// .sider-toggle:hover .sider-toggle-text {
//   font-size: 16px;
//   font-weight: 400;
//   line-height: 1.5em;
//   padding-left: 8px;
//   display: inline;
// }
.sider-toggle {
    position: absolute;
    background-color: #283360 !important;
    border: none;
    border-radius: 0;
    pointer-events: auto;
    padding: 8px 16px;
    margin-top: 16px;
    height: 50px;
    max-width: 46px;
    width: auto;
    right: 0;
    top: 56px;
    z-index: 10;
    transition: all 1s ease-in-out;
    margin-right: 10px;

    @include phone{
        margin-top: 7px;
        border-radius: 5px;
        padding: 7px !important
    }
}

// .sider-toggle:hover{
//   max-width: 600px;
// }
.sider-toggle:hover .sider-toggle-text {
    display: block;
}

.sider-parent {
    background-color: #ffffff;
    overflow-y: auto;
}

// .--plyr-line-height{
//   height: 100px !important;
// }
@media (max-width: 1024px) {
    .video-layout aside {
        // flex: 0 0 340px !important;
        // max-width: 340px !important;
        // min-width: 340px !important;
        // width: 340px !important;
        // overflow: scroll !important;
        flex: 0 0 30vw !important;
        max-width: 30vw !important;
        min-width: 30vw !important;
        width: 30vw !important;
    }
    .video-content {
        width: 100vw;
        padding: 15px !important;
    }
    .course-video {
        height: 24vh !important;
        width: 60vw;
    }
}

@media (max-width: 767px) {
    .video-layout aside {
        flex: 0 0 80vw !important;
        max-width: 90vw !important;
        min-width: 100vw !important;
        width: 100vw !important;
    }
    .video-layout section {
        padding: 0px !important;
    }
    .video-content {
        width: 100vw;
        padding: 0px !important;
        margin-left: 0 !important;
    }
    .video-content h1 {
        margin-left: 10px !important;
        font-size: 24px !important;
    }
    .course-video {
        height: 20vh !important;
        width: 80vw;
        margin-top: 10px !important;
    }
    .course-description {
        margin: -14px !important;
        text-justify: newspaper !important;
    }
}

.video-content h1 {
    color: #283360;
}

// #sidebarCollapse,
// #notebarCollapse {
//   height: 45px !important;
//   background: none;
//   color: #000;
//   border-color: #fff;
//   border: none !important;
//   cursor: pointer;
//   outline: none !important;
// }

/* Sticky notes */

.sticky-note-box {
    margin-left: 10% !important;
    margin-top: 22% !important;
    padding: 15px 15px 20px;
    margin: 0 20px 20px 0;
    width: 200px;
    max-height: 200px;
}

.sticky-note-box {
    // font: 16px "Gloria Hallelujah", cursive;
    line-height: 1.5;
    border: 0;
    border-radius: 3px;
    // background: linear-gradient(#f9efaf, #f7e98d);
    background-color: #ffff88;
    color: #050505;
    box-shadow: -5px 5px 6px 7px rgba(0, 0, 0, 0.1);
    overflow: scroll;
    transition: box-shadow 0.5s ease;
    max-width: 520px;
    max-height: 250px;
}

.sticky-note-box::-webkit-input-placeholder {
    color: #050505 !important;
}

.sticky-note-box:hover {
    box-shadow: -5px 5px 6px 7px rgba(0, 0, 0, 0.1);
}

.sticky-note-box:focus {
    box-shadow: -5px 5px 6px 7px rgba(0, 0, 0, 0.1);
    outline: none;
}

/* Description */

.video-downpart-heading {
    color: #283360;
    width: 100% !important;
    @include phone {
        font-size: 14px;
    }
}

// .toggle-tabs .ant-tabs-tab-active {
//     border-bottom: none !important;
// }

.toggle-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 !important;
}
.toggle-tabs .ant-tabs-tab:hover {
    color: #283360 !important;
}

.toggle-tabs .ant-tabs-nav-wrap {
    border-bottom: 1px solid #a5a3a3 !important;
}

.toggle-tabs .ant-tabs-ink-bar {
    background: #89c540 !important;
}

.add-doubt {
    background-color: #283360;
    color: #fff;
    border-color: #283360;
}

.add-sticky-note {
    cursor: pointer;
    transition: 0.8s ease;
}

.add-sticky-note-icon {
    border-radius: 50%;
    padding: 6px;
    right: 5%;
    position: absolute;
}

.add-sticky-note-icon:hover {
    background-color: #f0f2f5;
}

.add-sticky-note:hover,
.add-sticky-note .ant-input:hover,
.add-sticky-note .ant-input:focus {
    // border:1px solid #283360 !important;
    box-shadow: 0 1px 3px 0 rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%) !important;
}

.sticky-item-header {
    align-items: baseline;
    display: flex;
    flex-direction: row;
}

.sticky-item-header .header-items {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.sticky-item-header .link-items {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5em;
    padding-right: 12px;
    color: #283360;
    display: inline-block;
}

.sticky-item-header svg {
    line-height: 24px;
    margin-right: 12px;
}

.sticky-item-header .sub-items span {
    color: #050505;
    font-size: 16px;
    font-style: italic;
    cursor: pointer;
}

.sticky-item-header .header-items-left {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    min-width: 1px;
}

.sticky-item-header .header-items-right {
    align-items: flex-end;
    display: flex;
}

.sticky-item {
    // margin-left: 64px;
    margin-top: 12px;
    margin-bottom: 12px;
    background-color: #fff;
}

.sticky-item-content {
    padding: 24px;
    word-wrap: break-word;
}

.sticky-item-content p {
    margin: 0;
}

.sticky-text-wrapper {
    margin: 0;
    padding: 0;
    margin-top: 0;
    margin-bottom: 1rem;
}

// Question Answer Section starts
.questions-doubt .ant-btn-primary {
    background-color: #283360 !important;
}

.questions-doubt .ant-input:focus,
.questions-doubt .ant-input:hover {
    border: none !important;
    border-color: #fff !important;
    outline: none !important;
}

.course-question-collection-header {
    font-size: 12px;
    font-weight: 400;
    line-height: 1em;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.course-question-collection-header-inner-div {
    font-size: 12px;
    font-weight: 700;
    color: #050505;
}

.course-question-collection h3 {
    font-weight: 500;
}

.course-question-count {
    font-size: 20px;
    font-weight: 700;
    color: #050505;
    margin-left: 8px;
}

.course-question-collection {
    margin-bottom: 28px;
}

.course-question-collection .questions-list:first-child {
    border-top: 0;
}

.course-question-collection .questions-list {
    border-bottom: 1px solid #6693bdff;
}

.course-question-collection .question-wrapper {
    display: flex;
    border-top: 1px solid #6693bdff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43em;
    padding: 1.2rem 1.4rem;
}

.question-info-wrapper {
    flex: 1;
    min-width: 1px;
    padding-left: 1.6rem;
}

.question-info-text-content {
    display: block;
    flex: 1;
    min-width: 1px;
    padding-right: 2.5rem;
    text-align: justify;
}

.question-body-item {
    font-weight: 700;
    line-height: 1.43em;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.question-body-item h4 {
    font-size: 14px;
    overflow: auto;
    cursor: pointer;
}

@media (max-width: 767px) {
    .question-body-item h4 {
        font-size: 10px;
    }
}

.author a {
    white-space: nowrap;
    color: #283360;
}

.question-info-wrapper .question-body {
    font-size: 13px;
    line-height: 1.43;
    padding: 10px 0 0;
}

.question-info-wrapper .question-body p {
    font-size: inherit;
    font-weight: 400;
    max-width: 60rem;
}

.question-list-footer {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.33em;
}

.question-list-footer {
    color: #283360;
    display: inline-block;
    cursor: pointer;
}

.question-action-section-upvote {
    display: flex;
    flex-direction: column;
}

.question-upvote-count {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.number-of-upvotes,
.number-of-comments {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43em;
    display: inline-block;
    color: #283360;
    font-weight: 700;
    position: relative;
    vertical-align: bottom;
    z-index: 1;
}

.comment-icon-section {
    display: flex;
    justify-content: flex-end;
}

.upvote-button {
    background-color: transparent;
    padding: 10px 11px !important;
    color: #283360;
    margin-left: -4px;
    font-weight: 700 !important;
    font-size: 14px !important;
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent;
    vertical-align: bottom;
    transition: 0.8s ease-in-out;
    border-radius: 50%;
}

.upvote-button-single-question {
    background-color: transparent;
    color: #283360;
    font-weight: 700 !important;
    font-size: 14px !important;
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent;
    vertical-align: bottom;
    transition: 0.8s ease-in-out;
    border-radius: 50%;
}

.upvote-button.disabled {
    cursor: not-allowed;
}

.comment-button {
    vertical-align: -8%;
    color: #283360 !important;
    text-decoration: none !important;
}

.comment-button a {
    transition: 1s ease-in-out;
}

.comment-container {
    padding: 10px 7px;
    color: #dedfe0;
    border-radius: 50%;
    margin-left: -0.4rem;
}

.upvote-button:hover,
.comment-button span:hover {
    color: #89c540;
    font-weight: 1000 !important;
}

.number-of-comments {
    color: #283360;
    font-weight: 700;
}

.question-liked {
    color: #89c540 !important;
    transition: 1s ease-in-out;
}

@media (min-width: 768px) {
    .main-section {
        padding-top: 1.6rem;
        max-width: none;
        max-height: none;
        overflow: visible;
        cursor: auto;
        padding: 0 40px;
    }
    .author {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5em;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.author {
    font-size: 12px;
}

.comment-form .comment-textarea {
    background-color: #fff !important;
}

.comment-form .comment-textarea:focus,
.comment-form .comment-textarea:hover {
    border-color: #f0f2f5 !important;
}

.main-section .default-btn-one {
    cursor: pointer;
    margin-top: 24px !important;
    border-radius: 0.4rem !important;
}

.question-details-section {
    padding: 14px;
    // margin: 20px 0;
    background-color: #fff;
}

.question-details-inner-tab {
    padding-bottom: 20px;
    flex: 1;
    min-width: 1px;
    display: flex;
}

.question-comments-section {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5em;
    border-bottom: 1px solid #e8e9eb;
    display: flex;
    flex-direction: row;
    padding-bottom: 11px;
}

h4.comment-count {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5em;
    font-weight: 700;
    color: #505763;
    margin-right: auto;
    padding-top: 6px;
}

.comment-container-section {
    border-bottom: 1px solid #e8e9eb;
    background-color: #fff;
}

.comment-container-section .comment-container-inner-div {
    flex: 1;
    min-width: 1px;
    display: flex;
    padding: 16px 24px;
}

// Question answer section ends
.notes-input-area button {
    width: 100px;
    height: 42px;
    margin-top: -12px;
    line-height: 10px;
}

// .about-course {
//   margin-top: 25px;
// }
.notes-input-area {
    flex-direction: column;
    transition: 0.8s ease;
}

.sticky-item {
    color: #283360;
    font-size: 1.1rem;
}

// .course-description {
//   text-align: justify;
//   text-justify: inter-word;
// }
// .course-description p {
//   color: #050505;
//   margin: 0;
//   padding: 0;
//   font-family: Nunito, sans-serif;
//   font-size: 1rem;
//   font-weight: 400;
//   line-height: 1.5;
//   /* color: #000; */
//   text-align: left;
// }
textarea:hover .close-btn {
    display: block;
}

.course-overview-container {
    position: relative;
    font-family: "Robotto", sans-serif !important;
}

// @media (min-width: 1440px) {
//     .course-overview-container {
//         width: 1056px;
//         padding: 0 159px;
//     }
// }

// @media (min-width: 1200px) {
//   .course-overview-container {
//     width: 856px;
//     padding: 0 119px;
//   }
// }
// @media (min-width: 992px) {
//   .course-overview-container {
//     width: 646px;
//     padding: 0 40px;
//   }
// }
// @media (min-width: 768px) {
//   .course-overview-container {
//     width: 767px;
//     padding: 0 24px;
//   }
// }
// @media (min-width: 576px) {
//   .course-overview-container {
//     width: 573px;
//     padding: 0 24px;
//   }
// }
.course-overview-heading {
    margin: 0 24px;
}

.course-overview-heading .heading-text {
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.33em;
    color: #283360;
}

.course-overview-heading p {
    margin-bottom: 32px;
}

.course-overview--grid {
    // border-top: 1px solid gray;
    padding: 24px;
    display: flex;
}

.course-overview--grid > *:first-child {
    width: 25%;
}

.course-overview--grid > * {
    width: 33%;
}

@media (max-width: 767px) {
    .course-overview--grid {
        flex-wrap: wrap;
    }
}

@media (max-width: 767px) {
    .course-overview--grid-row > * {
        width: 100%;
    }
    .course-overview--grid .course-overview-description {
        width: 100%;
    }
}

.course-overview--grid .instructor-profile-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
}

.course-overview--grid .instructor-details {
    flex: 1;
    min-width: 1px;
    margin-left: 24px;
}

.instructor-profile-title a {
    font-size: 19px;
    line-height: 1.47em;
    font-weight: 700;
    color: #050505;
    text-decoration: none;
}

.instructor-profile-social-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
}

.instructor-profile-social-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    font-size: 19px;
    font-weight: 700;
    line-height: 1.47em;
    background: #fff;
    color: #283360;
    padding: 4px 0;
}

.instructor-profile-social-btn:hover {
    color: #050505;
}

.course-overview-description .instructor-profile-description {
    white-space: normal;
    word-wrap: break-word;
}

.course-overview--grid .course-overview-description {
    width: 100%;
    text-align: justify;
}

.view-more-container {
    padding-top: 30px;
    position: absolute;
    bottom: 0;
    width: 100%;
    // background: linear-gradient(rgba(255,255,255,0),rgba(240,242,245,0.95),#f0f2f5);
}

.view-more-container-button {
    padding: 2px 6px 3px;
    border-width: 2px;
    background-color: #f0f2f5;
    color: #283360;
}

.view-more-container-button,
.view-more-container-button:hover,
.view-more-container-button:focus,
.view-more-container-button:active {
    border-color: transparent;
}

/* ---------------------------------------------------
      MEDIAQUERIES
  ----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        margin-left: -240px;
    }
    /* #notebar {
      margin-right: -240px;
    } */
    /* #notebar.active {
      margin-right: 0;
    } */
    #sidebar.active {
        margin-left: 0;
    }
    // #sidebarCollapse span {
    //   display: none;
    // }
    /* #notebarCollapse span {
      display: none;
    } */
    .sticky-note-box {
        margin-left: 10% !important;
        margin-top: 10% !important;
        padding: 15px 15px 20px;
        margin: 0 20px 20px 0;
        width: 100px;
        height: 100px;
    }
}

.app-sidebar {
    // background-color: #f7f8fa;
    .nav-tab-link {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5em;
        display: flex;
        justify-content: space-between;
        padding: 16px;
        border: 1px solid #dedfe0;
        color: #050505 !important;
        border-right: none;
        background-color: #fff;
        margin-top: 0;
        margin-bottom: 0;
        top: 56px !important;
    }
}

.app-sidebar-content {
    height: 100%;
    z-index: 1;
    background: #fff;
    overflow-x: hidden;
    overflow-y: auto;
}

.app-sidebar-section {
    // border-bottom: 1px solid #dedfe0;
    transition: 0.6s ease-in-out;
}
.app-sidebar-section.active,
.app-sidebar-section-chapter {
    background: #669cc3;
    color: white;
}
.app-sidebar-section.inactive {
    margin-bottom: 3px;
}
.app-sidebar-section.active-subject {
    // background: #89c540;
    background: #283360;
    color: white;
}
.subject-divider {
    border-bottom: 1px solid #978d8d;
}
.app-section-heading {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 16px;
}

.app-section-heading-title {
    font-weight: 700;
    line-height: 1.43em;
    max-width: 100%;
    margin: 0 24px 0 0;
    cursor: pointer;
}

.app-section-heading-title span {
    font-size: 13px;
    // font-weight: 700;
    line-height: 1.43em;
    font-family: "Poppins", "sans-serif";
}

.font-text-xs,
.section-duration {
    font-size: 12px !important;
    font-weight: 400;
    line-height: 1.33em;
}

.unstyled-list {
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: none;
    display: none;
    font-family: "Poppins", "sans-serif";
}

.unstyled-list.list-active {
    display: block !important;
}

.list-active {
    // background: #e6e6e7 !important;
    // background-color: #283360 !important;
    .active {
        color: #283360 !important;
        color: #2d2d2d !important;
    }
}
.underline-animation {
    display: inline-block;
    position: relative;
    color: #89c540;
}
.underline-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #89c540;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.unstyled-list li {
    padding-left: 0;
}

.curriculum-item-link {
    position: relative;
}
.unstyled-list {
    .active {
        border-left: 5px solid #89c540;
        border-right: 5px solid #89c540;
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        opacity: 1;
        -webkit-transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
            -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
            -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
            opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
            opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
            -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
}
.curriculum-item-link:not(.active) {
    color: #000;
    background: #fff;
}

.curriculum-item-link .item-link {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
}

.curriculum-item-link .item-link-content {
    flex: 1;
    min-width: 1px;
    display: flex;
    flex-direction: column;
    margin-left: 4px;
}

.item-link-content-title {
    max-width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43em;
}

.item-link-duration {
    padding-top: 4px;
}

.curriculum-item-link.active {
    background: #f0f2f5;
}

// Course Resource
.course-resource-header {
    font-size: 20px !important;
    font-weight: 700;
    line-height: 1.33em;
    color: #283360;
    letter-spacing: -0.1px;
}

.resource-body {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.resource-body p {
    min-height: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 21px;
    font-family: "Poppins", Arial, sans-serif;
    color: #050505 !important;
}

.active-list {
    display: block;
}

.inactive-list {
    display: none;
}
.video-search-drawer {
    .ant-drawer-body {
        padding: 0px !important;
    }
}
.vjs-control-bar {
    @media (max-width: 281px) {
        .vjs-seek-button,
        .vjs-picture-in-picture-control {
            display: none;
        }
    }
}
@media (max-width: 600px) {
    .vjs-big-play-button {
        width: 68px !important;
        height: 68px !important;
    }
    .vjs-time-control {
        font-size: 0.9rem !important;
    }
}

.text-area-hover, .text-area-border:focus {
    border-color: #BDBDBD !important;
    outline: none !important;
    box-shadow: none !important;
}