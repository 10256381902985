.ant-table-thead {
    height: auto;
}
.ant-table-thead > tr > th {
    background: #f6f6f6;
    color: #3c4858;
    font-size: $font-size-14 !important;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    white-space: nowrap;
    text-align: left;
}
.ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: $border-radius-learnsic-sm !important;
    border-bottom-left-radius: $border-radius-learnsic-sm !important;
}
.ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: $border-radius-learnsic-sm !important;
    border-bottom-right-radius: $border-radius-learnsic-sm !important;
}

.ant-table-wrapper {
    overflow: auto;
    background: #fff;
}
