$color-border1: #c71a23;
$color-green: #283360;
$color: #ffffff;
$black: #000;
$color-balck: #202647;
$color-text: #6a6c72;
$color-yellow: #6a6c72;
$color-border: #6a6c72;
$font-size: 15px;
$transition: 0.5s;
$primary: #283360;
$color-gr-br: #283360;
$secondary: #89c540;
$white-color: $color;
$secondary-color: $color-border1;
$black-color: $black;
.order-container {
    .ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
        left: 1%;
    }
    .payment-options {
        .option-esewa,
        .option-khalti,
        .option-stripe {
            background: #f8f9fc;
            &:focus-within {
                box-shadow: none !important;
            }
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            // border-color: #f8f9fc !important;
            background: #f8f9fc;
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
            background-color: #f8f9fc;
        }
        .ant-radio-button-wrapper {
            height: 55px !important;
        }
        span {
            .vendor-name {
                font-size: 0.85rem;
                font-weight: 500;
            }
        }
    }
    .cart-summary {
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 25px 35px 35px;
        .cs-title {
            h5 {
                color: #000;
                font-weight: 600;
                padding-bottom: 10px;
                margin-bottom: 20px;
                position: relative;
                &:before {
                    position: absolute;
                    content: "";
                    background: $primary;
                    width: 50px;
                    height: 2px;
                    bottom: 0;
                    left: 42%;
                }
            }
        }
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
        background: transparent !important;
    }
}

.verification-failure {
    font-family: "Popinns", "serif";
    font-size: 1.5rem;
}

.verification-message {
    font-family: "Popinns", "serif";
}

.order-details {
    .price {
        font-size: 16px;
        font-weight: normal;
    }
}

.coupon-box {
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;
    list-style: none;
}
.coupon-box-hidden {
    opacity: 0;
    visibility: hidden;
}

.coupon-box-visible {
    visibility: visible;
    opacity: 1;
}
