.wrapper {
    text-align: center;
    margin: 2rem auto;
    padding: 2rem 0;
    font-family: 'Poppins','sans-serif';
    .main-btn {
        padding: 15px;
        background-color: #6c63ff;
        color: #fff;
        border: none;
        font-weight: 700;
        letter-spacing: 1px;
        border-radius: 6px;
        cursor: pointer;
    }
}

