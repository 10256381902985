.mock-test__right-page {
    background: #283360;
    position: fixed;
    border-radius: 0.5rem !important;
    right: 4%;
    width: 23%;
    margin-top: 3%;
    height: 80vh;
    overflow-y: auto;
    @media screen and (max-height: 700px) {
        margin-top: 1%;
    }
    @media (max-width: 1000px) {
        height: 10vh;
        left: 0 !important;
        bottom: 0 !important;
        width: 100% !important;
        color: white;
        /* top:none !important; */
        text-align: center;
        z-index: 10 !important;
        border-radius: 0 !important;
        transition: height 0.4s ease-out;

        @include phone {
            height: 80px;
        }
        @include tablet {
            height: 80px;
        }
    }
    @media (max-width: 768px) {
        width: 32%;
        z-index: -1;
    }
}

.mocktest-rp__question-palette .display-question-up {
    display: none;
}

@media (max-width: 1000px) {
    //     .mock-test__right-page {
    //         top: 8%;
    //     }
    // .palette_up {
    //     height: 20vh !important;
    //     transition: height 0.4s ease-in;
    // }

    .mocktest-rp__question-palette .box-ads {
        display: flex !important;
        justify-content: center;
    }

    .mocktest-rp__question-palette .box-ads h1 {
        color: #fff !important;
        font-size: 1.3rem !important;
        position: absolute;
        top: 0 !important;
        margin-top: 24px !important;
        text-align: center !important;
    }

    .question-palette__footer-time-container .time-container {
        position: absolute;
        left: 50% !important;
        top: 0 !important;
        font-size: 0.8rem !important;
        margin-top: 10px !important;
    }

    .question-palette__footer-time-container .time-container h3 {
        position: absolute;
        right: -290% !important;
        top: 0 !important;
        font-size: 1.7rem !important;
        margin-top: -2px !important;
    }

    .mock-test__bottom-palette .display-question-up {
        display: block;
        position: absolute;
        right: 24px !important;
        margin-top: 4px;
        /* top:24% !important; */
    }
    .mocktest-rp__question-palette .display-question-up {
        display: block !important;
        position: absolute;
        right: 24px !important;
        margin-top: 4px;
        top: 18px !important;
    }

    .question-palette__footer-button-container {
        margin-right: 10px !important;
    }

    .question-palette__footer-button-container a {
        margin: 10px;
        margin-top: -10px !important;
    }
}

@media (max-width: 766px) {
    .mocktest-rp__question-palette .box-ads h1 {
        font-size: 1.1rem !important;
    }

    .question-palette__footer-time-container .time-container {
        font-size: 0.8rem !important;
        margin-top: 8px !important;
    }

    .question-palette__footer-time-container .time-container h3 {
        font-size: 1.3rem !important;
        right: -220% !important;
    }

    .palette_up {
        height: 37vh !important;
        transition: height 0.4s ease-in;
    }
}

@media (max-width: 374px) {
    .mocktest-rp__question-palette .box-ads h1 {
        font-size: 0.8rem !important;
        margin-bottom: 2px !important;
    }

    .question-palette__footer-time-container .time-container {
        font-size: 0.6rem !important;
        margin-top: 7px !important;
    }

    .question-palette__footer-time-container .time-container h3 {
        font-size: 1.1rem !important;
        right: -250% !important;
    }

    .mocktest-rp__question-palette .display-question-up {
        margin-top: 1px;
        font-size: 0.8rem;
        /* top:24% !important; */
    }

    .palette_up {
        height: 50vh !important;
        transition: height 0.4s ease-in;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .mock-test__right-page .container {
        width: 100%;
    }
}

@media (max-width: 1025px) {
    .mock-test__left-page {
        width: 70% !important;
    }

    .mock-test__left-page h2 {
        color: #283360;
        font-size: 1.4rem;
    }
}

@media (max-width: 768px) {
    .mock-test__left-page {
        width: 100% !important;
    }

    .mock-test__left-page h2 {
        color: #283360;
    }
    // .list-question span {
    //     font-size: 12px !important;
    // }
}

.mocktest-rp__question-palette h1 {
    color: #fff !important;
    font-size: 1.5rem;
}

.mock-test__left-page {
    width: 75%;
}

.mock-test__left-page h2 {
    color: #283360;
}

.box-ads .question-panel {
    float: none;
    height: auto;
}

.question-palette-items {
    overflow: hidden;
    outline: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.question-palette-item {
    margin: 10px 6px 0 7px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 50%;
    text-align: center;
    float: left;
    text-decoration: none;
    @media screen and (max-width: 1434px) {
        @media screen and(max-height: 750px) {
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
        }
        width: 23px;
        height: 23px;
        line-height: 23px;
        font-size: 12px;
    }
    @media screen and (max-width: 1094px) {
        width: 22px;
        height: 22px;
        line-height: 22px;
        font-size: 10px;
    }
    @media screen and (max-height: 700px) {
        width: 22px;
        height: 22px;
        line-height: 22px;
        font-size: 9px;
        margin: 7px 5px 0 7px;
    }

    @media screen and (max-width: 400px) {
        width: 22px;
        height: 22px;
        line-height: 22px;
        font-size: 10px;
    }
}

.question-palette-items .unanswered {
    background-color: #fff;
    color: #000;
}

.question-palette-items .answered {
    background-color: #89c540;
    color: #fff;
}

/* .question-palette-item:hover {
    color: #000;
} */

.question-bt {
    display: flex;
}

.question-bt span:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    background: #fff;
    left: 0;
    top: 1px;
    border-radius: 50%;
}

.question-bt span:first-child {
    margin-right: 25px;
}

.question-bt span {
    font-size: 12px;
    line-height: 1;
    text-align: center;
    color: #fff;
    padding-left: 24px;
    position: relative;
}

.question-bt span.answered:before {
    background: #f44052;
}

.question-palette__footer-time-container {
    width: 50%;
    float: left;
    text-align: center;
}

.question-palette__footer-time-container > .time-container > h3 {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}

.question-palette__footer-button-container > a {
    width: 80px;
    font-size: 10px;
}

@media (max-width: 1200px) {
    .mocktest-rp__question-palette h1 {
        font-size: 1rem !important;
    }

    .question-bt span {
        font-size: 8px;
        padding-left: 17px;
        line-height: 1;
    }

    .question-palette__footer-time-container > .time-container {
        margin-right: 10px !important;
    }
}

@media (max-width: 800px) {
    .question-bt span {
        display: none;
    }
}

/* Sections part Questions */

.section-caption {
    span {
        font-weight: 700;
        // font-family: "Montserrat", Helvetica, Arial, sans-serif;
        margin-right: auto;
    }
    .section-title {
        font-size: 16px;
        font-weight: 800;
    }
}
.section-collection {
    @include phone {
        padding: 5px;
    }
    padding: 30px;
    padding-bottom: 50px;
}

.question-board > button {
    display: inline-block;
    height: 26px;
    line-height: 25px;
    padding: 0 10px;
    border-radius: 4px;
    border: solid 1px #32b4c8;
    font-size: 12px;
    color: #32b4c8;
    margin-right: 10px;
    background-color: #fff;
    -moz-transition: all ease 0.8s;
    -o-transition: all ease 0.8s;
    -webkit-transition: all ease 0.8s;
    transition: all ease 0.8s;
}

.question-board > button:focus {
    /* outline: 0 !important; */
    box-shadow: none !important;
}

.notepad-draft {
    border-radius: 0.4rem !important;
    background-color: transparent;
    outline: none;
    width: 40%;
    display: none;
    transition: visibility 0s, opacity 0.5s linear;
}

.show-notepad-draft {
    display: block;
}

.sl-item div.number {
    background-color: #283360;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 14px;
    // font-size: 1.1vw;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    line-height: 30px;
    color: #fff;
    float: left;
    overflow: hidden;
    margin-right: 6px;
}
.sl-item div.number-text {
    font-size: 16px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    background-color: #283360;
    color: #fff;
    margin-left: 5px;
    display: inline-block;
    border-radius: 5px;
}

.list-question {
    list-style: none;
    span {
        text-transform: none;
        @media (max-width: 300px) {
            display: block;
        }
        display: inherit;
        margin-left: 1px;
    }
}

.list-question li {
    margin: 10px 0;
    margin-top: 4px;

    display: flex;
    align-items: center;

    border: 1px solid #e2e2e2;
    padding: 15px;
    border-radius: 5px;
    &:hover {
        background-color: #d5e5f8;
    }
    overflow: auto;
}

/* .list-question {
    padding-left: 15px !important;
} */
.mock-test-question-wrapper {
    border: 2px solid #f3f3f3;
    padding: 10px;
    margin: 15px 0px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    font-size: 15px;
}

.list-question .lq-number,
.type_blank .lq-number,
.type_checkbox .lq-number {
    display: inline-block;
    width: 25px;
    min-width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    background-color: #89c540;
    color: #fff;
    border-radius: 50%;
    margin: 0 7px 0 0;
    font-weight: 600;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
}

.my-radio label {
    cursor: pointer;
    display: inline;
    font-weight: bold;
    margin-left: 10px;
}

.my-radio input {
    cursor: pointer;
}

.sl-item {
    margin: 20px 0 0 0;
}

.sl-item ul {
    margin: 0;
    padding: 0;
}

.sl-item p {
    padding: 0 5px;
    font-weight: bold;
    text-align: justify;
    white-space: normal;
    line-height: 1.5;
    display: block;
}

.question-title {
    margin-left: 45px;
    p {
        margin-bottom: 20px;
        word-break: break-word;
        font-weight: 700;
        font-family: "Montserrat", Helvetica, Arial, sans-serif;
        font-size: 15px !important;
    }
}

.number-wrap-inline {
    display: inline-block;
    padding: 0;
}

input.iot-question {
    padding: 0 5px;
    border-radius: 2px;
    border: none;
    border-bottom: 2px solid #aaa;
    margin: 0 5px;
    outline: none;
    max-width: 130px;
}

.my-check {
    position: relative;
    display: inline;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    margin-bottom: 0;
    cursor: pointer;
}

.my-check label {
    cursor: pointer;
    display: inline;
    font-weight: normal;
}

@media (max-width: 1025px) {
    // .list-question span {
    //     font-size: 10px !important;
    // }

    .notepad textarea {
        width: 80%;
        height: 20%;
    }

    .mock-test__audioplayer {
        width: 70% !important;
    }
}

/* Section part Question ends */

/* Reading Mock test css */

.reading-paragraph,
.exam-side,
.exam-paragraph {
    overflow-y: scroll;
    padding-bottom: 40px;
    height: 88vh;
    @media (max-height: 815px) {
        height: 81vh;
    }
}

.reading-paragraph {
    background-color: #f3f2ef !important;
}

.reading-paragraph::-webkit-scrollbar,
.exam-side::-webkit-scrollbar,
.exam-paragraph::-webkit-scrollbar {
    display: block;
    width: 10px;
    cursor: pointer;
}

/* Track */

.reading-paragraph::-webkit-scrollbar-track,
.reading-paragraph::-webkit-scrollbar,
.exam-paragraph::-webkit-scrollbar {
    background: #f1f1f1;
}

/* Handle */

.reading-paragraph::-webkit-scrollbar-thumb,
.exam-side::-webkit-scrollbar-thumb,
.exam-paragraph::-webkit-scrollbar-thumb {
    position: relative;
    top: 0px;
    /* float: right; */
    /* width: 6px; */
    height: auto;
    background-color: #283360;
    // border: 1px solid rgb(255, 255, 255);
    /* background-clip: padding-box; */
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    cursor: pointer;
}

@media (max-width: 767px) {
    .reading-paragraph {
        height: 40vh;
    }

    .exam-side {
        height: 50vh;
    }
}

.reading-paragraph h1 {
    color: #283360;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin: 0 0 14px 0;
}

.exam-side .section-collection {
    margin-top: -24px !important;
}

.exam-side span {
    // color: #283360;
    font-weight: 700;
    line-height: normal;
}

.reading-paragraph p {
    margin: 20px 0 0 0;
    color: #3c4858;
    line-height: 1.6;
}

.reading-paragraph .section-image {
    text-align: center;
}

.reading-paragraph .subtitle {
    font-size: 26px;
    line-height: 1.2;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    margin-top: 10px !important;
}

.passage-content ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.passage-content li {
    margin-top: 0;
    margin-bottom: 1rem;
}

.reading-notepad-draft {
    width: 70% !important;
}

.mock-test__bottom-palette {
    height: 5vh;
    left: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    color: white;
    /* top:none !important; */
    text-align: center;
    z-index: 10 !important;
    border-radius: 0 !important;
    background: #283360;
    position: fixed;
    margin-top: 3%;
    transition: height 0.4s ease-out;
}

// .palette_up {
//     height: 35vh !important;
//     transition: height 0.4s ease-in;
// }

.display-question-up:hover {
    cursor: pointer;
}

.mock-test__bottom-palette .box-ads {
    display: flex !important;
}

.mock-test__bottom-palette .box-ads h1 {
    color: #fff !important;
    font-size: 1.5rem !important;
    position: absolute;
    top: 0 !important;
    text-align: center !important;
}

.question-palette__footer-time-container .time-container {
    position: absolute;
    left: 50% !important;
    top: 0 !important;
    font-size: 0.8rem !important;
    margin-top: 5px !important;
}

.question-bt {
    margin-top: 8px !important;
}

.question-palette__footer-time-container .time-container h3 {
    position: absolute;
    right: -290% !important;
    top: 0 !important;
    font-size: 1.7rem !important;
    margin-top: -2px !important;
}

.mock-test__bottom-palette .display-question-up {
    display: block;
    position: absolute;
    right: 24px !important;
    margin-top: 4px;
    /* top:24% !important; */
}

.question-palette__footer-button-container {
    margin-right: 10px !important;
    button {
        font-size: 1rem;
    }
}

.question-palette__footer-button-container a {
    margin: 10px;
    margin-top: -10px !important;
}

@media screen and (max-width: 1025px) {
    .mock-test__bottom-palette {
        height: 4vh !important;
        transition: height 0.4s ease-out;
    }

    .palette_up {
        @media (max-height: 700px) {
            height: 38vh !important;
        }
        height: 32vh !important;
        transition: height 0.4s ease-in;
    }
}
@media screen and (max-width: 766px) {
    .mock-test__bottom-palette {
        height: 6vh !important;
        transition: height 0.4s ease-out;
    }

    .mock-test__bottom-palette .box-ads h1 {
        font-size: 1rem !important;
    }

    .question-palette__footer-time-container .time-container {
        font-size: 0.8rem !important;
        margin-top: 8px !important;
    }

    .question-palette__footer-time-container .time-container h3 {
        font-size: 1.3rem !important;
        right: -220% !important;
    }

    .palette_up {
        height: 65vh !important;
        transition: height 0.4s ease-in;
    }

    .reading-notepad-draft {
        width: 90% !important;
    }
}

@media screen and (max-width: 400px) {
    .mock-test__bottom-palette .box-ads h1 {
        font-size: 0.8rem !important;
        margin-bottom: 2px !important;
    }

    .question-palette__footer-time-container .time-container {
        font-size: 0.6rem !important;
        margin-top: 7px !important;
    }

    .question-palette__footer-time-container .time-container h3 {
        font-size: 1.1rem !important;
        right: -250% !important;
    }

    .mock-test__bottom-palette .display-question-up {
        margin-top: 1px;
        font-size: 0.8rem;
        /* top:24% !important; */
    }

    .palette_up {
        height: 85vh !important;
        transition: height 0.4s ease-in;
    }
}

/* Reading Mock test css ends */

/* Writing Mock test css starts */

.reading-paragraph .task-item__description-box {
    padding: 20px 40px;
    position: relative;
}

.reading-paragraph .task-item__title {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 28px;
}

.reading-paragraph .task-item__description-box p {
    font-size: 14px;
    color: #3c4858 !important;
}

.reading-paragraph .task-item__description-box p {
    margin: 0 0 10px !important;
    /* line-height: 0 !important; */
}

.reading-paragraph .task-item__description-box strong {
    white-space: normal !important;
    /* line-height: 0 !important; */
}

.reading-paragraph .task-item__description-box strong {
    font-weight: 700;
}

.reading-paragraph .task-item__img-wrap {
    text-align: center;
    margin: 40px 0;
}

.task-item__answer-box {
    background-color: transparent;
    padding: 40px 60px;
    overflow-y: scroll;
    height: 100% !important;
}

.task-item__answer-box .task-item__caption {
    font-size: 20px;
    font-weight: bold;
    color: #3c4858;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    margin: 10px 0;
}

.exam-paragraph .task-item__time-note {
    font-size: 16px;
    margin-bottom: 30px;
    color: #3c4858;
}

.exam-paragraph .task-item__answer-wrapper textarea {
    height: 100%;
    box-shadow: none;
    padding: 20px 25px;
    font-size: 16px;
    color: #282828 !important;
    border-radius: 0;
    resize: none;
}

.task-item__answer:focus {
    outline: #282828 auto 1px !important;
}

.task-item__words-count {
    margin: 20px 0;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: #284664;
}

@media (max-width: 1023px) {
    .task-item__answer-box {
        padding: 40px 20px;
    }

    .task-item__answer-wrapper {
        margin-right: 60px;
    }
}

@media (max-width: 767px) {
    .reading-paragraph {
        height: 40vh;
    }

    .exam-paragraph {
        padding: 20px 16px;
        height: 50vh;
    }

    .task-item__answer {
        height: 20vh !important;
    }

    .task-item__answer-wrapper {
        margin-right: 40px;
    }
}

/* Writing Mock test css starts */

/* Speaking Mock test css starts */

.speaking-test-container {
    max-width: 1056px !important;
    margin: 0 auto !important;
    text-align: center !important;
}

.mic-box__contents {
    min-height: 470px;
    border-radius: 8px;
    border: solid 1px #d4dae0;
    background-color: #f3f3f3;
    margin-bottom: 40px;
    margin-top: 40px;
    position: relative;
}

.mic-box__part-title {
    font-size: 24px;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #89c540;
    line-height: 60px;
    height: 60px;
    padding: 0 146px;
}

.mic-box__part-num {
    line-height: 60px;
    height: 60px;
    width: 146px;
    background-color: #283360;
    position: absolute;
    left: 0;
    top: 0;
    border-top-left-radius: 8px;
}

.mic-box__part-name {
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
}

.mic-box__question {
    font-family: "Nunito", Helvetica, Arial, sans-serif;
    font-size: 28px;
    font-weight: 800;
    text-align: center;
    color: #283360;
    padding: 0 15px;
    max-width: 778px;
    margin: 0 auto;
    line-height: 1.36;
}

.mic-box__question-number {
    font-weight: bold;
    color: #283360;
    text-transform: uppercase;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
}

.mic-box__question-title {
    padding: 0 10px;
    color: #3c4858 !important;
}

.mic-box__question-title p:last-child {
    margin-bottom: 0;
}

.mic-box__contents.-question .mic-box__icon-wrap {
    margin-top: 40px;
}

.hide,
.hide_intro-section {
    display: none !important;
}

.show-speaking-question {
    display: block !important;
}

.speaking-test .mic-box__test-content {
    margin: 80px 0 0;
}

.speaking-test .mic-box__test-content {
    margin: 80px 0 0;
}

.mic-box__instruction-title {
    margin-top: 100px;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: #283360;
}

.mic-box__instruction-desc {
    color: #3c4858;
    font-size: 20px;
    max-width: 407px;
    margin: 0 auto;
    font-weight: bold;
}

.mic-box__instruction-desc p {
    margin: 0 0 10px;
}

.mic-box__test-content {
    margin: 80px 0 0;
}

.speaking-btn {
    background-color: #89c540;
    margin-bottom: 0;
    width: 100%;
    max-width: 350px;
}

.mic-icon {
    font-size: 40px !important;
}

.mic-box__note {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #282828;
    margin-bottom: 18px;
}

.mic-box__question {
    font-family: "Nunito", Helvetica, Arial, sans-serif;
    font-size: 28px;
    font-weight: 800;
    text-align: center;
    color: #284664;
    padding: 0 15px;
    max-width: 778px;
    margin: 0 auto;
    line-height: 1.36;
}

@media (max-width: 767px) {
    .mic-box__contents {
        min-height: 391px;
        border-radius: 10px;
        padding: 0 15px;
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .mic-box__part-title {
        height: 40px;
        line-height: 40px;
        padding: 0 0 0 80px;
        display: flex;
        align-items: center;
    }

    .mic-box__part-num {
        font-size: 16px;
        text-transform: capitalize;
        width: 80px;
        height: 40px;
        line-height: 40px;
    }

    .mic-box__part-name {
        font-size: 12px;
        letter-spacing: normal;
        width: 100%;
    }
}

/* Speaking Mock test css ends */

.disable-content {
    background-color: #fff;
    opacity: 0.04;
    z-index: 2;
    width: 70%;
    pointer-events: none;
}

.width-100 {
    width: 100% !important;
}

.lock-content svg {
    font-size: 10rem;
    white-space: nowrap;
    color: #89c540;
    position: fixed;
    z-index: 5;
    padding: 10px;
    top: 30%;
    left: 46%;
}

@media only screen and (max-width: 767px) {
    .lock-content svg {
        left: 32%;
    }
}

.lock-text {
    white-space: nowrap;
    position: fixed;
    padding: 10px;
    // background-color: rgba(0, 0, 0, 0.7);
    top: 30%;
    left: 50%;
    z-index: 5;
    // color: #fff;
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    -webkit-transform: translate3d(0, 163px, 0) translateX(-50%);
    transform: translate3d(0, 163px, 0) translateX(-50%);
    transition: all 0.55s cubic-bezier(0.32, -0.08, 0.42, 1.22);
    .play-btn,
    .play-btn:hover {
        color: #89c540 !important;
    }
}

.lock-text-1 {
    @extend .lock-text;
    top: 33%;
}
.lock-text-2 {
    @extend .lock-text;
    @media screen and (max-width: 1400px) {
        top: 38%;
    }
    @include phone {
        top: 36%;
    }
    top: 36%;
}

.listening-audio-player {
    background-color: #89c540 !important;
    border-radius: 8px;
    padding: 18px !important;
}

.listening-audio-player button {
    color: #ffffff !important;
}

.listening-audio-player .rhap_progress-bar {
    background: #fff !important;
}

.listening-audio-player .rhap_current-time {
    color: #fff !important;
}

.listening-audio-player .rhap_total-time {
    color: #fff !important;
}

.listening-audio-player .rhap_progress-filled,
.listening-audio-player .rhap_progress-indicator,
.listening-audio-player .rhap_volume-indicator,
.listening-audio-player .rhap_volume-bar {
    background: #ffffff;
}

.listening-audio-player .rhap_download-progress {
    background: #f0f2f5 !important;
}

/* Mock test submit loader */

:root {
    --hue: 223;
    --bg: hsl(var(--hue), 10%, 90%);
    --fg: hsl(var(--hue), 10%, 10%);
    --primary: hsl(var(--hue), 90%, 55%);
    --primary-l: hsl(var(--hue), 90%, 65%);
    --primary-d: hsl(var(--hue), 90%, 45%);
    --white: hsl(var(--hue), 10%, 100%);
    --white-d: hsl(var(--hue), 10%, 45%);
}

.book,
.book__pg-shadow,
.book__pg {
    animation: cover 7s ease-in-out infinite;
}

.book {
    background-color: var(--primary-l);
    border-radius: 0.25em;
    box-shadow: 0 0.25em 0.5em hsla(0, 0%, 0%, 0.3), 0 0 0 0.25em var(--primary) inset;
    padding: 0.25em;
    perspective: 37.5em;
    position: relative;
    width: 8em;
    height: 6em;
    transform: translate3d(0, 0, 0);
    transform-style: preserve-3d;
    white-space: nowrap;
    position: fixed;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    top: 35%;
    left: 40%;
    z-index: 5;
    font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
}

.submit-text {
    white-space: nowrap;
    position: fixed;
    padding: 10px;
    top: 55%;
    left: 37%;
    z-index: 5;
    font-size: calc(16px + (24 - 16) * (50vw - 320px) / (1280 - 20));
    color: #89c540;
}

.book__pg-shadow,
.book__pg {
    position: absolute;
    left: 0.25em;
    width: calc(50% - 0.25em);
}

.book__pg-shadow {
    animation-name: shadow;
    background-image: linear-gradient(-45deg, hsla(0, 0%, 0%, 0) 50%, hsla(0, 0%, 0%, 0.3) 50%);
    filter: blur(0.25em);
    top: calc(100% - 0.25em);
    height: 3.75em;
    transform: scaleY(0);
    transform-origin: 100% 0%;
}

.book__pg {
    animation-name: pg1;
    background-color: var(--white);
    background-image: linear-gradient(90deg, hsla(var(--hue), 10%, 90%, 0) 87.5%, hsl(var(--hue), 10%, 90%));
    height: calc(100% - 0.5em);
    transform-origin: 100% 50%;
}

.book__pg--2,
.book__pg--3,
.book__pg--4 {
    background-image: repeating-linear-gradient(
            hsl(var(--hue), 10%, 10%) 0 0.125em,
            hsla(var(--hue), 10%, 10%, 0) 0.125em 0.5em
        ),
        linear-gradient(90deg, hsla(var(--hue), 10%, 90%, 0) 87.5%, hsl(var(--hue), 10%, 90%));
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2.5em 4.125em, 100% 100%;
}

.book__pg--2 {
    animation-name: pg2;
}

.book__pg--3 {
    animation-name: pg3;
}

.book__pg--4 {
    animation-name: pg4;
}

.book__pg--5 {
    animation-name: pg5;
}

/* Dark theme */

@media (prefers-color-scheme: dark) {
    :root {
        --bg: hsl(var(--hue), 10%, 30%);
        --fg: hsl(var(--hue), 10%, 90%);
    }
}

/* Animations */

@keyframes cover {
    from,
    5%,
    45%,
    55%,
    95%,
    to {
        animation-timing-function: ease-out;
        background-color: var(--primary-l);
    }

    10%,
    40%,
    60%,
    90% {
        animation-timing-function: ease-in;
        background-color: var(--primary-d);
    }
}

@keyframes shadow {
    from,
    10.01%,
    20.01%,
    30.01%,
    40.01% {
        animation-timing-function: ease-in;
        transform: translate3d(0, 0, 1px) scaleY(0) rotateY(0);
    }

    5%,
    15%,
    25%,
    35%,
    45%,
    55%,
    65%,
    75%,
    85%,
    95% {
        animation-timing-function: ease-out;
        transform: translate3d(0, 0, 1px) scaleY(0.2) rotateY(90deg);
    }

    10%,
    20%,
    30%,
    40%,
    50%,
    to {
        animation-timing-function: ease-out;
        transform: translate3d(0, 0, 1px) scaleY(0) rotateY(180deg);
    }

    50.01%,
    60.01%,
    70.01%,
    80.01%,
    90.01% {
        animation-timing-function: ease-in;
        transform: translate3d(0, 0, 1px) scaleY(0) rotateY(180deg);
    }

    60%,
    70%,
    80%,
    90%,
    to {
        animation-timing-function: ease-out;
        transform: translate3d(0, 0, 1px) scaleY(0) rotateY(0);
    }
}

@keyframes pg1 {
    from,
    to {
        animation-timing-function: ease-in-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(0.4deg);
    }

    10%,
    15% {
        animation-timing-function: ease-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(180deg);
    }

    20%,
    80% {
        animation-timing-function: ease-in;
        background-color: var(--white-d);
        transform: translate3d(0, 0, 1px) rotateY(180deg);
    }

    85%,
    90% {
        animation-timing-function: ease-in-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(180deg);
    }
}

@keyframes pg2 {
    from,
    to {
        animation-timing-function: ease-in;
        background-color: var(--white-d);
        transform: translate3d(0, 0, 1px) rotateY(0.3deg);
    }

    5%,
    10% {
        animation-timing-function: ease-in-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(0.3deg);
    }

    20%,
    25% {
        animation-timing-function: ease-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(179.9deg);
    }

    30%,
    70% {
        animation-timing-function: ease-in;
        background-color: var(--white-d);
        transform: translate3d(0, 0, 1px) rotateY(179.9deg);
    }

    75%,
    80% {
        animation-timing-function: ease-in-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(179.9deg);
    }

    90%,
    95% {
        animation-timing-function: ease-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(0.3deg);
    }
}

@keyframes pg3 {
    from,
    10%,
    90%,
    to {
        animation-timing-function: ease-in;
        background-color: var(--white-d);
        transform: translate3d(0, 0, 1px) rotateY(0.2deg);
    }

    15%,
    20% {
        animation-timing-function: ease-in-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(0.2deg);
    }

    30%,
    35% {
        animation-timing-function: ease-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(179.8deg);
    }

    40%,
    60% {
        animation-timing-function: ease-in;
        background-color: var(--white-d);
        transform: translate3d(0, 0, 1px) rotateY(179.8deg);
    }

    65%,
    70% {
        animation-timing-function: ease-in-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(179.8deg);
    }

    80%,
    85% {
        animation-timing-function: ease-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(0.2deg);
    }
}

@keyframes pg4 {
    from,
    20%,
    80%,
    to {
        animation-timing-function: ease-in;
        background-color: var(--white-d);
        transform: translate3d(0, 0, 1px) rotateY(0.1deg);
    }

    25%,
    30% {
        animation-timing-function: ease-in-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(0.1deg);
    }

    40%,
    45% {
        animation-timing-function: ease-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(179.7deg);
    }

    50% {
        animation-timing-function: ease-in;
        background-color: var(--white-d);
        transform: translate3d(0, 0, 1px) rotateY(179.7deg);
    }

    55%,
    60% {
        animation-timing-function: ease-in-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(179.7deg);
    }

    70%,
    75% {
        animation-timing-function: ease-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(0.1deg);
    }
}

@keyframes pg5 {
    from,
    30%,
    70%,
    to {
        animation-timing-function: ease-in;
        background-color: var(--white-d);
        transform: translate3d(0, 0, 1px) rotateY(0);
    }

    35%,
    40% {
        animation-timing-function: ease-in-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(0deg);
    }

    50% {
        animation-timing-function: ease-in-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(179.6deg);
    }

    60%,
    65% {
        animation-timing-function: ease-out;
        background-color: var(--white);
        transform: translate3d(0, 0, 1px) rotateY(0);
    }
}

/* Mock Test Report */

.test-hero {
    position: relative;
    margin-top: 13px;
}

.clearfix {
    display: block;
    clear: both;
}

.test-hero .book-img-wrap {
    float: left;
    margin-right: 15px;
    margin-top: 6px;
}

.book-img-wrap {
    position: relative;
    display: inline-block;
}

.test-hero img {
    width: 145px;
    border: 4px solid #f0f2f5;
    border-radius: 8px;
}

.test-hero h1.test-caption {
    color: #284664;
}

.test-caption {
    color: #284664;
    margin: 0px;
    padding: 0px;
}

.test-hero .test-des {
    overflow: hidden;
}

.test-des p {
    margin: 5px 0px 0px 0px;
    padding: 0px;
    color: #787878;
    font-size: 12px;
}

.user-box {
    margin: 20px 0px 0px 0px;
    background-color: #f8f8f8;
}

.user-box .user-avt .ant-avatar-string {
    font-size: 40px;
}

.us-user {
    text-align: center;
}

.user-score {
    text-align: center;
}

.test-hero .test-hero-icon {
    position: absolute;
    width: 160px !important;
    bottom: 0px;
    right: 30px;
    text-align: center;
}

.box-caption-margin {
    margin-top: 70px;
}

.box-caption p {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    line-height: 55px;
    color: #284664;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    margin: 0;
}

.box-caption .bc-right {
    float: right;
}

.result {
    .test-hero-icon span {
        background: url(https://ieltsonlinetests.com/themes/iot/images/icon_listen.png) no-repeat center center;
    }
    .list-answer {
        padding: 0px;
        margin: 0px;

        li {
            margin: 20px 0px 0px 0px;
            padding: 0px 0px 0px 40px;
            list-style: none;
            float: none;
            list-style: none;

            span.number {
                width: 30px;
                height: 30px;
                margin: -3px 0px 0px -40px;
                float: left;
                text-align: center;
                line-height: 30px;
                font-size: 14px;
                color: #fff;
                font-weight: 700;
                font-family: "Montserrat", Helvetica, Arial, sans-serif;
                background-color: #f44052;
                border-radius: 50%;
            }

            span.sys-answer {
                color: #284664;
                font-size: 16px;
            }

            em.false {
                background: url(https://ieltsonlinetests.com/themes/iot/images/icon_false.png) no-repeat center center !important;
                height: 10px !important;
                width: 14px !important;
                display: inline-block !important;
                margin: 0px 0px 0px 5px !important;
            }
        }
    }
}

.box-caption .bc-right a {
    display: inline-block;
    line-height: 25px;
    padding: 0px 20px 0px 20px;
    background-color: #f5f5f5;
    border-radius: 20px;
    font-size: 12px;
    color: #787878;
    -moz-transition: all ease 0.2s;
    -o-transition: all ease 0.2s;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s;
}

/* Writing Submit Modal */

#success_tic .page-body {
    max-width: 300px;
    background-color: #ffffff;
    margin: 10% auto;
}

#success_tic .page-body .head {
    text-align: center;
}

/* #success_tic .tic{
  font-size:186px;
} */
#success_tic .close {
    opacity: 1;
    position: absolute;
    right: 0px;
    font-size: 30px;
    padding: 3px 15px;
    margin-bottom: 10px;
}

#success_tic .checkmark-circle {
    width: 150px;
    height: 150px;
    position: relative;
    display: inline-block;
    vertical-align: top;
}

.checkmark-circle .background {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #283360;
    position: absolute;
}

#success_tic .checkmark-circle .checkmark {
    border-radius: 5px;
}

#success_tic .checkmark-circle .checkmark.draw:after {
    -webkit-animation-delay: 300ms;
    -moz-animation-delay: 300ms;
    animation-delay: 300ms;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease;
    -moz-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-name: checkmark;
    -moz-animation-name: checkmark;
    animation-name: checkmark;
    -webkit-transform: scaleX(-1) rotate(135deg);
    -moz-transform: scaleX(-1) rotate(135deg);
    -ms-transform: scaleX(-1) rotate(135deg);
    -o-transform: scaleX(-1) rotate(135deg);
    transform: scaleX(-1) rotate(135deg);
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

#success_tic .checkmark-circle .checkmark:after {
    opacity: 1;
    height: 75px;
    width: 37.5px;
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
    border-right: 15px solid #fff;
    border-top: 15px solid #fff;
    border-radius: 2.5px !important;
    content: "";
    left: 35px;
    top: 80px;
    position: absolute;
}

@-webkit-keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }

    20% {
        height: 0;
        width: 37.5px;
        opacity: 1;
    }

    40% {
        height: 75px;
        width: 37.5px;
        opacity: 1;
    }

    100% {
        height: 75px;
        width: 37.5px;
        opacity: 1;
    }
}

@-moz-keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }

    20% {
        height: 0;
        width: 37.5px;
        opacity: 1;
    }

    40% {
        height: 75px;
        width: 37.5px;
        opacity: 1;
    }

    100% {
        height: 75px;
        width: 37.5px;
        opacity: 1;
    }
}

@keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }

    20% {
        height: 0;
        width: 37.5px;
        opacity: 1;
    }

    40% {
        height: 75px;
        width: 37.5px;
        opacity: 1;
    }

    100% {
        height: 75px;
        width: 37.5px;
        opacity: 1;
    }
}

.text-match-question .col {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.puzzle-item {
    height: 30px;
    width: 20px;
    border: 2px solid red;
    position: absolute;
    top: 50%;
    left: -19px;
    margin-top: -17px;
    border-radius: 20px/25px;
    border: 2px solid #b7b7b7;
    background-color: #fff;
}

.empty-content {
    top: 50%;
    position: absolute;
    right: 0px;
    background-color: #fff;
}

.puzzle-item:after {
    content: "";
    width: 5px;
    height: 19px;
    background: #fff;
    position: absolute;
    right: -2px;
    top: 3px;
}

/* Speaking Mock test css ends */

// .mock-test__reading-component {
//     overflow: hidden !important;
//     &::-webkit-scrollbar {
//         display: none !important;
//     }
// }

.exam-side {
    position: relative;
    // overflow-x: hidden !important;
    overflow-y: scroll;
}
.navigation-controller {
    button {
        &:hover {
            color: #fff !important;
            background-color: #89c540 !important;
        }
    }
}

.instruction-container {
    margin-top: 100px;
    .instruction-item {
        span {
            font-size: 14px;
            color: #282828;
            position: relative;
            padding-left: 53px;
        }
    }
}

.mock-test-submit-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    @include phone {
        bottom: 50px;
    }
    @media (max-width: 990px) {
        bottom: 50px;
    }
    @media (min-width: 990px) and (max-width: 1100px) {
        bottom: 130px;
    }
    @media (min-width: 600px) and (max-width: 770px) {
        bottom: 130px;
    }
}

.question-margin {
    margin-top: 7%;
}

.gradient-background {
    background-image: linear-gradient(95.2deg, rgb(220 220 220 / 46%) 26.8%, rgb(250 250 250) 64%);
    padding: 1rem;
}

.mock-test-collapse {
    .ant-collapse {
        background-color: #fff !important;
        .ant-collapse-content {
            border-top: none !important;
        }
        .ant-collapse-item {
            margin-top: 10px;
            border-bottom: none;
        }
        .ant-collapse-expand-icon {
            svg {
                position: absolute;
                top: 50%;
            }
        }
    }
}

.katex-display > .katex {
    width: 100%;
}
.katex-display > .katex > .katex-html {
    text-align: left !important;
}
.katex-display > .katex {
    white-space: normal;
}
/* Add space between broken lines: */
.katex-display > .base {
    margin: 0.25em 0;
}
/* Compensate by reducing space around display math */
.katex-display {
    margin: 0px !important;
    font-size: 13px !important;
}

.katex .base {
    white-space: normal !important;
    width: initial !important;
}
.list-question {
    .katex .base {
        white-space: nowrap !important;
        width: initial !important;
    }
}
.mocktest-answer-block {
    .output {
        display: inline-flex;
    }
}

.katex .katex-html {
    @media (min-width: 821px) {
        display: inline !important;
    }
}

//not equal sign fix

.rlap .inner .mrel {
    visibility: hidden;
}
.rlap .inner .mrel:after {
    content: "/";
    visibility: visible;
    font-family: "Times New Roman", Times, serif;
}
//end
