.ant-menu-submenu-title {
  &:hover {
    color: #283360 !important;
  }
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #f4f4f4;
  position: relative;
  padding: 24px;
  margin: 16px;
}

.sider-logo {
  color: #fff;
  padding: 25px;
  font-size: 20px;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 20px;
}

.ant-layout-sider {
  flex: 0 0 227px !important;
  width: 227px !important;
  // background: #0e3863;
  background: #fff;
  min-width: 227px !important;
  max-width: 227px !important;
}

.ant-layout.ant-layout-has-sider {
  height: 100vh;
}

.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  background: #fff;
}

.ant-layout-header {
  margin: 10px 16px 0px 14px;
  border-radius: 8px;
}

.dashboard-sidenav {
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu:not(.ant-menu-vertical) .ant-menu-item-selected {
    background-color: #283360 !important;
    color: white;
  }
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: #eef1f3 !important;
  }
  .main-menu-selected {
    font-weight: 800;
  }
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover,
.ant-menu-item a:hover {
  color: #283360 !important;

  .ant-menu-light .ant-menu-submenu-active {
    color: #283360;
  }
}

.ant-layout {
  overflow: auto;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid $secondary;
}

.ant-layout-content {
  min-height: auto;
  margin: initial;
  background: #f4f4f4;
}

.ant-layout-sider-trigger {
  background: #283360;
  width: 227px !important;
}

.ant-menu-item > a {
  background: transparent !important;
  text-decoration: none !important;
}
.ant-menu-item > span > a {
  text-decoration: none !important;
}

.banner-button {
  position: absolute;
  font-size: 1em;
  background: #fff;
  padding: 10px 30px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  margin-top: 5px;
  color: #111;
  transition: 0.6s;
  z-index: 20 !important;
  left: 46% !important;
}
.our-courses {
  left: 43% !important;
}
.banner-button:hover {
  transform: scale(0.96);
}

.submenu-active {
  color: #283360;
}
#navbarSupportedContent li {
  float: left;
  display: block;
  position: relative;
  margin: 0 10px;
}

#navbarSupportedContent li a {
  font-size: 16px;
}

//reactstrap navbar
.nav-vertical {
  overflow: hidden;
  .nav.nav-tabs {
    // nav item styles for vertical nav tabs
    .nav-item {
      .nav-link {
        border: none;
        margin-bottom: 0;

        &.active {
          border: none;
          border-radius: 0;

          &:after {
            transform: rotate(90deg);
            left: -1rem;
            bottom: 100%;
            top: 1rem;
            width: 2.14rem;
          }
        }

        &:hover {
          border-right: none;
        }
      }
    }

    // vertical nav left tabs
    &.nav-left {
      float: left;
      display: table;
      margin-right: 1rem;
      ~ .tab-content {
        .tab-pane {
          display: none;
          background-color: white;
          overflow-y: auto;
          padding-left: 1rem;
          &.active {
            display: block;
          }
        }
      }
    }

    // vertical right nav tabs
    &.nav-right {
      float: right;
      display: table;
      margin-left: 1rem;

      .nav-item {
        .nav-link {
          &.active {
            &:after {
              left: 2.6rem;
            }
          }
        }
      }

      ~ .tab-content {
        .tab-pane {
          display: none;
          background-color: white;
          overflow-y: auto;
          padding-right: 1rem;
          &.active {
            display: block;
          }
        }
      }
    }
  }
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #283360;
}

#navbar {
  z-index: 1002 !important;
}

.ant-layout-sider-collapsed{
  flex: 0 0 99px !important;
  min-width: 99px !important;

  .ant-layout-sider-trigger{
    width: 99px !important
  }
}
