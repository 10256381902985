#ambassador-banner{
    background-image: url('/images/ambassador/bg.webp');
    padding: 50px 0px;
    .ambs-title{
        font-size: 3.875rem;
        line-height: 1.125;
        font-weight: 700;

         @media (min-width: 767px) and (max-width: 1400px){
            font-size: 3rem;
         }
         @media (max-width: 767px) {
            font-size: 1.8rem;
         }
    }

    .ambs-subtitle{
        font-size: 2rem;
         @media (max-width: 767px) {
            font-size: 1rem;
         }
    }

    .ambs-btn{
        font-size: 1.2rem;
        height: auto !important;
        padding: 1rem !important;
        letter-spacing: 3px;
         @media (max-width: 767px) {
            font-size: 1rem;
             padding: 0.5rem !important;
         }
    }
}

.ambassador-form{
    padding: 50px 0px;
    
    @media (max-width: 767px) {
    padding: 10px 0px;
    }
    background-color: #EFF2F7;

    .ant-select-dropdown{
        left: 0 !important;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-search{
        left: 0px !important
    }
}

#ambassador-perks{
    padding: 100px 0px;

    h1{
        color: $color-black;
    }
    .vertical-shadow{
        width: 2px;
        height: auto;
    }
    .vertical-shadow-left{
        @extend .vertical-shadow;
        box-shadow: -6px 0px 4px rgba(0, 0, 0, 0.5); 
    }
    .vertical-shadow-right{
        @extend .vertical-shadow;
        box-shadow: 6px 0px 4px rgba(0, 0, 0, 0.5); 
    }
    .perk-item .perk-desc{
        font-size: 1rem;
        font-weight: 600;
    }

    .icon-col{
        min-width: 280px; 
        box-shadow: 25px 0px 20px -20px rgba(0,0,0,0.45),
        -25px 0px 20px -20px rgba(0,0,0,0.45);
    }
    .perks-col{
        margin: 0px 2rem;
    }
 
    @media (max-width: 767px) {
      padding: 10px 0px;
    }

    @media (min-width: 767px) and (max-width: 1400px){
        .perk-icon{
            margin-bottom: 10px;
        }
        .perks-col{
            margin: 0px 1rem;
        }
    }
}