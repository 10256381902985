.landing-wrapper{
   display: flex;
    align-items: center;
    position: relative;
    height: 50px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
    overflow: hidden;
    cursor: default;
    padding: 2rem;
    width: 100%;
    background: #fff;
    .landing-icon{
        // background-image: url(../landing/images/saas-image/monitor.png);
        // background-position: -64px -57px;
        width: 28px;
        height: 14px;
    }
    .landing-title{
         flex: 0 0 auto;
         margin-left: 16px;
         font-weight: 600 ;
         color: #42515f;
    }
    .landing-subtitle{
        flex: 1 1 auto;
        margin-left: 4px;
        font-size: 12px ;
        color: #73899e;
    }
}


.landing-wrapper:hover{
         .landing-actions-wrapper{
             display: block;
         }
         .landing-actions-ellipsis{
             display:none;
        }
}

.landing-actions-wrapper {
    display: none;
}
.landing-actions-ellipsis {
    display: block;
}

.landing-section-title{
    font-weight: 700;
    color: #9caab7;
}