$phone-width: 600px;
$tablet-width: 768px;
$desktop-width: 992px;

$breakpoints: (
  phone : 0px, 
  tablet : 680px, 
  desktop: 960px
);

@mixin laptop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: #{$phone-width}) {
    @content;
  }
}

@mixin phone-to-tablet {
  @media (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin media($keys...){
  @each $key in $keys { 
    @media (min-width: map-get($breakpoints, $key)){
      @content
    } 
  }
}