.add-button{
    background: #283360;
    color: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
    -webkit-box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
    height: 37px;
    padding: 0 24px;
    font-weight: 600;
}

.role-close-button {
    float: right;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    box-shadow: none;
    border: 2px solid #1759A0;
    background-color: #1759A0;
    color: white;
    }

 .ant-btn-primary[disabled] {
    background:#f5f5f5 !important ;
 }

.ant-btn-primary{
  border-color: #283360;
  background: #283360 !important;

  &:hover{
    background: #fff !important;
    color:#283360;
    border-color: #283360;
  }
  &:focus{
    border-color: #283360;
  }
}
.ant-btn:hover{
  color: #283360;
  border-color: #283360;
}