//color

$color-border1: #c71a23;
$color-green: #283360;
$color: #ffffff;
$black: #000;
$color-balck: #202647;
$color-black: #202647;
$color-text: #6a6c72;
$color-yellow: #6a6c72;
$color-border: #6a6c72;
$primary: #283360;
$color-gr-br: #283360;
$secondary: #89c540;
$white-color: $color;
$secondary-color: $color-border1;
$black-color: $black;
$dark: #3c4858;
$white: #ffffff;

//font

$font-size: 15px;
$transition: 0.5s;

$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-32: 32px;
$font-size-48: 48px;
$font-size-72: 72px;

$font-weight-medium: 500;

$line-spacing: 1.125;

$border-radius-learnsic-sm: 10px;
$border-radius-learnsic-xs: 5px;
